import { useSelector, useDispatch } from "react-redux";
import { AUTH_ACTIONS } from "../Redux/Reducers/authorization";
import { useNavigate } from "react-router-dom";
import { apis, post } from "../Utils/axios";

function useLoginHook() {
	const NAVIGATE = useNavigate();
	const dispatch = useDispatch();
	let authorization = useSelector((state) => state.authorization);
	return {
		...authorization,
		login: (data) => {
			localStorage.removeItem("configuration");
			localStorage.setItem("token", data.token);
			dispatch({
				type: AUTH_ACTIONS.login,
				data: { token: data.token },
			});
			NAVIGATE("/blank");
			window.location.href = "/app/home";
		},
		logout: () => {
			post(apis.terminateSession, {}, (r, e) => {
				localStorage.removeItem("token");
				localStorage.removeItem("configuration");
				window.location.href = "/login";
			});
		},
		getToken: () => {
			return localStorage.getItem("token");
		},
	};
}

export { useLoginHook };
