import React from "react";
import { Img } from "../../../../../../Components";

function Processing({ stay }) {
	if (!stay) {
		return null;
	}

	const { thumbnail, name, address } = stay;

	return (
		<>
			<div className="w-full h-full flex-center">
				<div className="flex flex-col gap-y-8">
					<span className="payment-loader m-auto max-w-xs"></span>
					<div className="text-center">
						<h4>We are confirming your booking</h4>
						<div className="text-label">Please wait while we confirm your booking. This will only take a minute.</div>
					</div>
					<div className="align-center max-w-[18rem] m-auto rounded-md p-3 bg-light">
						<div>
							<Img src={thumbnail} className="w-16 h-16 rounded-full mr-4" />
						</div>
						<div className="text-sm">
							<div>{name}</div>
							<div className="text-label">{address}</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Processing;
