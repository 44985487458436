import React, { useState } from "react";
import { Searchbox, Spinner, Toast, Avatar } from "../index";
import { FiUsers } from "react-icons/fi";
import { apis, get } from "../../Utils/axios";
import { useNavigate } from "react-router-dom";

function QuickSearch() {
	const NAVIGATE = useNavigate();
	const [SearchFocussed, setSearchFocussed] = useState();
	const [AllUsers, setAllUsers] = useState([]);
	const [Loader, setLoader] = useState();

	const onChange = (query) => {
		if (query) {
			fetchUsers(query);
		} else {
			setAllUsers([]);
		}
	};

	const fetchUsers = (query) => {
		setLoader(true);
		get(apis.searchUsers, { search: query, page: 1 }, (r, e) => {
			if (r) {
				setAllUsers(r.users);
				setLoader(false);
			} else if (e) {
				console.log(e);
				Toast.handleError(e);
				setLoader(false);
			}
		});
	};

	return (
		<div className="relative pt-1" onMouseEnter={() => setSearchFocussed(true)} onMouseLeave={() => setSearchFocussed()}>
			<Searchbox
				onChange={onChange}
				onFocus={(bool) => {
					if (bool) {
						setSearchFocussed(bool);
					}
				}}
				cmd="c"
				placeholder="Search co-workers"
			/>
			<div className={`${SearchFocussed ? "h-44" : "h-0"} overflow-hidden transition-all duration-300`}>
				<div className="flex-col h-full text-xs text-strong flex-center">
					{Loader ? (
						<Spinner />
					) : AllUsers.length > 0 ? (
						<div className="w-full h-full mt-2 overflow-hidden border rounded-md">
							{AllUsers.map((user, i) => {
								return (
									<div key={i} onClick={() => NAVIGATE(`/app/chat?userId=${user?.id}`)} className={`border-b p-2 gap-x-2 cursor-pointer align-center hover:bg-light`}>
										<Avatar photo={user.photo} className="w-6 h-6" />
										<div className={`text-sm text-ellipsis`}>{user.fullName}</div>
									</div>
								);
							})}
						</div>
					) : (
						<>
							<FiUsers className="mb-2 text-2xl" />
							<div>Search co-workers</div>
						</>
					)}
				</div>
			</div>
			<div className="absolute left-0 w-full h-16 -top-16" style={{ backgroundImage: "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0.7), rgba(255,255,255,1))" }} />
		</div>
	);
}

export default QuickSearch;
