import { useSelector, useDispatch } from "react-redux";
import { PREF_ACTIONS } from "../Redux/Reducers/preferences";

function useUserPreference() {
	const dispatch = useDispatch();
	let preferences = useSelector((state) => state.preferences);

	return {
		...preferences,
		showSidebar: (bool) => {
			dispatch({
				type: PREF_ACTIONS.sidebar,
				data: { sidebar: bool },
			});
		},
		showSidePane: (bool) => {
			dispatch({
				type: PREF_ACTIONS.sidePane,
				data: { sidePane: bool },
			});
		},
		setFontSize: (size) => {
			dispatch({
				type: PREF_ACTIONS.fontSize,
				data: { fontSize: size },
			});
		},
		reset: () => {
			dispatch({
				type: PREF_ACTIONS.reset,
			});
		},
	};
}

export { useUserPreference };
