import { useSelector, useDispatch } from "react-redux";
import { CONFIG_ACTIONS } from "../Redux/Reducers/configuration";
import { useEffect } from "react";
import { apis, get } from "../Utils/axios";

//Fetching flag to prevent multiple requests
let Fetching = false;

function useUserProfile() {
	const dispatch = useDispatch();

	let config = useSelector((state) => state.configuration);

	useEffect(() => {
		if (!Fetching && config?.profile.loading) {
			fetchProfile();
		}
	}, []);

	const fetchProfile = () => {
		Fetching = true;
		get(apis.getMyProfile, {}, (r, e) => {
			if (r) {
				Fetching = false;
				dispatch({
					type: CONFIG_ACTIONS.profile,
					data: {
						profile: {
							...r,
							loading: false,
						},
					},
				});
			} else if (e) {
				console.log(e);
				Fetching = false;
			}
		});
	};

	return {
		profile: config.profile,
		reload: fetchProfile,
	};
}

export default useUserProfile;
