import React, { useEffect, useState } from "react";
import Processing from "./processing";
import Success from "./success";
import { apis, post } from "../../../../../../Utils/axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Toast } from "../../../../../../Components";
import BookingFailed from "./failed";

function ConfirmBooking() {
	const NAVIGATE = useNavigate();
	const LOCATION = useLocation();
	const { itineraryId, thumbnail, name, address, approvalId, requestId, customFields, costCenterId, guests, paymentMode, razorpayData } = LOCATION.state || {};
	const [Booking, setBooking] = useState();
	const [Failed, setFailed] = useState();

	if (!itineraryId) {
		Toast.error("Retry Booking");
		NAVIGATE(-1);
	}

	useEffect(() => {
		bookFlight();
	}, []);

	const bookFlight = () => {
		setBooking();
		post(apis.bookStay, { itineraryId, requestId, approvalId, customFields, costCenterId, guests, paymentMode, razorpayData }, (r, e) => {
			if (r) {
				setBooking(r);
			} else if (e) {
				setFailed(true);
				Toast.handleError(e);
			}
		});
	};

	if (Booking) return <Success booking={Booking} />;
	if (Failed) return <BookingFailed />;
	return <Processing stay={{ thumbnail, name, address }} />;
}

export default ConfirmBooking;
