import { useEffect, useRef } from "react";
import { apis, get } from "../Utils/axios";
import { useDispatch, useSelector } from "react-redux";

function useAdminPermission() {
	const PERMISSIONS = {
		VIEW_ORGANIZATION: "org",
		VIEW_PAYMENT: "payment",
		EDIT_ORGANIZATION: "editOrg",
		EDIT_USERS: "editUsers",
		EDIT_ADMINS: "editAdmins",
		VIEW_POLICY: "policy",
		EDIT_POLICY: "editPolicy",
	};

	const dispatch = useDispatch();
	//Fetching flag to prevent multiple requests
	const Fetching = useRef(false);

	let permissions = useSelector((state) => state.adminPermissions);

	useEffect(() => {
		if (!Fetching.current && permissions?.loading) {
			Fetching.current = true;
			get(apis.getMyAdminPermissions, {}, (r, e) => {
				if (r) {
					dispatch({
						type: "ADMIN_PERMISSIONS",
						data: r,
					});
					Fetching.current = false;
				} else if (e) {
					console.log(e);
					Fetching.current = false;
				}
			});
		}
	}, []);

	return {
		...permissions,
		checkAdminPermission: (permission) => {
			if (permissions[PERMISSIONS[permission]]) {
				return true;
			}
			return false;
		},
	};
}

export { useAdminPermission };
