import React, { useEffect } from "react";
import { useUserPreference } from "../../Hooks/useUserPreference";
import { TbPigMoney, TbPlanet, TbHome, TbMessageCircle, TbTicket, TbHelp, TbMoneybag, TbShield, TbUsers, TbSitemap, TbShieldHalf, TbCreditCard, TbSettings, TbTypography, TbStack2, TbBolt, TbReceipt, TbCalendar, TbChartBar, TbCash } from "react-icons/tb";
import { useUserConfig } from "../../Hooks/useUserConfig";
import MiniSidebar from "./mini";
import FullSidebar from "./full";
import useMobileCheck from "../../Hooks/useMobileCheck";
import { useAdminPermission } from "../../Hooks/useAdminPermission";
import { useClientSettings } from "../../Hooks/useClientSettings";

function Sidebar({ children }) {
	const { checkAdminPermission } = useAdminPermission();
	const { isEnabled } = useClientSettings();
	const { windowSize } = useMobileCheck();
	const { sidebar, showSidebar } = useUserPreference();
	const { role, checkUserPermission } = useUserConfig();
	const isAdmin = role === "ADMIN";

	useEffect(() => {
		if (windowSize < 1200) {
			showSidebar(false);
		} else {
			showSidebar(true);
		}
	}, [windowSize]);

	//Default options for User
	let options = [
		"Travel & Expense",
		{ label: "Home", route: "/app/home", icon: <TbHome className="text-lg" /> },
		{ label: "Travel", route: "/app/travel/", icon: <TbPlanet className="text-lg" /> },
		// { label: "Trips", route: "/app/trips", icon: <TbMap className="text-lg" /> },
		{ label: "Bookings", route: "/app/bookings", icon: <TbTicket className="text-lg" /> },
		...(!isEnabled("ORGANIZATION") || isEnabled("EXPENSE") ? [{ label: "Expenses", route: "/app/expense/", icon: <TbPigMoney className="text-lg" /> }] : []),
		...(isEnabled("POLICY") ? [{ label: "Approvals", route: "/app/approvals", icon: <TbShield className="text-lg" /> }] : []),
		...(isEnabled("ORGANIZATION") && checkUserPermission("TA") ? ["Travel Head Section", { label: "Requests", route: "/app/requests", icon: <TbStack2 className="text-lg" /> }, { label: "Travellers", route: "/app/travellers", icon: <TbUsers className="text-lg" /> }] : []),
		// "Extras",
		"Others",
		// ...(isEnabled("ORGANIZATION") ? [{ label: "Chat", route: "/app/chat", icon: <TbMessageCircle className="text-lg" /> }] : []),
		// { label: "Offers", route: "/app/offers", icon: <TbDiscount2 className="text-lg" /> },
		{ label: "Support", route: "/app/support", icon: <TbHelp className="text-lg" /> },
	];

	if (isAdmin) {
		options = [
			"Admin Dashboard",
			{ label: "Home", route: "/app/home", icon: <TbHome className="text-lg" /> },
			...(isEnabled("ORGANIZATION") && checkAdminPermission("VIEW_ORGANIZATION")
				? [
						{
							label: "Organization",
							route: "/app/organization/",
							icon: <TbSitemap className="text-lg" />,
						},
					]
				: []),
			...(isEnabled("ORGANIZATION") && checkAdminPermission("VIEW_ORGANIZATION")
				? [
						{
							label: "Users",
							route: "/app/users/",
							icon: <TbUsers className="text-lg" />,
						},
					]
				: []),
			...(isEnabled("POLICY") && checkAdminPermission("VIEW_POLICY") ? [{ label: "Policies", route: "/app/policies/", icon: <TbShieldHalf className="text-lg" /> }] : []),
			...(isEnabled("EXPENSE") ? [{ label: "Expense", route: "/app/expense/", icon: <TbReceipt className="text-lg" /> }] : []),
			...(isEnabled("EXPENSE") ? [{ label: "Payouts", route: "/app/payout/", icon: <TbCash className="text-lg" /> }] : []),
			...(isEnabled("CUSTOM_FIELD") ? [{ label: "Custom Fields", route: "/app/fields/", icon: <TbTypography className="text-lg" /> }] : []),
			...(isEnabled("COST_CENTER") ? [{ label: "Cost Centers", route: "/app/costs/", icon: <TbMoneybag className="text-lg" /> }] : []),
			{ label: "Reports", route: "/app/reports", icon: <TbChartBar className="text-lg" /> },
			...(checkAdminPermission("VIEW_PAYMENT") ? [{ label: "Wallet", route: "/app/wallet/", icon: <TbCreditCard className="text-lg" /> }] : []),
			...(checkAdminPermission("EDIT_ADMINS")
				? [
						"Settings",
						{ label: "Power Ups", route: "/app/powers/", icon: <TbBolt className="text-lg" /> },
						{ label: "Settings", route: "/app/settings/", icon: <TbSettings className="text-lg" /> },
						{ label: "Subscription", route: "/app/subscription/", icon: <TbCalendar className="text-lg" /> },
					]
				: []),
		];
	}

	return <div className={`sidebar-wrapper ${sidebar ? "expanded" : "collapsed"}`}>{sidebar ? <FullSidebar content={children} options={options} /> : <MiniSidebar options={options} />}</div>;
}

export default Sidebar;
