import React from 'react';
import BusCard from '../SRP/Result/Components/busCard';
import { StampTo24hr, StampToDate } from '../../../../../../Utils/utils';
import { FaBus } from 'react-icons/fa';
import moment from 'moment';
import { Button } from '../../../../../../Components';

function Review() {

    const Mock_Bus = [
        {
            id: 1,
            from: {
                name: 'Delhi',
                country: 'India',
            },
            to: {
                name: 'Jaipur',
                country: 'India',
            },
            departure: {
                time: 0,
                point: 'Kashmiri Gate',
            },
            arrival: {
                time: 0,
                point: 'Station Road',
            },
            operator: {
                name: 'Goldline Super Deluxe',
            },
            bus: 'Bharat Benz A/C Sleeper (2+1)',
            rating: 3.8,
            seats: 40,
            cancellation: {
                0 : '0 to 12 hr(s) before travel',
                50 : '12 to 24 hr(s) before travel',
                100 : '24 to 48 hr(s) before travel',
            },
            images: [],
            pickups: [
                {
                    id: 1,
                    name: 'Kashmiri Gate',
                    time: '9:00 PM',
                },
                {
                    id: 2,
                    name: 'ISBT',
                    time: '9:30 PM',
                },
                {
                    id: 3,
                    name: 'Majnu Ka Tila',
                    time: '10:00 PM',
                },
            ],
            drops: [
                {
                    id: 1,
                    name: 'Station Road',
                    time: '5:00 AM',
                },
                {
                    id: 2,
                    name: 'Sindhi Camp',
                    time: '5:30 AM',
                },
                {
                    id: 3,
                    name: 'Ajmeri Gate',
                    time: '6:00 AM',
                },
            ],
            amenities: ['AC', 'Water Bottle', 'Blanket', 'Charging Point', 'Reading Light'],
            amount: 600,
            date: '',
            type: 'bus',
        },
    ]

    const {operator, bus, from, to, departure, arrival} = Mock_Bus[0]

    return ( 
        <>
        <div className='border shadow-sm rounded-md mt-4 p-4 '>
            <div className='flex-center-between'>
                <h2>{operator.name}</h2>
                <div className='text-sm text-secondary'>
                    {bus}
                </div>
            </div>
            <div className='grid grid-cols-2 gap-x-6 mt-4'>
                <div className={`align-center gap-x-4`}>
                    <div className='max-w-[8rem]'>
                        <div className='font-bold whitespace-nowrap text-xl'>{StampTo24hr(departure.time)}</div>
                        <div className='text-secondary text-sm'>{StampToDate(departure.time)}</div>
                        <div className='text-secondary text-sm'>{departure.point}</div>
                        <div className='text-secondary text-sm'>{from.name}</div>
                    </div>
                    <div className='flex-grow min-w-[5rem]'>
                        <div className='align-center'>
                            <div className='w-2 h-2 rounded-full border border-dark'/>
                            <div className='flex-grow border-t border-muted border-dashed'/>
                            <FaBus/>
                            <div className='flex-grow border-t border-muted border-dashed'/>
                            <div className='w-2 h-2 rounded-full border border-dark'/>
                        </div>
                        <div className='text-center mt-2 text-xs text-secondary whitespace-nowrap'>2hr 30min</div> 
                    </div>
                    <div className='max-w-[8rem]'>
                        <div className='font-bold whitespace-nowrap text-xl'>{StampTo24hr(arrival.time)}</div>
                        <div className='text-secondary text-sm'>{StampToDate(arrival.time)}</div>
                        <div className='text-secondary text-sm'>{arrival.point}</div>
                        <div className='text-secondary text-sm'>{to.name}</div>
                    </div>

                </div>
                <div className='flex flex-col justify-between items-end'>
                    <div className='flex gap-x-2'>
                        <Button variant='outlined' className='btn-xs'>Amenities</Button>
                        <Button variant='outlined' className='btn-xs'>Cancellation</Button>
                    </div>
                    <div className='align-center gap-x-4 mt-4'>
                        <h2>Seat 4D</h2>
                    </div>
                </div>
            </div>
            <div className='mt-4 align-center gap-x-2'>
                <div className='text-sm bg-compliment p-2 rounded-md border border-accent'>Board from Boarding Point 1 <b>@ 5:30 AM</b></div>
                <div className='text-sm bg-compliment p-2 rounded-md border border-accent'>Drop off at Drop Point 2 <b>@ 5:30 AM</b></div>
            </div>
        </div>
        </>
     );
}

export default Review;