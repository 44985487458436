import React from "react";
import { Badge } from "../../../../../../../../Components";
import { StampTo24hr, StampToDate } from "../../../../../../../../Utils/utils";

function FlightItinerary({ onwardFlight, returnFlight }) {
	const onwardJourney = onwardFlight.segments ? onwardFlight.segments[0] : null;
	const returnJourney = returnFlight?.segments ? returnFlight?.segments[0] : null;

	return (
		<>
			<h6>Itinerary</h6>
			<div className="mt-4 pl-4">
				{onwardJourney && (
					<div className="flex text-sm">
						<div className="border-l border" />
						<div className="align-center relative pl-8 pt-6">
							<div className="absolute -left-6 p-1 bg-canvas">
								<img src={onwardJourney.logo} alt="airline-logo" className="h-10 w-10 rounded-full mr-2" />
							</div>
							<div>
								<h4>Departure</h4>
								<Badge className="mt-2">
									{StampTo24hr(onwardJourney.departureTime)} on {StampToDate(onwardJourney.departureTime)}
								</Badge>
								<div className="mt-2 text-xs">
									{onwardJourney.airlineName} {onwardJourney.airlineCode}-{onwardJourney.flightNumber}
								</div>
								<div className="mt-1 text-xs text-secondary">
									Terminal - {onwardJourney.departureTerminal}, {onwardJourney.departureAirport}
								</div>
							</div>
						</div>
					</div>
				)}
				{returnJourney && (
					<div className="flex text-sm">
						<div className="border-l border" />
						<div className="align-center relative pl-8 pt-6">
							<div className="absolute -left-6 p-1 bg-canvas">
								<img src={returnJourney.logo} alt="airline-logo" className="h-10 w-10 rounded-full mr-2" />
							</div>
							<div>
								<h4>Return</h4>
								<Badge className="mt-2">
									{StampTo24hr(returnJourney.departureTime)} on {StampToDate(returnJourney.departureTime)}
								</Badge>
								<div className="mt-2 text-xs">
									{returnJourney.airlineName} {returnJourney.airlineCode}-{returnJourney.flightNumber}
								</div>
								<div className="mt-1 text-xs text-secondary">
									Terminal - {returnJourney.departureTerminal}, {returnJourney.departureAirport}
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default FlightItinerary;
