import React from 'react';
import StatusIndicator from './status';
import { CopyValue } from '../../../../../../../../Components';

function BookingStatus({status, id}) {
    return ( 
        <div className='p-2 flex-center-between bg-whitish border-t'>
            <CopyValue className='text-xxs'>{id}</CopyValue>
            <StatusIndicator status={status}/>
        </div>
     );
}

export default BookingStatus;