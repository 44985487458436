import React from "react";
import EmployeeInfo from "../Cards/EmployeeInfo";
import StatusIndicator from "./status";

function Approvers({ approvers = [] }) {
	return approvers.map((approver, index) => (
		<div key={index} className="mt-2 flex-center-between">
			<EmployeeInfo employee={approver} />
			<StatusIndicator status={approver.status} />
		</div>
	));
}

export default Approvers;
