import React from "react";
import { Button, ButtonGroup, DatePicker, Input, Modal, Select, Toast } from "../../";
import { useState } from "react";
import moment from "moment";
import { apis, post } from "../../../Utils/axios";
import { COUNTRIES } from "../../../Utils/constants";

function AddGuest({ onReload = () => {}, btnLabel = "+ Add Guest", btnVariant = "primary", btnClass = "" }) {
	const [NewGuest, setNewGuest] = useState();
	const initial = {
		fName: "",
		lName: "",
		email: "",
		phone: "",
		gender: {
			label: "Male",
			value: "MALE",
		},
		nationality: "IN",
		dob: moment().subtract(18, "years").valueOf(),
	};
	const [Values, setValues] = useState(initial);
	const [Loader, setLoader] = useState();

	const onSubmit = () => {
		if (!Values.gender) {
			return Toast.error("Please select gender");
		}
		if (!Values.email) {
			return Toast.error("Please enter email address");
		}
		post(
			apis.addGuest,
			{
				...Values,
				gender: Values.gender?.value,
			},
			(r, e) => {
				if (r) {
					Toast.success("Guest added");
					setNewGuest(false);
					setLoader(false);
					onReload();
					setValues(initial);
				} else if (e) {
					Toast.handleError(e);
					setLoader(false);
				}
			}
		);
	};

	return (
		<>
			<Button onClick={() => setNewGuest(true)} variant={btnVariant} className={btnClass}>
				{btnLabel}
			</Button>
			<Modal open={NewGuest} onClose={() => setNewGuest()}>
				<div className="p-6">
					<h1>New Guest</h1>
					<div className="mt-4">
						<div className="grid grid-cols-2 gap-x-4">
							<Input required value={Values.fName} onChange={(fName) => setValues((prev) => ({ ...prev, fName }))} label="First Name" placeholder="First Name" />
							<Input required value={Values.lName} onChange={(lName) => setValues((prev) => ({ ...prev, lName }))} label="Last Name" placeholder="Last Name" />
						</div>
						<div className="grid grid-cols-2 mt-4 gap-x-4">
							<Input required value={Values.email} onChange={(email) => setValues((prev) => ({ ...prev, email }))} label="Email" placeholder="Email Address" />
							<Input required value={Values.phone} onChange={(phone) => setValues((prev) => ({ ...prev, phone }))} label="Phone" placeholder="Phone" />
						</div>
						<div className="grid grid-cols-2 mt-4 gap-x-4">
							<DatePicker backDateSelection={true} date={Values.dob} setDate={(dob) => setValues((prev) => ({ ...prev, dob }))} label="Date of Birth" />
							<Select
								btnClass="w-full"
								options={COUNTRIES.map(({ code, name }) => ({ value: code, label: name }))}
								required
								selected={{ value: Values.nationality, label: Values.nationality }}
								label="Nationality"
								placeholder="Nationality"
								onChange={(nationality) => setValues((prev) => ({ ...prev, nationality: nationality.value }))}
							/>
						</div>
						<div className="mt-4">
							<label className="block mb-2">Gender</label>
							<ButtonGroup
								selected={Values.gender}
								onClick={(gender) => setValues((prev) => ({ ...prev, gender }))}
								options={[
									{
										label: "Male",
										value: "MALE",
									},
									{
										label: "Female",
										value: "FEMALE",
									},
								]}
							/>
						</div>
						<Button onClick={onSubmit} loading={Loader} className="w-full mt-4">
							Add Guest
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default AddGuest;
