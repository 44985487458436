import React from "react";
import { Img } from "../..";
import PlaceholderImg from "../../../Assets/Images/placeholders/note.png";
import { FiArrowUpRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

function InlinePolicy({ policy = {}, className = "", type = "travel" }) {
	const NAVIGATE = useNavigate();
	const { logo, name, id } = policy;

	return (
		<div onClick={() => NAVIGATE(`/app/policies/${type}/${id}`)} className="inline-block px-2 py-1 align-middle rounded-md cursor-pointer bg-light hover:bg-primary hover:text-white">
			<div className={`align-center gap-x-1 ${className}`}>
				<Img preview placeholder={PlaceholderImg} src={logo} className="w-4 h-4 rounded-full pixelated" />
				<div className="text-xs">{name}</div>
				<FiArrowUpRight />
			</div>
		</div>
	);
}

export default InlinePolicy;
