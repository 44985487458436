import React from "react";
import { Container, KeyValue, Avatar } from "../../../../../../../Components";
import { StampToDate } from "../../../../../../../Utils/utils";
import { useClientSettings } from "../../../../../../../Hooks/useClientSettings";
import { TbUsers } from "react-icons/tb";

function TravellerProfile({ profile }) {
	const { email, photo, fName, lName, phone, gender, dob, office, team, identification } = profile;
	const { isEnabled } = useClientSettings();

	return (
		<>
			<div className="mt-6">
				<Container variant="outlined">
					<Avatar className="w-20 h-20" photo={photo} />
					<KeyValue label="Email" className="mt-6">
						{email}
					</KeyValue>
					<KeyValue label="First Name" className="mt-2">
						{fName}
					</KeyValue>
					<KeyValue label="Last Name" className="mt-2">
						{lName}
					</KeyValue>
					<KeyValue label="Phone" className="mt-2">
						{phone}
					</KeyValue>
					<KeyValue label="Gender" className="mt-2">
						{gender}
					</KeyValue>
					<KeyValue label="Date of Birth" className="mt-2">
						{StampToDate(dob)}
					</KeyValue>
					<KeyValue label="Passport Number" className="mt-2">
						{identification?.passportNumber} (Valid till {StampToDate(identification?.passportExpiry)})
					</KeyValue>
					<KeyValue label="National ID" className="mt-2">
						{identification?.nationalID}
					</KeyValue>
					<KeyValue label="Nationality" className="mt-2">
						{identification?.nationality}
					</KeyValue>
				</Container>

				{isEnabled("ORGANIZATION") && (
					<>
						<Container variant="outlined mt-4 !p-0">
							<div className="p-4">
								<h4 className="gap-1 mb-4 align-center">
									<TbUsers />
									Team
								</h4>
								<KeyValue label="Office" className="mt-2">
									{office?.name}
								</KeyValue>
								<KeyValue label="Team" className="mt-2">
									{team?.name}
								</KeyValue>
							</div>
						</Container>
					</>
				)}

				{/* <Container variant="outlined mt-4 !p-0">
					<div className="p-4">
						<h4 className="gap-1 align-center">
							<TbFiles />
							Travel Documents
						</h4>
						<div className="mt-4">{identification?.files?.length > 0 ? <Attachments files={identification?.files || []} /> : <div className="text-label">No Documents added</div>}</div>
					</div>
				</Container> */}
			</div>
		</>
	);
}

export default TravellerProfile;
