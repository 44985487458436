import React, {useState} from 'react';
import { EmployeeSearch } from '../../../../../../Components';

function SelectEmployee() {

    const [Passengers, setPassengers] = useState();

    return ( 
        <>
        <EmployeeSearch onSelect={setPassengers} selected={Passengers}/>
        </>
     );
}

export default SelectEmployee;