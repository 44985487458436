import React, { useEffect, useState } from "react";
import { BiFork } from "react-icons/bi";
import { MdLuggage } from "react-icons/md";
import { apis, post } from "../../../../../../../../Utils/axios";

function FlightDetails({ baggageKey = {} }) {
	const [Details, setDetails] = useState({});

	useEffect(() => {
		fetchDetails(baggageKey);
	}, [baggageKey]);

	const fetchDetails = (key) => {
		post(apis.getFreeBaggage, { key: key ? JSON.parse(key) : {} }, (r, e) => {
			if (r) {
				setDetails(r);
			}
		});
	};

	return (
		<>
			<h6>Flight Details</h6>
			{/* <div className='mt-2 align-center'>
            <div className='w-8 h-8 mr-2 rounded-sm bg-light flex-center'>
                <BiFork/>
            </div>
            <div>
                <h6>Free Meal</h6>
                <div className='text-xs text-secondary'>This fare includes free meal choice</div>
            </div>
        </div> */}
			<div className="mt-4 align-center">
				<div className="w-8 h-8 mr-2 rounded-sm bg-light flex-center">
					<MdLuggage />
				</div>
				<div>
					<h6>Check-in {Details?.checkIn}</h6>
					<div className="text-xs text-secondary">You can carry {Details?.cabin} in cabin</div>
				</div>
			</div>
		</>
	);
}

export default FlightDetails;
