import React, { useEffect, useRef, useState } from "react";
import Visa from "../../../../../../../Assets/Images/icons/visa.png";
import MasterCard from "../../../../../../../Assets/Images/icons/mastercard.png";
import Upi from "../../../../../../../Assets/Images/icons/upi.png";
import { useNavigate } from "react-router-dom";
import { Badge, Button, Razorpay, Toast } from "../../../../../../../Components";
import { apis, post } from "../../../../../../../Utils/axios";
import { useUserPolicy } from "../../../../../../../Hooks/useUserPolicy";
import { FaCreditCard, FaWallet } from "react-icons/fa";
import useUserProfile from "../../../../../../../Hooks/useUserProfile";

function Payment({ guests, customFields = {}, hotel = {}, hotelId, bookingCode, roomCode, checkIn, checkOut, occupancy, approvalId, requestId, room, costCenterId }) {
	const NAVIGATE = useNavigate();
	const { profile } = useUserProfile();
	const openPg = useRef();
	const { travelPolicy } = useUserPolicy();
	const { enabled, paymentGateway, wallet } = travelPolicy?.payment || { enabled: true, paymentGateway: true };

	const [ProvisionalBookData, setProvisionalBookData] = useState({});
	const [Loading, setLoading] = useState(false);
	const totalAmount = room.amount + room.tax - room.discount;

	//filtering platform user to tag them in the booking
	const platformUsers = [];
	guests.forEach(({ id, isGuest }) => {
		if (!isGuest) {
			platformUsers.push(id);
		}
	});

	console.log(guests);

	useEffect(() => {
		if (openPg) {
			openPg.current = startBooking;
		}
	}, []);

	const holdStay = (paymentMode) => {
		let travellers = guests.map(({ id, isGuest }) => {
			if (isGuest) {
				return {
					guestId: id,
					isGuest,
				};
			} else {
				return {
					userId: id,
				};
			}
		});

		if (travellers.length < occupancy?.length) {
			return Toast.error(`Please select at least one guest per room`);
		}

		setLoading(true);
		post(
			apis.holdStay,
			{
				travellers,
				hotelId,
				bookingCode,
				roomCode,
				checkIn,
				checkOut,
				occupancy,
				amount: totalAmount,
				approvalId,
				requestId,
				costCenterId,
				paymentMode,
			},
			(r, e) => {
				if (r && !r.error) {
					setProvisionalBookData(r);
					setLoading(false);
					if (paymentMode === "PG") {
						//Book via PG
						openPg.current();
						openPg.current({
							amount: totalAmount,
							orderId: r.orderId,
							user: {
								name: profile?.fullName,
								email: profile?.email,
								phone: profile?.phone,
							},
						});
					} else {
						//Book via wallet
						startBooking("WALLET");
					}
				} else if (e) {
					Toast.handleError(e);
					setLoading(false);
				}
			}
		);
	};

	const startBooking = (paymentMode, razorpayData) => {
		const { name, thumbnail, locality } = hotel;
		NAVIGATE(`/app/travel/stays/confirm`, {
			state: {
				itineraryId: ProvisionalBookData.itineraryId,
				thumbnail,
				name,
				address: locality?.address,
				customFields,
				approvalId,
				requestId,
				costCenterId,
				guests: platformUsers,
				paymentMode,
				razorpayData,
			},
		});
	};

	return (
		<>
			<div className="p-4 mt-4 border rounded-md fade-in-up">
				<h2>Your total amount is ₹ {totalAmount}</h2>
				<div className="mt-2 text-label">You can pay using Debit Card, Credit Card, Net Banking, UPI through payment gateway</div>
				<div className="gap-4 mt-4 align-center">
					<img src={Visa} alt="Visa" className="h-4" />
					<img src={MasterCard} alt="MasterCard" className="h-5" />
					<img src={Upi} alt="UPI" className="h-4" />
				</div>
				{enabled ? (
					<div className="flex gap-2 mt-8">
						{wallet && (
							<Button loading={Loading} onClick={() => holdStay("WALLET")} variant="primary">
								<FaWallet className="mr-2" />
								Pay from Wallet
							</Button>
						)}
						{paymentGateway && (
							<Button loading={Loading} onClick={() => holdStay("PG")}>
								<FaCreditCard className="mr-2" />
								Pay Online
							</Button>
						)}
					</div>
				) : (
					<Badge variant="danger mt-8">Payments are disabled for your account</Badge>
				)}
			</div>
			<Razorpay hideBtn openPg={openPg} onSuccess={(razorpayData) => startBooking("PG", razorpayData)} />
		</>
	);
}

export default Payment;
