import React from 'react';
import FlightBooking from './flight';
import BusBooking from './bus';
import StayBooking from './stay';

function Bookings({booking}) {

    if(booking.type === 'flight'){
        return <FlightBooking {...booking}/>   
    }
    if(booking.type === 'stay'){
        return <StayBooking {...booking}/>   
    }
    if(booking.type === 'bus'){
        return <BusBooking {...booking}/>   
    }
    if(booking.type === 'cab'){
        return <FlightBooking {...booking}/>   
    }
    return null
}

export default Bookings;