import React, { useEffect } from "react";
import { Badge, Button, ButtonGroup, DatePicker, EditNationalId, EditPassport, EditableInput, LabelValue, Toast, TravellerSearch, Avatar, ErrorMsg } from "../../../../../../../Components";
import { useClientSettings } from "../../../../../../../Hooks/useClientSettings";
import useUserProfile from "../../../../../../../Hooks/useUserProfile";
import { StampToDate, validateIndianPhoneNumber } from "../../../../../../../Utils/utils";
import apis from "../../../../../../../Utils/apis";
import { get, patch } from "../../../../../../../Utils/axios";
import moment from "moment";
import { FiMail } from "react-icons/fi";
import { useUserConfig } from "../../../../../../../Hooks/useUserConfig";
import { FaTrashAlt } from "react-icons/fa";

function Travellers({ mandatoryField = {}, errors = [], max, passengers = {}, setPassengers = () => {}, requestId }) {
	const { isEnabled } = useClientSettings();
	const { checkUserPermission } = useUserConfig();
	const allowTravellersSelection = isEnabled("ORGANIZATION") && checkUserPermission("TA");
	const { profile, reload } = useUserProfile();
	const { id, fName, lName, phone, email, dob, gender, photo, identification } = profile;

	useEffect(() => {
		if (allowTravellersSelection) {
			setPassengers([]);
		} else {
			setPassengers([profile]);
		}
	}, [allowTravellersSelection, profile]);

	useEffect(() => {
		//If booking is done against a request, automatically add the requestor in travellers
		if (requestId) {
			fetchRequestDetails(requestId);
		}
	}, [requestId]);

	const fetchRequestDetails = (requestId) => {
		get(apis.getRequestorDetails, { requestId }, (r, e) => {
			if (r) {
				addTraveller(r);
			}
		});
	};

	const addTraveller = (traveller) => {
		//Check if traveller already exists
		const index = passengers.findIndex((p) => p.email === traveller.email);
		if (index >= 0) {
			return Toast.error("You have already selected this traveller once");
		}
		setPassengers((prev) => [...prev, traveller]);
	};

	const removeTraveller = (email) => {
		setPassengers((prev) => prev.filter((p) => p.email !== email));
	};

	if (allowTravellersSelection) {
		return (
			<>
				{passengers.length < max && <TravellerSearch onSelect={addTraveller} selected={passengers} />}
				{requestId && passengers.length === max && <Badge variant="warning">Travellers were automatically added from the request</Badge>}
				{passengers.map((traveller, i) => {
					const { id, photo, fName, lName, email, phone, gender, dob, identification, isGuest } = traveller;

					return (
						<div key={i} className="mt-4 border rounded-md fade-in-up">
							<div className="px-4 py-3 flex-center-between bg-light rounded-t-md">
								<div className="flex-grow gap-2 align-center">
									<h5>
										{fName} {lName} {isGuest ? "(Guest)" : ""}
									</h5>
								</div>
								<div className="gap-2 text-label align-center">
									<FiMail />
									{email}
									<Button onClick={() => removeTraveller(email)} variant="danger" className="btn-xs">
										<FaTrashAlt className="mr-2" />
										Remove
									</Button>
								</div>
							</div>
							<div className="grid grid-cols-3 gap-4 p-4">
								<LabelValue label="Phone">{phone}</LabelValue>
								<LabelValue label="Gender">{gender}</LabelValue>
								<LabelValue label="Date of Birth">{StampToDate(dob)}</LabelValue>
								{mandatoryField.passportValidation && (
									<LabelValue label="Passport (Required)">
										<EditPassport reload={reload} identification={identification} userId={!isGuest && id} guestId={isGuest && id} photo={photo} />
									</LabelValue>
								)}
								{mandatoryField.nationalityValidation && (
									<LabelValue label="Government ID (Required)">
										<EditNationalId reload={reload} identification={identification} userId={!isGuest && id} guestId={isGuest && id} />
									</LabelValue>
								)}
							</div>
							{errors.length > 0 && (
								<div className="px-4 py-3 space-y-1 text-xs">
									{errors.map((error, i) => (
										<ErrorMsg key={i} e={`${i + 1}) ${error}`} className="!text-xs mt-0" />
									))}
								</div>
							)}
						</div>
					);
				})}
			</>
		);
	}

	const onUpdate = (obj) => {
		if (obj.phone && !validateIndianPhoneNumber(obj.phone)) {
			return Toast.error("Please enter valid phone number");
		}
		patch(apis.updateMyProfile, obj, (r, e) => {
			if (r) {
				reload();
			} else if (e) {
				console.log(e);
				Toast.handleError(e);
			}
		});
	};

	return (
		<>
			<div className="border rounded-md fade-in-up">
				<div className="px-4 py-3 flex-center-between bg-light rounded-t-md">
					<div className="flex-grow gap-2 align-center">
						<Avatar photo={photo} className="w-6 h-6" />
						<h5>
							{fName} {lName}
						</h5>
					</div>
					<div className="gap-2 text-label align-center">
						<FiMail />
						{email}
					</div>
				</div>
				<div className="grid grid-cols-3 gap-4 p-4">
					<LabelValue label="Phone">
						<EditableInput required onUpdate={(phone) => onUpdate({ phone })} value={phone} label="Phone Number" />
						{phone}
					</LabelValue>
					<LabelValue label="Gender">
						<ButtonGroup
							size="xs"
							selected={{
								label: gender,
								value: gender,
							}}
							onClick={(obj) => onUpdate({ gender: obj.value })}
							options={[
								{ label: "Male", value: "MALE" },
								{ label: "Female", value: "FEMALE" },
							]}
						/>
					</LabelValue>
					{mandatoryField.dobValidation && (
						<LabelValue label="Date of Birth">
							<DatePicker btnClass="!py-1 !text-xs max-w-min" date={dob} setDate={(dob) => onUpdate({ dob })} backDateSelection maxDate={moment().subtract(18, "years").valueOf()} />
						</LabelValue>
					)}
					{mandatoryField.passportValidation && (
						<LabelValue label="Passport (Required)">
							<EditPassport reload={reload} identification={identification} userId={id} photo={photo} />
						</LabelValue>
					)}
					{mandatoryField.nationalityValidation && (
						<LabelValue label="Government ID (Required)">
							<EditNationalId reload={reload} identification={identification} userId={id} />
						</LabelValue>
					)}
				</div>
			</div>
		</>
	);
}

export default Travellers;
