import React, { Suspense, lazy } from "react";
import { Button, Sidebar, SidePane, ActionBar, Navigator, Alerts, Intros } from "../../Components";
import { FiMaximize2, FiMinimize2, FiSidebar } from "react-icons/fi";
import { useUserPreference } from "../../Hooks/useUserPreference";
import { Route, Routes } from "react-router-dom";
import AccountSettings from "./Common/AccountSettings";
import Notifications from "./Common/Notifications";
import { useUserConfig } from "../../Hooks/useUserConfig";
import Chat from "./Common/Chat";
import Support from "./Common/Support";
import VerifyUserDetails from "./Wizards/User/verify";
import useUserProfile from "../../Hooks/useUserProfile";
import { useClientSettings } from "../../Hooks/useClientSettings";
import TryTppForTeams from "../../Components/Intros/TryTppForTeams";
import QuickActions from "./Employee/QuickActions";
import EmployeeRoutes from "./Employee";
import LoadingScreen from "./loading";
import useSocket from "../../Hooks/useSocket";
import AdvisoryMessage from "./advisory";
import { SizeMonitor, ScrollToTop } from "../../Components";
const AdminRoutes = lazy(() => import("./Admin"));

function App() {
	useSocket();
	const { role, userConfig } = useUserConfig();
	const { isAdmin } = userConfig;
	const { isEnabled, advisory } = useClientSettings();
	const { profile } = useUserProfile();
	const { showSidebar, sidebar, sidePane, showSidePane } = useUserPreference();

	const collapse = () => {
		showSidePane(!sidePane);
		showSidebar(!sidePane);
	};

	if (userConfig.loading) {
		return <LoadingScreen />;
	}

	//If user profile is not onboard, show the user onboarding wizard
	if (!profile.gender) {
		return <VerifyUserDetails profile={profile} />;
	}

	return (
		<>
			<SizeMonitor />
			<ScrollToTop />
			<Intros />
			{/* <Alerts /> */}
			<div className="flex w-full h-full">
				<Sidebar>{!isEnabled("ORGANIZATION") ? <TryTppForTeams /> : <AdvisoryMessage advisory={advisory} />}</Sidebar>
				<div className="relative flex flex-col flex-1 overflow-x-visible overflow-y-auto">
					<ActionBar>
						<Button onClick={() => showSidebar(!sidebar)} variant="icon">
							<FiSidebar />
						</Button>
						<div className="flex-1 mr-2 flex-center-between">
							<Navigator />
							{/* <CommandPalette /> */}
						</div>
						<Button onClick={collapse} variant="icon" className="rotate-180">
							{sidePane ? <FiMaximize2 /> : <FiMinimize2 />}
						</Button>
						<Button onClick={() => showSidePane(!sidePane)} variant="icon" className="rotate-180">
							<FiSidebar />
						</Button>
					</ActionBar>
					<div className="main-container scrollbar-autohide" id="main-container">
						{role === "USER" && <EmployeeRoutes />}
						{role === "ADMIN" && isAdmin && (
							<Suspense fallback={<LoadingScreen />}>
								<AdminRoutes />
							</Suspense>
						)}
						<Routes>
							<Route path="/account/*" element={<AccountSettings />} />
							<Route path="/chat/*" element={<Chat />} />
							<Route path="/support/*" element={<Support />} />
						</Routes>
					</div>
				</div>
				<SidePane>{isEnabled("ORGANIZATION") ? <Notifications /> : <QuickActions />}</SidePane>
			</div>
		</>
	);
}

export default App;
