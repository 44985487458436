import React, { useEffect, useState } from "react";

function BottomBar({ children }) {
	// const [AtBottom, setAtBottom] = useState(false);

	// useEffect(() => {
	// 	const mainContainer = document.getElementById("main-container");
	// 	mainContainer.addEventListener("scroll", function () {
	// 		if (mainContainer.scrollHeight - mainContainer.scrollTop === mainContainer.clientHeight) {
	// 			setAtBottom(true);
	// 		} else {
	// 			setAtBottom(false);
	// 		}
	// 	});
	// }, []);

	// if (AtBottom) {
	// 	return null;
	// }

	return (
		<>
			<div className="absolute bottom-0 left-0 right-0 z-10 p-4 border-t fade-in-down bg-canvas frosted">{children}</div>
		</>
	);
}

export default BottomBar;
