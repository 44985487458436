const CONFIG_ACTIONS = {
	token: "TOKEN",
	role: "ROLE",
	profile: "PROFILE",
	userConfig: "CONFIG",
};

const previousConfig = JSON.parse(localStorage.getItem("configuration") || "{}");

let initialState = {
	token: undefined,
	role: "USER",
	profile: {
		fName: "User",
		lName: "",
		email: "...",
		loading: true,
	},
	userConfig: {
		loading: true,
	},
	...previousConfig,
};

const CACHED_CONFIG = ["token", "role"];
const updateLocalStorage = (updatedState) => {
	//Only cache the keys in CACHED_CONFIG
	const cachedState = {};
	CACHED_CONFIG.forEach((key) => {
		cachedState[key] = updatedState[key];
	});
	localStorage.setItem("configuration", JSON.stringify(cachedState));
};

const configuration = (state = initialState, { data, type }) => {
	if (type === CONFIG_ACTIONS.token) {
		const updatedState = {
			...state,
			token: data.token,
		};
		updateLocalStorage(updatedState);
		return updatedState;
	} else if (type === CONFIG_ACTIONS.role) {
		const updatedState = {
			...state,
			role: data.role,
		};
		updateLocalStorage(updatedState);
		return updatedState;
	} else if (type === CONFIG_ACTIONS.profile) {
		const updatedState = {
			...state,
			profile: data.profile,
		};
		updateLocalStorage(updatedState);
		return updatedState;
	} else if (type === CONFIG_ACTIONS.userConfig) {
		const updatedState = {
			...state,
			userConfig: data.userConfig,
		};
		updateLocalStorage(updatedState);
		return updatedState;
	} else {
		return state;
	}
};

export { CONFIG_ACTIONS, configuration };
