import React from "react";
import StatusIndicator from "./status";
import BangaloreCity from "../../../Assets/Images/mock/bangalore.jpeg";
import moment from "moment";
import { FiShare } from "react-icons/fi";

function TripCard({ onClick = () => {}, id = "", name = "", description = "", origin = {}, destination = {}, createOn = 0, travelDate = 0, status = "", bookings = 0 }) {
	return (
		<>
			<div onClick={onClick} className="overflow-hidden text-white transition-all duration-300 cursor-pointer rounded-xl min-w-min background-cover" style={{ backgroundImage: `url(${BangaloreCity})` }}>
				<div className="pt-12" style={{ background: "linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))" }}>
					<div className="p-4 text-sm">
						<h1>{name}</h1>
						<div className="text-xs">Travelling on {moment().format("DD MMM YYYY")}</div>
						<div className="mt-2 flex-center-between">
							<div className="align-center gap-x-2">
								<StatusIndicator status={status} />
								<div className="text-xs">4 Bookings</div>
							</div>
							<FiShare />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default TripCard;
