import React from "react";
import { TbMasksTheater, TbMasksTheaterOff } from "react-icons/tb";
import { useState } from "react";
import { FiArrowUpRight, FiSave } from "react-icons/fi";
import { Modal, Button, EmployeeSearch, Toast } from "../../..";
import { useNavigate } from "react-router-dom";
import { apis, post } from "../../../../Utils/axios";

function ChangeApprover({ onChange, id, selected = {}, type = "", approver = "head1" }) {
	if (selected === null) {
		selected = {};
	}

	const NAVIGATE = useNavigate();
	const [ShowApprovers, setShowApprovers] = useState();
	const [Approver, setApprover] = useState({});

	const onSave = () => {
		setShowApprovers();
		setApprover({});
		changeApprovers({
			name: Approver.label,
			id: Approver.value,
		});
	};

	const showSearch = () => {
		setShowApprovers(true);
		setApprover({ label: selected.fullName, value: selected.id });
	};

	const changeApprovers = (head) => {
		post(
			type === "team" ? apis.updateTeamHeads : type === "office" ? apis.updateOfficeHeads : apis.updateCostCenterHeads,
			{
				...(type === "team" ? { teamId: parseInt(id) } : type === "office" ? { officeId: parseInt(id) } : { costCenterId: parseInt(id) }),
				...{ [approver]: head.id },
			},
			(r, e) => {
				if (r) {
					Toast.success(`Approver updated`);
					onChange && onChange();
				} else if (e) {
					console.log(e);
					Toast.handleError(e);
				}
			}
		);
	};

	return (
		<>
			{selected?.id ? (
				<Button onClick={showSearch} variant="primary" className="btn-xs">
					<TbMasksTheater className="mr-1" />
					{selected.fullName}
				</Button>
			) : (
				<Button onClick={showSearch} variant="outlined" className="btn-xs">
					<TbMasksTheaterOff className="mr-1" />
					No Approver
				</Button>
			)}
			<Modal open={ShowApprovers} onClose={() => setShowApprovers()}>
				<div className="p-6">
					<h1 className="capitalize align-center gap-x-2">Change {type} Approver</h1>
					<div className="mt-2 text-label">User you select will start receiving approvals as per policy flow</div>
					<div className="mt-4">
						<div className="flex gap-x-2">
							<EmployeeSearch onSelect={setApprover} selected={Approver} />
							{selected?.id && (
								<Button
									onClick={() => {
										NAVIGATE(`/app/users/${selected.id}`);
										setShowApprovers();
									}}
									variant="outlined"
								>
									<FiArrowUpRight />
								</Button>
							)}
						</div>
						<div className="flex mt-4 gap-x-2">
							<Button onClick={onSave} className="flex-grow">
								<FiSave className="mr-1" />
								Save
							</Button>
							<Button onClick={() => setApprover({ label: "", value: null })} variant="danger" className="flex-grow">
								Remove
							</Button>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default ChangeApprover;
