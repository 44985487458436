import React from 'react';
import { AnimatedTick, Button } from '../../../../../../Components';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import OkHandEmoji from '../../../../../../Assets/Images/emojis/ok-hand.png'

function Success() {

    const NAVIGATE = useNavigate();
    const [OkHand, setOkHand] = useState();

    useEffect(() => {
        setTimeout(() => {
            setOkHand(true)
        }, 2000);
    }, []);

    return ( 
        <div className='w-full h-full flex-center bg-light'>
            <div className='bg-canvas shadow-sm border rounded-xl p-8'>
                <div className='h-20 flex-center'>
                {
                    OkHand
                    ? <img src={OkHandEmoji} alt='okay' className='w-20 m-auto fade-in-up'/>
                    : <AnimatedTick/>
                }
                </div>
                <div className='text-center mt-8'>
                    <h1>Booking Confirmed</h1>
                    <div className='text-label'>
                        Wow! You're all set. We've sent you an email with your reservation details.
                    </div>
                    <Button onClick={()=>NAVIGATE('/app/travel')} variant='outlined' className='m-auto mt-8'>View Reservation</Button>
                </div>
                
            </div>
        </div>
     );
}

export default Success;