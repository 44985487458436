import React from "react";
import LogoImg from "Assets/Images/logo.png";
import ShortLogoImg from "Assets/Images/logo_short.png";

function Logo({ className = "", error, short }) {
	let fontSize = "text-2xl";

	if (short) {
		fontSize = "text-sm";
	}

	return <div className={`align-center font-bold ${fontSize} ${className}`}>{short ? <img src={ShortLogoImg} alt="logo" className="max-w-10" /> : <img src={LogoImg} alt="logo" className="max-w-48" />}</div>;
}

export default Logo;
