import { useEffect } from "react";
import { connectSocket, disconnectSocket, socket } from "../Utils/socket";
import { dispatchCustomEvent } from "../Utils/utils";

function useSocket() {
	useEffect(() => {
		const token = localStorage.getItem("token");
		connectSocket(token);
		if (socket) {
			socket.on("reloadChats", () => {
				dispatchCustomEvent("reloadChats");
			});

			socket.on("notification", (notification) => {
				dispatchCustomEvent("notification", notification);
			});

			socket.on("newMessage", (message) => {
				dispatchCustomEvent("newMessage", message);
			});

			socket.on("reloadReceiptStore", (message) => {
				dispatchCustomEvent("reloadReceiptStore", message);
			});

			socket.on("connect_error", (err) => {
				if (err.message === "invalid credentials") {
					disconnectSocket();
					connectSocket(localStorage.getItem("token"));
				}
			});
		}

		const disconnect = () => {
			disconnectSocket();
		};

		//Disconnect socket when component unmounts
		window.addEventListener("beforeunload", disconnect);
		return () => {
			window.removeEventListener("beforeunload", disconnect);
		};
	}, []);

	return {
		connectSocket,
		disconnectSocket,
	};
}

export default useSocket;
