import { Button } from "Components/Library";
import React from "react";

export const CheckoutPayment = ({ url, onClose, onSuccess, onFailure }) => {
	const handleLoad = (e) => {
		try {
			const currentUrl = e.target.contentWindow.location.href;
			if (currentUrl.includes("recharge=success")) {
				onSuccess();
			}
		} catch (error) {
			console.error("Cross-origin iframe, can't access location");
		}
	};

	if (!url) return null;
	return (
		<>
			<div className="flex justify-end pr-4">
				<Button variant="outlined" className="mt-4" onClick={onClose}>
					Cancel
				</Button>
			</div>
			<div className="w-full overflow-hidden rounded-b-3xl">
				<iframe onLoad={handleLoad} src={url} title="Checkout.com Payment Gateway" className="w-full h-[90vh] -mt-[6rem]" />
			</div>
		</>
	);
};
