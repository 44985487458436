import React, { useEffect } from "react";
import { Button } from "..";
import DotImage from "./dot.png";
import { FaCreditCard } from "react-icons/fa";

function RazorpayGateway({ hideBtn, btnLabel = "Pay Now", onSuccess = () => {}, onFailure = () => {}, onChange = () => {}, openPg }) {
	const loadScript = (src) => {
		return new Promise((resolve) => {
			const script = document.createElement("script");
			script.src = src;
			script.onload = () => {
				resolve(true);
			};
			script.onerror = () => {
				resolve(false);
			};
			document.body.appendChild(script);
		});
	};

	useEffect(() => {
		loadScript("https://checkout.razorpay.com/v1/checkout.js");
		if (openPg) {
			openPg.current = startPayment;
		}
	}, []);

	const startPayment = ({ orderId, amount, user }) => {
		onChange(true);
		let options = {
			key: "rzp_live_tI5XupaJPMvIaF",
			amount: amount * 100, //In Paise
			currency: "INR",
			name: "The Payment Project",
			description: "Paying to The Payment Project",
			image: DotImage,
			order_id: orderId,
			modal: {
				ondismiss: () => onChange(false),
			},
			handler: onSuccess,
			prefill: {
				name: user?.name,
				email: user?.email,
				contact: user?.phone,
			},
			notes: {
				address: "182D VPO Anangpur, Faridabad 121003, Haryana",
			},
			theme: {
				color: "#034C27",
			},
		};

		const RazPay = new window.Razorpay(options);
		RazPay.on("payment.failed", onFailure);
		RazPay.open();
	};

	if (hideBtn) return null;

	return (
		<Button className="btn-sm" onClick={startPayment}>
			<FaCreditCard className="mr-2" />
			{btnLabel}
		</Button>
	);
}

export default RazorpayGateway;
