import React from 'react';

function Finish({details={}}) {

    return ( 
        <div className='w-full h-full flex-center'>
            <div className='text-center'>
                <span className="payment-loader m-auto max-w-xs"></span>
                <h1 className='mt-6 text-5xl font-bold'>Hang tight</h1>
                <div className='mt-4 text-2xl font-thin'>Please wait a moment, while we wrap things up</div>
            </div>
        </div>
     );
}

export default Finish;