import React, { useEffect, useState } from "react";
import { BottomBar, ButtonGroup, CardsRenderer, CardsWrapper, Booking, DateRange, Pagination, Searchbox, Toast } from "../../../../../../../../Components";
import apis from "../../../../../../../../Utils/apis";
import { get } from "../../../../../../../../Utils/axios";
import moment from "moment";

function StayBookings() {
	const [BookingStatus, setBookingStatus] = useState({ label: "Upcoming", value: "upcoming" });

	const initial = {
		bookings: [],
		count: 0,
		pages: 0,
	};
	const [Query, setQuery] = useState({
		search: "",
		page: 1,
		dates: {
			startDate: moment().subtract(30, "days").valueOf(),
			endDate: moment().add(30, "days").valueOf(),
		},
	});
	const [Bookings, setBookings] = useState(initial);
	const [Loading, setLoading] = useState(true);

	//Change datepicker scope as per booking type
	useEffect(() => {
		if (BookingStatus.value === "upcoming") {
			setQuery((prev) => ({
				...prev,
				dates: {
					startDate: moment().startOf("date").valueOf(),
					endDate: Query.dates.endDate,
				},
			}));
		} else if (BookingStatus.value === "archived") {
			setQuery((prev) => ({
				...prev,
				dates: {
					startDate: Query.dates.startDate,
					endDate: moment().subtract(1, "days").startOf("date").valueOf(),
				},
			}));
		}
	}, [BookingStatus]);

	useEffect(() => {
		fetchBookings(BookingStatus.value, Query);
	}, [BookingStatus, Query]);

	const fetchBookings = (type, query) => {
		let url;
		if (type === "upcoming") {
			url = apis.getUpcomingStayBookings;
		} else if (type === "archived") {
			url = apis.getArchivedStayBookings;
		}
		setLoading(true);
		get(url, query, (r, e) => {
			if (r) {
				setBookings(r);
				setLoading();
			} else if (e) {
				Toast.handleError(e);
				setLoading();
			}
		});
	};

	return (
		<>
			<BottomBar>
				<div className="w-full flex-center-between">
					<div className="align-center gap-2">
						<h5>{Bookings.count} Stay Bookings</h5>
					</div>
					<div>
						<Pagination active={Query.page} total={Bookings.pages} onChange={(page) => setQuery({ ...Query, page })} />
					</div>
				</div>
			</BottomBar>
			<div className="mt-6 flex-center-between gap-2">
				<div className="align-center">
					<ButtonGroup
						onClick={setBookingStatus}
						size="xs !p-1.5"
						selected={BookingStatus}
						className="text-sm"
						options={[
							{ label: "Upcoming", value: "upcoming" },
							{ label: "Archived", value: "archived" },
						]}
					/>
				</div>
				<div className="align-center gap-x-2">
					<Searchbox placeholder="Search bookings" cmd="s" onChange={(search) => setQuery({ ...Query, search })} />
					<DateRange
						minDate={BookingStatus.value === "upcoming" ? moment().startOf("date").valueOf() : undefined}
						maxDate={BookingStatus.value === "archived" ? moment().subtract(1, "days").startOf("date").valueOf() : undefined}
						setRange={(dates) => setQuery({ ...Query, dates })}
						range={Query.dates}
						inputClass="!p-1.5 !text-xs"
					/>
				</div>
			</div>
			<CardsWrapper loading={Loading}>
				<CardsRenderer
					items={Bookings.bookings}
					render={(booking, i) => {
						return <Booking key={i} booking={booking} type="stay" />;
					}}
				/>
			</CardsWrapper>
		</>
	);
}

export default StayBookings;
