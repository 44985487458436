import React from 'react';
import { FaBus } from 'react-icons/fa';
import { StampTo24hr, StampToDate } from '../../../../../../../../Utils/utils';
import moment from 'moment';
import { Button } from '../../../../../../../../Components';
import { useNavigate } from 'react-router-dom';

function BusCard({operator, rating, bus, from, to, departure, arrival, amount, date, mode, type, onSelect=()=>{}, className=''}) {

    const NAVIGATE = useNavigate()

    return (
        <div className='border shadow-sm rounded-md mt-4 p-4 '>
            <div className='flex-center-between'>
                <h2>{operator.name}</h2>
                <div className='align-center gap-x-2'>
                    <div className='text-sm text-secondary'>
                        {bus}
                    </div>
                    <div className='border border-muted rounded-md p-1 text-xs bg-light font-bold'>
                        {rating}
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-2 gap-x-6 mt-4'>
                <div className={`align-center gap-x-4 ${className}`}>
                    <div className='max-w-[8rem]'>
                        <div className='font-bold whitespace-nowrap text-xl'>{StampTo24hr(departure.time)}</div>
                        <div className='text-secondary text-sm'>{StampToDate(departure.time)}</div>
                        <div className='text-secondary text-sm'>{departure.point}</div>
                        <div className='text-secondary text-sm'>{from.name}</div>
                    </div>
                    <div className='flex-grow min-w-[5rem]'>
                        <div className='align-center'>
                            <div className='w-2 h-2 rounded-full border border-dark'/>
                            <div className='flex-grow border-t border-muted border-dashed'/>
                            <FaBus/>
                            <div className='flex-grow border-t border-muted border-dashed'/>
                            <div className='w-2 h-2 rounded-full border border-dark'/>
                        </div>
                        <div className='text-center mt-2 text-xs text-secondary whitespace-nowrap'>2hr 30min</div> 
                    </div>
                    <div className='max-w-[8rem]'>
                        <div className='font-bold whitespace-nowrap text-xl'>{StampTo24hr(arrival.time)}</div>
                        <div className='text-secondary text-sm'>{StampToDate(arrival.time)}</div>
                        <div className='text-secondary text-sm'>{arrival.point}</div>
                        <div className='text-secondary text-sm'>{to.name}</div>
                    </div>

                </div>
                <div className='flex flex-col justify-between items-end'>
                    <div className='flex gap-x-2'>
                        <Button variant='outlined' className='btn-xs'>Amenities</Button>
                        <Button variant='outlined' className='btn-xs'>Pick & Drops</Button>
                        <Button variant='outlined' className='btn-xs'>Cancellation</Button>
                    </div>
                    <div className='align-center gap-x-4 mt-4'>
                        <h2>
                            ₹ 600
                        </h2>
                        <Button onClick={()=>NAVIGATE('/app/travel/buses/book')} className='btn-sm'>Select Seats</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BusCard;