import React, { useState } from "react";
import { StampTo24hr, StampToDate } from "../../../../Utils/utils";
import { FaClock, FaPlane, FaPlaneSlash } from "react-icons/fa";
import RequestStatus from "../Components/requestStatus";
import ViewRequest from "../ViewRequest";
import moment from "moment";

function FlightRequest({ id, airlineCode, flightNumber, departureCode, departureTime, arrivalCode, arrivalTime, type, status, approvalRequest, adminRequest, returnDate, onReload = () => {} }) {
	const [OpenId, setOpenId] = useState();

	const duration = Math.abs(moment(arrivalTime).diff(moment(departureTime)));
	const hours = Math.floor(moment.duration(duration).asHours());
	const minutes = moment.duration(duration).asMinutes() - 60 * hours;

	return (
		<>
			<div onClick={() => setOpenId(id)} className="overflow-hidden border shadow-sm cursor-pointer rounded-xl bg-canvas min-w-min hover:bg-light">
				<div className="p-4">
					<div className="flex items-start justify-between">
						<div className="align-center">
							<img src={`https://s3.ap-south-1.amazonaws.com/public-projectpay.in/airlines/${airlineCode}.png`} alt={airlineCode} className="w-6 h-6 mr-2 rounded-full" />
							<div className="text-xs text-secondary whitespace-nowrap">
								{airlineCode}-{flightNumber}
							</div>
						</div>
						<div className="text-xs font-bold">{StampToDate(departureTime)}</div>
					</div>
					<div className="flex mt-3 gap-x-4">
						<div>
							<div className="font-bold whitespace-nowrap">{departureCode}</div>
							<div className="text-xs text-secondary">{StampTo24hr(departureTime)}</div>
						</div>
						<div className="flex-grow w-24 flex-center">
							<div className="w-full">
								<div className="align-center">
									<div className="w-2 h-2 border rounded-full border-dark" />
									<div className="flex-grow border-t border-dotted border-dark" />
									{status === "booked" ? <FaPlane /> : status === "failed" || status === "cancelled" || status === "refunded" ? <FaPlaneSlash /> : status === "confirming" ? <FaClock /> : <></>}
									{(type === "RT" || type === "round") && (status === "booked" ? <FaPlane className="ml-6 rotate-180" /> : status === "failed" || status === "cancelled" || status === "refunded" ? <FaPlaneSlash className="ml-6 rotate-180" /> : <></>)}
									<div className="flex-grow border-t border-dotted border-dark" />
									<div className="w-2 h-2 border rounded-full border-dark" />
								</div>
								<div className="mt-2 text-xs text-center whitespace-nowrap">
									{Math.abs(hours)}Hr {Math.abs(minutes)}m
								</div>
							</div>
						</div>
						<div>
							<div className="font-bold whitespace-nowrap">{arrivalCode}</div>
							<div className="text-xs text-secondary">{StampTo24hr(arrivalTime)}</div>
						</div>
					</div>
				</div>
				{status && <RequestStatus {...{ id, status }} />}
			</div>
			<ViewRequest requestId={OpenId} onClose={() => setOpenId()} onReload={onReload} />
		</>
	);
}

export default FlightRequest;
