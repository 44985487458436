import React from "react";
import { Form, Input, Logo, ErrorMsg, Button } from "../../Components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { post, apis } from "../../Utils/axios";
import Reset from "../../Assets/Images/reset.jpg";
import { FiMail } from "react-icons/fi";

function ForgotPassword() {
	const NAVIGATE = useNavigate();
	const [Values, setValues] = useState({
		email: "",
	});
	const [Loader, setLoader] = useState();
	const [Error, setError] = useState();
	const [EmailSent, setEmailSent] = useState();

	const onLogin = (e) => {
		setError();
		setLoader(true);
		post(apis.resetPasswordUsingEmail, { ...Values }, (r, e) => {
			if (r) {
				setLoader();
				setEmailSent(true);
			} else if (e) {
				setError(e.response.data.error);
				setLoader();
			}
		});
	};

	return (
		<div className="grid h-full p-6 md:grid-cols-2">
			<div className="flex flex-col h-full">
				<Logo />
				<div className="justify-center flex-grow align-center">
					{EmailSent ? (
						<div className="w-full max-w-xs">
							<FiMail className="text-7xl" />
							<div className="mt-4 text-5xl font-bold">Check you Inbox</div>
							<div className="mt-3 text-sm">We just dropped you a mail to to set a new password. Follow the email instructions.</div>
							<Button onClick={() => NAVIGATE("/")} loading={Loader} className="mt-6">
								Go to Login
							</Button>
						</div>
					) : (
						<div className="w-full max-w-xs">
							<div className="flex text-4xl font-bold">Password Reset</div>
							<div className="mt-1 text-sm">No worries, enter your email and we will send you a reset link</div>
							<div className="flex flex-col mt-10">
								<Form onSubmit={onLogin}>
									<Input value={Values.email} onChange={(email) => setValues((prev) => ({ ...prev, email }))} required label="Email" placeholder="Email" />
									<ErrorMsg e={Error} />
									<Button loading={Loader} variant="dark" type="submit" className="w-full mt-4">
										Send Link
									</Button>
								</Form>

								<div onClick={() => NAVIGATE("/login")} className="mt-4 text-sm text-center cursor-pointer hover:underline">
									Go to Login
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="items-end hidden w-full h-full overflow-hidden text-white bg-center bg-no-repeat bg-cover border-4 md:flex rounded-2xl bg-primary border-dark" style={{ backgroundImage: `url(${Reset})` }}></div>
		</div>
	);
}

export default ForgotPassword;
