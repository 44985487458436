import React, { useEffect, useState } from "react";
import { Badge, Button, ImageViewer, Popup } from "../../../../../../Components";
import { useNavigate } from "react-router-dom";
import { TbCircleCheckFilled } from "react-icons/tb";
import { useUserConfig } from "../../../../../../Hooks/useUserConfig";
import AskApproval from "./askApproval";
import { useUserPolicy } from "../../../../../../Hooks/useUserPolicy";
import SendToAdmin from "./sendToAdmin";

function RoomsChoices({ hotel, hotelId, checkIn, checkOut, occupancy, approvalId, requestId, rooms = [] }) {
	const NAVIGATE = useNavigate();
	const { checkUserPermission } = useUserConfig();
	const { travelPolicy } = useUserPolicy();
	const [GroupedRooms, setGroupedRooms] = useState();

	useEffect(() => {
		let groupedByName = {};
		for (const room of rooms) {
			if (groupedByName[room.name]) {
				groupedByName[room.name].push(room);
			} else {
				groupedByName[room.name] = [room];
			}
		}
		setGroupedRooms(Object.values(groupedByName));
	}, [rooms]);

	const startBooking = (room) => {
		const { bookingCode, roomCode } = room;
		NAVIGATE(`/app/travel/stays/book`, {
			state: {
				hotelId,
				bookingCode,
				roomCode,
				checkIn,
				checkOut,
				occupancy,
				approvalId,
				requestId,
				room,
				hotel,
			},
		});
	};

	return (
		<>
			{GroupedRooms?.map((rooms, i) => {
				return (
					<div key={i} className="grid grid-cols-5 mt-4 overflow-hidden border rounded-md border-accent">
						<div className="flex flex-col justify-between col-span-2 p-4 bg-compliment tex-accent">
							<div>
								<h3>{rooms[0].name}</h3>
								{rooms[0].images?.length && (
									<div>
										<ImageViewer images={rooms[0].images} name={rooms[0].name} className="my-6" height="h-[10rem]" />
									</div>
								)}
							</div>
							<div className="mt-4">
								<h5>Cancellation Policy</h5>
								<div className="mt-2 text-xs">{rooms[0].cancelPolicy}</div>
							</div>
						</div>
						<div key={i} className="col-span-3">
							{rooms.map((room, i) => {
								const { amount, inPolicy, violations, images, amenities, bedTypes, bookingCode, cancelPolicy, discount, id, inclusions, name, provisionalBookingRequired, rateBreakdown, refundable, roomCode, specialCategories, tax } = room || {};
								const totalAmount = amount + tax - discount;
								const percentageOff = discount > 0 && Math.floor((discount * 100) / amount);
								const nights = rateBreakdown.length;
								const askApproval = !inPolicy && !checkUserPermission("TA");
								const adminBooking = travelPolicy?.domesticStay?.adminBooking && !checkUserPermission("TA");

								return (
									<div key={i} className="grid grid-cols-2 p-4 border-b">
										<div>
											<h3>Includes</h3>
											{inclusions.map((item, i) => {
												return (
													<div key={i} className="mt-2 text-sm align-center text-secondary">
														<TbCircleCheckFilled className="mr-2 text-primary" />
														{item === "Half Board" ? "Breakfast and Dinner 🌮" : item === "Full Board" ? "Breakfast, Lunch and Dinner 🌮" : item}
													</div>
												);
											})}
											{amenities?.length > 0 && (
												<Popup button="Amenities" btnClass="btn btn-outlined btn-xs mt-2">
													<div className="flex flex-wrap gap-2 p-4">
														{amenities.map((amenity, i) => {
															return (
																<div key={i} className="chip">
																	{amenity}
																</div>
															);
														})}
													</div>
												</Popup>
											)}
										</div>
										<div className="flex flex-col items-end justify-between">
											<div className="mb-2 text-end">
												{percentageOff && (
													<Badge variant="success" className="mr-1">
														{percentageOff}% off
													</Badge>
												)}{" "}
												<b>₹ {Number(amount).toFixed(2)}</b> <span className="text-sm text-secondary"></span>
												<br />
												<span className="text-sm text-secondary">
													{occupancy?.length} Room for {nights} Night
												</span>
											</div>
											{askApproval ? (
												<AskApproval {...{ hotel, room, checkIn: checkIn, checkOut: checkOut, violations }} />
											) : adminBooking ? (
												<SendToAdmin {...{ hotel, room, checkIn: checkIn, checkOut: checkOut, approvalId }} />
											) : (
												<Button onClick={() => startBooking(room)} className="btn-sm" variant="primary">
													Select
												</Button>
											)}
										</div>
									</div>
								);
							})}
						</div>
					</div>
				);
			})}
		</>
	);
}

export default RoomsChoices;
