import React from 'react';
import { Badge } from '../../../../../../../../Components';

function Cancellation() {
    return (
        <>
        <h6>Fare Rules</h6>
        <Badge className='mt-2' variant='success'>Full Refundable</Badge>

        <div className='text-xs mt-4'>Cancellation Charges</div>

        <div className='flex-center-between text-xs text-secondary mt-2'>
            <div>TPP Fee</div>
            <div className='text-end'>₹ 100</div>
        </div>
        
        <div className='text-xs mt-3'>Airline Charges</div>
        <div className='flex-center-between text-xs text-secondary mt-2'>
            <div>0 hrs to 4 hrs</div>
            <div className='text-end'>No-Refund</div>
        </div>
        <div className='flex-center-between text-xs text-secondary mt-2'>
            <div>Before 4 hrs</div>
            <div className='text-end'>100%</div>
        </div>
        </>
    );
}

export default Cancellation;