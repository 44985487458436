import React, { useEffect, useRef } from "react";
import NewChat from "./newChat";
import { Searchbox, Chats, Conversation } from "../../../../Components";
import useChat from "../../../../Hooks/useChat";

function Chat() {
	const chat = useChat();
	const newChatSearch = useRef();
	//Params to directly open chat
	const params = new URLSearchParams(window.location.search);
	const userId = params.get("userId");

	useEffect(() => {
		userId && chat.openChat(userId);
	}, [userId]);

	return (
		<div className="grid grid-cols-6 w-full h-full overflow-hidden relative">
			<div className="col-span-2 h-full">
				<div className="w-full h-full flex flex-col p-4">
					<div className="grid grid-cols-5 gap-2">
						<Searchbox placeholder="Search chats" className="col-span-4" onChange={chat.filterChats} />
						<NewChat chat={chat} newChatSearch={newChatSearch} />
					</div>
					<div className="flex-grow flex flex-col gap-y-2 mt-4 overflow-y-auto">
						<Chats chat={chat} />
					</div>
				</div>
			</div>
			<div className="col-span-4 h-full border-l overflow-x-hidden bg-whitish overflow-y-auto scrollbar-autohide">
				<Conversation chat={chat} newChatSearch={newChatSearch} />
			</div>
		</div>
	);
}

export default Chat;
