import React, { useEffect, useState } from "react";
import { MdSignalWifiStatusbarConnectedNoInternet4 } from "react-icons/md";
import OnOffGame from "../Games/onOff";

function InternetMonitor() {
	const [isOnline, setOnline] = useState(true);

	useEffect(() => {
		setOnline(navigator.onLine);
	}, []);

	window.addEventListener("online", () => {
		setOnline(true);
	});

	window.addEventListener("offline", () => {
		setOnline(false);
	});

	if (isOnline) return null;

	return (
		<div className="absolute top-0 left-0 z-20 w-full h-full text-white bg-black bg-opacity-80 flex-center">
			<div className="text-center">
				<MdSignalWifiStatusbarConnectedNoInternet4 className="m-auto text-8xl" />
				<div className="mt-6 text-4xl font-bold">It's your Wifi</div>
				<div className="mt-5 text-small">
					We are unable to reach our server,
					<br />
					please check your internet connection while we keep trying.
				</div>
				<OnOffGame className="mt-6 text-dark" />
			</div>
		</div>
	);
}

export default InternetMonitor;
