import React, { useEffect, useState } from "react";
import { Badge, Wizard } from "../../../../Components";
import CreateCompany from "./CreateCompany";
import AddOffice from "./AddOffice";
import DepartmentsNTeams from "./DepartmentsNTeams";
import InviteUsers from "./InviteUsers";
import DefinePolicy from "./SetupPolicy";
import SelectCompanySize from "./SelectCompanySize";
import RequestImplementation from "./RequestImplementation";
import Welcome from "./Welcome";
import Finish from "./Finish";
import { apis, post } from "../../../../Utils/axios";
import { useUserConfig } from "../../../../Hooks/useUserConfig";

function OnboardWizard() {
	const [ShowCompanySize, setShowCompanySize] = useState();
	const { switchRole } = useUserConfig();
	const [ShowRequestImplementation, setShowRequestImplementation] = useState();

	const [OnboardDetails, setOnboardDetails] = useState({});
	const [StartOnboarding, setStartOnboarding] = useState();
	const [FinishOnboarding, setFinishOnboarding] = useState();
	const [ActiveStep, setActiveStep] = useState(1);

	const [Error, setError] = useState();

	useEffect(() => {
		if (FinishOnboarding) {
			submitDetails(OnboardDetails);
		}
	}, [FinishOnboarding]);

	const onNext = (data) => {
		if (ActiveStep === 1) {
			setOnboardDetails((prev) => ({ ...prev, company: data }));
		} else if (ActiveStep === 2) {
			setOnboardDetails((prev) => ({ ...prev, users: data }));
		} else if (ActiveStep === 3) {
			setOnboardDetails((prev) => ({ ...prev, office: data }));
		} else if (ActiveStep === 4) {
			setOnboardDetails((prev) => ({ ...prev, department: data }));
		} else if (ActiveStep === 5) {
			setOnboardDetails((prev) => ({ ...prev, policy: data }));
		}
		if (ActiveStep < 5) {
			setActiveStep(ActiveStep + 1);
		} else if (ActiveStep === 5) {
			setFinishOnboarding(true);
		}
	};

	const onBack = () => {
		if (ActiveStep === 1) {
			setStartOnboarding();
			setShowRequestImplementation();
		}
		if (ActiveStep > 2) {
			setActiveStep(ActiveStep - 1);
		}
	};

	const submitDetails = (data) => {
		post(apis.onboardAdmin, data, (r, e) => {
			if (r) {
				switchRole("ADMIN");
				window.location.reload();
			} else if (e) {
				setError("We were unable to complete your onboarding. Please try again later.");
			}
		});
	};

	if (Error)
		return (
			<div className="w-full h-full flex-center">
				<Badge variant="danger">{Error}</Badge>
			</div>
		);

	if (FinishOnboarding) return <Finish details={OnboardDetails} />;

	if (ShowRequestImplementation) return <RequestImplementation onBack={onBack} />;

	if (StartOnboarding)
		return (
			<div className="w-full h-full">
				<Wizard
					active={ActiveStep}
					navigation={false}
					steps={[
						{
							label: "Create Company",
							content: <CreateCompany onSubmit={onNext} onBack={onBack} />,
						},
						{
							label: "Invite Users",
							content: <InviteUsers details={OnboardDetails} onSubmit={onNext} onBack={onBack} />,
						},
						{
							label: "Add Office",
							content: <AddOffice details={OnboardDetails} onSubmit={onNext} onBack={onBack} />,
						},
						{
							label: "Add Department",
							content: <DepartmentsNTeams details={OnboardDetails} onSubmit={onNext} onBack={onBack} />,
						},
						{
							label: "Travel Policy",
							content: <DefinePolicy details={OnboardDetails} onSubmit={onNext} onBack={onBack} />,
						},
					]}
				/>
			</div>
		);

	if (ShowCompanySize) return <SelectCompanySize onSME={() => setStartOnboarding(true)} onEnterprise={() => setShowRequestImplementation(true)} />;

	return <Welcome onSubmit={() => setShowCompanySize(true)} />;
}

export default OnboardWizard;
