import React from "react";
import { TbShieldCheck, TbShieldHalf, TbShieldOff } from "react-icons/tb";
import { useState } from "react";
import { FiArrowUpRight, FiSave } from "react-icons/fi";
import { PolicySearch, Modal, Button, Toast } from "../../..";
import { useNavigate } from "react-router-dom";
import { apis, post } from "../../../../Utils/axios";

function ChangePolicy({ onChange, id, selected = {}, entityType, policyType, placeholder }) {
	if (selected === null) {
		selected = {};
	}

	const NAVIGATE = useNavigate();
	const [ShowPolicies, setShowPolicies] = useState();
	const [Policy, setPolicy] = useState({});

	const onSave = () => {
		setShowPolicies();
		setPolicy({});
		changePolicy({
			name: Policy.label,
			id: Policy.value,
		});
	};

	const showSearch = () => {
		setShowPolicies(true);
		setPolicy({ label: selected.name, value: selected.id });
	};

	const changePolicy = (policy) => {
		post(
			entityType === "team" ? apis.updateTeamPolicy : apis.updateOfficePolicy,
			{
				...(entityType === "team" ? { teamId: parseInt(id) } : { officeId: parseInt(id) }),
				...(policyType === "travel" ? { travelPolicyId: policy.id } : { expensePolicyId: policy.id }),
			},
			(r, e) => {
				if (r) {
					Toast.success(entityType === "team" ? `Team Policy updated` : `Office Policy updated`);
					onChange && onChange();
				} else if (e) {
					console.log(e);
					Toast.handleError(e);
				}
			}
		);
	};

	return (
		<>
			{selected?.id ? (
				<Button onClick={showSearch} variant="outlined" className="btn-xs !text-primary !ring-primary">
					<TbShieldCheck className="mr-1" />
					{selected.name}
				</Button>
			) : (
				<Button onClick={showSearch} variant="outlined" className="btn-xs">
					<TbShieldOff className="mr-1" />
					No Policy
				</Button>
			)}
			<Modal open={ShowPolicies} onClose={() => setShowPolicies()}>
				<div className="p-6">
					<h1 className="align-center gap-x-2">
						<TbShieldHalf />
						{policyType === "travel" ? "Apply Travel Policy" : "Apply Expense Policy"}
					</h1>
					<div className="mt-2 text-label">Applying a policy will enforce the policy rules to all the children</div>
					<div className="mt-4">
						<div className="align-center gap-x-2">
							<PolicySearch type={policyType} onSelect={setPolicy} selected={Policy} placeholder={placeholder} />
							{selected?.id && (
								<Button
									onClick={() => {
										NAVIGATE(`/app/policies/${policyType}/${selected.id}`);
										setShowPolicies();
									}}
									variant="outlined"
								>
									View <FiArrowUpRight className="ml-2" />
								</Button>
							)}
						</div>
						<div className="flex mt-4 gap-x-2">
							<Button onClick={onSave} className="flex-grow">
								<FiSave className="mr-1" />
								Save
							</Button>
							<Button onClick={() => setPolicy({ label: "", value: null })} variant="danger" className="flex-grow">
								Remove
							</Button>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default ChangePolicy;
