import React, { useState } from "react";
import FlightCard from "./Components/flightCard";
import { classifyTime } from "../../../../../../../Utils/utils";
import Sort from "./Components/sort";
import FareCard from "./Components/fareCard";
import { useUserPolicy } from "../../../../../../../Hooks/useUserPolicy";
import UserChoice from "./Components/userChoice";
import HasApproval from "./Components/hasApproval";

function DomesticFlightResult({ approval, filters = {}, onSelect = () => {}, SelectedFlight, onwardFlights = [], returnFlights = [], journeyKey }) {
	const { travelPolicy } = useUserPolicy();
	const { showOutOfPolicyOptions = true } = travelPolicy?.domesticFlight || {};
	const [SortBy, setSortBy] = useState({
		type: "price",
		order: "asc",
	});

	const handleSelect = (selectedFare, flight, type) => {
		if (selectedFare) {
			if (type === "onward") {
				onSelect((prev) => ({ ...prev, onwardFlight: { ...flight, selectedFare } }));
			} else {
				onSelect((prev) => ({ ...prev, returnFlight: { ...flight, selectedFare } }));
			}
		} else {
			if (type === "onward") {
				const updated = { ...SelectedFlight };
				updated.onwardFlight = undefined;
				onSelect(updated);
			} else {
				const updated = { ...SelectedFlight };
				updated.returnFlight = undefined;
				onSelect(updated);
			}
		}
	};

	const sortFlights = (prev, next) => {
		if (SortBy.type === "price") {
			const prevPrice = prev.fares[0].price;
			const nextPrice = next.fares[0].price;
			return SortBy.order === "des" ? nextPrice - prevPrice : prevPrice - nextPrice;
		} else if (SortBy.type === "departure") {
			const prevDeparture = prev.segments[0]?.departureTime;
			const nextDeparture = next.segments[0]?.departureTime;
			return SortBy.order === "des" ? nextDeparture - prevDeparture : prevDeparture - nextDeparture;
		} else if (SortBy.type === "arrival") {
			const prevArrival = prev.segments[prev.segments.length - 1]?.arrivalTime;
			const nextArrival = next.segments[prev.segments.length - 1]?.arrivalTime;
			return SortBy.order === "des" ? nextArrival - prevArrival : prevArrival - nextArrival;
		} else if (SortBy.type === "duration") {
			return SortBy.order === "des" ? next.duration - prev.duration : prev.duration - next.duration;
		}
	};

	const filterFlights = (flight) => {
		if (!flight.fares.length) {
			return false;
		}
		const _stops = flight.segments.length - 1;
		const _inPolicy = true;
		const _fullRefundable = false;
		const _airline = flight.segments[0].airlineName;
		const _time = classifyTime(flight.segments[0].departureTime);
		const _price = flight.fares[0].price;

		const { maxPrice, airlines, stops, time, inPolicy, fullRefundable } = filters;

		let show = true;

		if (maxPrice && _price > maxPrice) {
			show = false;
		}
		if (stops.length && !stops.includes(_stops)) {
			show = false;
		}
		if (inPolicy && !_inPolicy) {
			show = false;
		}
		if (fullRefundable && !_fullRefundable) {
			show = false;
		}
		if (airlines.length && !airlines.includes(_airline)) {
			show = false;
		}
		if (time.length && !time.includes(_time)) {
			show = false;
		}
		return show;
	};

	if (!onwardFlights.length && !returnFlights.length)
		return (
			<div className="flex-col w-full py-20 flex-center">
				<h1 className="mb-12 text-2xl text-center">No Flights found</h1>
			</div>
		);

	return (
		<>
			<HasApproval approval={approval} />
			<UserChoice />
			<div className="grid grid-cols-2 gap-x-4">
				<div id="onward" className="group">
					<Sort sortBy={SortBy} updateSort={setSortBy} />
					{onwardFlights
						.filter(filterFlights)
						.sort(sortFlights)
						.map((flight, index) => {
							const { flightId, segments, fares, duration, inPolicy } = flight;
							const cheapestFare = fares.reduce((prev, current) => (prev.price < current.price ? prev : current));
							const highestFare = fares.reduce((prev, current) => (prev.price > current.price ? prev : current));
							const hasFares = flight?.fares.some((fare) => fare.price > 0);
							if ((!inPolicy && showOutOfPolicyOptions === false) || !hasFares) {
								return null;
							}
							return (
								<div key={index} className="mt-4 border rounded-md shadow-sm bg-canvas flight-card">
									<FlightCard key={index} segments={segments} duration={duration} />
									{flight?.fares.map((fare, index) => {
										return (
											<FareCard
												approval={approval && { approvedAmount: approval?.amount, usedAmount: SelectedFlight?.returnFlight?.selectedFare?.price }}
												key={index}
												selected={SelectedFlight?.onwardFlight}
												onSelect={(selectedFare) => handleSelect(selectedFare, flight, "onward")}
												{...{ flight, fare, cheapestFare, highestFare }}
											/>
										);
									})}
								</div>
							);
						})}
				</div>

				<div id="return" className="group">
					<Sort sortBy={SortBy} updateSort={setSortBy} />
					{returnFlights
						.filter(filterFlights)
						.sort(sortFlights)
						.map((flight, index) => {
							const { flightId, segments, fares, duration, inPolicy } = flight;
							const cheapestFare = fares.reduce((prev, current) => (prev.price < current.price ? prev : current));
							const highestFare = fares.reduce((prev, current) => (prev.price > current.price ? prev : current));
							const hasFares = flight?.fares.some((fare) => fare.price > 0);

							if (hasFares && (inPolicy || showOutOfPolicyOptions)) {
								return (
									<div key={index} className="mt-4 border rounded-md shadow-sm bg-canvas flight-card">
										<FlightCard segments={segments} duration={duration} />
										{flight?.fares.map((fare, i) => {
											return (
												<FareCard
													approval={approval && { approvedAmount: approval?.amount, usedAmount: SelectedFlight?.onwardFlight?.selectedFare?.price }}
													key={i}
													selected={SelectedFlight?.returnFlight}
													onSelect={(selectedFare) => handleSelect(selectedFare, flight, "return")}
													{...{ flight, fare, cheapestFare, highestFare }}
												/>
											);
										})}
									</div>
								);
							}

							return null;
						})}
				</div>
			</div>
		</>
	);
}

export default DomesticFlightResult;
