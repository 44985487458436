import React from "react";
import { BottomBar, Button, Container, Pagination, Searchbox, Table, Avatar } from "../../../../../Components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../../../../../Hooks/useFetch";
import apis from "../../../../../Utils/apis";

function Users() {
	const NAVIGATE = useNavigate();
	const initial = { users: [], count: 0, pages: 0 };
	const [Query, setQuery] = useState({ search: "", page: 1 });
	const { response, loading } = useFetch(apis.getAllTravellers, Query, initial);

	const viewEmployee = (user) => {
		NAVIGATE(`/app/travellers/${user.id}`);
	};

	return (
		<>
			<BottomBar>
				<div className="w-full flex-center-between">
					<h5>{response.users?.length} Users</h5>
					<div>
						<Pagination active={Query.page} total={response.pages} onChange={(page) => setQuery({ ...Query, page })} />
					</div>
				</div>
			</BottomBar>
			<div className="flex-center-between mt-6">
				<Searchbox placeholder="Search Users" cmd="s" onChange={(search) => setQuery({ ...Query, search })} />
			</div>

			<Container variant="outlined" className="!p-0 mt-4">
				<Table
					loading={loading}
					className="rounded-md"
					nodes={response.users.map((user) => ({
						avatar: <Avatar photo={user.photo} className="w-8 h-8" />,
						name: user.fullName,
						email: user.email,
						team: user.team?.name,
						office: user.office?.name,
						actions: (
							<Button onClick={() => viewEmployee(user)} variant="outlined" className="btn-xs">
								Open
							</Button>
						),
					}))}
					headers={[
						{ key: "photo", label: "Photo" },
						{ key: "name", label: "Name" },
						{ key: "email", label: "Email" },
						{ key: "team", label: "Team" },
						{ key: "office", label: "Office" },
						{ key: "actions", label: "Action" },
					]}
				/>
			</Container>
		</>
	);
}

export default Users;
