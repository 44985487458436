import React, { useEffect, useState } from "react";
import { Button, Clock, Notification, QuickChat, Toast } from "../../../../Components";
import apis from "../../../../Utils/apis";
import { get, post } from "../../../../Utils/axios";
import NotificationSound from "./notification.mp3";
import { subscribeCustomEvent } from "../../../../Utils/utils";

function Notifications() {
	const notificationSound = new Audio(NotificationSound);
	const [Notifications, setNotifications] = useState([]);

	useEffect(() => {
		fetchNotifications();
		//Subscribing to new notifications
		const removeListener = subscribeCustomEvent("notification", pushNotification);
		return removeListener;
	}, []);

	const fetchNotifications = () => {
		get(apis.getNotifications, {}, (r, e) => {
			if (r) {
				setNotifications([...r.notifications].reverse());
			}
		});
	};

	const pushNotification = (notification) => {
		if (notification.type === "NEW_MESSAGE" && window.location.pathname === "/app/chat") {
			return;
		}
		notificationSound.play();
		setNotifications((prev) => [
			{
				notification,
				unread: true,
			},
			...prev,
		]);
	};

	const markRead = (index) => {
		setNotifications((prev) => prev.map((n, i) => (i === index ? { ...n, unread: false } : n)));
	};

	const clearNotifications = (params) => {
		post(apis.clearNotifications, {}, (r, e) => {
			if (r) {
				Toast.success("Cleared");
				fetchNotifications();
			}
		});
	};

	return (
		<>
			<div className="overflow-y-auto">
				<div className="flex-center-between p-2 sticky top-0 bg-canvas z-10">
					<div className="font-semibold text-sm">Notifications</div>
					<Button onClick={clearNotifications} className="btn-xs" variant="outlined">
						Clear
					</Button>
				</div>
				<div className="p-2 pt-0">
					{Notifications.length > 0 ? (
						Notifications.map((n, i) => {
							//Parse stored notifications
							let notification = n.notification
							if (typeof notification === "string") {
								notification = JSON.parse(notification);
							}
							return <Notification key={i} onHover={() => markRead(i)} createdAt={n.createdAt} unread={n.unread} type={notification.type} notification={notification} />;
						})
					) : (
						<div className="text-label text-center py-6">No Notifications</div>
					)}
				</div>
			</div>
			<div>
				<div className="p-2 pt-0">
					<QuickChat />
				</div>
				<div className="p-2 text-center text-[11px] text-secondary">
					<Clock />
				</div>
			</div>
		</>
	);
}

export default Notifications;
