import React, { useEffect } from "react";
import { Toast } from "..";

function PeachPayments({ onSuccess = () => {}, onClose = () => {}, openPg }) {
	const loadScript = (src) => {
		return new Promise((resolve) => {
			const script = document.createElement("script");
			script.src = src;
			script.onload = () => {
				resolve(true);
			};
			script.onerror = () => {
				resolve(false);
			};
			document.body.appendChild(script);
		});
	};

	useEffect(() => {
		loadScript("https://sandbox-checkout.peachpayments.com/js/checkout.js");
		if (openPg) {
			openPg.current = startPayment;
		}
	}, [openPg]);

	const startPayment = ({ orderId, checkoutId, amount, user }) => {
		const checkout = window.Checkout.initiate({
			key: "8ac7a4c78a18b925018a1cb7ff59027e",
			checkoutId,
			events: {
				onCompleted: onSuccess,
				onCancelled: onClose,
				onExpired: (event) => {
					Toast.error("Payment expired, please try again");
					onClose();
				},
			},
			options: {
				theme: {
					brand: {
						primary: "#be3735",
					},
					cards: {
						background: "#ffffff",
					},
				},
			},
		});
		checkout.render("#payment-form");
	};

	return <div id="payment-form"></div>;
}

export default PeachPayments;
