import React, { useState } from "react";
import StayCard from "../../../../../../../Components/Travel/Stay/stayCard";
import { useNavigate } from "react-router-dom";
import { CardsRenderer, CardsWrapper } from "../../../../../../../Components";
import MapView from "./Components/maps";
import Sort from "./Components/sort";
import { useUserPolicy } from "../../../../../../../Hooks/useUserPolicy";
import UserChoice from "./Components/userChoice";
import HasApproval from "./Components/hasApproval";

function StayResult({ approval, map, filters = {}, stays = [], searchData = {} }) {
	const NAVIGATE = useNavigate();
	const { travelPolicy } = useUserPolicy();
	const { showOutOfPolicyOptions = true } = travelPolicy?.domesticStay || {};

	const [SortBy, setSortBy] = useState({
		type: "distance",
		order: "asc",
	});

	const viewHotel = (hotelId) => {
		const { rooms, checkIn, checkOut, requestId, approvalId } = searchData;
		NAVIGATE(`/app/travel/stays/hotel?hotelId=${hotelId}
		&checkIn=${checkIn}
		&checkOut=${checkOut}
		&requestId=${requestId}
		&approvalId=${approvalId}
		&rooms=${rooms}
		`);
	};

	const sortStays = (prev, next) => {
		if (SortBy.type === "price") {
			const prevPrice = prev.rooms[0].amount;
			const nextPrice = next.rooms[0].amount;
			return SortBy.order === "des" ? nextPrice - prevPrice : prevPrice - nextPrice;
		} else if (SortBy.type === "rating") {
			const prevRating = parseInt(prev.starRating);
			const nextRating = parseInt(next.starRating);
			return SortBy.order === "des" ? nextRating - prevRating : prevRating - nextRating;
		} else if (SortBy.type === "distance") {
			const prevDistance = parseInt(prev.distance);
			const nextDistance = parseInt(next.distance);
			return SortBy.order === "des" ? nextDistance - prevDistance : prevDistance - nextDistance;
		}
	};

	const filterStays = (stay) => {
		const _inPolicy = true;
		const _refundable = stay.refundable;
		const _price = stay.rooms[0].amount;
		const _chain = stay.chain;
		const _stars = parseInt(stay.starRating);

		const { maxPrice, chains, stars, query, inPolicy, refundable } = filters;

		let show = true;

		if (maxPrice && _price > maxPrice) {
			show = false;
		}
		if (inPolicy && !_inPolicy) {
			show = false;
		}
		if (refundable && !_refundable) {
			show = false;
		}
		if (chains.length && !chains.includes(_chain)) {
			show = false;
		}
		if (stars.length && !stars.includes(_stars)) {
			show = false;
		}
		if (query.length && !stay.name.toLowerCase().includes(query.toLowerCase())) {
			show = false;
		}
		return show;
	};

	if (map) {
		return (
			<div className="flex h-full">
				<MapView searchData={searchData} stays={stays} />
			</div>
		);
	}

	if (!stays.length)
		return (
			<div className="flex-col w-full py-20 flex-center">
				<h1 className="mb-12 text-2xl text-center">No Stays found</h1>
			</div>
		);

	return (
		<>
			<HasApproval approval={approval} />
			<Sort sortBy={SortBy} updateSort={setSortBy} />
			<CardsWrapper>
				<UserChoice onClick={viewHotel} />
				<CardsRenderer
					items={stays.filter(filterStays).sort(sortStays)}
					render={(stay, i) => {
						//Prevent rendering of more than 50 stays
						if (i > 50) {
							return null;
						}
						const { inPolicy } = stay;
						if (!inPolicy && showOutOfPolicyOptions === false) {
							return null;
						}
						return <StayCard key={i} {...stay} onClick={() => viewHotel(stay.id)} />;
					}}
				/>
			</CardsWrapper>
		</>
	);
}

export default StayResult;
