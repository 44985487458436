import React from 'react';
import { Popup } from '../../../../../../Components';
import FareRules from '../SRP/Result/Components/cancellation';

function FareSummary() {
    return (
        <>
        <h3>Fare Summary</h3>
        <Popup button='Fare Rules' btnClass='text-xs' contentClass='p-4 !max-w-xs'>
            <FareRules/>
        </Popup>

        <div className='text-xs mt-4'>Base Fare</div>
        <div className='flex-center-between text-xs text-secondary mt-2'>
            <div>1 Adult</div>
            <div className='text-end'>₹ 800</div>
        </div>

        <div className='text-xs mt-3'>Fee</div>
        <div className='flex-center-between text-xs text-secondary mt-2'>
            <div>Platform fee</div>
            <div className='text-end'>₹ 120</div>
        </div>
        <div className='flex-center-between text-xs text-secondary mt-2'>
            <div>Tax</div>
            <div className='text-end'>₹ 90</div>
        </div>
        <div className='flex-center-between font-bold bg-compliment text-accent p-3 mt-4 rounded-lg'>
            <div>Booking Amount</div>
            <div className='text-end'>₹ 10,10</div>
        </div>
        </>
    );
}

export default FareSummary;