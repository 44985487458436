import React from "react";
import FlightCard from "../../../../Pages/App/Employee/Travel/Flights/Oneway/Result/Components/flightCard";
import { StampToDate } from "../../../../Utils/utils";

function FlightApproval({ fare, flight, onwardFlight, returnFlight, onwardFare, returnFare, type }) {
	const renderFlight = (flight) => {
		return (
			<div className="border rounded-md">
				<FlightCard {...flight} />
			</div>
		);
	};

	return (
		<>
			<div className="p-4 rounded-lg shadow-sm bg-canvas">
				<h4>User's Choice</h4>
				<div className="flex flex-col mt-4 gap-y-2">
					{type === "oneway" ? (
						<>
							<div className="flex-center-between">
								<label>Departure on {StampToDate(flight.segments[0].departureTime)}</label>
								<label>₹ {fare.price}</label>
							</div>
							{renderFlight(flight)}
						</>
					) : type === "round" ? (
						<>
							<div className="flex-center-between">
								<label>Departure on {StampToDate(onwardFlight.segments[0].departureTime)}</label>
								<label>₹ {onwardFare.price}</label>
							</div>
							{renderFlight(onwardFlight)}
							<div className="flex-center-between">
								<label>Return on {StampToDate(returnFlight.segments[0].departureTime)}</label>
								<label>₹ {returnFare.price}</label>
							</div>
							{renderFlight(returnFlight)}
						</>
					) : null}
				</div>
			</div>
		</>
	);
}

export default FlightApproval;
