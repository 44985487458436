import React from 'react';
import { Badge } from '../..';

function StatusIndicator({status}) {
    if(status === 'approved'){
        return <Badge variant='success'>Approved</Badge>
    }
    if(status === 'booked'){
        return <Badge variant='success'>Booked</Badge>
    }
    if(status === 'rejected'){
        return <Badge variant='danger'>Rejected</Badge>
    }
    if(status === 'pending'){
        return <Badge variant='warning'>Pending</Badge>
    }
    return null
}

export default StatusIndicator;