import React, { useState } from "react";
import { Button } from "..";
import Image from "../../Assets/Images/intros/rainbow.png";
import UpgradeToTeams from "./UpgradeToTeams";
import { useAdminPermission } from "../../Hooks/useAdminPermission";

function TppForTeams() {
	const { checkAdminPermission } = useAdminPermission();
	const [Enable, setEnable] = useState();

	if (checkAdminPermission("EDIT_ADMINS")) {
		return (
			<>
				<div className="relative p-4 mb-2">
					<div className="absolute top-0 bottom-0 left-0 right-0 gradient-glass -z-10" />
					<img src={Image} className="w-full mb-4 max-w-[8rem]" alt="Tpp for teams" />
					<div className="font-medium">Try Travel Wings for Teams</div>
					<div className="text-label">Invite other users and simplify travel for your entire company</div>
					<Button onClick={() => setEnable(true)} variant="primary" className="mt-2 btn-xs">
						Enable for Teams
					</Button>
				</div>
				<UpgradeToTeams open={Enable} onClose={setEnable} />
			</>
		);
	}
	return null;
}

export default TppForTeams;
