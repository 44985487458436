import React from "react";
import { Combobox, Toast, AddUser } from "../../";
import { useState } from "react";
import { apis, get } from "../../../Utils/axios";
import EmployeeInfo from "../../Cards/EmployeeInfo";

function EmployeeSearch({ onSelect = () => {}, placeholder = "Search Users", label, selected = {}, searchOnly = false, className = "" }) {
	const [AllUsers, setAllUsers] = useState([]);
	const [Loader, setLoader] = useState();

	const onChange = (query) => {
		if (query) {
			fetchUsers(query);
		} else {
			setAllUsers([]);
		}
	};

	const fetchUsers = (query) => {
		setLoader(true);
		get(searchOnly ? apis.searchUsers : apis.getAllUsers, { search: query, page: 1 }, (r, e) => {
			if (r) {
				setAllUsers(r.users);
				setLoader(false);
			} else if (e) {
				console.log(e);
				Toast.handleError(e);
				setLoader(false);
			}
		});
	};

	return (
		<div className="flex items-end w-full gap-2">
			<Combobox
				loading={Loader}
				label={label}
				placeholder={placeholder}
				className={className}
				onChange={onChange}
				options={AllUsers.map((user) => ({
					...user,
					label: `${user.fName} ${user.lName}`,
					value: user.id,
				}))}
				selected={selected}
				onSelect={onSelect}
				renderItem={(employee, i) => (
					<li className="!p-0 hover:bg-light">
						<EmployeeInfo className="w-full" key={i} employee={employee} />
					</li>
				)}
			/>
			{!searchOnly && <AddUser btnLabel="+ New" />}
		</div>
	);
}

export default EmployeeSearch;
