import React, { useState } from "react";
import { Img } from "../../..";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { FiXCircle } from "react-icons/fi";

function ImageViewer({ name = "", images = [], className = "", height = "h-[20rem]" }) {
	const [ViewImage, setViewImage] = useState();
	const [ActiveSlide, setActiveSlide] = useState({ activeIndex: 0 });

	return (
		<>
			<div className={`md:grid md:grid-cols-2 gap-x-2 w-full rounded-lg overflow-hidden ${height} ${className}`}>
				<Img loading="lazy" onClick={() => setViewImage(images[0]?.image)} src={[images[0]?.image]} className="object-cover w-full cursor-pointer hover:brightness-75" />
				<div className={`gap-2 md:grid md:grid-cols-2 ${height}`}>
					{images.length > 4 &&
						[images[1], images[2], images[3], images[4]].map(({ image, thumbnail, category }, index) => {
							return <Img loading="lazy" onClick={() => setViewImage(image)} alt={category} key={index} src={image} className="object-cover w-full h-full cursor-pointer hover:brightness-75" />;
						})}
				</div>
			</div>
			{ViewImage && (
				<div className="fixed top-0 bottom-0 left-0 right-0 z-20 text-gray-200 bg-black fade-in">
					<div className="flex flex-col w-full h-full gap-6 p-6">
						<div className="h-10 flex-center-between">
							<div>{name}</div>
							<div>
								( {ActiveSlide.activeIndex || 0 + 1}/{images.length} )
							</div>
							<div onClick={() => setViewImage()} className="cursor-pointer align-center">
								<FiXCircle className="mr-1" />
								Close
							</div>
						</div>
						<div className="flex-grow px-20">
							<Swiper slidesPerView={"auto"} onSlideChange={setActiveSlide} modules={[Navigation]} navigation={true} className="w-full h-full swiper-carousel">
								{images.map(({ image, category }, index) => {
									return (
										<SwiperSlide key={index} className="flex-center">
											<Img src={image} alt={category} loading="lazy" className="w-full h-full max-h-[80vh] rounded-xl" />
										</SwiperSlide>
									);
								})}
							</Swiper>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default ImageViewer;
