import React, { useRef, useState } from "react";
import { AnimatedTick, Button, CustomFields, Employee, Modal, Toast, Violations } from "../../../../../../Components";
import { FaShieldAlt } from "react-icons/fa";
import { apis, post } from "../../../../../../Utils/axios";

function AskApproval({ hotel, room, violations, checkIn, checkOut }) {
	const [ShowPrompt, setShowPrompt] = useState();
	const [Approvers, setApprovers] = useState([]);
	const NOTES = useRef();
	const GET_CUSTOM_FIELDS = useRef();

	const sendApproval = () => {
		const customFields = GET_CUSTOM_FIELDS.current();
		if (!customFields) {
			//Required custom fields not filled
			return false;
		}
		post(
			apis.askStayApproval,
			{
				hotel,
				room,
				checkIn,
				checkOut,
				notes: NOTES.current.value,
			},
			(r, e) => {
				if (r) {
					setApprovers(r.approvers);
				} else if (e) {
					Toast.handleError(e);
				}
			}
		);
	};

	return (
		<>
			<Button onClick={() => setShowPrompt(true)} variant="primary" className="btn-sm">
				Ask for Approval
			</Button>
			<Modal
				open={ShowPrompt}
				onClose={() => {
					setShowPrompt(false);
					setApprovers([]);
				}}
				contentClass="!max-w-[30rem]"
			>
				{Approvers.length > 0 ? (
					<div className="p-8">
						<AnimatedTick />
						<h1 className="flex-center mt-4">Request Sent</h1>
						<div className="text-sm text-center">We have sent a request to your manager. You can track the status of approval in Stay bookings</div>
						<div className="flex-center flex-col gap-2 mt-6">
							<Employee className="bg-light" employee={Approvers[0]} />
						</div>
					</div>
				) : (
					<div className="p-6">
						<h1 className="align-center gap-2">
							<FaShieldAlt />
							Ask for Approval
						</h1>
						<div className="p-2 text-sm">This stay is out of your policy, if you want to book this flight you can send a request to your manager for approval.</div>
						<Violations violations={violations} />
						<div className="flex flex-col gap-2 mt-4">
							<label>Notes (Optional)</label>
							<textarea ref={NOTES} className="input" placeholder="Notes to the approver" />
						</div>
						<CustomFields className="mt-4" getCustomFields={GET_CUSTOM_FIELDS} activity="STAY_BOOK" />
						<Button onClick={sendApproval} className="mt-6 w-full" variant="primary">
							Yes, Ask for Approval
						</Button>
					</div>
				)}
			</Modal>
		</>
	);
}

export default AskApproval;
