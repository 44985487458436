const apis = {
	//App
	loginUsingEmail: "/login",
	signupUsingEmail: "/signup",
	resendSignupEmail: "/signup/link",
	onboardUser: "/account/profile",
	onboardNewUser: "/account/profile",
	getActiveSessions: "/login/sessions",
	terminateSession: "/login/session",
	getTempPassword: "/login/temp",
	changePassword: "/login/password",

	verifyEmail: "/signup/verify",
	verifyInvitation: "/signup/invite",
	activateAccount: "/signup/activate",

	resetPasswordUsingEmail: "/login/reset",

	getClientSettings: "/clients/settings",
	updateClientSettings: "/clients/settings",

	getAllOffices: "/offices",
	getOffices: "/offices",
	getOfficeDetails: "/offices/:id",
	addOffice: "/offices",
	updateOffice: "/offices/:id",
	deleteOffice: "/offices/:id",
	updateOfficeHeads: "/offices/:id/heads",
	updateOfficePolicy: "/offices/:id/policy",

	getAllTeams: "/teams",
	getTeams: "/teams",
	getTeamDetails: "/teams/:id",
	addTeam: "/teams",
	updateTeam: "/teams/:id",
	updateTeamOffice: "/teams/:id",
	deleteTeam: "/teams/:id",
	updateTeamHeads: "/teams/:id/heads",
	updateTeamPolicy: "/team/:id/policy",

	//User Management
	getUser: "/users/:id",
	getUserDetails: "/users/:id",

	getAllUsers: "/users",
	getAdmins: "/users/admins",
	getTravelAdmins: "/users/admins/travel",
	addUser: "/users",

	updateUser: "/users/:id",
	updateUserTeam: "/users/:id/team",
	updateUserRole: "/users/:id/config",
	getAdminConfig: "/users/:id/config",
	updateAdminConfig: "/users/:id/config",
	getUserConfig: "/users/:id/config",
	updateUserConfig: "/users/:id/config",

	updateUserIdentification: (id) => `/users/${id}/identification`,
	updateGuestIdentification: (id) => `/guests/${id}/identification`,
	updateMyIdentification: "/account/identification",

	deleteUser: "/users/:id",

	getMyAdminPermissions: "/account/config",
	getMyProfile: "/account/profile",
	updateMyProfile: "/account/profile",

	getMyPolicy: "/account/policy",
	getMyConfig: "/account/config",
	uploadDocument: "/account/documents",

	getWithMeReceipts: "/receipts/pending",
	getPendingReceipts: "/receipts/pending",
	getPayoutReceipts: "/receipts/payout",
	getCompletedReceipts: "/receipts/submitted",
	getReceiptDetails: "/receipts/:id",
	addReceipt: "/receipts",
	updateReceipt: "/receipts/:id",
	submitReceipts: "/receipts/submit",
	markReceiptPaid: "/receipts/:id/mark",
	uploadReceipt: "/receipts/file",
	runOcr: "/receipt/ocr",

	getExpenseCategories: "/expense/categories",
	addExpenseCategory: "/expense/category",
	updateExpenseCategory: "/expense/category/:id",
	removeExpenseCategory: "/expense/category/:id",

	getCostCenters: "/",
	getTeamsInCostCenter: "/budgets/:id/teams",
	getCostCenterDetails: "/budgets/:id",
	addCostCenter: "/budgets",
	updateCostCenter: "/budgets/:id",
	updateCostCenterBudget: "/budgets/:id",
	archiveCostCenter: "/budgets/:id/archive",
	updateCostCenterHeads: "/budgets/:id/heads",
	addTeamToCostCenter: "/budgets/:id/team",
	removeTeamFromCostCenter: "/budgets/:id",
	getCostCenterHistory: "/budgets/:id/history",

	sendInvite: "/invitations",
	sendJoiningLink: "/invitations/join",
	resendInvite: "/invitations/:id/resend",
	revokeInvite: "/invitations/:id",
	getInvitedUsers: "/invitations",

	getWalletBalance: "/wallet",
	getWalletTransactions: "/wallet/transactions",
	updatePaymentDetails: "/wallet/payment",

	//Not Updated
	joinTeam: "/signup/join",
	verifyGst: "/gstin/verify",

	addMoneyToWallet: "/wallet/addMoney",
	orderWalletRecharge: "/wallet/recharge",
	downloadWalletPassbook: "/wallet/passbook",
	getBalanceForecast: "/wallet/forecast",
	askGpt: "/ai",
	getWeather: "/weather",

	upgradeToTeams: "/subscription/upgrade",
	getTravelAnalytics: "/analytics/travel",
	getPendingApprovalsCount: "/analytics/pendingApprovals",
	getLeaderBoard: "/analytics/leaderBoard",

	toggleUser: "/user/toggle",
	getUserApprovalFlow: "/user/approvalFlow",

	getFlightReport: "/report/flight",
	downloadFlightReport: "/report/flight/download",
	getStayReport: "/report/stay",
	downloadStayReport: "/report/stay/download",
	getComplianceReport: "/report/compliance",
	downloadComplianceReport: "/report/compliance/download",
	getMissedSavingsReport: "/report/missed",
	downloadMissedSavingsReport: "/report/missed/download",
	downloadExpenseReport: "/report/expense/download",
	getExpenseReport: "/report/expense",

	getFlightReportFromAi: "/afi/report/flight",

	getAllTravellers: "/traveller/all",
	getTravellerDetails: "/traveller/details",
	getAllGuests: "/guest/all",
	addGuest: "/guest",
	deleteGuest: "/guest/delete",

	//Travel Policies
	getTravelPolicy: "/policy/travel/details",
	addTravelPolicy: "/policy/travel/add",
	deleteTravelPolicy: "/policy/travel/delete",
	getAllTravelPolicies: "/policy/travel/all",
	getTravelPolicies: "/policy/travel",
	getTravelPolicyConditions: "/policy/travel/conditions",
	getTravelPolicyRules: "/policy/travel/rules",
	updateTravelPolicyConditions: "/policy/travel/conditions",
	addTravelPolicyRule: "/policy/travel/rule",
	updateTravelPolicyRulePriority: "/policy/travel/rule/priority",
	deleteTravelPolicyRule: "/policy/travel/rule/delete",
	updateTravelPolicyRule: "/policy/travel/rule/update",
	getTravelPolicyHealth: "/policy/travel/health",
	getTeamsInTravelPolicy: "/policy/travel/teams",
	addTeamInTravelPolicy: "/policy/travel/team",
	removeTeamFromTravelPolicy: "/policy/travel/team/remove",
	getOfficesInTravelPolicy: "/policy/travel/offices",
	addOfficeInTravelPolicy: "/policy/travel/office",
	removeOfficeFromTravelPolicy: "/policy/travel/office/remove",
	getEntitiesInTravelPolicy: "/policy/travel/entity",

	//Expense Policies
	getExpensePolicy: "/policy/expense/details",
	addExpensePolicy: "/policy/expense/add",
	deleteExpensePolicy: "/policy/expense/delete",
	getAllExpensePolicies: "/policy/expense/all",
	getExpensePolicies: "/policy/expense",
	getExpensePolicyConditions: "/policy/expense/conditions",
	getExpensePolicyRules: "/policy/expense/rules",
	updateExpensePolicyConditions: "/policy/expense/conditions",
	addExpensePolicyRule: "/policy/expense/rule",
	updateExpensePolicyRulePriority: "/policy/expense/rule/priority",
	deleteExpensePolicyRule: "/policy/expense/rule/delete",
	updateExpensePolicyRule: "/policy/expense/rule/update",
	getExpensePolicyHealth: "/policy/expense/health",
	getTeamsInExpensePolicy: "/policy/expense/teams",
	addTeamInExpensePolicy: "/policy/expense/team",
	removeTeamFromExpensePolicy: "/policy/expense/team/remove",
	getOfficesInExpensePolicy: "/policy/expense/offices",
	addOfficeInExpensePolicy: "/policy/expense/office",
	removeOfficeFromExpensePolicy: "/policy/expense/office/remove",
	getEntitiesInExpensePolicy: "/policy/expense/entity",

	getStore: "/store",
	addInStore: "/store",
	getStoreCode: "/store/code",
	removeFromStore: "/store/delete",

	organizationCount: "/count/organization",
	policyCount: "/count/policy",
	atAGlanceCount: "/count/glance",
	getBookingCount: "/count/booking",

	getCustomFields: "/field",
	addCustomField: "/field/add",
	deleteCustomField: "/field/delete",

	searchTravellers: "/search/traveller",
	searchUsers: "/search/user",
	searchTeams: "/search/team",
	searchMyCostCenters: "/search/costCenter",
	searchCostCenters: "/search/costCenter/all",
	searchEntity: "/search/entity",
	getNotifications: "/notifications",
	clearNotifications: "/notifications/clear",

	getChats: "/chats",
	createChat: "/chats",
	getMessages: "/chats/messages",
	sendMessage: "/chats/message",

	searchCountry: "/search/country",
	searchAirports: "/search/airport",
	searchCities: "/search/city",
	searchPlaces: "/search/place",
	getOneWayFlights: "/flight/oneway",
	getRoundFlights: "/flight/round",
	getFreeBaggage: "/flight/freeBaggage",
	getFareRules: "/flight/rules",
	confirmFlightFare: "/flight/confirm",
	recheckFlightFare: "/flight/recheck",

	holdOnewayFlight: "/flight/hold/oneway",
	holdRoundFlight: "/flight/hold/round",
	getFlightAddons: "/flight/addons",
	updateTravellers: "/flight/travellers",
	updateAddons: "/flight/addons",
	checkFlightPrice: "/flight/checkPrice",
	bookFlight: "/flight/book",

	searchStays: "/stay/search",
	getHotelDetails: "/stay/details",
	getHotelPolicy: "/stay/policy",
	holdStay: "/stay/hold",
	bookStay: "/stay/book",

	///Bookings
	getTravelTimeline: "/bookings/timeline",
	//Cab
	getUpcomingCabBookings: "/bookings/cab/upcoming",
	getArchivedCabBookings: "/bookings/cab/archived",
	getCabBookingDetails: "/bookings/cab/details",
	importCabBooking: "/bookings/cab/import",
	addUserInCabBooking: "/bookings/cab/tagUser",
	//Train
	getUpcomingTrainBookings: "/bookings/train/upcoming",
	getArchivedTrainBookings: "/bookings/train/archived",
	getTrainBookingDetails: "/bookings/train/details",
	importTrainBooking: "/bookings/train/import",
	addUserInTrainBooking: "/bookings/train/tagUser",
	//Flight
	getPendingFlightBookings: "/bookings/flight/pending",
	getUpcomingFlightBookings: "/bookings/flight/upcoming",
	getArchivedFlightBookings: "/bookings/flight/archived",
	getFlightBookingDetails: "/bookings/flight/details",
	pollFlightBookingDetails: "/bookings/flight/poll",
	importFlightBooking: "/bookings/flight/import",
	addUserInFlightBooking: "/bookings/flight/tagUser",
	cancelFlightBooking: "/bookings/flight/cancel",
	getFlightCancellationCharges: "/bookings/flight/charges",

	//Stay
	getPendingStayBookings: "/bookings/stay/pending",
	getUpcomingStayBookings: "/bookings/stay/upcoming",
	getArchivedStayBookings: "/bookings/stay/archived",
	getStayBookingDetails: "/bookings/stay/details",
	importStayBooking: "/bookings/stay/import",
	addUserInStayBooking: "/bookings/stay/tagUser",

	//Approvals
	//Flight
	getFlightApprovalDetails: "approval/flight/details",
	askOnewayFlightApproval: "/approval/flight/oneway",
	askRoundFlightApproval: "/approval/flight/round",
	//Stay
	askStayApproval: "/approval/stay",

	//Manager
	getTodaysApprovals: "/approval/today",
	getPendingApprovals: "/approval/pending",
	getApprovedApprovals: "/approval/approved",
	getRejectedApprovals: "/approval/rejected",
	getApprovalDetails: "/approval/details",
	actionOnApproval: "/approval/action",
	withdrawApproval: "/approval/withdraw",

	//Requests
	//Cab
	requestCab: "/request/cab",
	getRequestedCabRequests: "/request/cab/requested",
	getFulfilledCabRequests: "/request/cab/fulfilled",
	getDeniedCabRequests: "/request/cab/denied",
	//Train
	requestTrain: "/request/train",
	getRequestedTrainRequests: "/request/train/requested",
	getFulfilledTrainRequests: "/request/train/fulfilled",
	getDeniedTrainRequests: "/request/train/denied",
	//Flight
	requestOnewayFlight: "/request/flight/oneway",
	requestRoundFlight: "/request/flight/round",
	getRequestedFlightRequests: "/request/flight/requested",
	getFulfilledFlightRequests: "/request/flight/fulfilled",
	getDeniedFlightRequests: "/request/flight/denied",
	//Stay
	getRequestedStayRequests: "/request/stay/requested",
	getFulfilledStayRequests: "/request/stay/fulfilled",
	getDeniedSTayRequests: "/request/stay/denied",
	requestStay: "/request/stay",
	//For TA
	getRequestDetails: "/request/details",
	denyBookingRequest: "/request/deny",
	withdrawBookingRequest: "/request/withdraw",
	getRequestorDetails: "/request/requestedBy",

	//Mail
	getMailApprovalDetails: "/mail/approval/details",
	actionOnMailApproval: "/mail/approval/action",
	getMailFlightBookingDetails: "/mail/flight/details",
};

export default apis;
