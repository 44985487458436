import React, { useEffect, useState } from "react";
import { Logo, Spinner } from "../../Components";
import apis from "Utils/apis";
import { post } from "Utils/axios";
function ActivateAccount() {
	const Params = new URLSearchParams(window.location.search);
	const code = Params.get("code");
	const [Activated, setActivated] = useState();

	useEffect(() => {
		if (code) {
			post(apis.activateAccount, { code }, (r) => {
				setActivated(r);
			});
		}
	}, [code]);

	if (Activated) {
		return (
			<div className="justify-center w-full h-full align-center">
				<div className="w-full max-w-md p-4 space-y-10 text-center">
					<Logo className="justify-center m-auto w-28" />
					<div className="text-4xl font-bold">Account Activated</div>
					<div className="text-lg font-light">
						Account was activated successfully, We have also informed {Activated.name} through email at {Activated.email}.
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="justify-center w-full h-full align-center">
			<Spinner size="lg" />
		</div>
	);
}

export default ActivateAccount;
