import React from "react";
import StatusIndicator from "./status";
import { CopyValue } from "../../..";

function TrainBookingStatus({ booking = {} }) {
	const { adminRequest, status, id } = booking;

	return (
		<div className="p-2 border-t flex-center-between bg-whitish">
			<CopyValue className="text-xxs">
				{adminRequest ? "TRN/REQ" : "TRN"}-{id}
			</CopyValue>
			<StatusIndicator status={status} />
		</div>
	);
}

export default TrainBookingStatus;
