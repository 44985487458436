import React from "react";
import Chat from "../../Pages/App/Common/Chat/Components/chat";

function Chats({ chat }) {
	const { loadingChat, chats, activeChat, setChat } = chat;

	if (loadingChat) {
		return new Array(3).fill(0).map((_, i) => <div key={i} role="status" className="w-full min-h-[3.6rem] rounded-xl animate-pulse bg-gray-100" />);
	}

	if (chats?.length > 0) {
		return chats?.map((chat, i) => <Chat key={i} {...chat} active={activeChat?.id === chat.id} onSelect={() => setChat(chat)} />);
	}

	return <div className="py-6 text-center text-label">No Chats Found</div>;
}

export default Chats;
