import React, { useEffect, useState } from "react";
import { Loader, Toast, Modal } from "../../../..";
import apis from "../../../../../Utils/apis";
import { get } from "../../../../../Utils/axios";
import Additional from "./additional";
import BookingDetails from "./details";
import Flights from "./flights";
import BookedFor from "../bookedFor";
import FlightCancellation from "./cancellation";
import DownloadTicket from "./downloadTicket";

function FlightBooking({ bookingId, onReload = () => {}, onClose = () => {} }) {
	const [Loading, setLoading] = useState(true);
	const [Booking, setBooking] = useState({});
	//To reload all bookings if polling changed the status
	const [IsUpdated, setIsUpdated] = useState(false);

	const { id, date, amount, airlineCode, flightNumber, departureCode, departureTime, arrivalCode, arrivalTime, type, booking, pnr, ticket, status, createdAt, updatedAt, userId, maker, passengers } = Booking;
	const { cancellations, contact, files, flights, fwd_txns, rev_txns, isInternational, meta, payment, travellers, tripId } = booking || {};
	const { airports, airlines } = meta || {};
	let pollingTimeout;

	useEffect(() => {
		if (bookingId) {
			fetchBooking(bookingId);
		}
		// Clear the polling timeout
		return () => {
			clearTimeout(pollingTimeout);
		};
	}, [bookingId]);

	const fetchBooking = (bookingId) => {
		setLoading(true);
		get(apis.getFlightBookingDetails, { bookingId }, (r, e) => {
			if (r) {
				setBooking(r);
				if (r.status === "confirming" || r.status === "cancelled") {
					pollingTimeout = setInterval(() => {
						pollBooking(bookingId);
					}, 5000);
				}
				setLoading(false);
			} else if (e) {
				Toast.handleError(e);
				setLoading(false);
			}
		});
	};

	const pollBooking = (bookingId) => {
		get(apis.pollFlightBookingDetails, { bookingId }, (r, e) => {
			if (r) {
				if (r.status !== "confirming" && r.status !== "cancelled") {
					clearInterval(pollingTimeout);
					setBooking((prev) => ({
						...prev,
						...r,
					}));
					setIsUpdated(true);
				}
			}
		});
	};

	return (
		<>
			<Modal
				open={bookingId}
				onClose={() => {
					//Reload flight bookings to get new status
					if (IsUpdated) {
						onReload();
					}
					onClose();
				}}
			>
				<Loader loading={Loading} />
				<div className="p-4 rounded-lg bg-light">
					<div className="rounded-lg shadow-md bg-canvas">
						<Flights {...{ flights, airports, departureCode, arrivalCode }} />
						<BookingDetails {...{ pnr, amount, id, status, payment }} />
						<Additional {...{ airlines, airlineCode, ticket, status, isImported: booking?.isImported }} />
					</div>
					<BookedFor type="flight" bookingId={bookingId} travellers={passengers} onReload={() => fetchBooking(bookingId)} />
					{status === "booked" && (
						<div className="flex mt-4 gap-x-2">
							<DownloadTicket data={Booking} />
							{!booking.isImported && status === "booked" && (
								<FlightCancellation
									bookingId={bookingId}
									onCancel={() => {
										fetchBooking(bookingId);
										setIsUpdated(true);
									}}
								/>
							)}
						</div>
					)}
				</div>
			</Modal>
		</>
	);
}

export default FlightBooking;
