import React from "react";
import { Attachments, KeyValue, Violations, Toast, DatePicker, EditableInput, EditableSelect, Remarks } from "../..";
import StatusIndicator from "../Components/status";
import { apis, post } from "../../../Utils/axios";
import moment from "moment";
import useFetch from "../../../Hooks/useFetch";
import NextAction from "./nextAction";

function EditableReceipt({ receipt, onReload = () => {} }) {
	const { response } = useFetch(apis.getExpenseCategories, null, { categories: [] });
	const { id, date, amount, category, status, description, violations, remarks } = receipt;

	const onUpdate = (obj) => {
		post(
			apis.updateReceipt,
			{
				...obj,
				receiptId: id,
			},
			(r, e) => {
				if (r) {
					Toast.success(`Receipt Updated`);
					onReload();
				} else if (e) {
					Toast.handleError(e);
				}
			}
		);
	};

	return (
		<>
			<div className="bg-canvas p-4 shadow-sm rounded-t-lg">
				<h1>Expense</h1>
				<div className="mt-6">
					<h4>Receipt Details</h4>
					<div className="flex flex-col gap-y-2 mt-4">
						<Violations className="mb-2" violations={violations} />
						<KeyValue label="Receipt ID">{id}</KeyValue>
						<KeyValue label="Date">
							<DatePicker btnClass="!py-1 !text-xs max-w-min" date={date} setDate={(date) => onUpdate({ date })} backDateSelection maxDate={moment().valueOf()} />
						</KeyValue>
						<KeyValue label="Amount">
							<EditableInput required onUpdate={(amount) => onUpdate({ amount })} value={amount} label="Amount" /> ₹ {amount}
						</KeyValue>
						<KeyValue label="Category">
							<EditableSelect options={response.categories.map((c) => ({ value: c.id, label: c.name }))} onChange={(category) => onUpdate({ categoryId: category.value })} selected={{ label: category?.name || "None", value: category?.id }} />
						</KeyValue>
						<KeyValue label="Purpose">
							<EditableInput required onUpdate={(description) => onUpdate({ description })} value={description} label="Description" />
							{description}
						</KeyValue>
						<KeyValue label="Status">
							<StatusIndicator status={status} />
						</KeyValue>
					</div>
					{receipt.attachments?.length > 0 && (
						<>
							<h4 className="mt-4">Attachments</h4>
							<Attachments className="mt-4" files={receipt.attachments} />
						</>
					)}
					<Remarks remarks={remarks} className="mt-4" />
				</div>
			</div>
			<div className="zigzag rotate-180" />
			<NextAction status={status} />
		</>
	);
}

export default EditableReceipt;
