import React from "react";
import { Badge, CostCenterSearch, CustomFields, StayPreviewCard } from "../../../../../../../../Components";
import moment from "moment";
import { StampToDate } from "../../../../../../../../Utils/utils";

function ReviewStep({ hotel = {}, room = {}, hotelPolicy = {}, checkIn, checkOut, occupancy = [], getCustomFields, costCenter, setCostCenter }) {
	const nights = moment(checkOut).diff(moment(checkIn), "days");
	const refundable = hotelPolicy.refundable === "REFUNDABLE";

	return (
		<>
			<div className="mt-4 flex-center-between">
				<div className="align-center gap-x-2">
					<h4>{nights} Nights Stay</h4>
					<div className="text-sm chip">Check in {StampToDate(checkIn)}</div>
					<div className="text-sm chip">Check out {StampToDate(checkOut)}</div>
				</div>
				{refundable ? <Badge variant="success">Refundable</Badge> : <Badge variant="danger">Non-Refundable</Badge>}
			</div>
			<StayPreviewCard hotel={hotel} room={room} occupancy={occupancy} />
			<div className="mt-4">
				<CostCenterSearch className="p-4 mb-4 border rounded-lg" label="Select Cost center" onSelect={setCostCenter} selected={costCenter} />
				<CustomFields className="p-4 mb-4 border rounded-lg" getCustomFields={getCustomFields} activity="STAY_BOOK" />
			</div>
		</>
	);
}

export default ReviewStep;
