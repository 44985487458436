import React from "react";
import { Button, Logo } from "../Components";
import { useNavigate } from "react-router-dom";

function NotFound() {
	const NAVIGATE = useNavigate();

	return (
		<div className="h-full w-full align-center justify-center">
			<div className="w-full max-w-md text-center">
				<Logo className="justify-center" error />
				<div className="text-5xl font-bold mt-6">Page not found</div>
				<div className="text-sm mt-3">It looks like you have followed a broken link, this page doesn't exist</div>
				<Button onClick={() => NAVIGATE("/app")} className="m-auto mt-8" variant="outlined">
					Go to Home
				</Button>
			</div>
		</div>
	);
}

export default NotFound;
