import React, { useEffect, useState } from "react";
import { Button, Modal } from "..";
import useFetch from "../../Hooks/useFetch";
import apis from "../../Utils/apis";
import { EmptyState, Img, ReceiptStoreCode, Spinner, Toast } from "..";
import { FiTrash2 } from "react-icons/fi";
import { post } from "../../Utils/axios";
import { subscribeCustomEvent } from "../../Utils/utils";

function ReceiptStore({ onSelect = () => {}, className = "" }) {
	const [Show, setShow] = useState(false);
	const { response, loading, reload } = useFetch(apis.getStore, null, { files: [] });

	useEffect(() => {
		//Subscribing to automatic store reloads
		const removeListener = subscribeCustomEvent("reloadReceiptStore", reload);
		return removeListener;
	}, []);

	const removeFromStore = (file) => {
		post(apis.removeFromStore, { fileId: file.id }, (r, e) => {
			if (r) {
				Toast.success("File removed");
				reload();
			} else if (e) {
				Toast.handleError(e);
			}
		});
	};

	return (
		<>
			<Button
				onClick={() => {
					reload();
					setShow(true);
				}}
				variant="outlined"
				className={`btn-sm shadow-sm ${className}`}
			>
				Open Receipt Store
			</Button>
			<Modal zIndex="z-20" contentClass="!max-w-[50rem] !bg-transparent !p-0 !shadow-none !text-white !rounded-2xl" open={Show} onClose={() => setShow(false)}>
				<div className="gap-2 lg:flex">
					<div className="flex-grow p-8 bg-black rounded-2xl">
						<h4>Select from Receipt Store</h4>
						<div className="flex flex-wrap gap-4 mt-8">
							{loading ? (
								<Spinner />
							) : response.files.length > 0 ? (
								response.files.map((file, i) => {
									return (
										<div key={i} className="relative group">
											<Img
												onClick={() => {
													onSelect(file);
													setShow(false);
												}}
												src={file.url}
												className={`w-20 h-20 hover:scale-110 cursor-pointer transition-all rounded-xl`}
											/>
											<div className="w-20 mt-2 overflow-hidden text-xs text-center text-ellipsis whitespace-nowrap">{file.name}</div>
											<div onClick={() => removeFromStore(file)} className="absolute hidden p-2 text-sm text-white rounded-full cursor-pointer group-hover:flex bg-dangerText top-1 right-1">
												<FiTrash2 />
											</div>
										</div>
									);
								})
							) : (
								<EmptyState className="text-white" />
							)}
						</div>
					</div>
					<div className="text-xs text-center bg-[#222] p-4 rounded-xl hover:scale-150 transition-all origin-top-left max-w-min">
						<div className="p-4 mb-2 rounded-lg bg-canvas">
							<ReceiptStoreCode className="m-auto" />
						</div>
						Scan to add photos in receipt store using your phone
					</div>
				</div>
			</Modal>
		</>
	);
}

export default ReceiptStore;
