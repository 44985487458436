import React, { useEffect, useState } from "react";
import { Badge } from "../../../../../../../Components";
import { unitToString } from "../../../../../../../Utils/utils";
import { apis, get } from "Utils/axios";

function FareRules({ fromCode, toCode, date, adult, child, infant, cabinClass, requestId, approvalId, flightOptionKey, flightSearchKey }) {
	const [Rules, setRules] = useState(null);

	useEffect(() => {
		getFareRules(fromCode, toCode, date, adult, child, infant, cabinClass, requestId, approvalId, flightOptionKey, flightSearchKey);
	}, [fromCode, toCode, date, adult, child, infant, cabinClass, requestId, approvalId, flightOptionKey, flightSearchKey]);

	const getFareRules = (fromCode, toCode, date, adult, child, infant, cabinClass, requestId, approvalId, flightOptionKey, flightSearchKey) => {
		get(apis.getFareRules, { from: fromCode?.toUpperCase(), to: toCode?.toUpperCase(), date, adult, child, infant, cabinClass, requestId, approvalId, flightOptionKey, flightSearchKey }, (r) => {
			setRules(r);
		});
	};

	return null;

	// return rules.map((rule, i) => {
	// 	return (
	// 		<div key={i}>
	// 			<div className="flex-center-between">
	// 				{rule.from && (
	// 					<h3>
	// 						{rule.from} - {rule.to}
	// 					</h3>
	// 				)}
	// 				{rule.refundable ? <Badge variant="success">Refundable</Badge> : <Badge variant="warning">Non-Refundable</Badge>}
	// 			</div>
	// 			{rule.refundable ? (
	// 				<>
	// 					<div className="mt-3 flex-center-between text-label">
	// 						<div>Amendment Charges</div>
	// 						<div className="text-end">₹ {parseFloat(rule.airlineCharges.amend) + parseFloat(rule.ctCharges.amend)}</div>
	// 					</div>

	// 					{rule.cancellation ? (
	// 						<>
	// 							<div className="mt-2 underline text-label">Charges when cancelled between</div>
	// 							{Object.keys(rule.cancellation)?.map((duration, index) => {
	// 								const from = duration.split("-")[0];
	// 								const to = duration.split("-")[1];
	// 								const fromUnit = unitToString(from[from.length - 1]);
	// 								const toUnit = unitToString(to[to.length - 1]);
	// 								const fromValue = from.substring(0, from.length - 1);
	// 								const toValue = to.substring(0, to.length - 1);

	// 								return (
	// 									<div key={index} className="mt-2 flex-center-between text-label">
	// 										<div>
	// 											{fromValue} {fromUnit} - {toValue} {toUnit}
	// 										</div>
	// 										<div className="text-end">₹ {parseFloat(rule.cancellation[duration]) + parseFloat(rule.ctCharges.cancel)}</div>
	// 									</div>
	// 								);
	// 							})}
	// 						</>
	// 					) : (
	// 						<>
	// 							<div className="mt-3 flex-center-between text-label">
	// 								<div>Cancellation Charges</div>
	// 								<div className="text-end">₹ {parseFloat(rule.airlineCharges.cancel) + parseFloat(rule.ctCharges.cancel)}</div>
	// 							</div>
	// 						</>
	// 					)}
	// 					<div className="p-2 mt-4 rounded-lg text-label text-xxs bg-light">{rule.note || rule.policy}</div>
	// 				</>
	// 			) : (
	// 				<div className="mt-3 flex-center-between text-label">
	// 					<div>This booking is not eligible for refund on cancellation</div>
	// 				</div>
	// 			)}
	// 		</div>
	// 	);
	// });
}
export default FareRules;
