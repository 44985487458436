import React from "react";
import MapIcon from "../../../../../../../../Assets/Images/icons/map.png";
import GoogleMapReact from "google-map-react";
import { Popup } from "../../../../../../../../Components";
import StayCard from "../../../../../../../../Components/Travel/Stay/stayCard";
import { useNavigate } from "react-router-dom";

function MapView({ stays = [], searchData = {} }) {
	const NAVIGATE = useNavigate();

	const StayMarket = ({ stay }) => {
		const nights = stay.rooms[0].rateBreakdown.length;
		return (
			<Popup contentClass="!w-auto !text-base" button={`₹ ${Math.ceil(stay.rooms[0].amount / nights)}`} btnClass="btn btn-light btn-sm !text-dark !rounded-full !shadow-md hover:bg-dark hover:!text-white">
				<StayCard onClick={() => viewHotel(stay.id)} {...stay} />
			</Popup>
		);
	};

	const viewHotel = (hotelId) => {
		const { rooms, checkIn, checkOut, requestId, approvalId } = searchData;
		NAVIGATE(`/app/travel/stays/hotel?hotelId=${hotelId}
		&checkIn=${checkIn}
		&checkOut=${checkOut}
		&requestId=${requestId}
		&approvalId=${approvalId}
		&rooms=${rooms}
		`);
	};

	const defaultProps = {
		center: {
			lat: Number(stays[0]?.locality?.latitude) || 28,
			lng: Number(stays[0]?.locality?.longitude) || 77,
		},
		zoom: 11,
	};

	return (
		<>
			<div className={`flex-grow rounded-lg overflow-hidden w-full h-full`} style={{ background: `#f2f2f2 url(${MapIcon}) no-repeat center`, backgroundSize: "3rem" }}>
				<GoogleMapReact
					yesIWantToUseGoogleMapApiInternals
					bootstrapURLKeys={{ key: "AIzaSyDv4xAaQY-Ewe3ZMxHXVc_PZJ_wh_VXpMs" }}
					defaultCenter={defaultProps.center}
					defaultZoom={defaultProps.zoom}
					options={{
						mapId: "b14bf5a458fa334b",
					}}
				>
					{stays.map((stay, i) => {
						const { locality } = stay;
						return <StayMarket key={i} lat={locality?.latitude} lng={locality?.longitude} stay={stay} />;
					})}
				</GoogleMapReact>
			</div>
		</>
	);
}

export default MapView;
