import React from "react";
import StatusIndicator from "./status";
import { CopyValue } from "../..";
import { MdOutlineDragIndicator } from "react-icons/md";

function ApprovalStatus({ draggable, status, id }) {
	return (
		<div className={`p-2 flex-center-between bg-whitish border-t ${draggable ? "cursor-move" : ""}`}>
			<div className="flex gap-2">
				{draggable && <MdOutlineDragIndicator />}
				<CopyValue className="text-xxs">RPT-{id}</CopyValue>
			</div>
			<StatusIndicator status={status} />
		</div>
	);
}

export default ApprovalStatus;
