import React, { useEffect, useState } from 'react';
import { BusFilters, BusSearch } from '../../../../../../Components';
import { Loader, SidePaneContent } from '../../../../../../Components';
import BusResult from './Result';
import { BsFillSunFill } from "react-icons/bs";

function BusSRP() {

    const [Loading, setLoading] = useState(true);
    const [SelectedBus, setSelectedBus] = useState();
    useEffect(() => {
        setTimeout(() => {
            setLoading()
        }, 2000);
    }, []);

    return (
        <>
        <Loader loading={Loading}/>
        <SidePaneContent>
            <div className='p-4 h-full'>
                <BusFilters/>
            </div>
        </SidePaneContent>

        <div className='p-4 relative '>
            <BusSearch/>
            {
                Loading
                ? <div className='flex-center w-full h-full'>
                    <div>
                        <span className='text-secondary text-sm'>Hang tight, getting the best fares for you ...</span>
                        <div className='mt-6 border rounded-md p-4'>
                            <div>
                                <div className='flex justify-between'>
                                    <h3>Delhi</h3>
                                    <div className='text-sm'>15 May 23</div>
                                </div>
                                <div className='text-4xl'>36°C</div>
                                <div className='align-center'>
                                    <BsFillSunFill className='mr-2'/> Mostly Sunny
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                : <BusResult onSelect={setSelectedBus} SelectedBus={SelectedBus}/>

            }
        </div>
        </>
    );
}

export default BusSRP;