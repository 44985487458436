import React, { useEffect, useState } from "react";
import useChat from "../../Hooks/useChat";
import Conversation from "./conversation";
import { Button, Modal } from "../Library";
import { FaReply } from "react-icons/fa";

function ReplyInChat({ onClick }) {
	const chat = useChat();
	const [ReplyContent, setReplyContent] = useState({});
	const { openChat, activeChat, setChat, sendMessage } = chat;

	useEffect(() => {
		const { userId, type, content } = ReplyContent;
		if (activeChat && content && activeChat.receiverId === userId) {
			sendMessage(JSON.stringify(content), type);
		}
	}, [activeChat]);

	const loadChat = () => {
		const { userId, type, content } = onClick();
		setReplyContent({ userId, type, content });
		openChat(userId);
	};

	return (
		<>
			<Button onClick={loadChat} variant="outlined" className="gap-2">
				<FaReply />
				Reply in Chat
			</Button>
			<Modal open={activeChat} onClose={() => setChat(null)} contentClass="rounded-lg overflow-hidden !max-w-[40rem] !h-[90vh]">
				<Conversation chat={chat} />
			</Modal>
		</>
	);
}

export default ReplyInChat;
