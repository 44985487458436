import React, { useEffect } from "react";
import { Spinner } from "../../Components";
import { useLoginHook } from "../../Hooks/useLoginHook";

function Authenticate() {
	const Params = new URLSearchParams(window.location.search);
	const token = Params.get("token");

	const { login } = useLoginHook();

	useEffect(() => {
		if (token) {
			login({ token });
		}
	}, [token]);

	return (
		<div className="w-full h-full flex-center">
			<Spinner />
		</div>
	);
}

export default Authenticate;
