import moment from "moment";
import React from "react";
import useUserProfile from "../../../../../Hooks/useUserProfile";
import { Approval, Booking, Button } from "../../../../../Components";
import FileIcon from "../../../../../Assets/Images/icons/file.png";
import { FiDownload } from "react-icons/fi";

function Chat({ userId, type, content, createdAt }) {
	const { profile } = useUserProfile();
	const inBound = userId !== profile.id;

	return (
		<div className={`allow-select flex p-1 ${inBound ? "justify-start fade-in-left" : "justify-end fade-in-right"}`}>
			<div className={`max-w-[60%] p-2 text-sm break-words rounded-2xl ${inBound ? "bg-white border !rounded-bl-sm" : "bg-primary text-white !rounded-br-sm"}`}>
				{type === "text" ? (
					content.startsWith("http") && !content.includes(" ") ? (
						<a className="italic underline" rel="noreferrer" target="_blank" href={content}>
							{content}
						</a>
					) : (
						content
					)
				) : type === "image" ? (
					<img onClick={() => window.open(content, "_blank")} src={content} alt="attachment" className="w-full rounded-lg cursor-pointer" />
				) : type === "file" ? (
					<div className="p-4 pb-0">
						<img src={FileIcon} alt="attachment" className="w-16" />
						<div className="mt-2 text-xs">{content?.substring(content?.lastIndexOf("/") + 1, content?.indexOf("-"))}</div>
						<Button onClick={() => window.open(content, "_blank")} variant="dark" className="mt-3 btn-xs">
							<FiDownload className="mr-1" />
							Download
						</Button>
					</div>
				) : type === "approval" ? (
					<div className="text-dark">
						<Approval readOnly {...JSON.parse(content || "{}")} />
					</div>
				) : type === "request" || type === "booking" ? (
					<div className="text-dark">
						<Booking booking={JSON.parse(content || "{}")} type={JSON.parse(content || "{}")?.type} />
					</div>
				) : null}
				<br />
				<div className="text-xs text-end opacity-70">{moment(createdAt).format("hh:mm a, DD MMM")}</div>
			</div>
		</div>
	);
}

export default Chat;
