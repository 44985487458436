import React, { useContext } from 'react';
import { TopBarContext } from './context';

function TopBar({children, className=''}) {

    const { TopBarContent } = useContext(TopBarContext);

    return (
        <div className='topbar-wrapper'>
            <div className={`topbar  ${TopBarContent ? 'hide' : 'show'} ${className}`}>
                {children}
            </div>
            <div className={`topbar !p-0 ${TopBarContent ? 'show' : 'hide'} ${className}`}>
                {TopBarContent}
            </div>
        </div>
    );
}

export default TopBar;