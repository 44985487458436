import { useContext, useEffect } from "react";
import { SidePaneContext } from "./context";
import { useUserPreference } from "../../Hooks/useUserPreference";

function Content({ children }) {
	const { setSidePaneContent } = useContext(SidePaneContext);
	const { showSidePane } = useUserPreference();

	useEffect(() => {
		//Showing sidepane every time when content is added to sidepane
		if (children) {
			showSidePane(true);
		} else {
			showSidePane(false);
		}
	}, []);

	useEffect(() => {
		// Update the side pane content
		if (children) {
			setSidePaneContent(children);
		}

		return () => {
			// Reset the side pane content
			setSidePaneContent(null);
		};
		//eslint-disable-next-line
	}, [children]);
}

export default Content;
