import React from 'react';

function SelectCompanySize({onSME=() => {}, onEnterprise=() => {}}) {
    return ( 
        <div className='h-full w-full flex-center flex-col gap-12 fade-in'>
            <h1 className='text-4xl font-bold'>Company Size</h1>
            <div className='flex gap-x-4'>
                <div onClick={onSME} className='border rounded-xl p-6 hover:bg-light cursor-pointer'>
                    <span className='text-3xl font-thin'>less than 100 People</span>
                </div>
                <div onClick={onEnterprise} className='border rounded-xl p-6 hover:bg-light cursor-pointer'>
                    <span className='text-3xl font-thin'>100+ People</span>
                </div>
            </div>
        </div>
     );
}

export default SelectCompanySize;