import React from 'react';
import { Button, Container, Toast } from '../../../../../Components';
import { apis, post } from '../../../../../Utils/axios';
import { TbDeviceLaptop } from 'react-icons/tb';
import moment from 'moment';
import useFetch from '../../../../../Hooks/useFetch';

function ActiveSessions() {

    const {response, reload} = useFetch(apis.getActiveSessions, null, {sessions: []}, )

    const terminateSession = (sessionId) => {
        post(apis.terminateSession, {sessionId}, (r,e)=>{
            if(r){
                Toast.success(`Session terminated`)
                reload()
            } else if(e){
                Toast.handleError(e)
            }
        })
    }
    
    return (
        <>
         <Container variant='outlined mt-4'>
            <h4 className='mb-2'>Active Sessions</h4>
            <div className='text-sm mb-6'>
                These are active logins for your account, if you don't recognize them, you can remove them from here.
            </div>
            <div className='flex flex-wrap gap-2'>
                {
                    response.sessions.map((session, index)=>(
                        <div key={index} className='p-4 rounded-md border'>
                            <TbDeviceLaptop className='text-4xl'/>
                            <div className=''>
                                {
                                    session.current ?
                                    <h3>This Device</h3> :
                                    <h3>Device {index+1}</h3>
                                }
                                <div className='text-xs text-secondary'>Logged in at {moment(session.createdAt).format('hh:mm a DD MMM YY')}</div>
                            </div>
                            {
                                session.current ? null :
                                <Button onClick={()=>terminateSession(session.id)} className='btn-xs mt-2 w-full' variant='outlined'>Terminate</Button>
                            }
                        </div>
                    ))
                }
            </div>
        </Container>
        </>
    );
}

export default ActiveSessions;