import React from "react";
import { Button } from "../../../../Components";
import ClayBits from "../../../../Assets/Images/intros/rainbow.png";
import { useLoginHook } from "../../../../Hooks/useLoginHook";

function Welcome({ onSubmit = () => {} }) {
	const { logout } = useLoginHook();
	return (
		<div className="w-full h-full flex-center">
			<div className="p-10 text-center">
				<img src={ClayBits} alt="Welcome" className="h-[400px] floating m-auto" />
				<h1 className="mt-6 text-5xl font-bold">Welcome to Travel Wings</h1>
				<div className="mt-4 text-2xl font-thin">We just need few things to setup your account</div>
				<div className="m-auto mt-8 flex-center gap-x-2">
					<Button onClick={onSubmit} variant="primary" className="btn-lg">
						Get Started
					</Button>
					<Button onClick={logout} variant="outlined" className="btn-lg">
						Sign out
					</Button>
				</div>
			</div>
		</div>
	);
}

export default Welcome;
