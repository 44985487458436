import React, { useState } from "react";
import { Button, DatePicker, DateRange } from "../../..";
import { TbArrowsExchange } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import CitySearch from "../../Inputs/CitySearch";
import { FiMapPin } from "react-icons/fi";
import { useUserConfig } from "../../../../Hooks/useUserConfig";
import SelectPassengers from "../../Inputs/SelectPassengers";

function BusSearch() {
	const NAVIGATE = useNavigate();
	const { role, checkUserPermission } = useUserConfig();
	const isUser = role === "USER";

	const MODES = [
		{ label: "Search One Way", value: "oneway" },
		{ label: "Search Round Buss", value: "round" },
	];

	const CLASSES = [
		{ label: "Economy", value: "economy" },
		{ label: "Premium Economy", value: "premium" },
		{ label: "Business", value: "business" },
		{ label: "First Class", value: "first" },
	];

	const [Values, setValues] = useState({
		from: "",
		to: "",
		date: "",
		range: "",
		returnDate: "",
		passengers: 1,
		cabinClass: CLASSES[0],
		mode: MODES[0],
	});

	const updateValues = (obj) => {
		setValues((prev) => ({ ...prev, ...obj }));
	};

	return (
		<div className="flex p-4 rounded-md bg-light gap-x-2">
			<div className="flex-grow">
				<CitySearch label="Travelling from" selected onSelect={(from) => updateValues({ from })} placeholder="Travelling from" icon={<FiMapPin />} />
			</div>
			{isUser && (
				<div className="p-2 text-2xl rounded-md cursor-pointer flex-center bg-light mt-7">
					<TbArrowsExchange className="transition-all duration-200 hover:rotate-180" />
				</div>
			)}
			<div className="flex-grow">
				<CitySearch label="Going to" onSelect={(to) => updateValues({ to })} placeholder="Going to" icon={<FiMapPin />} />
			</div>
			{checkUserPermission("TA") && <div className="flex-grow">{/* <SelectPassengers label='Passengers'/> */}</div>}
			<div className="flex-grow">{Values?.mode?.value === "oneway" ? <DatePicker label="Travel Date" setDate={(date) => updateValues({ date })} date={Values.date} /> : <DateRange label="Travel Dates" setRange={(range) => updateValues({ range })} range={Values.range} />}</div>
			<div className="flex-grow">
				<Button onClick={() => NAVIGATE("/app/travel/buses/search")} variant="primary" className="w-full mt-7">
					Find
				</Button>
			</div>
		</div>
	);
}

export default BusSearch;
