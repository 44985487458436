import React from "react";

function Processing({ flight }) {
	if (!flight) {
		return null;
	}

	const { logo, name, code } = flight || {};

	return (
		<>
			<div className="w-full h-full flex-center">
				<div className="flex flex-col gap-y-8">
					<span className="payment-loader m-auto max-w-xs"></span>
					<div className="text-center">
						<h4>We are confirming your booking</h4>
						<div className="text-label">Please wait while we confirm your booking. This will only take a minute.</div>
					</div>
					<div className="text-center">
						<img src={logo} alt={name} className="w-10 h-10 rounded-full m-auto" />
						<div className="text-xs text-secondary whitespace-nowrap mt-2">
							{name}&nbsp;&nbsp;{code}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Processing;
