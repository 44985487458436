import React from "react";
import { StayPreviewCard } from "../../../";
import moment from "moment";
import { StampToDate } from "../../../../Utils/utils";

function StayApproval({ hotel, checkIn, checkOut, room }) {
	const nights = moment(checkOut).diff(moment(checkIn), "days");

	return (
		<>
			<div className="p-4 rounded-lg shadow-sm bg-canvas">
				<h4 className="mb-4">User's Choice</h4>
				<div className="mt-4 flex-center-between">
					<div className="align-center gap-x-2">
						<h4>{nights} Nights Stay</h4>
						<div className="text-sm chip">Check in {StampToDate(checkIn)}</div>
						<div className="text-sm chip">Check out {StampToDate(checkOut)}</div>
					</div>
				</div>
				<StayPreviewCard hotel={hotel} room={room} />
			</div>
		</>
	);
}

export default StayApproval;
