import React, { useRef, useState } from "react";
import { FiMapPin } from "react-icons/fi";
import { AnimatedTick, Button, Chips, CustomFields, DatePicker, Form, TimePicker, Toast, PlaceSearch } from "../../../../../Components";
import { FaArrowRight } from "react-icons/fa";
import { apis, post } from "../../../../../Utils/axios";

function TrainHome() {
	const initial = {
		board: {},
		drop: {},
		date: "",
		time: "",
		coach: {
			value: "sleeper",
			label: "Sleeper",
		},
		notes: "",
	};
	const [Values, setValues] = useState(initial);
	const [Success, setSuccess] = useState(false);
	const GET_CUSTOM_FIELDS = useRef();

	const updateValues = (obj) => {
		setValues((prev) => ({ ...prev, ...obj }));
	};

	const onSubmit = (params) => {
		if (!Values.board?.label) {
			return Toast.error("Please select board station");
		}
		if (!Values.drop?.label) {
			return Toast.error("Please select drop stations");
		}
		const customFields = GET_CUSTOM_FIELDS.current();
		if (!customFields) {
			return;
		}
		post(
			apis.requestTrain,
			{
				board: Values.board.value,
				drop: Values.drop.value,
				time: Values.time,
				date: Values.date,
				coach: Values.coach.value,
				notes: Values.notes,
				customFields,
			},
			(r, e) => {
				if (r) {
					Toast.success("Request submitted");
					setValues(initial);
					setSuccess(true);
				} else if (e) {
					Toast.handleError(e);
				}
			}
		);
	};

	if (Success) {
		return (
			<div className="w-full mt-20 flex-center">
				<div className="bg-light rounded-xl p-8">
					<div className="h-20 flex-center">
						<AnimatedTick />
					</div>
					<div className="text-center mt-4">
						<h1>Train Requested</h1>
						<div className="text-label mt-4">
							Your request for train booking has been sent to travel head, <br />
							you can check your train bookings for status
						</div>
						<Button onClick={() => setSuccess(false)} className="mx-auto mt-6">
							Request Another
						</Button>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="p-4 pt-2">
			<div className="max-w-lg">
				<h3>Request a Train</h3>
				<div className="text-label">Your request will be forwarded to travel heads for booking</div>
				<Form onSubmit={onSubmit} className="border rounded-lg p-4 mt-4">
					<div>
						<h4>Stations</h4>
						<div className="text-label">Where are you going to travel</div>
						<div className="grid grid-cols-2 mt-4 gap-2">
							<PlaceSearch label="Board Station" placeholder="Search Station" icon={<FiMapPin />} selected={Values.board} onSelect={(board) => updateValues({ board })} />
							<PlaceSearch label="Drop Station" placeholder="Search Station" icon={<FiMapPin />} selected={Values.drop} onSelect={(drop) => updateValues({ drop })} />
						</div>
					</div>
					<div className="mt-4">
						<h4>Travel Schedule</h4>
						<div className="text-label">When will you be travelling</div>
						<div className="grid grid-cols-2 mt-4 gap-2">
							<DatePicker label="Date" date={Values.date} setDate={(date) => updateValues({ date })} />
							<TimePicker label="Time" required value={Values.time} onChange={(time) => updateValues({ time })} />
						</div>
					</div>
					<div className="mt-4">
						<h4>Train Coach</h4>
						<div className="text-label">Select your preference for cabin</div>
						<Chips
							selected={Values.coach}
							onSelect={(coach) => updateValues({ coach })}
							className="mt-4"
							options={[
								{
									label: "Sleeper",
									value: "sleeper",
								},
								{
									label: "Third AC",
									value: "3AC",
								},
								{
									label: "Second AC",
									value: "2AC",
								},
								{
									label: "First AC",
									value: "1AC",
								},
							]}
						/>
					</div>
					<CustomFields className="mt-4" getCustomFields={GET_CUSTOM_FIELDS} activity="TRAIN_BOOK" />
					<div className="flex flex-col gap-2 mt-4">
						<label>Notes (Optional)</label>
						<textarea onChange={(e) => updateValues({ notes: e.target.value })} className="input text-sm !ring-1 !ring-inset !ring-muted !bg-canvas shadow-sm rounded-md" placeholder="Notes for the booking maker" />
					</div>
					<Button type="submit" className="mt-4" variant="primary">
						Submit Request <FaArrowRight className="ml-2" />
					</Button>
				</Form>
			</div>
		</div>
	);
}

export default TrainHome;
