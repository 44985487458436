let initialState = {
	loading: true,
};

const userPolicy = (state = initialState, { data, type }) => {
	if (type === "USER_POLICY") {
		return {
			...state,
			...data,
			loading: false,
		};
	} else {
		return state;
	}
};

export { userPolicy };
