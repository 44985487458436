import React, { useEffect, useState } from 'react';
import { useUserPreference } from '../../../../../../Hooks/useUserPreference';
import FareSummary from './fareSummary';
import { Loader, Steps } from '../../../../../../Components';
import ReviewStep from './selectSeat';
import Payment from './payment'
import { useNavigate } from 'react-router-dom';
import PickupAndDrop from './pickupDrop';
import Review from './review';
import SelectEmployee from './selectEmployee';
import { useUserConfig } from '../../../../../../Hooks/useUserConfig';

function BookBus() {

    const NAVIGATE = useNavigate();
    const {checkUserPermission} = useUserConfig()
    const {showSidePane} = useUserPreference()
    const [Loading, setLoading] = useState();

    useEffect(() => {
        showSidePane(false)
        return () => {
            showSidePane(true)
        }
    }, []);

    const handleStep = (step) => {
        if(step > 3 ){
            setLoading(true)
            
            setTimeout(() => {
                NAVIGATE('/app/travel/buses/confirm')
            }, 2000);
        }
    }

    return (
        <>
        <Loader loading={Loading}/>
        <div className='grid grid-cols-4 gap-x-4 p-4'>
            <div className='col-span-3'>
                <Steps onChange={handleStep} steps={[
                    {heading: 'Select Seat', description: 'Reserve your seat in the bus', content: <ReviewStep/>},
                    {heading: 'Pickup and Drop', description: 'Select Board and Drop Off Points', content: <PickupAndDrop/>},
                    //For travel admin only
                    ...checkUserPermission('TA') ? [{heading: 'Select Passengers', description: 'Select Passenger for which you are booking', content: <SelectEmployee/>}] : [],
                    {heading: 'Review Booking', description: 'Review your bus booking', content: <Review/>},
                    {heading: 'Payment', description: 'Pay ₹ 600 to completed booking', content: <Payment/>},
                ]}/>
            </div>
            <div className='col-span-1'>
                <div className='border rounded-md p-4'>
                    <FareSummary/>
                </div>
            </div>
        </div>
        </>
    );
}

export default BookBus;