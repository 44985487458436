import React from "react";
import { ReceiptStoreCode } from "../../../../Components";

function QuickActions() {
	return (
		<>
			<div className="flex-grow overflow-y-auto flex-center">
				<div className="p-6 text-center">
					<ReceiptStoreCode className="m-auto mb-12" />
					<div className="text-label">Scan to upload photos to receipt store using your phone</div>
				</div>
			</div>
		</>
	);
}

export default QuickActions;
