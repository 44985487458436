import React from "react";
import { Combobox, Toast } from "../..";
import { useState } from "react";
import { apis, get } from "../../../Utils/axios";
import { TbBuilding, TbUsers } from "react-icons/tb";

function EntitySearch({ onSelect = () => {}, placeholder = "Search Teams or Office", label, selected = {}, className = "", searchOnly = false }) {
	const [Entities, setEntities] = useState([]);
	const [Loader, setLoader] = useState();

	const onChange = (query) => {
		if (query) {
			fetchEntities(query);
		} else {
			setEntities([]);
		}
	};

	const fetchEntities = (query) => {
		setLoader(true);
		//Use admin api only for admin
		get(apis.searchEntity, { search: query, page: 1 }, (r, e) => {
			if (r) {
				setEntities(r.entities);
				setLoader(false);
			} else if (e) {
				console.log(e);
				Toast.handleError(e);
				setLoader(false);
			}
		});
	};

	return (
		<div className="flex items-end w-full gap-2">
			<Combobox
				loading={Loader}
				label={label}
				placeholder={placeholder}
				className={className}
				onChange={onChange}
				options={Entities.map((entity) => ({
					...entity,
					label: entity.name,
					value: entity.id,
					office: entity.office?.name,
				}))}
				renderItem={(option, active, selected) => (
					<li className={`${active ? "bg-light" : "bg-white"}`}>
						{option.label}
						<div className="gap-2 align-center">
							{option.office ? (
								<>
									<TbUsers /> Team
								</>
							) : (
								<>
									<TbBuilding /> Office
								</>
							)}
						</div>
					</li>
				)}
				selected={selected}
				onSelect={onSelect}
			/>
		</div>
	);
}

export default EntitySearch;
