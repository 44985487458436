import React from "react";
import { Combobox, Toast } from "../..";
import { useState } from "react";
import { apis, get } from "../../../Utils/axios";

function PolicySearch({ onSelect = () => {}, type, placeholder = "Search Policies", label, selected = {}, className = "" }) {
	const [AllPolicies, setAllPolicies] = useState([]);
	const [Loader, setLoader] = useState();

	const onChange = (query) => {
		if (query) {
			fetchPolicies(query);
		} else {
			setAllPolicies([]);
		}
	};

	const fetchPolicies = (query) => {
		setLoader(true);
		get(type === "travel" ? apis.getTravelPolicies : apis.getExpensePolicies, { search: query, page: 1 }, (r, e) => {
			if (r) {
				setAllPolicies(r.policies);
				setLoader(false);
			} else if (e) {
				console.log(e);
				Toast.handleError(e);
				setLoader(false);
			}
		});
	};

	return (
		<>
			<Combobox
				loading={Loader}
				label={label}
				placeholder={placeholder}
				className={className}
				onChange={onChange}
				options={AllPolicies.map((policy) => ({
					...policy,
					label: policy.name,
					value: policy.id,
				}))}
				selected={selected}
				onSelect={onSelect}
			/>
		</>
	);
}

export default PolicySearch;
