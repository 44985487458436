import React from 'react';
import ChangePassword from './password';
import TemporaryPassword from './temporaryPassword';
import ActiveSessions from './activeSessions';

function SecuritySettings() {
    
    return (
        <>
            <div className='grid grid-cols-2 gap-x-4 mt-4'>
                <ChangePassword/>
                <TemporaryPassword/>
            </div>
            <ActiveSessions/>
        </>
    );
}

export default SecuritySettings;