import React, { useState } from "react";
import { Spinner } from "../../../../Components";
import Dear from "../../../../Assets/Images/intros/bits.png";
import CreateCompany from "./CreateCompany";

function RequestImplementation({ onBack = () => {} }) {
	const [Success, setSuccess] = useState();
	const [Loader, setLoader] = useState();

	const sendRequest = (data) => {
		setSuccess(true);
		setLoader(true);
		// post(apis.requestImplementation,{
		//     company: data,
		//     user: profile
		// },(r,e)=>{
		//     if(r){
		//         setSuccess(true)
		//         setLoader()
		//     } else if(e){
		//         Toast.handleError(e)
		//         setLoader()
		//     }
		// })
	};

	if (Loader)
		return (
			<div className="w-full h-full flex-center">
				<Spinner size="lg" />
			</div>
		);

	if (Success)
		return (
			<div className="w-full h-full flex-center">
				<div className="text-center fade-in-right">
					<img src={Dear} alt="Welcome" className="h-[400px] floating m-auto" />
					<h1 className="mt-6 text-5xl font-bold">That's it, Sit tight</h1>
					<div className="mt-2 text-xl font-thin">We we'll contact you shortly, all excited to get you started with us.</div>
				</div>
			</div>
		);

	return (
		<div className="w-full h-full flex-center">
			<CreateCompany onBack={onBack} onSubmit={sendRequest} />
		</div>
	);
}

export default RequestImplementation;
