import React from "react";
import { FiCheck } from "react-icons/fi";

function Status({ status }) {
	status = status?.toLowerCase();

	if (status === "booked")
		return (
			<div className={`rounded-full text-white align-center max-w-[2rem] h-[2rem] group-hover:max-w-[8rem] transition-all duration-500 bg-successText`}>
				<div className="mx-[0.6rem]">
					<FiCheck />
				</div>
				<div className="pr-4">Booked</div>
			</div>
		);
	return <></>;
}

export default Status;
