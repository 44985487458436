import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Img } from "../../../index";
import BookingStatus from "../Components/stayBookingStatus";
import ViewBooking from "../ViewBooking";
import { useState } from "react";
import ViewApproval from "../../../Approval/ViewApproval";
import ViewRequest from "../../Request/ViewRequest";
import PlaceholderImage from "../../../../Assets/Images/placeholders/stay.jpg";

function StayBooking({ photo, rating = 0, amount = 0, name = "", address = "", className = "", status, checkIn, checkOut, locality, approvalRequest, adminRequest, id, onReload = () => {}, onClick = () => {} }) {
	const [BookingId, setBookingId] = useState();
	const [ApprovalId, setApprovalId] = useState();
	const [RequestId, setRequestId] = useState();

	const openBooking = () => {
		if (approvalRequest) {
			setApprovalId(id);
		} else if (adminRequest) {
			setRequestId(id);
		} else {
			setBookingId(id);
		}
	};

	return (
		<>
			<div onClick={openBooking} className={`relative flex flex-col justify-between min-w-[15rem] border hover:shadow-md cursor-pointer rounded-lg shadow-sm overflow-hidden ${className}`}>
				<div className="p-2">
					<Swiper slidesPerView={"auto"} onSlideChange={(i) => {}} modules={[Navigation]} navigation={true} className="rounded-lg swiper-carousel">
						<SwiperSlide>
							<Img placeholder={PlaceholderImage} src={photo} className="w-full aspect-[5/3]" />
						</SwiperSlide>
					</Swiper>

					<div onClick={onClick} className="p-2">
						<div className="mt-2 font-semibold text-ellipsis">{name}</div>
						<div className="text-sm text-secondary text-ellipsis">{address}</div>
						<div className="mt-2 flex-center-between">
							<div className="text-sm">
								<span className="font-semibold">₹ {amount}</span>
							</div>
						</div>
					</div>
				</div>
				{status && <BookingStatus booking={{ status, id, checkIn, checkOut, locality, approvalRequest, adminRequest }} />}
			</div>
			<ViewBooking id={BookingId} type="stay" onClose={() => setBookingId()} onReload={onReload} />
			<ViewApproval readOnly approvalId={ApprovalId} onClose={() => setApprovalId()} onReload={onReload} />
			<ViewRequest requestId={RequestId} onClose={() => setRequestId()} onReload={onReload} />
		</>
	);
}

export default StayBooking;
