import React, { useRef } from "react";
import { savePDF } from "@progress/kendo-react-pdf";
import { Button, Toast } from "../Library";
import moment from "moment";
import { StampTo24hr, StampToDate } from "../../Utils/utils";
import { FiDownload } from "react-icons/fi";

function FlightTicket({ ticket = {} }) {
	const TICKET = useRef(null);

	const downloadPDF = () => {
		savePDF(TICKET.current, {
			paperSize: "auto",
			margin: 50,
			fileName: `Flight Ticket`,
		});
		Toast.success("Ticket Downloaded");
	};

	const { booking, passengers, amount, date, maker } = ticket;

	return (
		<>
			<div ref={TICKET} className="max-w-[900px] min-w-[700px] m-auto text-sm">
				<div className="p-12">
					<div className="p-6 text-white bg-black rounded-lg flex-center-between">
						<div className="text-2xl font-bold align-center">
							TPP
							<div className="w-4 h-4 ml-4 rounded-full bg-primary" />
						</div>
						<div className="text-2xl">Flight Booking</div>
					</div>
					<div className="gap-6 p-6 mt-8 rounded-lg bg-light">
						<div className="mb-6 text-lg font-bold">Booking Details</div>
						<div className="flex-center-between">
							<div>
								<div className="mb-2 font-bold">Booked by</div>
								<div>{maker?.email}</div>
							</div>
							<div>
								<div className="mb-2 font-bold">Booked On</div>
								<div>{moment(date).format("DD MMM YYYY")}</div>
							</div>
							<div>
								<div className="mb-2 font-bold">TPP ID</div>
								<div>{booking?.tripId}</div>
							</div>
							<div className="text-end">
								<div className="mb-2 font-bold">Amount</div>
								<div>Rs. {amount}</div>
							</div>
						</div>
					</div>
					<div className="p-6 mt-6 border rounded-lg">
						<div className="text-lg font-bold">Flight Details</div>
						{booking?.flights?.map(({ segments }) => {
							return segments.map((segment, j) => {
								const { airline, flightNumber, departureCode, departureAirport, departureTime, arrivalCode, arrivalAirport, arrivalTime, departureTerminal, arrivalTerminal, pnrs } = segment;
								return (
									<div key={j} className="flex items-start justify-between gap-6 mt-6">
										<div>
											<div className="font-bold">
												{airline}-{flightNumber}
											</div>
											<img src={`https://s3.ap-south-1.amazonaws.com/public-projectpay.in/airlines/${airline}.png`} alt="Airline" className="w-10 h-10 mt-2 rounded-full" />
										</div>
										<div className="text-xs text-end">
											<div className="mb-2 text-2xl font-bold">{departureCode}</div>
											<div>
												{StampTo24hr(departureTime)}, {StampToDate(departureTime)}
											</div>
											<div>
												Terminal {departureTerminal}
												<br />
												{departureAirport}
											</div>
										</div>
										<div className="flex-grow flex-center min-w-sm">
											<div className="w-full mt-1 align-center">
												<div className="w-2 h-2 border rounded-full bg-light" />
												<div className="flex-grow border border-dashed" />
												<div className="px-2 py-1 text-xs text-center border rounded-full bg-light whitespace-nowrap">4hr 30m</div>
												<div className="flex-grow border border-dashed" />
												<div className="w-2 h-2 border rounded-full bg-light" />
											</div>
										</div>
										<div className="text-xs">
											<div className="mb-2 text-2xl font-bold">{arrivalCode}</div>
											<div>
												{StampTo24hr(arrivalTime)}, {StampToDate(arrivalTime)}
											</div>
											<div>
												Terminal {arrivalTerminal}
												<br />
												{arrivalAirport}
											</div>
										</div>
										<div>
											<div className="font-bold">PNR</div>
											<div>{pnrs[0]?.pnr}</div>
										</div>
									</div>
								);
							});
						})}
					</div>
					<div className="p-6 mt-5 border rounded-lg">
						<div className="text-lg font-bold">Traveller(s)</div>
						<table className="w-full mt-6">
							<tbody>
								<tr className="font-bold">
									<td>NAME</td>
									<td>GENDER</td>
									<td>EMAIL</td>
									<td>PHONE</td>
								</tr>
								{passengers?.map((passenger, i) => {
									const { fName, lName, email, phone, gender } = passenger;
									return (
										<tr key={i}>
											<td>
												{fName} {lName}
											</td>
											<td>{gender}</td>
											<td>{email}</td>
											<td>{phone}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
					<div className="mt-6 text-xs text-secondary">
						<b>Note:</b> The respective airlines will issue a separate GST invoice for their tickets showing the fare, tax and GST applied on the fare. Please download the same from the airline’s website. If you have purchased travel insurance, the insurance provider will issue a
						separate GST invoice for the same. <br />
						<br />
						Neospend Private Limited is committed to ensuring complete safety of your card details. We do not request for card details over an email or a phone call. Payments made on blinctrip.com will appear as Digital Blinc Technologies Pvt. Ltd. In your bank/card statements.
						<br />
						<br />
						This is an electronically generated invoice and does not require signature/stamp.
						<br />
						<br />
					</div>
					<div className="p-6 text-xs rounded-lg bg-light flex-center-between">
						<Button onClick={downloadPDF} variant="primary" className="gap-2 btn-sm">
							<FiDownload /> Download as PDF
						</Button>
						<div>
							<b>For support</b> reach out to +91 8512862512, info@projectpay.in
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default FlightTicket;
