import React from "react";
import FlightBooking from "./Types/flight";
import StayBooking from "./Types/stay";
import CabBooking from "./Types/cab";
import TrainBooking from "./Types/train";

function Bookings({ booking, type, onReload = () => {} }) {
	if (type === "flight") {
		return <FlightBooking {...booking} onReload={onReload} />;
	}
	if (type === "stay") {
		return <StayBooking {...booking} onReload={onReload} />;
	}
	if (type === "cab") {
		return <CabBooking {...booking} onReload={onReload} />;
	}
	if (type === "train") {
		return <TrainBooking {...booking} onReload={onReload} />;
	}

	return null;
}

export default Bookings;
