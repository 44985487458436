import React from "react";
import { useUserPreference } from "../../Hooks/useUserPreference";
import useMobileCheck from "../../Hooks/useMobileCheck";

function CardsWrapper({ children, cols = 3, loading }) {
	const { sidePane, sidebar } = useUserPreference();
	const { windowSize } = useMobileCheck();

	//Default when size is between 2000 - 800
	let gridSize = cols;
	if (windowSize > 2000 || windowSize < 1000) {
		//Responsive Grid
		if (windowSize > 4000) {
			gridSize = 6;
		} else if (windowSize > 3000) {
			gridSize = 5;
		} else if (windowSize > 2000) {
			gridSize = 4;
		} else if (windowSize < 1000) {
			gridSize = 2;
		}
	}
	//Take extra space
	gridSize = sidePane && sidebar ? gridSize : gridSize + 1;

	if (loading)
		return (
			<div className={`grid grid-cols-${gridSize} gap-4 mt-4`}>
				{new Array(3).fill(0).map((_, i) => (
					<div key={i} role="status" className="w-full min-h-[7rem] rounded-xl animate-pulse bg-gray-100" />
				))}
			</div>
		);
	// return <div className='w-full mt-10 flex-center'><Spinner size='lg'/></div>

	return <div className={`grid grid-cols-${gridSize} gap-4 mt-4`}>{children}</div>;
}

export default CardsWrapper;
