import React from "react";
import { Badge } from "..";

function StatusIndicator({ status }) {
	if (status === "approved") {
		return <Badge variant="success">Approved</Badge>;
	}
	if (status === "denied") {
		return <Badge variant="danger">Denied</Badge>;
	}
	if (status === "pending") {
		return <Badge variant="warning">Pending</Badge>;
	}
	return null;
}

export default StatusIndicator;
