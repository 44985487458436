import React, { useEffect, useState } from "react";
import { Badge } from "../../../../../../../../Components";
import apis from "../../../../../../../../Utils/apis";
import { post } from "../../../../../../../../Utils/axios";

function FareRules({ rulesKey }) {
	const [Rules, setRules] = useState({});

	useEffect(() => {
		setRules({});
		fetchRules(rulesKey);
	}, [rulesKey]);

	const fetchRules = (key) => {
		post(apis.getFareRules, { key: key ? JSON.parse(key) : {} }, (r, e) => {
			if (r) {
				setRules(r);
			}
		});
	};

	return (
		<>
			<h6>Cancellation</h6>
			{Rules.Refund === "Y" ? (
				<>
					<Badge className="mt-2" variant="success">
						Refundable
					</Badge>
					<div className="mt-2 flex-center-between text-label">
						<div>Cancellation Charges</div>
						<div className="text-end">₹ {Rules.ADT?.BD?.C}</div>
					</div>
					<div className="mt-2 flex-center-between text-label">
						<div>Amendment Charges</div>
						<div className="text-end">₹ {Rules.ADT?.BD?.A}</div>
					</div>
				</>
			) : Rules.Refund === "N" ? (
				<>
					<Badge className="mt-2" variant="warning">
						Non-Refundable
					</Badge>
					<div className="mt-3 flex-center-between text-label">
						<div>This booking is not eligible for refund on cancellation</div>
					</div>
				</>
			) : (
				<></>
			)}
		</>
	);
}

export default FareRules;
