import React from "react";
import StatusIndicator from "./status";
import { CopyValue } from "../../..";

function CabBookingStatus({ booking = {} }) {
	const { adminRequest, status, id } = booking;

	return (
		<div className="p-2 border-t flex-center-between bg-whitish">
			<CopyValue className="text-xxs">
				{adminRequest ? "CAB/REQ" : "CAB"}-{id}
			</CopyValue>
			<StatusIndicator status={status} />
		</div>
	);
}

export default CabBookingStatus;
