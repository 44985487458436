import React, { useEffect, useState } from "react";
import { Badge, Img, Toast } from "..";
import PlaceholderImg from "../../Assets/Images/placeholders/snowflake.png";
import { TbSquareRoundedChevronDownFilled, TbSquareRoundedChevronRightFilled } from "react-icons/tb";
import { apis, get } from "../../Utils/axios";
import { useParams } from "react-router-dom";

function ApprovalPreview({ when = "OUT_POLICY", type = "FLIGHT" }) {
	const { userId } = useParams();

	const [Sequence, setSequence] = useState(true);
	const [Approvers, setApprovers] = useState([]);
	const [Loader, setLoader] = useState();

	useEffect(() => {
		fetchApprovalFlow(userId, when, type);
	}, [userId, when, type]);

	const fetchApprovalFlow = (userId, when, type) => {
		setLoader(true);
		get(apis.getUserApprovalFlow, { userId, when, type }, (r, e) => {
			if (r) {
				setSequence(r.order === "SEQUENCE" ? true : false);
				setApprovers(r.from);
				setLoader();
			} else if (e) {
				Toast.handleError(e);
				setLoader();
			}
		});
	};

	if (!Approvers.length) return <Badge variant="warning">No approvers set for this scenario</Badge>;

	if (Sequence)
		return (
			<div className="flex fade-in-down">
				<div className="w-[1px] rounded-full bg-dark ml-4" />
				<div className="-ml-5 pl-[2px]">
					{Approvers.map((approver, i) => {
						const nextApproverIsOptional = Approvers[i + 1]?.optional;
						const currentApproverIsOptional = approver.optional;

						return (
							<div
								key={i}
								className={`align-center gap-x-4 p-2 relative 
                                ${nextApproverIsOptional ? "bg-light border-t border-x border-dark rounded-t-md" : ""}
                                ${currentApproverIsOptional ? "bg-light border-b border-x border-dark rounded-b-md" : ""}
                            `}
							>
								<TbSquareRoundedChevronDownFilled className={`${i === 0 ? "text-primary" : ""} bg-white text-xl`} />
								<Img preview placeholder={PlaceholderImg} className="w-10 h-10 rounded-full pixelated" />
								<div className="font-bold">{approver.label}</div>
								{nextApproverIsOptional && <div className="absolute right-3 -top-2 text-xxs text-white bg-dark py-0.5 px-2 rounded-md">Any One</div>}
							</div>
						);
					})}
				</div>
			</div>
		);

	return (
		<div className="flex fade-in-left">
			<div className="w-[1px] rounded-full bg-dark ml-4" />
			<div className="-ml-5 pl-[2px]">
				{Approvers.map((approver, i) => {
					const nextApproverIsOptional = Approvers[i + 1]?.optional;
					const currentApproverIsOptional = approver.optional;

					return (
						<div
							key={i}
							className={`align-center gap-x-4 p-2 relative 
                                ${nextApproverIsOptional ? "bg-light border-t border-x border-dark rounded-t-md" : ""}
                                ${currentApproverIsOptional ? "bg-light border-b border-x border-dark rounded-b-md" : ""}
                            `}
						>
							<div className="align-center">
								<div className="h-[1px] w-[20px] rounded-full bg-dark ml-3" />
								<TbSquareRoundedChevronRightFilled className={`${i === 0 ? "text-primary" : ""} bg-white text-xl`} />
							</div>
							<Img preview placeholder={PlaceholderImg} className="w-10 h-10 rounded-full pixelated" />
							<div className="font-bold">{approver.label}</div>
							{nextApproverIsOptional && <div className="absolute right-3 -top-2 text-xxs text-white bg-dark py-0.5 px-2 rounded-md">Any One</div>}
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default ApprovalPreview;
