import React from 'react';
import { FaBus } from 'react-icons/fa';

function Processing() {

    const Mock_Bus = [
        {
            id: 1,
            from: {
                name: 'Delhi',
                country: 'India',
            },
            to: {
                name: 'Jaipur',
                country: 'India',
            },
            departure: {
                time: 0,
                point: 'Kashmiri Gate',
            },
            arrival: {
                time: 0,
                point: 'Station Road',
            },
            operator: {
                name: 'Goldline Super Deluxe',
            },
            bus: 'Bharat Benz A/C Sleeper (2+1)',
            rating: 3.8,
            seats: 40,
            cancellation: {
                0 : '0 to 12 hr(s) before travel',
                50 : '12 to 24 hr(s) before travel',
                100 : '24 to 48 hr(s) before travel',
            },
            images: [],
            pickups: [
                {
                    id: 1,
                    name: 'Kashmiri Gate',
                    time: '9:00 PM',
                },
                {
                    id: 2,
                    name: 'ISBT',
                    time: '9:30 PM',
                },
                {
                    id: 3,
                    name: 'Majnu Ka Tila',
                    time: '10:00 PM',
                },
            ],
            drops: [
                {
                    id: 1,
                    name: 'Station Road',
                    time: '5:00 AM',
                },
                {
                    id: 2,
                    name: 'Sindhi Camp',
                    time: '5:30 AM',
                },
                {
                    id: 3,
                    name: 'Ajmeri Gate',
                    time: '6:00 AM',
                },
            ],
            amenities: ['AC', 'Water Bottle', 'Blanket', 'Charging Point', 'Reading Light'],
            amount: 600,
            date: '',
            type: 'bus',
        },
    ]
    
    return ( 
        <>
        <div className='w-full h-full flex-center'>
            <div className='flex flex-col gap-y-8'>
                <span className="payment-loader m-auto max-w-xs"></span>
                <div className='text-center'>
                    <h4>We are confirming your booking</h4>
                    <div className='text-label'>
                        Please wait while we confirm your booking. This will only take a minute.
                    </div>
                </div>
                <div className='text-center flex-center'>
                    <FaBus className='mr-2'/>
                    <h2>Bus from {Mock_Bus[0].from.name} to {Mock_Bus[0].to.name}</h2>
                </div>
            </div>
        </div>
        </>
     );
}

export default Processing;