import React, { useState } from "react";
import { Button, Toast } from "../../../../../../../../Components";
import { useNavigate } from "react-router-dom";
import FlightItinerary from "./itinerary";
import { useUserPolicy } from "../../../../../../../../Hooks/useUserPolicy";
import { useUserConfig } from "../../../../../../../../Hooks/useUserConfig";
import AskApproval from "./askApproval";
import SendToAdmin from "./sendToAdmin";
import { apis, post } from "../../../../../../../../Utils/axios";
import FlightDetails from "./details";
import FareRules from "./fareRules";

function Summary({ approval, onwardFlight = {}, returnFlight = {}, journeyKey, passengers = {}, approvalId, requestId }) {
	const { checkUserPermission } = useUserConfig();
	const { travelPolicy } = useUserPolicy();
	const adminBooking = travelPolicy?.domesticFlight?.adminBooking && !checkUserPermission("TA");
	const askApproval = (!onwardFlight?.selectedFare?.inPolicy || !returnFlight?.selectedFare?.inPolicy) && !checkUserPermission("TA");
	const onwardViolations = onwardFlight?.selectedFare ? [...onwardFlight?.selectedFare?.violations, ...onwardFlight?.violations] : [];
	const returnViolations = returnFlight?.selectedFare ? [...returnFlight?.selectedFare?.violations, ...returnFlight?.violations] : [];

	const approvedAmount = approval?.amount;
	const segments = onwardFlight?.segments || returnFlight?.segments;
	const flightDetails = segments[0];
	const NAVIGATE = useNavigate();
	const [Loading, setLoading] = useState();

	const holdBooking = () => {
		setLoading(true);
		post(
			apis.holdRoundFlight,
			{
				journeyKey,
				onwardFlight: {
					fareKey: onwardFlight?.selectedFare.fareKey,
					fareClass: onwardFlight?.selectedFare.fareType,
					price: onwardFlight?.selectedFare.price,
				},
				returnFlight: {
					fareKey: returnFlight?.selectedFare.fareKey,
					fareClass: returnFlight?.selectedFare.fareType,
					price: returnFlight?.selectedFare.price,
				},
			},
			(r, e) => {
				if (r?.holdId) {
					NAVIGATE(`/app/travel/flights/book`, {
						state: {
							flight: r,
							approvalId,
							requestId,
						},
					});
				} else if (e) {
					Toast.handleError(e);
					setLoading(false);
				}
			}
		);
	};

	return (
		<div className="flex flex-col justify-between w-full h-full fade-in-right">
			<div className="flex flex-col gap-6 p-4 overflow-y-scroll">
				<div className="align-center">
					<img src={flightDetails.logo} alt="airline-logo" className="w-10 h-10 mr-4 rounded-full" />
					<h3>{flightDetails.airlineName}</h3>
				</div>
				<div>
					<FlightItinerary onwardFlight={onwardFlight} returnFlight={returnFlight} />
				</div>
				{onwardFlight?.segments && (
					<>
						<div>
							<FlightDetails baggageKey={onwardFlight?.baggageKey} />
						</div>
						<div>
							<FareRules rulesKey={onwardFlight?.rulesKey} />
						</div>
					</>
				)}
			</div>
			<div className="p-4">
				{!onwardFlight?.segments ? (
					<div>
						{approval && <h5 className="p-3 rounded-lg bg-compliment text-accent">Remaining approved amount ₹ {approvedAmount - returnFlight?.selectedFare?.price}</h5>}
						<Button loading={Loading} variant="outlined" className="w-full mt-4">
							Choose Onward
						</Button>
					</div>
				) : !returnFlight?.segments ? (
					<div>
						{approval && <h5 className="p-3 rounded-lg bg-compliment text-accent">Remaining approved amount ₹ {approvedAmount - onwardFlight?.selectedFare?.price}</h5>}
						<Button loading={Loading} variant="outlined" className="w-full mt-4">
							Choose Return
						</Button>
					</div>
				) : (
					<>
						<div>
							<div className="gap-1 mb-2 text-sm align-center">
								For {passengers.adult} Adult
								{passengers.child > 0 && <>, {passengers.child} Child</>}
								{passengers.infant > 0 && <>, {passengers.infant} Infant</>}
							</div>
							<h1>Total ₹ {onwardFlight?.selectedFare?.price + returnFlight?.selectedFare?.price}</h1>
						</div>
						<div className="mt-4">
							{askApproval ? (
								<AskApproval
									{...{
										onwardFlight,
										returnFlight,
										onwardFare: onwardFlight?.selectedFare,
										returnFare: returnFlight?.selectedFare,
										onwardViolations,
										returnViolations,
										type: "round",
									}}
								/>
							) : adminBooking ? (
								<SendToAdmin
									{...{
										onwardFlight,
										returnFlight,
										onwardFare: onwardFlight?.selectedFare,
										returnFare: returnFlight?.selectedFare,
										type: "round",
										approvalId,
									}}
								/>
							) : (
								<Button loading={Loading} variant="primary" onClick={holdBooking} className="w-full">
									Continue
								</Button>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default Summary;
