import React from "react";
import { Badge } from "../..";

function StatusIndicator({ status }) {
	if (status === "pending") {
		return <Badge variant="warning">Pending</Badge>;
	}
	if (status === "upcoming") {
		return <Badge variant="success">Upcoming</Badge>;
	}
	if (status === "archived") {
		return <Badge variant="success">Archived</Badge>;
	}
	if (status === "denied") {
		return <Badge variant="danger">Denied</Badge>;
	}
	return null;
}

export default StatusIndicator;
