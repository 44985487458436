import React, { useState } from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { useUserPolicy } from "../../Hooks/useUserPolicy";
import { Tab } from "@headlessui/react";
import { Badge, Tabs, Modal } from "..";
import { FaShieldAlt } from "react-icons/fa";
import { useUserConfig } from "../../Hooks/useUserConfig";
import Dear from "../../Assets/Images/intros/dear.png";
import { useClientSettings } from "../../Hooks/useClientSettings";

function ViewYourPolicy() {
	const { isEnabled } = useClientSettings();
	const { checkUserPermission } = useUserConfig();
	const [ShowPolicy, setShowPolicy] = useState(false);
	const { expensePolicy, travelPolicy } = useUserPolicy();
	const { domesticFlight, domesticStay } = travelPolicy || {};

	if (isEnabled("ORGANIZATION") && isEnabled("POLICY"))
		return (
			<>
				<div onClick={() => setShowPolicy(true)} className="p-3 text-xs font-bold rounded-md cursor-pointer bg-compliment text-accent flex-center-between">
					View my Policy
					<FiArrowUpRight />
				</div>
				<Modal open={ShowPolicy} onClose={() => setShowPolicy(false)}>
					<div className="p-6">
						{checkUserPermission("TA") ? (
							<div className="text-center">
								<img src={Dear} alt="Dear" className="w-32 m-auto mb-4" />
								<h1>You are Travel Head</h1>
								<div className="text-sm">You can book any travel for yourself and others</div>
							</div>
						) : (
							<>
								<h2 className="gap-2 mb-4 align-center">
									<FaShieldAlt />
									Your Policy
								</h2>
								<Tabs options={["Flight", "Stay", "Expense"]}>
									<div className="mt-2">
										<Tab.Panel>
											{domesticFlight?.enabled ? (
												<ul className="text-sm">
													<li>1) You can book flight of amount upto ₹ {domesticFlight.maxBookingAmount}</li>
													<li>2) Minimum flight duration is {domesticFlight.minFlyDuration} hr to be eligible for flight</li>
													<li>3) Need to book flight {domesticFlight.bookingWindow} days before travel</li>
												</ul>
											) : (
												<Badge variant="warning">No Flight Policy</Badge>
											)}
										</Tab.Panel>
										<Tab.Panel>
											{domesticFlight?.enabled ? (
												<ul className="text-sm ">
													<li>1) You can book stay of amount upto ₹ {domesticStay.maxBookingAmount}</li>
													<li>2) You can book stays of upto {domesticStay.starRating} star rating</li>
													<li>3) Need to book stays {domesticStay.bookingWindow} days before travel</li>
												</ul>
											) : (
												<Badge variant="warning">No Stay Policy</Badge>
											)}
										</Tab.Panel>
										<Tab.Panel>
											{Object.keys(expensePolicy).length > 0 ? (
												<div>
													{Object.keys(expensePolicy).map((category, i) => {
														if (expensePolicy[category].enabled) {
															return (
																<>
																	<h4>For {category}</h4>
																	<ul className="text-sm " key={i}>
																		<li>1) You can book expense upto {expensePolicy[category].maxAmount}</li>
																		<li>2) You can book expense max {expensePolicy[category].submitWindow} days old</li>
																	</ul>
																</>
															);
														}
														return null;
													})}
												</div>
											) : (
												<Badge variant="warning">No Expense Policy</Badge>
											)}
										</Tab.Panel>
									</div>
								</Tabs>
							</>
						)}
					</div>
				</Modal>
			</>
		);

	return null;
}

export default ViewYourPolicy;
