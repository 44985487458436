import React, { useState } from "react";
import { classifyTime } from "../../../../../../../Utils/utils";
import Sort from "./Components/sort";
import FareCard from "./Components/fareCard";
import { useUserPolicy } from "../../../../../../../Hooks/useUserPolicy";
import UserChoice from "./Components/userChoice";
import HasApproval from "./Components/hasApproval";
import FlightCard from "./Components/flightCard";

function InternationalFlightResult({ approval, filters = {}, onSelect = () => {}, SelectedFlight, flights = [], journeyKey }) {
	const { travelPolicy } = useUserPolicy();
	const { showOutOfPolicyOptions = true } = travelPolicy?.domesticFlight || {};
	const [SortBy, setSortBy] = useState({
		type: "price",
		order: "asc",
	});

	const handleSelect = (selectedFare, flight) => {
		if (selectedFare) {
			onSelect((prev) => ({ ...prev, flight: { ...flight, selectedFare } }));
		} else {
			const updated = { ...SelectedFlight };
			updated.flight = undefined;
			onSelect(updated);
		}
	};

	const sortFlights = (prev, next) => {
		if (SortBy.type === "price") {
			const prevPrice = prev.fares[0].price;
			const nextPrice = next.fares[0].price;
			return SortBy.order === "des" ? nextPrice - prevPrice : prevPrice - nextPrice;
		} else if (SortBy.type === "departure") {
			const prevDeparture = prev.segments[0]?.departureTime;
			const nextDeparture = next.segments[0]?.departureTime;
			return SortBy.order === "des" ? nextDeparture - prevDeparture : prevDeparture - nextDeparture;
		} else if (SortBy.type === "arrival") {
			const prevArrival = prev.segments[prev.segments.length - 1]?.arrivalTime;
			const nextArrival = next.segments[prev.segments.length - 1]?.arrivalTime;
			return SortBy.order === "des" ? nextArrival - prevArrival : prevArrival - nextArrival;
		} else if (SortBy.type === "duration") {
			return SortBy.order === "des" ? next.duration - prev.duration : prev.duration - next.duration;
		}
	};

	const filterFlights = (flight) => {
		if (!flight.fares.length) {
			return false;
		}
		const _stops = flight.segments.length - 1;
		const _inPolicy = true;
		const _fullRefundable = false;
		const _airline = flight.segments[0].airlineName;
		const _time = classifyTime(flight.segments[0].departureTime);
		const _price = flight.fares[0].price;

		const { maxPrice, airlines, stops, time, inPolicy, fullRefundable } = filters;

		let show = true;

		if (maxPrice && _price > maxPrice) {
			show = false;
		}
		if (stops.length && !stops.includes(_stops)) {
			show = false;
		}
		if (inPolicy && !_inPolicy) {
			show = false;
		}
		if (fullRefundable && !_fullRefundable) {
			show = false;
		}
		if (airlines.length && !airlines.includes(_airline)) {
			show = false;
		}
		if (time.length && !time.includes(_time)) {
			show = false;
		}
		return show;
	};

	if (!flights.length)
		return (
			<div className="w-full py-20 flex-center flex-col">
				<h1 className="text-center mb-12 text-2xl">No Flights found</h1>
			</div>
		);

	return (
		<>
			<HasApproval approval={approval} />
			<UserChoice />
			<div id="onward" className="group">
				<Sort sortBy={SortBy} updateSort={setSortBy} />
				{flights
					.filter(filterFlights)
					.sort(sortFlights)
					.map((flight, index) => {
						const { segments, returnSegments, fares, duration, returnDuration, inPolicy } = flight;
						const cheapestFare = fares.reduce((prev, current) => (prev.price < current.price ? prev : current));
						const highestFare = fares.reduce((prev, current) => (prev.price > current.price ? prev : current));
						const hasFares = flight?.fares.some((fare) => fare.price > 0);

						if (hasFares && (inPolicy || showOutOfPolicyOptions))
							return (
								<div key={index} className="border shadow-sm rounded-md mt-4 bg-canvas flight-card">
									<div className="grid grid-cols-2 divide-x">
										<div>
											<FlightCard key={`onward-${index}`} {...{ segments: segments, duration }} />
										</div>
										<div>
											<FlightCard key={`return-${index}`} {...{ segments: returnSegments, duration: returnDuration }} />
										</div>
									</div>
									{flight?.fares.map((fare, index) => {
										return <FareCard key={index} selected={SelectedFlight?.flight} onSelect={(selectedFare) => handleSelect(selectedFare, flight)} {...{ flight, fare, cheapestFare, highestFare }} />;
									})}
								</div>
							);

						return null;
					})}
			</div>
		</>
	);
}

export default InternationalFlightResult;
