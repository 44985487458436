import React, { useState } from "react";
import { Button, Modal } from "../../../../Library";
import { FiEye } from "react-icons/fi";
import FlightTicket from "../../../../Templates/flightTicket";

function DownloadTicket({ data }) {
	const [ShowTicket, setShowTicket] = useState();
	return (
		<>
			<Button onClick={() => setShowTicket(true)} className="flex-grow">
				<FiEye className="mr-2" />
				Open Ticket
			</Button>
			<Modal contentClass="!max-w-[50rem]" open={ShowTicket} onClose={() => setShowTicket()}>
				<FlightTicket ticket={data} />
			</Modal>
		</>
	);
}

export default DownloadTicket;
