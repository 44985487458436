import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { apis, get } from "../../../../../../../../Utils/axios";
import StayCard from "../../../../../../../../Components/Travel/Stay/stayCard";

function UserChoice({onClick=() => {}}) {
	const [searchParams] = useSearchParams();
	const requestId = searchParams.get("requestId") || "";

	const [UserChoice, setUserChoice] = useState();

	useEffect(() => {
		if (requestId) {
			get(apis.getRequestDetails, { requestId }, (r, e) => {
				if (r?.choices) {
					setUserChoice(r.choices[0]);
				}
			});
		}
	}, [requestId]);

	if (UserChoice) {
		const { hotel } = UserChoice;
		return (
			<div className="bg-accent rounded-lg p-2 shadow-sm fade-in-up">
				<div className="flex-center-between mb-2 text-white px-2">
					<h4>User's Choice</h4>
				</div>
				<StayCard {...hotel} onClick={onClick}/>
			</div>
		);
	}

	return null;
}

export default UserChoice;
