import React from "react";
import { FaLightbulb } from "react-icons/fa";

function NextAction({ status }) {
	const STEPS = {
		outOfPolicy: "You will have to edit the receipt to resolve violations",
		inPolicy: "This receipt is eligible for reimbursement",
		needsApproval: "You can submit the receipt for reimbursement, but it will go through the approval process",
		approval: "Receipt is currently in approval process",
		pending: "Receipt is currently in payout process",
	};

	if (STEPS[status])
		return (
			<div className="gap-2 pt-4 pb-2 flex-center">
				<FaLightbulb />
				<div className="text-xs">{STEPS[status]}</div>
			</div>
		);

	return null;
}

export default NextAction;
