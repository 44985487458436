import React, { useEffect, useState } from "react";
import WhatsNew from "./whatsNew";

function Intros() {
	const { pathname } = window.location;
	const [PendingIntros, setPendingIntros] = useState([]);

	useEffect(() => {
		loadIntros();
	}, [pathname]);

	const loadIntros = () => {
		let intros = localStorage.getItem("intros");
		if (intros) {
			intros = JSON.parse(intros);
			setPendingIntros(intros);
		} else {
			localStorage.setItem("intros", JSON.stringify([]));
		}
	};

	const removeIntro = () => {
		const updatedIntros = PendingIntros.filter((path) => path !== pathname);
		localStorage.setItem("intros", JSON.stringify(updatedIntros));
		loadIntros();
	};

	//Mapping of the intros with the page path to show on
	const INTROS = {
		"/app/home": WhatsNew,
	};

	const ActiveIntro = INTROS[pathname];

	if (PendingIntros.includes(pathname) && ActiveIntro) {
		return <ActiveIntro open={true} onClose={removeIntro} />;
	}

	return null;
}

export default Intros;
