import React, { useState } from "react";
import Travel from "../../Assets/Videos/cycle.mp4";
import { Form, Input, ErrorMsg, Button, Logo, Toast } from "../../Components";
import { apis, post } from "../../Utils/axios";
import { useNavigate } from "react-router-dom";
import { FiMail } from "react-icons/fi";

function Signup() {
	const NAVIGATE = useNavigate();
	const [UserId, setUserId] = useState();
	const [Values, setValues] = useState({
		fName: "",
		lName: "",
		email: "",
		company: "",
	});
	const [CheckInbox, setCheckInbox] = useState();
	const [Loader, setLoader] = useState();
	const [Error, setError] = useState();

	const onSubmit = (e) => {
		setError();
		setLoader(true);
		post(apis.signupUsingEmail, { ...Values }, (r, e) => {
			if (r) {
				setCheckInbox(true);
				setUserId(r.userId);
				setLoader();
			} else if (e) {
				setError(e.response.data.error);
				setLoader();
			}
		});
	};

	const resendMail = (e) => {
		setError();
		setLoader(true);
		post(apis.resendSignupEmail, { userId: UserId }, (r, e) => {
			if (r) {
				Toast.success("Signup email sent");
				setLoader();
			} else if (e) {
				Toast.handleError(e);
				setLoader();
			}
		});
	};

	return (
		<div className={`grid md:grid-cols-2 p-6 h-full`}>
			<div className="relative items-end hidden w-full h-full overflow-hidden text-white bg-center bg-no-repeat bg-cover border-4 md:flex rounded-2xl bg-light border-dark">
				<video muted autoPlay loop className="object-cover w-full h-full ">
					<source src={Travel} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
				<div className="absolute left-0 z-10 p-10 h-50" style={{ background: "linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))" }}>
					<div className="font-bold text-8xl">
						Fast Travel for,
						<br />
						Faster Businesses
					</div>
					<div className="mt-4">Simplified expense reimbursements, faster travel bookings and easy to manage compliances that's how we help you to catch your last minute flight. Create a free account and explore what Travel Wings has to offers for your business.</div>
				</div>
			</div>
			<div className="justify-center h-full align-center">
				<div className="w-full max-w-sm">
					{CheckInbox ? (
						<>
							<FiMail className="text-7xl" />
							<Logo />
							<div className="mt-6 text-5xl font-bold">Check you Inbox</div>
							<div className="mt-3 text-sm">We just dropped you a mail to verify your email address. Follow the email instructions to activate your account</div>
							<div className="flex">
								<Button onClick={() => NAVIGATE("/")} loading={Loader} className="mt-6">
									Go to Login
								</Button>
								<Button variant="" onClick={resendMail} loading={Loader} className="mt-6">
									Resend Email
								</Button>
							</div>
						</>
					) : (
						<>
							<Logo />
							<div className="mt-6 text-5xl font-bold">All aboard !</div>
							<div className="mt-3 text-sm">Just punch in few details so that we can create your brand new account</div>
							<div className="flex flex-col mt-10">
								<Form onSubmit={onSubmit} loading={Loader}>
									<div className="grid grid-cols-2">
										<Input className="mr-2" value={Values.fName} onChange={(fName) => setValues((prev) => ({ ...prev, fName }))} required label="First name" placeholder="First Name" />
										<Input value={Values.lName} onChange={(lName) => setValues((prev) => ({ ...prev, lName }))} required label="Last name" placeholder="Last Name" />
									</div>
									<div className="grid grid-cols-2 mt-2">
										<Input type="email" className="mr-2" value={Values.email} onChange={(email) => setValues((prev) => ({ ...prev, email }))} required label="Email" placeholder="Email" />
										<Input value={Values.company} onChange={(company) => setValues((prev) => ({ ...prev, company }))} required label="Company" placeholder="Company Name" />
									</div>
									<ErrorMsg e={Error} />
									<Button loading={Loader} type="submit" className="w-full mt-6">
										Create Account
									</Button>
									<div onClick={() => NAVIGATE("/")} className="mt-4 text-sm text-center cursor-pointer hover:underline">
										Already have an account ?
									</div>
								</Form>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default Signup;
