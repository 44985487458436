import { useEffect, useState } from "react";

function useMobileCheck() {
	const [isMobile, setIsMobile] = useState(false);
	const [windowSize, setWindowSize] = useState();

	useEffect(() => {
		const handleResize = () => {
			setWindowSize(window.innerWidth);
			if (window.innerWidth < 768) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		};
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return { isMobile, windowSize };
}

export default useMobileCheck;
