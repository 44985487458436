import React, { useState } from 'react';
import { HScroll, PackageCard, ProductCard } from '../../../../Components';
import { FiShoppingBag } from 'react-icons/fi';
import Plane from '../../../../Assets/Images/emojis/plane.png'
import Stay from '../../../../Assets/Images/emojis/stay.png'
import Bus from '../../../../Assets/Images/emojis/bus.png'
import Shop from '../../../../Assets/Images/emojis/shop.png'

function Offers() {

    const [Products, setProducts] = useState([
        {
            id: 1,
            name: 'Mokobara Travel Luggage',
            description: 'Mokobara Travel Luggage',
            price: 12000,
            originalPrice: 15000,
            photo: require(`../../../../Assets/Images/mock/luggage.webp`)
        },
        {
            id: 1,
            name: 'Uniqlo jacket',
            description: 'Uniqlo jacket',
            price: 3000,
            originalPrice: 5000,
            photo: require(`../../../../Assets/Images/mock/jacket.webp`)
        },
        {
            id: 1,
            name: 'Daily Objects Hand Bag',
            description: 'Daily Objects Hand Bag',
            price: 3000,
            originalPrice: 4500,
            photo: require(`../../../../Assets/Images/mock/bag.webp`)
        },
        {
            id: 1,
            name: 'Zara Suit Coat',
            description: 'Zara Suit Coat',
            price: 9000,
            originalPrice: 11000,
            photo: require(`../../../../Assets/Images/mock/suit.jpeg`)
        },
        {
            id: 1,
            name: 'Zara Patch Sneakers',
            description: 'Zara Patch Sneakers',
            price: 12000,
            originalPrice: 15000,
            photo: require(`../../../../Assets/Images/mock/shoe.jpeg`)
        },
        {
            id: 1,
            name: 'Uniqlo Neck Pillow',
            description: 'Uniqlo Neck Pillow',
            price: 1400,
            originalPrice: 2000,
            photo: require(`../../../../Assets/Images/mock/pillow.jpeg`)
        },
        {
            id: 1,
            name: 'Rayban Sunglass',
            description: 'Rayban Sunglass',
            price: 9000,
            originalPrice: 10000,
            photo: require(`../../../../Assets/Images/mock/sunglasses.jpeg`)
        },
    ]);
    const [Packages, setPackages] = useState([
        {
            name: 'Andaman Islands',
            description: '4 Nights in surreal Andaman islands',
            price: 40000,
            nights: 4,
            photo: require('../../../../Assets/Images/mock/andaman.jpeg')
        },
        {
            name: 'Kashmir',
            description: '3 Nights in Kashmir valley',
            price: 20000,
            nights: 3,
            photo: require('../../../../Assets/Images/mock/kashmir.webp')
        },
        {
            name: 'Kerela',
            description: '4 Nights in Kerala',
            price: 42000,
            nights: 4,
            photo: require('../../../../Assets/Images/mock/kerela.webp')
        },
        {
            name: 'Ladakh',
            description: '3 Nights in Ladakh valley',
            price: 35000,
            nights: 3,
            photo: require('../../../../Assets/Images/mock/ladakh.jpeg')
        },
        {
            name: 'Shri Lanka',
            description: '4 Nights in Shri Lanka',
            price: 60000,
            nights: 4,
            photo: require('../../../../Assets/Images/mock/lanka.jpeg')
        },
        {
            name: 'Ajmer',
            description: '3 Nights in desserts of Ajmer',
            price: 28000,
            nights: 3,
            photo: require('../../../../Assets/Images/mock/ajmer.jpeg')
        },
    ]);

    return ( 
        <div className='p-4 flex flex-col gap-y-6'>
            <h1 className='text-2xl'>All things for Personal Travel</h1>
            
            <div className='bg-light border rounded-xl'>
                <div className='p-4 text-center font-bold text-sm'>
                    Book for yourself at the best price
                </div>
                <div className='grid grid-cols-4 gap-x-4 font-bold rounded-xl px-4'>
                    <div className='rounded-lg bg-canvas shadow-md hover:bg-primary hover:text-white p-4 text-xl flex-center cursor-pointer transition-all duration-200'>
                        <img src={Plane} alt='plane' className='w-8 mr-4'/>
                        Flights
                    </div>
                    <div className='rounded-lg bg-canvas shadow-md hover:bg-primary hover:text-white p-4 text-xl flex-center cursor-pointer transition-all duration-200'>
                        <img src={Stay} alt='plane' className='w-8 mr-4'/>
                        Stays
                    </div>
                    <div className='rounded-lg bg-canvas shadow-md hover:bg-primary hover:text-white p-4 text-xl flex-center cursor-pointer transition-all duration-200'>
                        <img src={Bus} alt='plane' className='w-8 mr-4'/> 
                        Buses
                    </div>
                    <div className='rounded-lg bg-canvas shadow-md hover:bg-primary hover:text-white p-4 text-xl flex-center cursor-pointer transition-all duration-200'>
                        <img src={Shop} alt='plane' className='w-8 mr-4'/> 
                        Shop
                    </div>
                </div>
                <div className='p-4 pt-0'>
                    <div className='mt-4 grid grid-cols-2 gap-x-4'>
                        <div className='bg-accent rounded-xl text-white p-6'>
                            <h1 className='text-6xl mb-2'>Get 1%</h1>
                            <div className='text-2xl font-thin'>in rewards every time you travel with us</div>
                        </div>
                        <div className='bg-black rounded-xl text-white p-6'>
                            <h1 className='text-4xl flex gap-x-2 mb-2'><FiShoppingBag/>Shop with Rewards</h1>
                            <div className='text-xl font-thin'>Use your rewards to boost your travel game with our curated collections of travel essentials</div>
                        </div>
                    </div>
                </div>
            </div>

            <HScroll heading='Travel in Style'>
                {
                    Products.map((product,i) => (
                        <ProductCard key={i} {...product}/>
                    ))
                }
            </HScroll>
            <HScroll heading='Curated Holiday Packages'>
                {
                    Packages.map((item,i) => (
                        <PackageCard key={i} {...item}/>
                    ))
                }
            </HScroll>
        </div>
     );
}

export default Offers;