import React, { useContext } from "react";
import { useUserPreference } from "../../Hooks/useUserPreference";
import { SidePaneContext } from "./context";

function SidePane({ children, className = "" }) {
	const { sidePane } = useUserPreference();
	const { SidePaneContent } = useContext(SidePaneContext);

	return (
		<div className={`sidepane-wrapper ${sidePane ? "expanded" : "collapsed"}`}>
			<div className={`sidepane ${SidePaneContent ? "hide" : "show"} ${className}`}>
				<div className="sidepane-content">{children}</div>
			</div>
			<div className={`sidepane ${SidePaneContent ? "show" : "hide"} ${className}`}>
				<div className="sidepane-content">{SidePaneContent}</div>
			</div>
		</div>
	);
}

export default SidePane;
