import React from "react";
import { Tabs } from "../../../../../Components";
import { Tab } from "@headlessui/react";
import { FaBed, FaCar, FaPlane, FaTrain } from "react-icons/fa";
import FlightRequests from "./Flights";
import StayRequests from "./Stays";
import BusRequests from "./Buses";
import CabRequests from "./Cab";
import TrainRequests from "./Train";

function Requests() {
	return (
		<>
			<div className="p-4 pb-24">
				<Tabs
					options={[
						<div className="align-center px-1">
							<FaPlane className="mr-1" />
							Flights
						</div>,
						<div className="align-center px-1">
							<FaBed className="mr-1" />
							Stays
						</div>,
						<div className="align-center px-1">
							<FaCar className="mr-1" />
							Cabs
						</div>,
						<div className="align-center px-1">
							<FaTrain className="mr-1" />
							Train
						</div>,
						// <div className="align-center px-1">
						// 	<FaBus className="mr-1" />
						// 	Buses
						// </div>,
					]}
				>
					<Tab.Panel>
						<FlightRequests />
					</Tab.Panel>
					<Tab.Panel>
						<StayRequests />
					</Tab.Panel>
					<Tab.Panel>
						<CabRequests/>
					</Tab.Panel>
					<Tab.Panel>
						<TrainRequests/>
					</Tab.Panel>
					<Tab.Panel>
						<BusRequests />
					</Tab.Panel>
				</Tabs>
			</div>
		</>
	);
}

export default Requests;
