import React, { useState } from "react";
import { FaPlane } from "react-icons/fa";
import Status from "./status";
import { ViewBooking } from "../../../../../Components";

function Flight({ departureCode, arrivalCode, status, id }) {
	const [ShowBooking, setShowBooking] = useState();

	return (
		<>
			<div onClick={() => setShowBooking(id)} className="group shadow-sm border rounded-full pr-3 align-center gap-2 text-sm font-semibold cursor-pointer whitespace-nowrap">
				<Status status={status} />
				<FaPlane /> {departureCode} - {arrivalCode}
			</div>
			<ViewBooking type="flight" id={ShowBooking} onClose={() => setShowBooking(false)} />
		</>
	);
}

export default Flight;
