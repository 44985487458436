import React from 'react';
import { RadioGroup } from '../../../../../../Components';
import moment from 'moment';
import { StampToTime } from '../../../../../../Utils/utils';

function PickupAndDrop() {

    const BoardPoints = [
        {label: 'Boarding Point 1', value: 1, time: ''},
        {label: 'Boarding Point 2', value: 2, time: ''},
        {label: 'Boarding Point 3', value: 3, time: ''},
        {label: 'Boarding Point 4', value: 4, time: ''},
        {label: 'Boarding Point 5', value: 5, time: ''},
        {label: 'Boarding Point 6', value: 6, time: ''},
        {label: 'Boarding Point 7', value: 7, time: ''},
        {label: 'Boarding Point 8', value: 8, time: ''},
        {label: 'Boarding Point 9', value: 9, time: ''},
    ]

    const DropPoints = [
        {label: 'Drop Point 1', value: 1, time: ''},
        {label: 'Drop Point 2', value: 2, time: ''},
        {label: 'Drop Point 3', value: 3, time: ''},
        {label: 'Drop Point 4', value: 4, time: ''},
        {label: 'Drop Point 5', value: 5, time: ''},
        {label: 'Drop Point 6', value: 6, time: ''},
        {label: 'Drop Point 7', value: 7, time: ''},
        {label: 'Drop Point 8', value: 8, time: ''},
        {label: 'Drop Point 9', value: 9, time: ''},
    ]


    const renderOptions = ({label, value, time}) => {
        return {
            label: 
            <div className='align-center'>
                <div className='bg-light p-1.5 mr-2 text-xs font-bold border rounded-md'>{StampToTime(Number(time))}</div>
                <div>{label}</div>
            </div>,
            value
        }
    }

    return ( 
        <div className='grid grid-cols-2 gap-x-6'>
            <div className='relative'>
                <h4 className='mb-4'>Select Boarding Point</h4>
                <RadioGroup options={BoardPoints.map(renderOptions)}/>

            </div>
            <div className='relative'>
                <h4 className='mb-4'>Select Drop Point</h4>
                <RadioGroup options={DropPoints.map(renderOptions)}/>
            </div>
        </div>
     );
}

export default PickupAndDrop;