import React from "react";
import { Badge, Button } from "../../../../../../../../Components";
import { useUserConfig } from "../../../../../../../../Hooks/useUserConfig";

function Fare({ approval, flight, selected, fare, cheapestFare, highestFare, onSelect }) {
	const { checkUserPermission } = useUserConfig();
	const { fareType, fareKey, price, category, basePrice, refundable, totalTax, inPolicy } = fare;
	const active = selected?.selectedFare?.fareKey === fareKey;

	//When approval is present and If the user has selected any one side fare then allow to book flight within remaining amount
	const { approvedAmount = price, usedAmount = 0 } = approval || {};
	const approvedBalance = approvedAmount - usedAmount;
	//If out of policy or remaining amount is not enough then fare is marked out of policy
	const exceedsApprovalAmount = approval && approvedBalance < price && !checkUserPermission("TA");
	const askApproval = !inPolicy && !checkUserPermission("TA");

	if (price > 0)
		return (
			<>
				<div className={`${cheapestFare ? "" : active ? "bg-compliment" : "fare-option"} border-b`}>
					<div className="flex gap-4 p-2">
						<div className="flex items-center justify-start flex-grow">
							{exceedsApprovalAmount ? (
								<Badge variant="danger">Exceeds Approval</Badge>
							) : askApproval ? (
								<Badge variant="danger">Out of Policy</Badge>
							) : (
								<Badge variant={category === "Corporate" ? "warning" : "success"} className="capitalize">
									{category === "Corporate" && "🚀 "}
									{category}
								</Badge>
							)}
						</div>
						<div>
							<h2 className="whitespace-nowrap">₹ {price}</h2>
						</div>
						<div className="flex items-center justify-end">
							{onSelect &&
								(active ? (
									<Button onClick={() => onSelect()} className="btn-xs">
										Remove
									</Button>
								) : (
									<Button onClick={() => onSelect(fare)} variant="outlined" className="btn-xs">
										Select
									</Button>
								))}
						</div>
					</div>
				</div>
			</>
		);
	return null;
}

export default Fare;
