import React from "react";
import { StampToDate } from "../../../../Utils/utils";
import { FaTrain } from "react-icons/fa";
import TrainBookingStatus from "../Components/trainBookingStatus";
import ViewBooking from "../ViewBooking";
import { useState } from "react";
import ViewRequest from "../../Request/ViewRequest";

function TrainBooking({ id, date, amount, board, drop, coach, bookingId, booking, status, adminRequest, onReload = () => {} }) {
	const [BookingId, setBookingId] = useState();
	const [RequestId, setRequestId] = useState();

	const openBooking = () => {
		if (adminRequest) {
			setRequestId(id);
		} else {
			setBookingId(id);
		}
	};

	return (
		<>
			<div onClick={openBooking} className="overflow-hidden border shadow-sm cursor-pointer rounded-xl bg-canvas min-w-min hover:bg-light">
				<div className="p-4">
					<div className="flex-center-between">
						<h4 className="gap-2 capitalize align-center">
							<FaTrain /> Train
						</h4>
						<div className="text-xs">{StampToDate(date)}</div>
					</div>
					<div className="mt-3">
						<div className="text-label">From: {board.split(",")[0]}</div>
						<div className="text-label">To: {drop.split(",")[0]}</div>
					</div>
				</div>
				{status && <TrainBookingStatus booking={{ status, id, adminRequest }} />}
			</div>
			<ViewBooking id={BookingId} type="train" onClose={() => setBookingId()} onReload={onReload} />
			<ViewRequest requestId={RequestId} onClose={() => setRequestId()} onReload={onReload} />
		</>
	);
}

export default TrainBooking;
