import React from "react";
import { StampToDate } from "../../../../Utils/utils";
import { FaTrain } from "react-icons/fa";
import { useState } from "react";
import ViewRequest from "../ViewRequest";
import RequestStatus from "../Components/requestStatus";

function TrainRequest({ id, date, amount, board, drop, coach, bookingId, booking, status, adminRequest, onReload = () => {} }) {
	const [OpenId, setOpenId] = useState();

	return (
		<>
			<div onClick={() => setOpenId(id)} className="overflow-hidden border shadow-sm cursor-pointer rounded-xl bg-canvas min-w-min hover:bg-light">
				<div className="p-4">
					<div className="flex-center-between">
						<h4 className="gap-2 capitalize align-center">
							<FaTrain /> Train
						</h4>
						<div className="text-xs">{StampToDate(date)}</div>
					</div>
					<div className="mt-3">
						<div className="text-label">From: {board.split(",")[0]}</div>
						<div className="text-label">To: {drop.split(",")[0]}</div>
					</div>
				</div>
				{status && <RequestStatus {...{ id, status }} />}
			</div>
			<ViewRequest requestId={OpenId} onClose={() => setOpenId()} onReload={onReload} />
		</>
	);
}

export default TrainRequest;
