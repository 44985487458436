import React from "react";
import { useNavigate } from "react-router-dom";
import ProfileMenu from "../ProfileMenu";
import { Tooltip } from "react-tooltip";

function MiniSidebar({ options = [] }) {
	const NAVIGATE = useNavigate();
	const pathname = window.location.pathname;

	return (
		<>
			<div className="sidebar fade-in-left">
				<div className="flex-1 p-4 overflow-y-auto scrollbar-hidden">
					<ProfileMenu mini />
					<div className="mt-4 menu">
						{options.map((option, index) => {
							if (typeof option === "string") return null;

							return (
								<div key={index}>
									<div data-tooltip-id="mini-item" data-tooltip-content={option.label} key={index} className={`item flex justify-between ${pathname.startsWith(option.route) ? "active" : ""}`} onClick={option.route ? () => NAVIGATE(option.route) : option.onClick}>
										<span className="align-center">{option.icon}</span>
									</div>
									{option.children?.length > 0 && (
										<div key={`sub-${index}`} className={`sub-items mb-1 bg-light rounded-md ${pathname.startsWith(option.route) ? "active" : ""}`}>
											{option.children.map((child, index) => {
												return (
													<div data-tooltip-id="mini-subitem" data-tooltip-content={child.label} key={index} className={`sub-item !rounded-none ${pathname.startsWith(child.route) ? "active" : ""}`} onClick={child.route ? () => NAVIGATE(child.route) : child.onClick}>
														{child.icon}
													</div>
												);
											})}
										</div>
									)}
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<Tooltip id="mini-item" className="z-20 text-xs" />
			<Tooltip id="mini-subitem" className="z-20 text-xs" />
		</>
	);
}

export default MiniSidebar;
