import React, { useState, useEffect } from "react";
import { Loader, Modal, Toast } from "../..";
import { apis, get } from "../../../Utils/axios";
import ReceiptDetails from "./details";
import EditableReceipt from "./edit";

function ViewReceipt({ receiptId, onClose = () => {}, onReload = () => {} }) {
	const [Receipt, setReceipt] = useState({});
	//To reload all receipts when receipt is edited
	const [WasEdited, setWasEdited] = useState(false);
	const [Loading, setLoading] = useState();

	useEffect(() => {
		if (receiptId) {
			fetchReceipt(receiptId);
		}
	}, [receiptId]);

	const fetchReceipt = (receiptId) => {
		setLoading(true);
		get(apis.getReceiptDetails, { receiptId }, (r, e) => {
			if (r) {
				setReceipt(r);
				setLoading(false);
			} else if (e) {
				Toast.handleError(e);
				setLoading(false);
			}
		});
	};

	const { status } = Receipt;
	const editable = status === "needsApproval" || status === "inPolicy" || status === "created" || status === "outOfPolicy";

	return (
		<Modal
			contentClass="!bg-light p-4"
			open={receiptId}
			onClose={() => {
				if (WasEdited) {
					onReload();
					setWasEdited(false);
				}
				onClose();
			}}
		>
			<Loader loading={Loading} />
			{editable ? (
				<EditableReceipt
					receipt={Receipt}
					receiptId={receiptId}
					onReload={() => {
						fetchReceipt(receiptId);
						setWasEdited(true);
					}}
					onClose={onClose}
				/>
			) : (
				<ReceiptDetails receipt={Receipt} />
			)}
		</Modal>
	);
}

export default ViewReceipt;
