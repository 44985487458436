import React from "react";
import { TbSteeringWheel } from "react-icons/tb";

function BusSeatMap() {
	return (
		<>
			<div className="p-4 align-center gap-x-2">
				<div className="text-xs align-center">
					<div className="w-4 h-4 mr-2 rounded-full bg-light" />
					Free
				</div>
				<div className="text-xs align-center">
					<div className="w-4 h-4 mr-2 rounded-full bg-light" />
					Taken
				</div>
				<div className="text-xs align-center">
					<div className="w-4 h-4 mr-2 rounded-full bg-light" />₹ 200
				</div>
				<div className="text-xs align-center">
					<div className="w-4 h-4 mr-2 rounded-full bg-light" />₹ 500
				</div>
			</div>
			<div className="grid grid-cols-2 p-10 rounded-md bg-light">
				<div className="flex-col flex-center">
					<div className="p-2 mb-4 text-sm rounded-md bg-canvas text-secondary">Upper Berth</div>
					<div className="p-4 rounded-md bg-canvas">
						<div className="flex items-center justify-end mb-4 gap-x-2">
							<TbSteeringWheel className="text-2xl" /> <div className="text-label">Driver</div>
						</div>
						<div className="grid grid-cols-2 gap-10">
							<div className="grid grid-cols-2 gap-2">
								{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
									return (
										<div key={index} className="w-8 h-8 text-xs rounded-md cursor-pointer bg-light flex-center hover:bg-primary">
											1A
										</div>
									);
								})}
							</div>
							<div className="grid grid-cols-2 gap-2">
								{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
									return (
										<div key={index} className="w-8 h-8 text-xs rounded-md cursor-pointer bg-light flex-center hover:bg-primary">
											2B
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
				<div className="flex-col flex-center">
					<div className="p-2 mb-4 text-sm rounded-md bg-canvas text-secondary">Lower Berth</div>
					<div className="p-4 rounded-md bg-canvas">
						<div className="flex items-center justify-end mb-4 gap-x-2">
							<TbSteeringWheel className="text-2xl" /> <div className="text-label">Driver</div>
						</div>
						<div className="grid grid-cols-2 gap-10">
							<div className="grid grid-cols-2 gap-2">
								{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
									return (
										<div key={index} className="w-8 h-8 text-xs rounded-md cursor-pointer bg-light flex-center hover:bg-primary">
											1A
										</div>
									);
								})}
							</div>
							<div className="grid grid-cols-2 gap-2">
								{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
									return (
										<div key={index} className="w-8 h-8 text-xs rounded-md cursor-pointer bg-light flex-center hover:bg-primary">
											2B
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default BusSeatMap;
