import React from "react";
import useMobileCheck from "../../Hooks/useMobileCheck";
import { Logo } from "..";

function SizeMonitor() {
	const { isMobile } = useMobileCheck();

	if (isMobile)
		return (
			<div className="w-[100vw] h-[100vh] bg-black absolute left-0 top-0 bottom-0 right-0 p-10 flex-center z-20 text-white">
				<div className="text-center">
					<Logo className="justify-center" error />
					<div className="mt-6 text-4xl font-bold">Too Small</div>
					<div className="mt-5 text-small">Please use a desktop or laptop to access our web platform or download the mobile app.</div>
				</div>
			</div>
		);

	return null;
}

export default SizeMonitor;
