import React from "react";
import { TbMasksTheater, TbMasksTheaterOff } from "react-icons/tb";
import { useState } from "react";
import { FiArrowUpRight, FiSave } from "react-icons/fi";
import { Modal, Button, TeamSearch, OfficeSearch, Toast } from "../../..";
import { useNavigate } from "react-router-dom";
import { apis, patch, post } from "../../../../Utils/axios";

function ChangeEntity({ onChange, id, selected = {}, type = "" }) {
	if (selected === null) {
		selected = {};
	}

	const NAVIGATE = useNavigate();

	const [ShowEntities, setShowEntities] = useState();
	const [Entity, setEntity] = useState({});

	const onSave = () => {
		setEntity({});
		changeEntity({
			name: Entity.label,
			id: Entity.value,
		});
	};

	const showSearch = () => {
		setShowEntities(true);
		setEntity({ label: selected.name, value: selected.id });
	};

	const changeEntity = (entity) => {
		if (!entity.id) {
			Toast.error(`Please select ${type} to change`);
			return;
		}

		patch(type === "office" ? apis.updateTeamOffice : `/users/${id}/team`, type === "office" ? { officeId: parseInt(entity.id), teamId: id } : { teamId: parseInt(entity.id), userId: id }, (r, e) => {
			if (r) {
				Toast.success(type === "office" ? `Office updated` : `Team updated`);
				onChange && onChange();
				setShowEntities();
			} else if (e) {
				console.log(e);
				Toast.handleError(e);
			}
		});
	};

	return (
		<>
			{selected?.id ? (
				<Button onClick={showSearch} variant="outlined" className="btn-xs">
					<TbMasksTheater className="mr-1" />
					{selected.name}
				</Button>
			) : (
				<Button onClick={showSearch} variant="outlined" className="btn-xs">
					<TbMasksTheaterOff className="mr-1" />
					No {type}
				</Button>
			)}
			<Modal open={ShowEntities} onClose={() => setShowEntities()}>
				<div className="p-6">
					<h1 className="capitalize align-center gap-x-2">Change {type}</h1>
					<div className="mt-4">
						<div className="flex gap-x-2">
							{type === "office" ? <OfficeSearch onSelect={setEntity} selected={Entity} /> : type === "team" ? <TeamSearch onSelect={setEntity} selected={Entity} /> : null}
							{selected?.id && (
								<Button
									onClick={() => {
										NAVIGATE(`/app/${type}s/${selected.id}`);
										setShowEntities();
									}}
									variant="outlined"
								>
									<FiArrowUpRight />
								</Button>
							)}
						</div>
						<div className="flex mt-4 gap-x-2">
							<Button onClick={onSave} className="flex-grow">
								<FiSave className="mr-1" />
								Save
							</Button>
							{/* <Button onClick={() => setEntity({ label: "", value: null })} variant="danger" className="flex-grow">
								Remove
							</Button> */}
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default ChangeEntity;
