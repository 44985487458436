import React from "react";
import { Booking } from "../../../";
import { CopyValue } from "../../..";

function TripApproval({ tripId, bookings = [] }) {
	return (
		<>
			<div className="flex-center-between">
				<h4>{bookings.length} Trip Bookings</h4>
				<div className="text-label">
					<CopyValue>{tripId}</CopyValue>
				</div>
			</div>
			<div className="flex flex-col mt-4 gap-y-4">
				{bookings.map((booking, i) => (
					<Booking key={i} booking={booking} />
				))}
			</div>
		</>
	);
}

export default TripApproval;
