import React, { useEffect, useState } from "react";
import { Button, DateRange, Toast } from "../../../../Components";
import { useNavigate } from "react-router-dom";
import CitySearch from "../../Inputs/CitySearch";
import { FiMap } from "react-icons/fi";
import { useUserConfig } from "../../../../Hooks/useUserConfig";
import SelectRooms from "../../Inputs/SelectRooms";
import moment from "moment";

function StaySearch({ city, range, rooms, map }) {
	const NAVIGATE = useNavigate();
	const { role, checkUserPermission } = useUserConfig();
	const isUser = role === "USER";

	const [Values, setValues] = useState({
		city: city || "",
		range: range || {
			startDate: moment().valueOf(),
			endDate: moment().add(1, "day").valueOf(),
		},
		rooms: rooms || [
			{
				adult: 1,
				child: 0,
			},
		],
	});

	useEffect(() => {
		//Quick Selection
		if (city) {
			setValues((prev) => ({ ...prev, city }));
		}
	}, [city]);

	const updateValues = (obj) => {
		setValues((prev) => ({ ...prev, ...obj }));
	};

	const searchHotels = () => {
		if (!Values.city) {
			return Toast.error("Please select city");
		}
		NAVIGATE(`/app/travel/stays/search/?city=${Values.city.label}&lat=${Values.city.lat}&lng=${Values.city.lng}&checkIn=${Values.range?.startDate}&checkOut=${Values.range?.endDate}&rooms=${JSON.stringify(Values.rooms)}`);
	};

	return (
		<div className={`rounded-md bg-light p-4 flex gap-x-2 ${map ? "absolute top-6 left-6 right-20 frosted shadow-sm z-10" : ""}`}>
			<div className="flex-grow">
				<CitySearch label="Where ?" selected={Values.city} onSelect={(city) => updateValues({ city })} placeholder="Search City" icon={<FiMap />} />
			</div>
			<div className="flex-grow">
				<DateRange label="Check in - Check out" setRange={(range) => updateValues({ range })} range={Values.range} />
			</div>
			{checkUserPermission("TA") && (
				<div className="flex-grow">
					<SelectRooms label="Add Rooms" rooms={Values.rooms} onChange={(rooms) => updateValues({ rooms })} />
				</div>
			)}
			<div className="flex-grow">
				<Button onClick={searchHotels} variant="primary" className="w-full mt-7">
					Find Stays
				</Button>
			</div>
		</div>
	);
}

export default StaySearch;
