import React from "react";

function PackageCard({ onClick = () => {}, id = "", name = "", description = "", price = 0, photo = "", days = 0 }) {
	return (
		<>
			<div onClick={onClick} className="transition-all duration-300 rounded-xl min-w-[16rem] max-w-full cursor-pointer text-white overflow-hidden background-cover" style={{ backgroundImage: `url(${photo})` }}>
				<div className="pt-12" style={{ background: "linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))" }}>
					<div className="p-4 text-sm">
						<h1>{name}</h1>
						<div className="text-xs">{description}</div>
						<div className="mt-2 text-xs font-bold">INR {price}</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default PackageCard;
