import React from "react";
import { BiSortDown, BiSortUp } from "react-icons/bi";

function Sort({ sortBy = {}, updateSort = () => {} }) {
	const sortTypes = [
		{
			type: "rating",
			label: "Rating",
		},
		{
			type: "distance",
			label: "Distance",
		},
		{
			type: "price",
			label: "Price",
		},
	];

	return (
		<>
			<div className="mt-4 text-xs text-secondary flex gap-10">
				<div className="text-dark font-bold">Sort By</div>
				{sortTypes.map(({ type, label }, i) => {
					let active = sortBy.type === type;
					return (
						<div
							onClick={() => {
								updateSort({
									type,
									order: active && sortBy.order === "asc" ? "des" : "asc",
								});
							}}
							key={i}
							className={`cursor-pointer text-center flex-center gap-2 ${active ? "text-dark font-bold" : ""}`}
						>
							{label}
							{active ? sortBy.order === "des" ? <BiSortDown /> : <BiSortUp /> : ""}
						</div>
					);
				})}
			</div>
		</>
	);
}

export default Sort;
