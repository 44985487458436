import React from "react";
import { Tabs } from "../../../../../Components";
import { Tab } from "@headlessui/react";
import FlightHome from "../Flights";
import StaysHome from "../Stays";
import { FaBed, FaCar, FaPlane, FaTrain } from "react-icons/fa";
import { useUserPolicy } from "../../../../../Hooks/useUserPolicy";
import CabHome from "../Cab";
import TrainHome from "../Train";
import { useClientSettings } from "../../../../../Hooks/useClientSettings";

function TravelHome() {
	const { isEnabled } = useUserPolicy();
	const { isEnabled: Enabled } = useClientSettings();
	return (
		<>
			<Tabs
				className="h-full"
				tabsClass="p-4 pb-2"
				options={[
					...(isEnabled("FLIGHT")
						? [
								<div className="gap-1 flex-center">
									<FaPlane /> Flight
								</div>,
							]
						: []),
					...(isEnabled("STAY")
						? [
								<div className="gap-1 flex-center">
									<FaBed /> Stay
								</div>,
							]
						: []),
					...(isEnabled("CAB") && Enabled("ORGANIZATION")
						? [
								<div className="gap-1 flex-center">
									<FaCar /> Cab
								</div>,
							]
						: []),
					...(isEnabled("TRAIN") && Enabled("ORGANIZATION")
						? [
								<div className="gap-1 flex-center">
									<FaTrain /> Train
								</div>,
							]
						: []),
				]}
			>
				<Tab.Panel>
					<FlightHome />
				</Tab.Panel>
				<Tab.Panel>
					<StaysHome />
				</Tab.Panel>
				<Tab.Panel>
					<CabHome />
				</Tab.Panel>
				<Tab.Panel>
					<TrainHome />
				</Tab.Panel>
			</Tabs>
		</>
	);
}

export default TravelHome;
