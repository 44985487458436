import React from "react";
import ImportFlightBooking from "./flight";
import ImportStayBooking from "./stay";
import ImportCabBooking from "./cab";
import ImportTrainBooking from "./train";

function ImportBooking({ type, requestId, onReload = () => {}, btnLabel, btnClass, btnVariant }) {
	if (type === "flight") {
		return <ImportFlightBooking {...{ requestId, onReload, btnLabel, btnClass, btnVariant }} />;
	}
	if (type === "stay") {
		return <ImportStayBooking {...{ requestId, onReload, btnLabel, btnClass, btnVariant }} />;
	}
	if (type === "cab") {
		return <ImportCabBooking {...{ requestId, onReload, btnLabel, btnClass, btnVariant }} />;
	}
	if (type === "train") {
		return <ImportTrainBooking {...{ requestId, onReload, btnLabel, btnClass, btnVariant }} />;
	}

	return null;
}

export default ImportBooking;
