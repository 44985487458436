import React, { useEffect, useState } from "react";
import { Badge, Button, CopyValue, Divider, Loader, Spinner, Toast, ImageViewer, ReadMore } from "../../../../../../Components";
import { FiArrowLeft, FiEye, FiEyeOff, FiHome, FiMapPin, FiShare } from "react-icons/fi";
import { TbPlaneDeparture } from "react-icons/tb";
import { BsShieldFillCheck } from "react-icons/bs";
import { useNavigate, useSearchParams } from "react-router-dom";
import MapIcon from "../../../../../../Assets/Images/icons/map.png";
import { apis, get } from "../../../../../../Utils/axios";
import moment from "moment";
import { StampToDate } from "../../../../../../Utils/utils";
import RoomsChoices from "./rooms";
import { useUserPreference } from "../../../../../../Hooks/useUserPreference";

function HotelPage() {
	const { showSidePane } = useUserPreference();
	const [ExpandAmenities, setExpandAmenities] = useState(false);
	const NAVIGATE = useNavigate();

	const [searchParams] = useSearchParams();
	const hotelId = searchParams.get("hotelId");
	const rooms = searchParams.get("rooms");
	const parsedRooms = JSON.parse(searchParams.get("rooms") || "[]");
	const checkIn = parseInt(searchParams.get("checkIn"));
	const checkOut = parseInt(searchParams.get("checkOut"));
	const requestId = searchParams.get("requestId") || "";
	const approvalId = searchParams.get("approvalId") || "";
	const [Loading, setLoading] = useState();

	const [Details, setDetails] = useState({
		amenities: [],
		cardsAccepted: {},
		chain: "",
		checkInTime: "",
		checkOutTime: "",
		id: "",
		locality: {},
		name: "",
		notice: "",
		overview: "",
		restrictions: [],
		rooms: [],
		starRating: 0,
		thumbnail: "",
		totalFloors: "",
		totalRooms: "",
	});

	useEffect(() => {
		showSidePane(false);
		return () => {
			showSidePane(true);
		};
	}, []);

	useEffect(() => {
		fetchDetails(hotelId, rooms, checkIn, checkOut, requestId, approvalId);
	}, [hotelId, rooms, checkIn, checkOut, requestId, approvalId]);

	const fetchDetails = (hotelId, rooms, checkIn, checkOut, requestId, approvalId) => {
		setLoading(true);
		get(
			apis.getHotelDetails,
			{
				hotelId,
				occupancy: JSON.parse(rooms || "[]"),
				checkIn,
				checkOut,
				requestId,
				approvalId,
			},
			(r, e) => {
				if (r) {
					setDetails(r);
					setLoading(false);
				} else if (e) {
					Toast.handleError(e);
					setLoading(false);
				}
			}
		);
	};

	const copyLink = () => {
		navigator.clipboard.writeText(window.location.href);
		Toast.success("Link Copied");
	};

	const nights = moment(checkOut).diff(moment(checkIn), "days");
	const { amenities, images, chain, checkInTime, checkOutTime, overview, locality, name, rooms: roomOptions, starRating, thumbnail, totalFloors, totalRooms } = Details;

	if (Loading) {
		return (
			<div className="w-full h-1/2">
				<Loader loading={Loading} />
				<div className="flex items-end justify-center w-full h-full">
					<Spinner />
				</div>
			</div>
		);
	}

	return (
		<>
			<div className="p-8">
				<Button onClick={() => NAVIGATE(-1)} className="mb-6 btn-sm" variant="outlined">
					<FiArrowLeft className="mr-2" /> Back to Search
				</Button>
				<div className="text-2xl font-semibold">{name}</div>
				<div className="mb-3 text-label">
					<CopyValue>
						{locality?.address}, {locality?.localityName}
					</CopyValue>
				</div>
				<div className="flex justify-between mb-10">
					<div className="align-center">
						{(totalRooms > 0 || totalFloors > 0) && (
							<Badge className="mr-1">
								{totalRooms} Rooms | {totalFloors} Floors
							</Badge>
						)}
						<div className="text-sm">
							{[...Array(parseInt(starRating))].map((e, i) => {
								return <span key={i}>⭑</span>;
							})}
						</div>
					</div>
					<div className="flex gap-x-4">
						<div onClick={copyLink} className="text-sm cursor-pointer align-center">
							<FiShare className="mr-1" /> Share
						</div>
					</div>
				</div>

				<ImageViewer images={images} name={name} className="mb-10 fade-in-up" />

				<div className="grid grid-cols-7 mb-10 gap-x-8">
					<div className="col-span-4">
						<h1>About</h1>
						<ReadMore height="h-32" className="mt-2 overflow-hidden text-sm text-secondary text-ellipsis">
							<div dangerouslySetInnerHTML={{ __html: overview }} />
						</ReadMore>
						{chain && (
							<div className="align-center mt-4 chip w-min bg-compliment !border-accent">
								<BsShieldFillCheck className="mr-2 text-lg text-accent" />
								<span className="text-accent">By {chain}</span>
							</div>
						)}
						<Divider className="my-10 !border-surface" />
						<h1>Amenities</h1>
						<div className="mt-8">
							{amenities.map((amenity, i) => {
								const { category, amenities } = amenity;
								if (!ExpandAmenities && i > 1) return null;
								return (
									<div key={i} className="mb-6">
										<h4 className="mb-2">{category}</h4>
										<div className="flex flex-wrap gap-2">
											{amenities.map((amenity, i) => (
												<div key={i} className="chip">
													{amenity}
												</div>
											))}
										</div>
									</div>
								);
							})}
						</div>
						<Button onClick={() => setExpandAmenities(!ExpandAmenities)} variant="outlined" className="mt-6 btn-sm">
							{ExpandAmenities ? (
								<>
									<FiEyeOff className="mr-2" />
									Hide amenities
								</>
							) : (
								<>
									<FiEye className="mr-2" />
									View all amenities
								</>
							)}
						</Button>
					</div>

					<div className="col-span-3">
						<div className="p-4 border rounded-lg shadow-sm">
							<h2>Your Stay</h2>
							<div className="pl-6 mt-4">
								<div className="flex text-sm">
									<div className="border border-l" />
									<div className="relative pt-6 pl-8 align-center">
										<div className="absolute p-1 -left-3 bg-canvas">
											<FiHome />
										</div>
										<div>
											<Badge variant="">{StampToDate(checkIn)}</Badge>
											<div className="mt-2 text-xs">Check in at {moment(parseInt(checkInTime), "HHmm").format("hh:mm A")}</div>
										</div>
									</div>
								</div>
								<div className="flex text-sm">
									<div className="border border-l" />
									<div className="relative pt-6 pl-8 align-center">
										<div className="absolute p-1 -left-6 bg-canvas">
											<img src={thumbnail} alt="stay" className="w-10 h-10 mr-2 rounded-full" />
										</div>
										<div>
											<div className="mt-2 text-xs">
												<b>{nights} Night stay</b> at {Details.name}
											</div>
										</div>
									</div>
								</div>
								<div className="flex text-sm">
									<div className="border border-l" />
									<div className="relative pt-6 pl-8 align-center">
										<div className="absolute p-1 -left-3 bg-canvas">
											<FiMapPin />
										</div>
										<div>
											<Badge variant="">{StampToDate(checkOut)}</Badge>
											<div className="mt-2 text-xs">Checkout before {moment(parseInt(checkOutTime), "HHmm").format("hh:mm A")}</div>
										</div>
									</div>
								</div>
							</div>

							<div className="flex mt-12 gap-x-2">
								<Button onClick={() => NAVIGATE("/app/travel/")} variant="outlined" className="flex-grow shadow-md btn-sm">
									<TbPlaneDeparture className="mr-2" />
									Search Flight
								</Button>
							</div>
						</div>
					</div>
				</div>

				<div className="mb-10">
					<h1 className="mb-10">Room Choices</h1>
					<div>
						<RoomsChoices {...{ requestId, approvalId, hotelId, checkIn, checkOut, occupancy: parsedRooms, hotel: Details }} rooms={roomOptions} />
					</div>
				</div>

				<div>
					<h1 className="mb-8">Where you will be</h1>
					<div className={`relative rounded-lg overflow-hidden w-full h-80`} style={{ background: `#f2f2f2 url(${MapIcon}) no-repeat center`, backgroundSize: "3rem" }}>
						<iframe
							className="w-full h-full border-none"
							title="map"
							src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d112173.19063607592!2d${Details.locality?.latitude}!3d${Details.locality?.longitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x52c2b7494e204dce!2sNew%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1684064115759!5m2!1sen!2sin`}
							allowFullScreen
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export default HotelPage;
