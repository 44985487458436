import React from "react";
import FlightRequest from "./Types/flight";
import BusRequest from "./Types/bus";
import StayRequest from "./Types/stay";
import CabRequest from "./Types/cab";
import TrainRequest from "./Types/train";

function Requests({ request = {}, type, onReload = () => {}, readOnly }) {
	if (type === "flight") {
		return <FlightRequest {...request} {...{ onReload, readOnly }} />;
	}
	if (type === "stay") {
		return <StayRequest {...request} {...{ onReload, readOnly }} />;
	}
	if (type === "cab") {
		return <CabRequest {...request} {...{ onReload, readOnly }} />;
	}
	if (type === "train") {
		return <TrainRequest {...request} {...{ onReload, readOnly }} />;
	}
	if (type === "bus") {
		return <BusRequest {...request} {...{ onReload, readOnly }} />;
	}

	return null;
}

export default Requests;
