import React from "react";
import ImportBooking from "../../../../ImportBooking";
import TrainDetails from "./trainDetails";

function TrainRequest({ id, choices, status, onReload = () => {} }) {
	const request = choices[0];

	return (
		<>
			{status === "requested" && <ImportBooking onReload={onReload} type="train" requestId={id} btnLabel="Add Booking" btnVariant="outlined" />}
			<div className="p-4 rounded-lg shadow-sm bg-canvas">
				<div className="mb-4 flex-center-between">
					<h4>Train Details</h4>
				</div>
				<TrainDetails {...request} />
			</div>
		</>
	);
}

export default TrainRequest;
