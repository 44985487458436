import React from 'react';
import { FaBed, FaBus, FaCar, FaPlane, FaReceipt, FaTrain } from 'react-icons/fa';
import { BackButton, Button, Portal } from '../../../../../../Components';
import { useNavigate } from 'react-router-dom';
import { LabelValue } from '../../../../../../Components';
import Bookings from './Booking';

import mock1 from '../../../../../../Assets/Images/mock/1.webp'
import mock2 from '../../../../../../Assets/Images/mock/2.webp'

function ViewTrip({}) {

    const NAVIGATE = useNavigate()

    const Mock_Flight_Booking1 = {
        from: {
            name: 'Delhi',
            code: 'DEL',
            country: 'India',
        },
        to: {
            name: 'Mumbai',
            code: 'BOM',
            country: 'India',
        },
        departure: {
            time: 1684679400000,
            terminal: 'Terminal 1',
            airport: 'Indira Gandhi International Airport',
        },
        arrival: {
            time: 1684688400000,
            terminal: 'Terminal 1',
            airport: 'Chhatrapati Shivaji Maharaj International Airport',
        },
        airline: {
            name: 'Vistara',
            code: '6E',
            number: '202',
            logo: 'https://play-lh.googleusercontent.com/E_SwrB7iPlTB53XBwlrgNsWPM_JhJ8DPK60Ht_RDHLkim_FIGF558LPA7QXw4ksWYEU',
        },
        amount: 4500,
        date: 1684679400000,
        mode: 'oneway',
        type: 'flight',
        status: 'booked',
        id: 'TTP-33434-XCXV',
        pnr: 'XFD5352',
        cabinClass: 'Economy',
    }

    const Mock_Flight_Booking2 = {
        to: {
            name: 'Delhi',
            code: 'DEL',
            country: 'India',
        },
        from: {
            name: 'Mumbai',
            code: 'BOM',
            country: 'India',
        },
        departure: {
            time: 1684679400000,
            terminal: 'Terminal 1',
            airport: 'Indira Gandhi International Airport',
        },
        arrival: {
            time: 1684688400000,
            terminal: 'Terminal 1',
            airport: 'Chhatrapati Shivaji Maharaj International Airport',
        },
        airline: {
            name: 'Vistara',
            code: '6E',
            number: '202',
            logo: 'https://play-lh.googleusercontent.com/E_SwrB7iPlTB53XBwlrgNsWPM_JhJ8DPK60Ht_RDHLkim_FIGF558LPA7QXw4ksWYEU',
        },
        amount: 4500,
        date: 1684679400000,
        mode: 'oneway',
        type: 'flight',
        status: 'booked',
        id: 'TTP-33434-XCXV',
        pnr: 'XFD5352',
        cabinClass: 'Economy',
    }

    const Mock_Hotel_Booking = {
        images: [mock1, mock2],
        amenities: ['Free Wifi', 'Free Parking', 'Swimming Pool', 'Gym'],
        name: 'Golden Tulip Goa Candolim',
        address: 'Condolim, South Goa',
        coords: {lat: 0, long: 0},
        price: 2000,
        rating: 4.5,
        stars: 3,
        type: 'stay',
        status: 'booked',
        id: 'TTP-33434-XCXV',
    }

    const Mock_Bus_Booking = {
        id: 1,
        from: {
            name: 'Delhi',
            country: 'India',
        },
        to: {
            name: 'Jaipur',
            country: 'India',
        },
        departure: {
            time: 0,
            point: 'Kashmiri Gate',
        },
        arrival: {
            time: 0,
            point: 'Station Road',
        },
        operator: {
            name: 'Goldline Super Deluxe',
        },
        bus: 'Bharat Benz A/C Sleeper (2+1)',
        rating: 3.8,
        seats: 40,
        cancellation: {
            0 : '0 to 12 hr(s) before travel',
            50 : '12 to 24 hr(s) before travel',
            100 : '24 to 48 hr(s) before travel',
        },
        images: [],
        pickups: [
            {
                id: 1,
                name: 'Kashmiri Gate',
                time: '9:00 PM',
            },
            {
                id: 2,
                name: 'ISBT',
                time: '9:30 PM',
            },
            {
                id: 3,
                name: 'Majnu Ka Tila',
                time: '10:00 PM',
            },
        ],
        drops: [
            {
                id: 1,
                name: 'Station Road',
                time: '5:00 AM',
            },
            {
                id: 2,
                name: 'Sindhi Camp',
                time: '5:30 AM',
            },
            {
                id: 3,
                name: 'Ajmeri Gate',
                time: '6:00 AM',
            },
        ],
        amenities: ['AC', 'Water Bottle', 'Blanket', 'Charging Point', 'Reading Light'],
        amount: 600,
        date: '',
        type: 'bus',
    }

    return ( 
        <Portal>
        <div className='absolute top-0 left-0 z-20 h-full w-full bg-light flex p-8 fade-in overflow-y-auto'>
            <div className='flex-grow'>
                <div className='text-4xl align-center'>
                    <BackButton className='mr-4 border bg-canvas'/>
                    <span>Trip to Bangalore</span>
                </div>
                <div className='p-6 flex pl-2'>
                    <div className='border-l-2 border-dashed border-dark w-2 mt-8'/>
                    <div className='-ml-3.5'>
                        <div className='flex mt-6'>
                            <div className='w-3.5 h-3.5 mt-1 rounded-full bg-dark'/>
                            <div className='ml-8'>
                                <div className='font-semibold'>
                                    15 April 2023
                                </div>
                                <div className='flex gap-x-4 mt-4 bg-surface rounded-xl p-4'>
                                    <Bookings booking={Mock_Flight_Booking1}/>
                                    <Bookings booking={Mock_Hotel_Booking}/>
                                </div>
                            </div>
                        </div>
                        <div className='flex mt-6'>
                            <div className='w-3.5 h-3.5 mt-1 rounded-full bg-dark'/>
                            <div className='ml-8'>
                                <div className='font-semibold'>
                                    19 April 2023
                                </div>
                                <div className='flex gap-x-4 mt-4 bg-surface rounded-xl p-4'>
                                    <Bookings booking={Mock_Bus_Booking}/>
                                </div>
                            </div>
                        </div>
                        <div className='flex mt-6'>
                            <div className='w-3.5 h-3.5 mt-1 rounded-full bg-dark'/>
                            <div className='ml-8'>
                                <div className='font-semibold'>
                                    25 April 2023
                                </div>
                                <div className='flex gap-x-4 mt-4 bg-surface rounded-xl p-4'>
                                    <Bookings booking={Mock_Flight_Booking2}/>
                                </div>
                            </div>
                        </div>
                        <div className='flex mt-6'>
                            <div className='w-3.5 h-3.5 rounded-full bg-dark'/>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='sticky top-0 w-72'>
                <div className='p-4 rounded-lg bg-gray-950 text-white shadow-sm overflow-hidden'>
                    <LabelValue className='mb-2' label='Trip Name'>
                        Trip to Bangalore
                    </LabelValue>
                    <LabelValue className='mb-2' label='Trip ID'>
                        TPP-234023049
                    </LabelValue>
                    <LabelValue className='mb-2' label='Created On'>
                        12th May 2023
                    </LabelValue>
                    <Button variant='light' className='btn-sm w-full mt-4'>Cancel this Trip</Button>
                </div>
                <div className='mt-4 border rounded-lg bg-canvas shadow-sm overflow-hidden'>
                    <h3 className='p-4'>Add to trip</h3>
                    <div className='border-t p-4 align-center gap-x-4 hover:bg-light cursor-pointer'>
                        <FaPlane/>Add Flight
                    </div>
                    <div className='border-t p-4 align-center gap-x-4 hover:bg-light cursor-pointer'>
                        <FaBed/>Add Stay
                    </div>
                    <div className='border-t p-4 align-center gap-x-4 hover:bg-light cursor-pointer'>
                        <FaBus/>Add Bus
                    </div>
                    <div className='border-t p-4 align-center gap-x-4 hover:bg-light cursor-pointer'>
                        <FaCar/>Add Cab
                    </div>
                    <div className='border-t p-4 align-center gap-x-4 hover:bg-light cursor-pointer'>
                        <FaTrain/>Add Train
                    </div>
                    <div className='border-t p-4 align-center gap-x-4 hover:bg-light cursor-pointer'>
                        <FaReceipt/>Add Expense
                    </div>
                </div>
            </div>
        </div>
        </Portal>
     );
}

export default ViewTrip;