import React from "react";
import { Button, Input, Modal, FileInput, DatePicker, Toast, TeamSearch, ButtonGroup, Checkbox } from "../../";
import { useState } from "react";
import { apis, post } from "../../../Utils/axios";
import moment from "moment";
import { useAdminPermission } from "../../../Hooks/useAdminPermission";

function AddEmployee({ teamId, onReload = () => {}, btnLabel = "+ Add User", btnClass = "", btnVariant = "primary" }) {
	const { checkAdminPermission } = useAdminPermission();
	const [NewEmployee, setNewEmployee] = useState();
	const [SendInvite, setSendInvite] = useState(true);

	const initial = {
		fName: "",
		lName: "",
		email: "",
		gender: {
			label: "Male",
			value: "MALE",
		},
		phone: "",
		photo: "",
		team: {},
		dob: moment().subtract(18, "years").valueOf(),
	};
	const [Values, setValues] = useState(initial);
	const [Loader, setLoader] = useState();

	const onSubmit = () => {
		if (!Values.gender) {
			return Toast.error("Please select gender");
		}
		if (!Values.email) {
			return Toast.error("Please enter email address");
		}
		post(
			apis.addUser,
			{
				...Values,
				gender: Values.gender?.value,
				teamId: teamId || Values.team.value,
			},
			(r, e) => {
				if (r) {
					Toast.success("Verification link sent to user");
					setNewEmployee(false);
					setLoader(false);
					onReload();
					setValues(initial);
				} else if (e) {
					if (e.response.status === 409) {
						Toast.error("User already exists");
					} else {
						Toast.handleError(e);
					}
					setLoader(false);
				}
			}
		);
	};

	const sendInvite = () => {
		if (!Values.email) {
			return Toast.error("Please enter email address");
		}
		setLoader(true);
		post(apis.sendInvite, Values, (r, e) => {
			if (r) {
				Toast.success("Invite sent successfully");
				setNewEmployee(false);
				setLoader(false);
				onReload();
				setValues(initial);
			} else if (e) {
				if (e.response.status === 409) {
					Toast.error("User already exists");
				} else {
					Toast.handleError(e);
				}
				setLoader(false);
			}
		});
	};

	if (!checkAdminPermission("EDIT_USERS")) {
		return null;
	}

	return (
		<>
			<Button onClick={() => setNewEmployee(true)} variant={btnVariant} className={btnClass}>
				{btnLabel}
			</Button>
			<Modal
				open={NewEmployee}
				onClose={() => {
					setNewEmployee();
					setValues(initial);
					setSendInvite(true);
				}}
			>
				<div className="p-6">
					<h1>Invite User</h1>
					<div className="mt-2 text-label">You can invite users to join Travel Wings by sending them invitation on their email address</div>
					<div className="flex flex-col mt-4 gap-y-4">
						<Input allowSpecialChars={SendInvite} required value={Values.email} onChange={(email) => setValues((prev) => ({ ...prev, email }))} label="Email Address" placeholder="Email" />
						{!SendInvite && (
							<>
								{!teamId && <TeamSearch label="Team this user belongs to" placeholder="Search Teams" onSelect={(team) => setValues((prev) => ({ ...prev, team }))} selected={Values.team} />}
								<div className="grid grid-cols-2 gap-x-4">
									<Input required value={Values.fName} onChange={(fName) => setValues((prev) => ({ ...prev, fName }))} label="First Name" placeholder="First Name" />
									<Input required value={Values.lName} onChange={(lName) => setValues((prev) => ({ ...prev, lName }))} label="Last Name" placeholder="Last Name" />
								</div>
								<div className="grid grid-cols-2 gap-x-4">
									<Input type="number" required value={Values.phone} onChange={(phone) => setValues((prev) => ({ ...prev, phone }))} label="Phone" placeholder="Phone" />
									<DatePicker backDateSelection={true} maxDate={moment().subtract(18, "years").valueOf()} date={Values.dob} setDate={(dob) => setValues((prev) => ({ ...prev, dob }))} label="Date of Birth" />
								</div>
								<div className="grid grid-cols-2 gap-x-4">
									<FileInput file={Values.photo} onUpload={(obj) => setValues((prev) => ({ ...prev, photo: obj.file.url }))} label="Profile Photo" />
									<div>
										<label className="block mb-2">Gender</label>
										<ButtonGroup
											selected={Values.gender}
											onClick={(gender) => setValues((prev) => ({ ...prev, gender }))}
											options={[
												{
													label: "Male",
													value: "MALE",
												},
												{
													label: "Female",
													value: "FEMALE",
												},
											]}
										/>
									</div>
								</div>
							</>
						)}
						<Checkbox checked={SendInvite} onChange={setSendInvite} label="Add user by sending invitation on email" />
						{SendInvite ? (
							<Button loading={Loader} onClick={sendInvite} className="w-full mt-4">
								Send Invite
							</Button>
						) : (
							<Button loading={Loader} onClick={onSubmit} className="w-full mt-4">
								Create Employee
							</Button>
						)}
					</div>
				</div>
			</Modal>
		</>
	);
}

export default AddEmployee;
