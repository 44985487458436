import React, { Suspense, lazy, useState } from "react";
import { BottomBar, Button, ButtonGroup, CardsRenderer, CardsWrapper, DateRange, Pagination, Searchbox, Spinner, TripCard } from "../../../../../Components";
import { useNavigate } from "react-router-dom";
import { FiCalendar, FiGrid, FiPlus } from "react-icons/fi";
// const BasicCalendar = lazy(() => import("./Calendar/calendar"));

function Trips() {
	const NAVIGATE = useNavigate();
	const [View, setView] = useState({ label: "Card", value: "card" });

	const Mock_Trip = [
		{
			id: "TRIP-234234BFD",
			name: "Trip to Bangalore",
			description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.",
			origin: {
				name: "Delhi",
				id: 234,
			},
			destination: {
				name: "Bangalore",
				id: 234,
			},
			createOn: 0,
			travelDate: 0,
			status: "upcoming",
			bookings: 4,
		},
	];

	return (
		<>
			<div className="h-full p-4">
				{View !== "calendar" && (
					<BottomBar>
						<div className="w-full flex-center-between">
							<div className="align-center gap-x-4">
								<Button onClick={() => NAVIGATE("/add/trip")} variant="primary">
									<FiPlus className="mr-2" />
									Create Trip
								</Button>
								<h5>4 Trips</h5>
							</div>
							<div>
								<Pagination />
							</div>
						</div>
					</BottomBar>
				)}
				<div className="flex-center-between">
					<div className="flex gap-2">
						<ButtonGroup
							size="xs !p-1.5"
							selected={{ label: "All", value: "all" }}
							className="text-sm"
							options={[
								{ label: "All", value: "all" },
								{ label: "Pending", value: "pending" },
								{ label: "Approved", value: "approved" },
								{ label: "Cancelled", value: "cancelled" },
							]}
						/>
						<ButtonGroup
							size="xs !p-1.5"
							selected={{ value: View }}
							onClick={(obj) => setView(obj.value)}
							className="text-sm"
							options={[
								{ label: <FiGrid />, value: "card" },
								{ label: <FiCalendar />, value: "calendar" },
							]}
						/>
					</div>
					<div className="align-center gap-x-2">
						<Searchbox placeholder="Search bookings" cmd="s" />
						<DateRange inputClass="!p-1.5 !text-xs" />
					</div>
				</div>
				{View === "calendar" ? (
					<Suspense
						fallback={
							<div className="w-full h-full flex-center">
								<Spinner />
							</div>
						}
					>
						{/* <BasicCalendar /> */}
					</Suspense>
				) : (
					<CardsWrapper>
						<CardsRenderer items={Mock_Trip} render={(trip, i) => (i === 3 ? <div className="text-xs text-center col-span-full text-secondary">05 May 2023</div> : <TripCard onClick={() => NAVIGATE("/trip/TRIP-SAMPLE")} key={i} {...trip} />)} />
					</CardsWrapper>
				)}
			</div>
		</>
	);
}

export default Trips;
