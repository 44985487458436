import React from "react";
import { FiAlertTriangle, FiInfo } from "react-icons/fi";
import { RiDragDropLine } from "react-icons/ri";

function ReceiptDrop({ dragging, onDrop }) {
	if (dragging) {
		//Receipt does not have a category
		if (!dragging.categoryId) {
			return (
				<div onDragOver={(e) => e.preventDefault()} className="absolute left-0 right-0 bottom-0 top-0 p-2">
					<div className={`w-full h-full transition-all bg-dark text-white rounded-xl flex-center p-6 text-sm`}>
						<div className="text-center ">
							<FiInfo size={52} className="m-auto mb-6" />
							This receipt does not have a category specified, add a category first
						</div>
					</div>
				</div>
			);
		}

		//Receipt is out of policy
		if (dragging.status === "outOfPolicy") {
			return (
				<div onDragOver={(e) => e.preventDefault()} className="absolute left-0 right-0 bottom-0 top-0 p-2">
					<div className={`w-full h-full transition-all bg-dangerText text-white rounded-xl flex-center p-6 text-sm`}>
						<div className="text-center ">
							<FiAlertTriangle size={52} className="m-auto mb-6" />
							This receipt is out of your expense policy, please resolve the violations first
						</div>
					</div>
				</div>
			);
		}

		//Receipt is eligible for reimbursement
		if (dragging.status === "inPolicy" || dragging.status === "needsApproval") {
			return (
				<div onDragOver={(e) => e.preventDefault()} onDrop={onDrop} className="absolute left-0 right-0 bottom-0 top-0 p-2">
					<div className={`w-full h-full transition-all bg-primary text-white rounded-xl flex-center p-6 text-sm`}>
						<div className="text-center ">
							<RiDragDropLine size={52} className="m-auto mb-6" />
							Drop here to add this receipt
						</div>
					</div>
				</div>
			);
		}
	}

	return null;
}

export default ReceiptDrop;
