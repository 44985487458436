import React from "react";
import ApprovalStatus from "./Components/approvalStatus";
import ViewReceipt from "./ViewReceipt";
import { useState } from "react";
import { StampToDate } from "../../Utils/utils";
import { FiTrash2 } from "react-icons/fi";

function Receipt({ openId, onDrag, id = "", date, createdOn, amount = 0, merchant, currency = "INR", categoryId, category = {}, status = "", description = "", attachments = [], approvers = [], onReload = () => {}, onDelete }) {
	const [OpenId, setOpenId] = useState(openId);
	const editable = status === "needsApproval" || status === "inPolicy" || status === "created" || status === "outOfPolicy";

	const draggable = onDrag && editable && status !== "created";

	return (
		<>
			<div
				draggable={draggable}
				onDragEnd={() => onDrag()}
				onDragStart={() => onDrag({ id, date, createdOn, amount, merchant, currency, categoryId, category, status, description, attachments, approvers })}
				onClick={() => setOpenId(id)}
				className="relative flex flex-col justify-between overflow-hidden border shadow-sm cursor-pointer rounded-xl bg-canvas min-w-min hover:bg-light group"
			>
				<div className="p-4">
					<div className="flex justify-between">
						<h2>₹ {amount}</h2>
						<div className="text-label">{StampToDate(createdOn)}</div>
					</div>
					<div className="mt-2 text-xs text-secondary">
						Spent on {StampToDate(date)} at {merchant} for {category?.name}
					</div>
				</div>
				<ApprovalStatus draggable={draggable} status={status} id={id} />
				{onDelete && (
					<div
						onClick={(e) => {
							e.stopPropagation();
							onDelete(id);
						}}
						className="h-[0] group-hover:h-[2.5rem] transition-all flex-center absolute bottom-0 left-0 right-0 bg-dangerText text-white gap-2 text-xs"
					>
						<FiTrash2 /> Remove
					</div>
				)}
			</div>
			<ViewReceipt onReload={onReload} receiptId={OpenId} onClose={() => setOpenId()} />
		</>
	);
}

export default Receipt;
