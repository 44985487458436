import React from 'react';
import { Badge } from '../../../../../../../../Components';

function StatusIndicator({status}) {
    if(status === 'booked'){
        return <Badge variant='success'>Booked</Badge>
    }
    if(status === 'cancelled'){
        return <Badge variant='danger'>Cancelled</Badge>
    }
    if(status === 'denied'){
        return <Badge variant='danger'>Denied</Badge>
    }
    if(status === 'pending'){
        return <Badge variant='warning'>Pending</Badge>
    }
    return null
}

export default StatusIndicator;