import React from "react";
import { Button, Input, Modal, FileInput, Toast, Form, TimePicker, DatePicker, Checkbox, Chips } from "../..";
import { useState } from "react";
import { mergeDateAndTime } from "../../../Utils/utils";
import { apis, post } from "../../../Utils/axios";
import { FiMapPin, FiUpload } from "react-icons/fi";
import { FaRupeeSign } from "react-icons/fa";
import PlaceSearch from "../Inputs/PlaceSearch";

function ImportTrainBooking({ requestId, onReload = () => {}, btnLabel = "Import", btnClass = "", btnVariant = "primary" }) {
	const [ImportTrain, setImportTrain] = useState();
	const initial = {
		bookingId: "",
		departureDate: "",
		departureTime: "",
		arrivalDate: "",
		arrivalTime: "",
		amount: "",
		board: {},
		drop: {},
		coach: {
			label: "Sleeper",
			value: "sleeper",
		},
		ticket: "",
		createExpense: true,
		requestId,
	};
	const [Values, setValues] = useState(initial);
	const [Loader, setLoader] = useState();

	const onSubmit = () => {
		if (!Values.departureDate) {
			return Toast.error("Please select departure date");
		}
		if (!Values.departureTime) {
			return Toast.error("Please select departure time");
		}
		if (!Values.arrivalDate) {
			return Toast.error("Please select arrival date");
		}
		if (!Values.arrivalTime) {
			return Toast.error("Please select arrival time");
		}
		if (!Values.board?.value) {
			return Toast.error("Please select boarding station");
		}
		if (!Values.drop?.value) {
			return Toast.error("Please select drop station");
		}
		if (!Values.coach?.value) {
			return Toast.error("Please select train coach");
		}
		setLoader(true);
		post(
			apis.importTrainBooking,
			{
				...Values,
				departureTime: mergeDateAndTime(Values.departureDate, Values.departureTime),
				arrivalTime: mergeDateAndTime(Values.arrivalDate, Values.arrivalTime),
				coach: Values.coach.value,
				board: Values.board.value,
				drop: Values.drop.value,
			},
			(r, e) => {
				if (r) {
					setLoader();
					setImportTrain();
					setValues(initial);
					onReload();
					Toast.success("Train booking imported");
				} else if (e) {
					console.log(e);
					Toast.handleError(e);
					setLoader();
				}
			}
		);
	};

	return (
		<>
			<Button onClick={() => setImportTrain(true)} variant={btnVariant} className={btnClass}>
				<FiUpload className="mr-2" />
				{btnLabel}
			</Button>
			<Modal
				open={ImportTrain}
				onClose={() => {
					setImportTrain();
					setValues(initial);
				}}
			>
				<div className="p-6">
					<h1 className="gap-2 align-center">
						<FiUpload />
						Import Train Booking
					</h1>
					<div className="mt-2 text-label">You can import bookings done from outside and use it in Travel Wings</div>
					<Form onSubmit={onSubmit} className="flex flex-col mt-4 gap-y-4">
						<div className="grid grid-cols-2 gap-4">
							<PlaceSearch selected={Values.board} label="Boarding Station" onSelect={(board) => setValues((prev) => ({ ...prev, board }))} placeholder="Boarding Station" icon={<FiMapPin />} />
							<PlaceSearch selected={Values.drop} label="Drop Station" onSelect={(drop) => setValues((prev) => ({ ...prev, drop }))} placeholder="Drop Station" icon={<FiMapPin />} />
						</div>
						<div className="grid grid-cols-2 gap-4">
							<DatePicker label="Departure Date" setDate={(departureDate) => setValues((prev) => ({ ...prev, departureDate }))} date={Values.departureDate} />
							<TimePicker label="Departure Time" onChange={(departureTime) => setValues((prev) => ({ ...prev, departureTime }))} value={Values.departureTime} required />
						</div>
						<div className="grid grid-cols-2 gap-4">
							<DatePicker label="Arrival Date" setDate={(arrivalDate) => setValues((prev) => ({ ...prev, arrivalDate }))} date={Values.arrivalDate} />
							<TimePicker label="Arrival Time" onChange={(arrivalTime) => setValues((prev) => ({ ...prev, arrivalTime }))} value={Values.arrivalTime} required />
						</div>
						<div className="grid grid-cols-2 gap-4">
							<Input required value={Values.bookingId} onChange={(bookingId) => setValues((prev) => ({ ...prev, bookingId }))} label="PNR" placeholder="PNR" />
							<Input min={0} icon={<FaRupeeSign />} type="number" required value={Values.amount} onChange={(amount) => setValues((prev) => ({ ...prev, amount }))} label="Amount" placeholder="Amount" />
						</div>
						<div>
							<label>Train Coach</label>
							<Chips
								selected={Values.coach}
								onSelect={(coach) => setValues((prev) => ({ ...prev, coach }))}
								className="mt-2"
								options={[
									{
										label: "Sleeper",
										value: "sleeper",
									},
									{
										label: "Third AC",
										value: "3AC",
									},
									{
										label: "Second AC",
										value: "2AC",
									},
									{
										label: "First AC",
										value: "1AC",
									},
								]}
							/>
						</div>
						<div className="grid grid-cols-2 gap-4">
							<FileInput onDelete={() => setValues((prev) => ({ ...prev, ticket: "" }))} file={Values.ticket} label="Receipt File" onUpload={(obj) => setValues((prev) => ({ ...prev, ticket: obj.file.url }))} />
						</div>
						<Checkbox checked={Values.createExpense} onChange={(createExpense) => setValues((prev) => ({ ...prev, createExpense }))} label="Create an expense for this booking" />
						<Button loading={Loader} type="submit" className="w-full mt-4">
							Import
						</Button>
					</Form>
				</div>
			</Modal>
		</>
	);
}

export default ImportTrainBooking;
