import React from "react";
import { Button } from "..";
import userIcon from "../ProfileMenu/user.png";
import adminIcon from "../ProfileMenu/admin.png";
import { useClientSettings } from "../../Hooks/useClientSettings";
import { useUserConfig } from "../../Hooks/useUserConfig";

function ProfileSwitch() {
	const { isEnabled } = useClientSettings();
	const { userConfig, role, switchRole } = useUserConfig();
	const isAdmin = isEnabled("ORGANIZATION") && userConfig.isAdmin;

	const reloadApp = (value) => {
		if (role !== value) {
			document.getElementById("root").classList.add("role-switch");
			//Adding delay to allow animation to enter
			setTimeout(() => {
				window.location.href = "/app/home";
				switchRole(value);
			}, 500);
		}
	};

	if (isAdmin)
		return (
			<div className="mt-2">
				{role === "ADMIN" ? (
					<Button onClick={() => reloadApp("USER")} variant="outlined" className="btn btn-sm !text-xs w-full">
						<img src={userIcon} alt="User Profile" className="w-4 mr-3" />
						Switch to User
					</Button>
				) : (
					<Button onClick={() => reloadApp("ADMIN")} variant="outlined" className="btn btn-sm !text-xs w-full">
						<img src={adminIcon} alt="Admin Profile" className="w-4 mr-3" />
						Switch to Admin
					</Button>
				)}
			</div>
		);

	return null;
}

export default ProfileSwitch;
