import React from "react";
import { CopyValue } from "../..";
import { ChangePolicy, Img, Toast } from "../..";
import PlaceholderImg from "../../../Assets/Images/placeholders/building.png";
import { apis, post } from "../../../Utils/axios";

function OfficeCard({ onClick = () => {}, className = "", office = {}, onReload = () => {} }) {
	const { id, name, policy, address, phone, email, gstin, logo } = office;

	const onSelect = (policy) => {
		post(
			apis.updateOfficePolicy,
			{
				officeId: parseInt(id),
				policyId: policy.id,
			},
			(r, e) => {
				if (r) {
					Toast.success(`Office Policy updated`);
					onReload();
				} else if (e) {
					console.log(e);
					Toast.handleError(e);
				}
			}
		);
	};

	return (
		<>
			<div onClick={onClick} className={`rounded-xl bg-canvas hover:bg-light shadow-sm border overflow-hidden cursor-pointer p-4 ${className}`}>
				<div className="align-center gap-x-4">
					<Img preview placeholder={PlaceholderImg} src={logo} className="w-12 h-12 rounded-full pixelated" />
					<div style={{ maxWidth: "calc(100% - 3rem)" }}>
						<h2 className="truncate">{name}</h2>
						<CopyValue className="text-label">{gstin}</CopyValue>
					</div>
				</div>
				<div className="flex items-end justify-between mt-3">
					<ChangePolicy selected={policy} onSelect={onSelect} />
					{/* <div className='text-xs'>5 Department</div> */}
				</div>
			</div>
		</>
	);
}

export default OfficeCard;
