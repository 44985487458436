import React, { useEffect, useState } from "react";
import { Button, Container, Table, ViewApproval, Toast, InlineEmployee } from "../../../../Components";
import { apis, get } from "../../../../Utils/axios";
import { StampToDate } from "../../../../Utils/utils";
import moment from "moment";

function ApprovalTasks() {
	const [ApprovalsToday, setApprovalsToday] = useState([]);
	const [OpenId, setOpenId] = useState();

	const [Loading, setLoading] = useState(true);
	const [Query, setQuery] = useState({
		search: "",
		page: 1,
		dates: {
			startDate: moment().subtract(1, "days").valueOf(),
			endDate: moment().valueOf(),
		},
	});

	useEffect(() => {
		fetchApprovals();
	}, []);

	const fetchApprovals = () => {
		setLoading(true);
		get(apis.getTodaysApprovals, Query, (r, e) => {
			if (r) {
				setApprovalsToday(r.approvals);
				setLoading(false);
			} else if (e) {
				Toast.handleError(e);
				setLoading(false);
			}
		});
	};

	return (
		<>
			<div>
				<div>
					<h3 className="mt-8">Today's Approvals</h3>
					<Container className="!rounded-xl !p-0 max-h-[20rem] overflow-y-auto mt-4">
						<div>
							<Table
								loading={Loading}
								nodes={ApprovalsToday.map((approval) => ({
									date: StampToDate(approval.date),
									type: <div className="capitalize">{approval.type}</div>,
									amount: `₹ ${approval.amount}`,
									requestor: <InlineEmployee employee={approval.requestedBy}/>,
									actions: (
										<Button onClick={() => setOpenId(approval.id)} variant="outlined" className="btn-xs">
											Open
										</Button>
									),
								}))}
								headers={[
									{ key: "date", label: "Date" },
									{ key: "type", label: "Type" },
									{ key: "amount", label: "Amount" },
									{ key: "requestor", label: "Requested By" },
									{ key: "actions", label: "Action" },
								]}
							/>
						</div>
					</Container>
				</div>
			</div>
			<ViewApproval approvalId={OpenId} onClose={() => setOpenId()} onReload={fetchApprovals} />
		</>
	);
}

export default ApprovalTasks;
