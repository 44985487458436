import { useEffect, useState } from "react";
import { apis, get, post } from "../Utils/axios";
import { Toast } from "../Components";
import useFetch from "./useFetch";
import { subscribeCustomEvent } from "../Utils/utils";
import Sound from "../Assets/Audio/sound.mp3";

function useChat() {
	const receivedSound = new Audio(Sound);
	const [LoadingMessages, setLoadingMessages] = useState(false);
	const [Messages, setMessages] = useState([]);

	const [ActiveChat, setActiveChat] = useState();
	const [Query, setQuery] = useState({
		search: "",
		page: 1,
	});

	const { response, loading: loadingChat, reload } = useFetch(apis.getChats, Query, { chats: [] });

	useEffect(() => {
		if (ActiveChat?.id) {
			loadMessages(ActiveChat.id);
		}
		//Subscribing to new message
		const removeMessageListener = subscribeCustomEvent("newMessage", (message) => {
			if (ActiveChat?.id === message.chatId) {
				setMessages((prev) => [...prev, message]);
				receivedSound.play();
			}
		});
		//Subscribing to automatic chat reloads
		const removeChatListener = subscribeCustomEvent("reloadChats", reload);
		return () => {
			removeMessageListener();
			removeChatListener();
		};
	}, [ActiveChat]);

	const loadMessages = (chatId) => {
		setLoadingMessages(true);
		get(apis.getMessages, { chatId }, (r, e) => {
			if (r) {
				setMessages(r.messages);
				setLoadingMessages();
			} else if (e) {
				Toast.handleError(e);
				setLoadingMessages();
			}
		});
	};

	const sendMessage = (content, type) => {
		post(apis.sendMessage, { chatId: ActiveChat?.id, content, type }, (r, e) => {
			if (r) {
				setMessages((prev) => [...prev, r.message]);
			} else if (e) {
				Toast.handleError(e);
			}
		});
	};

	const openChat = (receiverId) => {
		post(apis.createChat, { receiverId }, (r, e) => {
			if (r) {
				setActiveChat(r.chat);
				reload();
			} else if (e) {
				console.log(e);
				Toast.handleError(e);
			}
		});
	};

	return {
		openChat,
		activeChat: ActiveChat,
		setChat: setActiveChat,
		filterChats: (search) => setQuery({ ...Query, search }),
		chats: response.chats,
		reloadChats: reload,

		sendMessage,
		messages: Messages,
		loadingMessages: LoadingMessages,
		loadMessages,
		loadingChat,
	};
}

export default useChat;
