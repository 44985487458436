import React from 'react';
import { Request } from '../../../../../../Components';
import { BottomBar, ButtonGroup, CardsRenderer, CardsWrapper, DateRange, Pagination, Searchbox} from '../../../../../../Components';

function BusRequest() {

    const Mock_Bus = [
        {
            selection: {
                from: {
                    name: 'Delhi',
                    country: 'India',
                },
                to: {
                    name: 'Jaipur',
                    country: 'India',
                },
                departure: {
                    time: 0,
                    point: 'Kashmiri Gate',
                },
                arrival: {
                    time: 0,
                    point: 'Station Road',
                },
                operator: {
                    name: 'Goldline Super Deluxe',
                },
                bus: 'Bharat Benz A/C Sleeper (2+1)',
                rating: 3.8,
                seats: 40,
                cancellation: {
                    0 : '0 to 12 hr(s) before travel',
                    50 : '12 to 24 hr(s) before travel',
                    100 : '24 to 48 hr(s) before travel',
                },
                images: [],
                pickups: [
                    {
                        id: 1,
                        name: 'Kashmiri Gate',
                        time: '9:00 PM',
                    },
                    {
                        id: 2,
                        name: 'ISBT',
                        time: '9:30 PM',
                    },
                    {
                        id: 3,
                        name: 'Majnu Ka Tila',
                        time: '10:00 PM',
                    },
                ],
                drops: [
                    {
                        id: 1,
                        name: 'Station Road',
                        time: '5:00 AM',
                    },
                    {
                        id: 2,
                        name: 'Sindhi Camp',
                        time: '5:30 AM',
                    },
                    {
                        id: 3,
                        name: 'Ajmeri Gate',
                        time: '6:00 AM',
                    },
                ],
                amenities: ['AC', 'Water Bottle', 'Blanket', 'Charging Point', 'Reading Light'],
                amount: 600,
                date: '',
                type: 'bus',
            },
            type: 'bus',
            status: 'cancelled',
            id: 'TPP-234234-SDFSDF'
        },
    ]

    return (
        <>
        <div>
            <BottomBar>
                <div className='w-full flex-center-between'>
                    <h5>4 Bus Request</h5>
                    <div>
                        <Pagination/>
                    </div>
                </div>
            </BottomBar>
            <div className='mt-6 flex-center-between'>
                <div className='align-center'>
                    <ButtonGroup size='xs !p-1.5' selected={{label: 'Pending', value: 'pending'}} className='text-sm' options={[
                        {label: 'Pending', value: 'pending'},
                        {label: 'Fulfilled', value: 'fulfilled'},
                        {label: 'Cancelled', value: 'cancelled'},
                    ]}/>
                </div>
                <div className='align-center gap-x-2'>
                    <Searchbox placeholder='Search requests' cmd='s'/>
                    <DateRange inputClass='!p-1.5 !text-xs'/>
                </div>
            </div>
            <CardsWrapper>
                <CardsRenderer items={Mock_Bus} render={(request,i)=>(
                    i===4
                    ? <div className='col-span-full text-center text-xs text-secondary'>05 May 2023</div>
                    : <Request key={i} request={request}/>
                )}/>
            </CardsWrapper>
        </div>
        </>
     );
}

export default BusRequest;