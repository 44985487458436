import React from "react";
import { Tabs } from "../../../../../Components";
import { Tab } from "@headlessui/react";
import { FaBed, FaCar, FaPlane, FaTrain } from "react-icons/fa";
import FlightBookings from "./Flights";
import StayBookings from "./Stays";
import CabBookings from "./Cab";
import TrainBookings from "./Train";
import { useClientSettings } from "../../../../../Hooks/useClientSettings";

function Bookings() {
	const { isEnabled } = useClientSettings();

	return (
		<>
			<div className="p-4 pb-24">
				<Tabs
					options={[
						<div className="align-center">
							<FaPlane className="mr-1" />
							Flight
						</div>,
						<div className="align-center">
							<FaBed className="mr-1" />
							Stay
						</div>,
						...(isEnabled("ORGANIZATION")
							? [
									<div className="align-center">
										<FaCar className="mr-1" />
										Cab
									</div>,
									<div className="align-center">
										<FaTrain className="mr-1" />
										Train
									</div>,
							  ]
							: []),
						// <div className='align-center'><FaBus className='mr-1'/>Buses</div>,
					]}
				>
					<Tab.Panel>
						<FlightBookings />
					</Tab.Panel>
					<Tab.Panel>
						<StayBookings />
					</Tab.Panel>
					<Tab.Panel>
						<CabBookings />
					</Tab.Panel>
					<Tab.Panel>
						<TrainBookings />
					</Tab.Panel>
					{/* <Tab.Panel>
                    <BusBookings/>
                </Tab.Panel> */}
				</Tabs>
			</div>
		</>
	);
}

export default Bookings;
