import React, { useEffect, useRef } from "react";
import MessageBox from "../../Pages/App/Common/Chat/Components/messageBox";
import TopBar from "../../Pages/App/Common/Chat/Components/topBar";
import Message from "../../Pages/App/Common/Chat/Components/message";
import { Button, Spinner } from "..";
import Placeholder from "../../Assets/Images/intros/chat.png";

function Conversation({ newChatSearch, chat }) {
	const { activeChat, loadingMessages, messages, sendMessage } = chat;
	const SCROLLER = useRef();
	const { id, receiver } = activeChat || {};

	useEffect(() => {
		SCROLLER.current.scrollTop = SCROLLER.current.scrollHeight;
	}, [messages]);

	//No chat is opened
	if (!id) {
		return (
			<div ref={SCROLLER} className="w-full h-full flex-center">
				<div className="p-16 text-center">
					<h1 className="text-4xl">Chat with others</h1>
					<div className="text-secondary">Chat with your managers, team members, share bookings, receipts</div>
					<Button onClick={() => newChatSearch.current(true)} variant="outlined" className="m-auto mt-6">
						+ New Message
					</Button>
					<img src={Placeholder} className="w-full max-w-sm m-auto mt-12 floating" alt="Placeholder" />
				</div>
			</div>
		);
	}

	return (
		<div className="relative flex flex-col w-full h-full">
			<TopBar receiver={receiver} />
			<div ref={SCROLLER} className="flex flex-col flex-grow p-2 overflow-x-hidden overflow-y-auto scrollbar-autohide">
				<div className="flex-grow" />
				{loadingMessages ? (
					<div className="flex-center">
						<Spinner />
					</div>
				) : messages?.length > 0 ? (
					messages?.map((message, i) => <Message key={i} {...message} />)
				) : (
					<div className="py-6 text-center text-label">No Messages</div>
				)}
			</div>
			<MessageBox onSend={sendMessage} />
		</div>
	);
}

export default Conversation;
