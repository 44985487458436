import React, { useEffect, useState } from 'react';
import Processing from './processing';
import Success from './success';

function ConfirmBooking() {

    const [Confirmed, setConfirmed] = useState();

    useEffect(() => {
        setTimeout(() => {
            setConfirmed(true)
        }, 3000);
    }, []);

    if(Confirmed){
        return <Success/>
    }

    return ( 
        <Processing/>
     );
}

export default ConfirmBooking;