import { useEffect, useRef } from "react";
import { apis, get } from "../Utils/axios";
import { useDispatch, useSelector } from "react-redux";

function useUserPolicy() {
	const ACTIONS = {
		FLIGHT: "travel.domesticFlight.enabled",
		STAY: "travel.domesticStay.enabled",
		BUS: "travel.domesticBus.enabled",
		CAB: "travel.domesticCab.enabled",
		TRAIN: "travel.domesticTrain.enabled",
	};

	const getValueFromPolicy = (action, policyObject) => {
		const keys = ACTIONS[action].split(".");
		let value = policyObject;

		for (const key of keys) {
			if (value.hasOwnProperty(key)) {
				value = value[key];
			} else {
				return undefined;
			}
		}
		return value;
	};

	const dispatch = useDispatch();
	//Fetching flag to prevent multiple requests
	const Fetching = useRef(false);

	let { policy } = useSelector((state) => {
		return { policy: state.userPolicy };
	});

	useEffect(() => {
		if (!Fetching.current && policy?.loading) {
			Fetching.current = true;
			get(apis.getMyPolicy, {}, (r, e) => {
				if (r) {
					dispatch({
						type: "USER_POLICY",
						data: r,
					});
					Fetching.current = false;
				} else if (e) {
					console.log(e);
					Fetching.current = false;
				}
			});
		}
	}, []);

	return {
		travelPolicy: policy?.travel || {},
		expensePolicy: policy?.expense || {},
		isEnabled: (action) => {
			//If no policy is defined everything is enabled
			if (getValueFromPolicy(action, policy) === undefined) return true;

			if (getValueFromPolicy(action, policy)) {
				return true;
			}
			return false;
		},
	};
}

export { useUserPolicy };
