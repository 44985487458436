import React, { useState } from 'react';
import { Button, Form, Input, Select, Toast } from '../../../../Components';
import { FaArrowRight } from 'react-icons/fa';
import { validateEmail, validateIndianPhoneNumber } from '../../../../Utils/utils';

function AddOffice({onSubmit=()=>{}, onBack=()=>{}, details={}}) {
    
    const [Values, setValues] = useState({
        gstin: '',
        address: '',
        name: '',
        email: '',
        phone: '',
        head: {}
    });

    const handleSubmit = () => {
        if(!Values.gstin || !Values.name || !Values.email || !Values.phone){
            return Toast.error('Please provide all the details');
        }
        if(!validateEmail(Values.email)){
            return Toast.error('Please enter a valid email');
        }
        if(!validateIndianPhoneNumber(Values.phone)){
            return Toast.error('Please enter a valid 10 digit phone number');
        }
        onSubmit({
            ...Values,
            head: Values.head.value
        });
    }

    return ( 
        <div className='text-center fade-in-right p-10'>
            <h1 className='text-4xl font-bold'>Add Office</h1>
            <div className='mt-2 text-xl font-thin'>We will create a single office for you. If you want you can add more later</div>
            <Form onSubmit={handleSubmit} className='mt-8 flex flex-col gap-4 max-w-[400px] m-auto'>
                <Input value={Values.gstin} onChange={gstin=>setValues(prev=>({...prev, gstin: gstin.toUpperCase()}))} required placeholder='Office GSTIN'/>
                <Input value={Values.name} onChange={name=>setValues(prev=>({...prev, name}))} required placeholder='Office Name'/>
                <Input value={Values.address} onChange={address=>setValues(prev=>({...prev, address}))} required placeholder='Office Address'/>
                <Input type='email' value={Values.email} onChange={email=>setValues(prev=>({...prev, email}))} required placeholder='Office Email'/>
                <Input type='number' value={Values.phone} onChange={phone=>setValues(prev=>({...prev, phone}))} required placeholder='Office Phone'/>
                {
                    details.users?.length > 0 &&
                    <Select placeholder='Select Office Head for Approvals' btnClass='w-full' 
                        options={details.users.map(user=>({
                            label: user, value: user
                        }))}
                        onChange={user=>setValues(prev=>({...prev, head: user}))}
                        selected={Values.head}
                    />
                }
                <div className='flex gap-x-2 mt-4 max-w-min m-auto'>
                    <Button onClick={onBack} className='flex-grow'>Back</Button>
                    <Button type='submit' variant='primary' className='flex-grow'>Continue <FaArrowRight className='ml-2'/></Button>
                </div>
            </Form>
        </div>
     );
}

export default AddOffice;