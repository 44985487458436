import React from "react";
import { Avatar } from "../../../../../Components";
// import { FiInfo, FiSearch } from "react-icons/fi";

function TopBar({ receiver = {} }) {
	const { fName, lName, email, photo } = receiver;
	return (
		<>
			<div className="sticky top-0 z-10">
				<div className="bg-canvas border-b frosted flex-center-between p-2">
					<div className={`gap-x-2 rounded-md align-center`}>
						<Avatar photo={photo} className="w-10 h-10" />
						<div>
							<div className="align-center gap-x-1">
								<div className={`font-semibold text-ellipsis align-center`}>
									{fName} {lName}
									{/* <span className="text-primary text-xs ml-2">Online</span> */}
								</div>
							</div>
							<div className={`text-xs text-secondary text-ellipsis`}>{email}</div>
						</div>
					</div>
					{/* <div className="align-center">
						<div className="p-2 rounded-full hover:bg-light cursor-pointer text-lg">
							<FiInfo />
						</div>
						<div className="p-2 rounded-full hover:bg-light cursor-pointer text-lg">
							<FiSearch />
						</div>
					</div> */}
				</div>
			</div>
		</>
	);
}

export default TopBar;
