import React from "react";
import userIcon from "./user.png";
import adminIcon from "./admin.png";
import { OptionsPopup } from "..";
import { Toast } from "..";

function SwitchRole({ switchRole, role, userConfig, open = false, onClose = () => {} }) {
	const { isAdmin } = userConfig;

	const reloadApp = (value) => {
		if (role !== value) {
			onClose();
			document.getElementById("root").classList.add("role-switch");
			//Adding delay to allow animation to enter
			setTimeout(() => {
				window.location.href = "/app/home";
				switchRole(value);
			}, 500);
		} else {
			Toast.error("You are already using this role");
		}
	};

	const renderOption = (icon, label, value, description) => (
		<div className="relative text-center">
			<img src={icon} alt="avatar" className="w-[5rem] m-auto mb-4" />
			<h5>{label}</h5>
			<div className="text-label">{description}</div>
			{role === value && <div className="absolute top-0 right-0 px-2 py-1 text-white rounded-full text-xxs bg-primary">Active</div>}
		</div>
	);

	let OPTIONS = [
		{
			render: renderOption(userIcon, "Employee", "USER", "Manage your expense and travel"),
			onClick: () => reloadApp("USER"),
		},
	];

	if (isAdmin) {
		OPTIONS.push({
			render: renderOption(adminIcon, "Company Admin", "ADMIN", "Manage other users and permissions"),
			onClick: () => reloadApp("ADMIN"),
		});
	}

	return <OptionsPopup col={OPTIONS.length} open={open} onClose={onClose} options={OPTIONS} />;
}

export default SwitchRole;
