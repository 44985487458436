import React from "react";
import SwitchRole from "../../Assets/Images/intros/switchRole.png";
import TravelAdmin from "../../Assets/Images/intros/travelAdmin.png";
import Notification from "../../Assets/Images/intros/notifications.png";
import { Badge, Button, Modal } from "..";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

function WhatsNew({ open, onClose = () => {} }) {
	return (
		<>
			<Modal open={open} onClose={() => {}}>
				<div className="p-6">
					<div className="flex-center-between">
						<h1>What's new</h1>
						<Badge variant="warning">Quick Tip</Badge>
					</div>
					<Swiper slidesPerView={"auto"} onSlideChange={(i) => {}} modules={[Navigation]} navigation={true} className="rounded-lg swiper-carousel">
						<SwiperSlide>
							<img src={SwitchRole} alt="Graph" className="mt-6 m-auto max-h-[12rem] border rounded-lg shadow-lg" />
							<div className="p-6 text-sm text-center">
								<h3 className="mb-2 text-center">Switch Roles</h3>
								Now you can use switch role options under user options to jump between your user and admin roles. Use Admin role to manage other users, policies, permissions and payments.
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<img src={TravelAdmin} alt="Graph" className="mt-6 m-auto max-h-[12rem] border rounded-lg shadow-lg" />
							<div className="p-6 text-sm text-center">
								<h3 className="mb-2 text-center">Travel Admin Options</h3>
								We enabled Travel admin options for you, you can browse all the users in your organization, manage their bookings and also receive requests to book on their behalf.
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<img src={Notification} alt="Graph" className="mt-6 m-auto max-h-[12rem] border rounded-lg shadow-lg" />
							<div className="p-6 text-sm text-center">
								<h3 className="mb-2 text-center">Notifications</h3>
								You will receive all the important updates about your travel and expense like booking confirmations, reimbursement payout, approval updates in notification panel.
							</div>
						</SwiperSlide>
					</Swiper>
					<Button variant="outlined" className="m-auto" onClick={onClose}>
						Close
					</Button>
				</div>
			</Modal>
		</>
	);
}

export default WhatsNew;
