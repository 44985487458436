import React, { useState } from 'react';
import { Badge, Button, Toast } from '../../../../Components';
import { FaArrowRight } from 'react-icons/fa';

function DefinePolicy({onSubmit=()=>{}}) {
    
    const [SelectedPolicy, setSelectedPolicy] = useState();

    const handleSubmit = () => {
        if(!SelectedPolicy){
            return Toast.error('Please select a policy')
        }
        onSubmit(SelectedPolicy);
    }

    return ( 
        <div className='text-center fade-in-right p-10'>
            <h1 className='text-4xl font-bold'>Travel Policy</h1>
            <div className='mt-2 text-xl font-thin'>We will apply this policy on Office level, you can later change this or create new policies</div>
            <div className='grid grid-cols-2 gap-x-4 mt-8'>
                <div onClick={()=>setSelectedPolicy('SAVER')} className={`border rounded-xl p-6 flex-grow hover:bg-light cursor-pointer ${SelectedPolicy === 'SAVER' ? 'ring-2 ring-primary' : ''}`}>
                    <h1 className='text-3xl'>Saver</h1>
                    <Badge variant='success' className='mt-2'>Increased Savings</Badge>
                    <ul className='mt-6 text-sm text-start text-secondary flex flex-col gap-y-2'>
                        <li>✅ &nbsp;Allows booking 25% above median price</li>
                        <li>✅ &nbsp;Allows Flights under 8 Hr duration</li>
                        <li>✅ &nbsp;Book Flight and Stays 5 days before travel</li>
                        <li>✅ &nbsp;No Seat, Meal and Baggage upgrades for flights</li>
                        <li>✅ &nbsp;Allows only Retail, Group and SME Fares</li>
                        <li>✅ &nbsp;Flight with Economy Class</li>
                        <li>✅ &nbsp;Stays upto 3 Stars</li>
                    </ul>
                </div>
                <div onClick={()=>setSelectedPolicy('MODERATE')} className={`border rounded-xl p-6 flex-grow hover:bg-light cursor-pointer ${SelectedPolicy === 'MODERATE' ? 'ring-2 ring-primary' : ''}`}>
                    <h1 className='text-3xl'>Moderate</h1>
                    <Badge variant='warning' className='mt-2'>Increased Flexibility</Badge>
                    <ul className='mt-6 text-sm text-start text-secondary flex flex-col gap-y-2'>
                        <li>Allows booking 50% above median price</li>
                        <li>Allows Flights over 8 Hr duration</li>
                        <li>Allow same day booking of Flight and Stays</li>
                        <li>Allows Meals and Baggage upgrades for flights</li>
                        <li>Allows Corporate Fares</li>
                        <li>Flight upto Business Class</li>
                        <li>Stays upto 5 Stars</li>
                    </ul>
                </div>
            </div>
            {
                SelectedPolicy &&
                <div className='flex gap-x-2 mt-8 max-w-min m-auto'>
                    <Button onClick={handleSubmit} variant='primary' className='flex-grow'>Continue <FaArrowRight className='ml-2'/></Button>
                </div>
            }
        </div>
     );
}

export default DefinePolicy;