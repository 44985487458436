import React, { useEffect, useState } from "react";
import { Button, EmployeeSearch, Modal } from "../../../../Components";

function NewChat({ newChatSearch, chat }) {
	const { openChat } = chat;
	const [ShowUsers, setShowUsers] = useState();

	useEffect(() => {
		if (newChatSearch) {
			newChatSearch.current = setShowUsers;
		}
	}, [newChatSearch]);

	return (
		<>
			<Button onClick={() => setShowUsers(true)} className="btn-xs flex-grow">
				+ New
			</Button>
			<Modal open={ShowUsers} onClose={() => setShowUsers(false)}>
				<div className="p-4">
					<EmployeeSearch
						onSelect={(user) => {
							openChat(user.id);
							setShowUsers(false);
						}}
						searchOnly
						placeholder={"Search Users"}
						label="New Message to"
					/>
				</div>
			</Modal>
		</>
	);
}

export default NewChat;
