import { useEffect } from "react";
import { apis, get } from "../Utils/axios";
import { useDispatch, useSelector } from "react-redux";

//Fetching flag to prevent multiple requests
let Fetching = false;

function useClientSettings() {
	const SETTINGS = {
		EXPENSE: "expense",
		ORGANIZATION: "organization",
		POLICY: "policy",
		PAYOUT: "payout",
		COST_CENTER: "costCenter",
		CUSTOM_FIELD: "customField",
	};

	const dispatch = useDispatch();

	let settings = useSelector((state) => state.clientSettings);

	useEffect(() => {
		if (!Fetching && settings?.loading) {
			fetchSettings();
		}
	}, []);

	const fetchSettings = () => {
		Fetching = true;
		get(apis.getClientSettings, {}, (r, e) => {
			if (r) {
				dispatch({
					type: "CLIENT_SETTINGS",
					data: r,
				});
				Fetching = false;
			} else if (e) {
				console.log(e);
				Fetching = false;
			}
		});
	};

	return {
		...settings,
		isEnabled: (permission) => {
			if (settings[SETTINGS[permission]]) {
				return true;
			}
			return false;
		},
		reload: fetchSettings,
	};
}

export { useClientSettings };
