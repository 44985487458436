import React, { useState } from "react";
import { Modal } from "../../Components";

function Policy({ className = "" }) {
	const [ViewPolicy, setViewPolicy] = useState(null);

	return (
		<>
			<div className={`gap-4 flex-center ${className}`}>
				<div onClick={() => setViewPolicy("terms")} className="cursor-pointer text-label">
					Terms & Conditions
				</div>
			</div>
			<Modal contentClass="!max-w-[70%]" open={ViewPolicy} onClose={() => setViewPolicy(null)}>
				<div className="h-[90vh] overflow-hidden rounded-lg w-full relative">
					<iframe title="Terms of Service" src="https://www.travelwings.com/termsofservices/United%20Arab%20Emirates" className="w-full absolute left-0 right-0 -top-[25vh] h-[125vh]" />
				</div>
			</Modal>
		</>
	);
}

export default Policy;
