import React, { Suspense, lazy, useState } from 'react';
import { Button, Chips, Form, Input, Toast } from '../../../../Components';
import { FaArrowRight } from 'react-icons/fa';
import { validateEmail } from '../../../../Utils/utils';
const BulkUpload = lazy(() => import('./BulkUpload'));

function InviteUsers({onSubmit=()=>{}, details={}}) {
    
    const [ShowBulkUpload, setShowBulkUpload] = useState();
    const [Values, setValues] = useState({
        email: ''
    });
    const [Users, setUsers] = useState([]);

    const handleSubmit = () => {
        onSubmit(Users.map(item=>item.value))
    }

    const addUser = () => {
        if(validateEmail(Values.email)){
            setUsers(prev => [...prev, {label: Values.email, value: Values.email}]);
            setValues({email: ''});
        }else{
            Toast.error('Please enter a valid email');
        }
    }

    const deleteUser = (userEmail) => {
        setUsers(prev => prev.filter(item => item !== userEmail));
    }

    return ( 
        <>
        <div className='text-center fade-in-right p-10'>
            <h1 className='text-4xl font-bold'>Invite Users</h1>
            <div className='mt-2 text-xl font-thin'>Enter email of users you want add to your company, we'll send them invite</div>
            <Form onSubmit={handleSubmit} className='mt-8 flex flex-col gap-4 max-w-[1000px] m-auto'>
                <div className='flex-center gap-x-2'>
                    <Input type='email' value={Values.email} onChange={email=>setValues(prev=>({...prev, email}))} placeholder='Enter Email'/>
                    <Button onClick={addUser}>Add</Button>
                </div>
                    <Chips options={Users} className='m-auto mt-2 flex-center flex-wrap gap-2' onDelete={deleteUser}/>
                
                <div className='flex gap-x-2 mt-8 max-w-min m-auto'>
                    <Button type='submit' variant='outlined' className='flex-grow'>Skip for now</Button>
                    <Button type='submit' variant='primary' className='flex-grow'>Continue <FaArrowRight className='ml-2'/></Button>
                </div>
                <div>or</div>
                <div onClick={()=>setShowBulkUpload(true)} className='text-secondary underline cursor-pointer hover:text-dark'>
                    Bulk Upload using .xlsx file
                </div>
            </Form>
        </div>
        {
            ShowBulkUpload && 
            <Suspense fallback={<></>}>
                <BulkUpload companyDetails={details.company} onClose={()=>setShowBulkUpload(false)} open={ShowBulkUpload}/>
            </Suspense>
        }
        </>
     );
}

export default InviteUsers;