import React, { useState } from "react";
import { Button, FileInput } from "../../../../../Components";
import { FiImage, FiPaperclip, FiPlus, FiSend } from "react-icons/fi";
// import useAutosizeTextArea from "../../../../../Hooks/useAutoSizeTextArea";

function MessageBox({ onSend = () => {} }) {
	const [value, setValue] = useState("");
	const [ShowOptions, setShowOptions] = useState();
	// const textAreaRef = useRef();
	// useAutosizeTextArea(textAreaRef.current, value);

	const handleChange = (evt) => {
		const val = evt.target?.value;
		setValue(val);
	};

	const sendMessage = () => {
		if (value) {
			setValue("");
			onSend(value, "text");
		}
	};

	const onImage = (obj) => {
		onSend(obj.file.url, "image");
	};

	const onDocument = (obj) => {
		onSend(obj.file.url, "file");
	};

	return (
		<div className="sticky bottom-0 z-10 w-full border-t p-2 bg-canvas">
			<div className="flex-center gap-x-2">
				<input onKeyDown={(e) => e.key === "Enter" && sendMessage()} onChange={handleChange} className="p-2 bg-canvas flex-grow !border-none !rounded-none focus:outline-none h-full" placeholder="Start typing here..." value={value} />
				<div className="flex items-end gap-x-2">
					{ShowOptions && (
						<div className="grid grid-cols-2 gap-2 fade-in-right">
							<div className="border rounded-lg py-1 px-2 cursor-pointer text-sm hover:bg-light">
								<FileInput extensions={[".pdf", ".xls", ".xlsx", ".sheet", ".csv"]} onUpload={onDocument}>
									<div className="flex-center gap-2">
										<FiPaperclip /> Document
									</div>
								</FileInput>
							</div>
							<div className="border rounded-lg py-1 px-2 cursor-pointer text-sm hover:bg-light">
								<FileInput extensions={[".png", "/jpg", ".jpeg"]} onUpload={onImage}>
									<div className="flex-center gap-2">
										<FiImage /> Image
									</div>
								</FileInput>
							</div>
						</div>
					)}
					<div onClick={() => setShowOptions(!ShowOptions)} className={`p-2 rounded-full ${ShowOptions ? "bg-dark text-white" : "hover:bg-light"} cursor-pointer text-lg`}>
						<FiPlus className={`transition-all ${ShowOptions ? "rotate-45" : ""}`} />
					</div>
					{value && (
						<Button onClick={sendMessage} className="btn-sm">
							<FiSend className="mr-2" />
							Send
						</Button>
					)}
				</div>
			</div>
		</div>
	);
}

export default MessageBox;
