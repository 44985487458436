import React from "react";
import { StampTo24hr, StampToDate } from "../../../../Utils/utils";
import { FaCar, FaRegClock } from "react-icons/fa";
import RequestStatus from "../Components/requestStatus";
import ViewRequest from "../ViewRequest";
import { useState } from "react";
import { Badge } from "../../..";

function CabRequest({ id, pickupTime, amount, pickup, drop, variant, type, bookingId, booking, status, adminRequest, onReload = () => {} }) {
	const [OpenId, setOpenId] = useState();

	return (
		<>
			<div onClick={() => setOpenId(id)} className="overflow-hidden border shadow-sm cursor-pointer rounded-xl bg-canvas min-w-min hover:bg-light">
				<div className="p-4">
					<div className="flex-center-between">
						<h4 className="gap-2 capitalize align-center">
							<FaCar />
							{type} Cab
						</h4>
						<div className="text-xs">{StampToDate(pickupTime)}</div>
					</div>
					<div className="mt-3 flex-center-between cent">
						<div className="gap-2 align-center">
							<FaRegClock /> {StampTo24hr(pickupTime)}
						</div>
						<Badge>{drop.split(",")[0]}</Badge>
					</div>
				</div>
				{status && <RequestStatus {...{ id, status }} />}
			</div>
			<ViewRequest requestId={OpenId} onClose={() => setOpenId()} onReload={onReload} />
		</>
	);
}

export default CabRequest;
