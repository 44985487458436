import React from 'react';
import { BusSearch } from '../../../../../Components';

function BusHome() {

    return (
        <>
        <div className='p-4'>
            <BusSearch/>
        </div>
        </>
    );
}

export default BusHome;