import React from "react";
import FlightBooking from "./flight";
import StayBooking from "./stay";
import CabBooking from "./cab";
import TrainBooking from "./train";

function ViewBooking({ id, type, onClose = () => {}, onReload }) {
	if (type === "flight") {
		return <FlightBooking bookingId={id} onClose={onClose} onReload={onReload} />;
	}
	if (type === "stay") {
		return <StayBooking bookingId={id} onClose={onClose} onReload={onReload} />;
	}
	if (type === "cab") {
		return <CabBooking bookingId={id} onClose={onClose} onReload={onReload} />;
	}
	if (type === "train") {
		return <TrainBooking bookingId={id} onClose={onClose} onReload={onReload} />;
	}

	return null;
}

export default ViewBooking;
