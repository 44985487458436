import React from "react";
import { FaInfoCircle } from "react-icons/fa";

function AdvisoryMessage({ advisory }) {
	if (advisory)
		return (
			<>
				<div className="p-2 rounded-lg mb-2 relative text-xs bg-light border">
					<h5 className="align-center gap-1">
						<FaInfoCircle /> Advisory
					</h5>
					<div className="mt-1">{advisory}</div>
				</div>
			</>
		);
	return null;
}

export default AdvisoryMessage;
