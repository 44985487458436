import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import BookingStatus from './Components/bookingStatus';
import { Img } from '../../../../../../../Components';

function StayBooking({images=[], amenities=[], rating=0, stars=0, price=0, name='', address='', className='', status, id, onClick=()=>{}}) {

    return ( 
        <>
        <div className={`relative min-w-[15rem] border hover:shadow-md bg-canvas cursor-pointer rounded-lg shadow-sm overflow-hidden ${className}`}>
            <div className='p-2 align-center'>
                <Img src={images[0]} className='h-20 aspect-square rounded-full'/>
                <div onClick={onClick} className='p-2'>
                    <div className='font-semibold text-ellipsis'>{name}</div>
                    <div className='text-secondary text-sm text-ellipsis'>{address}</div>
                    <div className='mt-2 flex-center-between'>
                        <div className='text-sm'><span className='font-semibold'>₹ {price}</span></div>
                    </div>
                </div>
            </div>
            {
                status && <BookingStatus status={status} id={id}/>
            }
        </div>
        </>
     );
}

export default StayBooking;