import React, { useEffect, useState } from "react";
import Processing from "./processing";
import Success from "./success";
import { apis, post } from "../../../../../../Utils/axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Toast } from "../../../../../../Components";
import BookingFailed from "./failed";

function ConfirmBooking() {
	const LOCATION = useLocation();
	const { tripId, holdId, logo, name, code, approvalId, requestId, customFields, costCenterId, passengers, paymentMode, razorpayData } = LOCATION.state || {};

	const NAVIGATE = useNavigate();
	const [Booking, setBooking] = useState();
	const [Failed, setFailed] = useState();

	useEffect(() => {
		if (tripId && holdId && paymentMode) {
			bookFlight();
		} else {
			Toast.error("Retry Booking");
			NAVIGATE(-1);
		}
	}, []);

	const bookFlight = () => {
		setBooking();
		post(apis.bookFlight, { tripId, holdId, requestId, approvalId, customFields, costCenterId, passengers, paymentMode, razorpayData }, (r, e) => {
			if (r) {
				setBooking(r);
			} else if (e) {
				setFailed(true);
				Toast.handleError(e);
			}
		});
	};

	if (Booking) return <Success booking={Booking} />;
	if (Failed) return <BookingFailed />;
	return <Processing flight={{ logo, name, code }} />;
}

export default ConfirmBooking;
