import io from "socket.io-client";

const ngrokUrl = localStorage.getItem("ngrok");

const getSocketOrigin = () => {
	if (ngrokUrl) {
		//ngrok Tunnel
		return ngrokUrl.replace("http", "ws");
	} else if (origin.includes("localhost")) {
		//Local Environment
		return "ws://localhost:8080";
	} else {
		//Production
		return "wss://corp.twingstech.com";
	}
};

const ORIGIN = getSocketOrigin();
let socket = null;

function connectSocket(token) {
	if (!socket && token) {
		socket = io(ORIGIN, { auth: { token } });
	}
}

function disconnectSocket() {
	if (socket) {
		socket.disconnect();
		socket = null;
	}
}
export { connectSocket, disconnectSocket, socket };
