import React from "react";
import { FiArrowUpRight } from "react-icons/fi";
import useFetch from "../../../../Hooks/useFetch";
import apis from "../../../../Utils/apis";

function AtAGlance() {
	const { response } = useFetch(apis.atAGlanceCount, null, {
		count: {
			approvalsToday: 0,
			totalPendingApprovals: 0,
			readyToBook: 0,
			awaitingApproval: 0,
			pendingWithTravelHead: 0,
		},
	});

	const { approvalsToday, totalPendingApprovals, readyToBook, awaitingApproval, pendingWithTravelHead } = response.count;

	return (
		<>
			<div className="col-span-2 rounded-2xl mt-6">
				<h3>At a Glance</h3>
				<div className="mt-4 grid grid-cols-5 gap-4 text-dark">
					<div className="bg-accent text-white rounded-xl p-4 flex flex-col justify-between">
						<h1 className="text-4xl">{approvalsToday}</h1>
						<div className="text-sm mt-2">Approvals Arrived Today</div>
					</div>
					<div className="bg-whitish border rounded-xl p-4 flex flex-col justify-between">
						<h1 className="text-4xl flex-center-between">
							{totalPendingApprovals}
							<div className="w-8 h-8 rounded-full bg-surface flex-center text-lg">
								<FiArrowUpRight />
							</div>
						</h1>
						<div className="text-sm mt-2">Total Pending Approvals</div>
					</div>
					<div className="bg-whitish border rounded-xl p-4 flex flex-col justify-between">
						<h1 className="text-4xl flex-center-between">
							{readyToBook}
							<div className="w-8 h-8 rounded-full bg-surface flex-center text-lg">
								<FiArrowUpRight />
							</div>
						</h1>
						<div className="text-sm mt-2">Approvals ready to book</div>
					</div>
					<div className="bg-whitish border rounded-xl p-4 flex flex-col justify-between">
						<h1 className="text-4xl flex-center-between">
							{awaitingApproval}
							<div className="w-8 h-8 rounded-full bg-surface flex-center text-lg">
								<FiArrowUpRight />
							</div>
						</h1>
						<div className="text-sm mt-2">Requested Approvals</div>
					</div>
					<div className="bg-whitish border rounded-xl p-4 flex flex-col justify-between">
						<h1 className="text-4xl flex-center-between">
							{pendingWithTravelHead}
							<div className="w-8 h-8 rounded-full bg-surface flex-center text-lg">
								<FiArrowUpRight />
							</div>
						</h1>
						<div className="text-sm mt-2">Bookings pending with Travel Head</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default AtAGlance;
