import moment from "moment";

export const IsDev = () => origin.includes("localhost");

export const StampToTime = (stamp) => {
	return moment(stamp).format("hh:mm A");
};

export const StampTo24hr = (stamp) => {
	return moment(parseInt(stamp)).format("HH:mm");
};

export const StampToDate = (stamp) => {
	if (!stamp) return "";
	return moment(stamp).format("DD MMM YYYY");
};

export const removeElementAtIndex = (arr, index) => {
	return arr.filter((_, i) => i !== index);
};

export const removeElementAtIndices = (arr, indeces) => {
	return arr.filter((_, i) => !indeces.includes(i));
};

export const validateEmail = (email) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

export function validateIndianPhoneNumber(phoneNumber) {
	const phoneNumberRegex = /^[0-9]{10}$/;
	return phoneNumberRegex.test(parseInt(phoneNumber));
}

export const debounce = (fn, delay = 500) => {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			fn(...args);
		}, delay);
	};
};

export const getAlphabetIndex = (alphabet, total) => {
	alphabet = alphabet.toLowerCase();
	const charCode = alphabet.charCodeAt(0) - 97;
	const index = charCode % total;
	return index;
};

export const removeNullKeys = (obj) => {
	return Object.keys(obj).reduce((acc, key) => {
		if (obj[key] !== null) {
			acc[key] = obj[key];
		}
		return acc;
	}, {});
};

export const camelCaseToWords = (camelCaseString) => {
	const words = camelCaseString
		.replace(/([A-Z])/g, " $1")
		.trim()
		.split(" ");
	const capitalizedWords = words.map((word) => {
		return word.charAt(0).toUpperCase() + word.slice(1);
	});
	return capitalizedWords.join(" ");
};

export const getErrorMsg = (err) => {
	return err.response.data.error;
};

export const getValueFromObject = (key, object) => {
	const keys = key.split(".");
	let value = object;

	for (const key of keys) {
		if (value.hasOwnProperty(key)) {
			value = value[key];
		} else {
			return undefined;
		}
	}
	return value;
};

export const scheduleIntro = (path) => {
	let intros = localStorage.getItem("intros") || "[]";
	intros = JSON.parse(intros);
	if (!intros.includes(path)) {
		intros.push(path);
		localStorage.setItem("intros", JSON.stringify(intros));
	}
};

export const isMac = (params) => {
	if (navigator.userAgent.indexOf("Mac OS X") !== -1) {
		return true;
	} else {
		return false;
	}
};

export const dispatchCustomEvent = (eventName, data) => {
	const customEvent = new CustomEvent(eventName, {
		detail: data,
	});

	document.dispatchEvent(customEvent);
};

export const subscribeCustomEvent = (eventName, callback) => {
	const customEventListener = (event) => {
		const data = event.detail;
		callback(data);
	};

	document.addEventListener(eventName, customEventListener);

	return () => {
		document.removeEventListener(eventName, customEventListener);
	};
};

export const getSegmentsDuration = (segments) => {
	let duration = 0;
	segments.forEach((segment) => {
		duration += segment.duration;
	});
	return duration;
};

export const getTimeBetweenSegments = (segments) => {
	let time = 0;
	let prev = segments[0];
	segments.forEach((segment, i) => {
		if (i === 0) return;
		time = moment(segment.departureTime).diff(moment(prev.arrivalTime), "minutes");
		prev = segment;
	});
	return time;
};

export const classifyTime = (time) => {
	const hourOfDay = moment(time).hour();
	if (hourOfDay < 8) {
		return "early";
	}
	if (hourOfDay < 12) {
		return "morning";
	}
	if (hourOfDay < 16) {
		return "noon";
	}
	if (hourOfDay < 20) {
		return "evening";
	} else {
		return "night";
	}
};

export const loadScript = (src) => {
	return new Promise((resolve) => {
		const script = document.createElement("script");
		script.src = src;
		script.onload = () => {
			resolve(true);
		};
		script.onerror = () => {
			resolve(false);
		};
		document.body.appendChild(script);
	});
};

export const mergeDateAndTime = (dateStamp, timeStamp) => {
	return dateStamp + (timeStamp - moment().startOf("day").valueOf());
};

export const getAvatar = (name = "a") => {
	const totalAvatars = 11;
	const index = getAlphabetIndex(name[0], totalAvatars);
	const avatar = require(`../Assets/Images/avatars/${index}.png`);
	return avatar;
};

export const convertToHtmlElements = (html) => {
	const temp = document.createElement("div");
	temp.innerHTML = html;
	return Array.from(temp.childNodes);
};

export const convertToHtmlElement = (html) => {
	const temp = document.createElement("div");
	temp.innerHTML = html;
	const container = document.createElement("div");
	Array.from(temp.childNodes).forEach((node) => {
		container.appendChild(node.cloneNode(true));
	});
	return container;
};

export const unitToString = (unit) => {
	switch (unit) {
		case "d":
			return "days";
		case "h":
			return "hrs";
		default:
			return unit;
	}
};
