import React from "react";
import ReactDOM from "react-dom/client";
import Pages from "./Pages";
import SplashScreen from "./Components/SplashScreen/SplashScreen";
import { Provider } from "react-redux";
import store from "./Redux";
import { Toaster } from "react-hot-toast";
import "react-tooltip/dist/react-tooltip.css";
import "./Assets/Css/animations.css";
import "./Assets/Css/styles.css";
import { toast } from "./Utils/constants";
import { SidePaneProvider, TopBarProvider, InternetMonitor, ErrorBoundary } from "./Components";
import LockScreen from "./Components/LockScreen";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<ErrorBoundary>
		<Toaster toastOptions={toast} />
		<InternetMonitor />
		<Provider store={store()}>
			<SplashScreen />
			<LockScreen />
			{/* Context to change SidePane's content dynamically */}
			<SidePaneProvider>
				{/* Context to change TopBar's content dynamically */}
				<TopBarProvider>
					<BrowserRouter>
						<Pages />
					</BrowserRouter>
				</TopBarProvider>
			</SidePaneProvider>
		</Provider>
	</ErrorBoundary>
);
