import React from "react";
import StatusIndicator from "./status";
import { CopyValue } from "../../..";

function RequestStatus({ status, id }) {
	return (
		<div className="p-2 border-t flex-center-between bg-whitish">
			<CopyValue className="text-xxs">REQ-{id}</CopyValue>
			<StatusIndicator status={status} />
		</div>
	);
}

export default RequestStatus;
