import React from "react";
import { Route, Routes } from "react-router-dom";
import UserHome from "./Home";
import Travel from "./Travel";
import Approvals from "./Approvals";
import Travellers from "../Common/Travellers";
import Offers from "./Offers";
import TripBuilder from "./Travel/Trips/TripBuilder";
import ViewTrip from "./Travel/Trips/TripBuilder/viewTrip";
import Receipts from "./Expense/Receipts";
import Bookings from "./Travel/Bookings";
import Trips from "./Travel/Trips";
import Requests from "./Travel/Requests";
import ViewTraveller from "../Common/Travellers/Users/ViewTraveller";

function EmployeeRoutes() {
	return (
		<Routes>
			<Route path="/trips" element={<Trips />} />
			<Route path="/requests" element={<Requests />} />
			<Route path="/bookings" element={<Bookings />} />
			<Route path="/travellers/:userId" element={<ViewTraveller />} />
			<Route path="/travellers" element={<Travellers />} />
			<Route path="/trip/:id" element={<ViewTrip />} />
			<Route path="/add/trip" element={<TripBuilder />} />
			<Route path="/approvals/*" element={<Approvals />} />
			<Route path="/expense/*" element={<Receipts />} />
			<Route path="/travel/*" element={<Travel />} />
			<Route path="/offers/*" element={<Offers />} />
			<Route path="/home" element={<UserHome />} />
			<Route path="/" element={<UserHome />} />
		</Routes>
	);
}

export default EmployeeRoutes;
