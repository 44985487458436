import React from "react";
import { useState } from "react";
import EmployeeInfo from "../Cards/EmployeeInfo";
import ApprovalStatus from "./Components/approvalStatus";
import ViewApproval from "./ViewApproval";
import moment from "moment";
import { TbBed, TbBus, TbCar, TbMap, TbPlane, TbReceipt, TbTrain } from "react-icons/tb";

function Approvals({ id, status, amount, type, date, trail, violations, requestedBy, approvers, onReload = () => {}, readOnly }) {
	const [OpenId, setOpenId] = useState();
	console.log(OpenId, OpenId);
	const ICONS = {
		trip: <TbMap />,
		receipt: <TbReceipt />,
		flight: <TbPlane />,
		bus: <TbBus />,
		stay: <TbBed />,
		train: <TbTrain />,
		cab: <TbCar />,
	};
	console.log("Refreshed");

	return (
		<>
			<div onClick={() => setOpenId(id)} className="overflow-hidden border shadow-sm cursor-pointer rounded-xl bg-canvas min-w-min hover:bg-light">
				<div className="p-4">
					<div className="flex justify-between">
						<div className="align-center gap-x-2">
							{ICONS[type]}
							<h4 className="capitalize">
								{type} - ₹ {amount}
							</h4>
						</div>
						<div className="text-label">{moment(date).format("DD MMM YYYY")}</div>
					</div>
					<EmployeeInfo size="sm" className="!p-0 mt-3" employee={requestedBy} />
				</div>
				{status && <ApprovalStatus status={status} id={`APP-${id}`} />}
			</div>
			<ViewApproval readOnly={readOnly} approvalId={OpenId} onClose={() => setOpenId()} onReload={onReload} />
		</>
	);
}

export default Approvals;
