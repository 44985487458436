import React from "react";
import { StampTo24hr, StampToDate } from "../../../../../Utils/utils";
import { FaPlane } from "react-icons/fa";
import moment from "moment";

function Flights({ flights, airports = {} }) {
	return (
		<>
			<div className="p-6 pb-2">
				{flights?.map(({ segments }, i) => {
					const departure = segments[0];
					const arrival = segments[segments.length - 1];
					const duration = moment(arrival.arrivalTime).diff(moment(departure.departureTime), "seconds");
					const hours = Math.floor(duration / 60 / 60);
					const minutes = (duration / 60) % 60;

					return (
						<div key={i} className="grid grid-cols-3 mb-4">
							<div>
								<h1>{departure.departureCode}</h1>
								<div className="text-label whitespace-nowrap">{StampTo24hr(departure.departureTime)}</div>
								<div className="text-label whitespace-nowrap">{StampToDate(departure.departureTime)}</div>
								<div className="text-label">{airports[departure.departureCode]?.name}</div>
								{departure.departureTerminal && <div className="text-label whitespace-nowrap">Terminal {departure.departureTerminal}</div>}
							</div>
							<div className="flex-grow flex-center">
								<div className="w-full">
									<div className="align-center">
										<div className="w-2 h-2 border rounded-full border-dark" />
										<div className="flex-grow border-t border-dotted border-dark" />
										<FaPlane />
										<div className="flex-grow border-t border-dotted border-dark" />
										<div className="w-2 h-2 border rounded-full border-dark" />
									</div>
									<div className="mt-2 text-xs text-center whitespace-nowrap">
										{Math.abs(hours)}hr {Math.abs(minutes)}m
									</div>
								</div>
							</div>
							<div className="text-end">
								<h1>{arrival.arrivalCode}</h1>
								<div className="text-label whitespace-nowrap">{StampTo24hr(arrival.arrivalTime)}</div>
								<div className="text-label whitespace-nowrap">{StampToDate(arrival.arrivalTime)}</div>
								<div className="text-label">{airports[arrival.arrivalCode]?.name}</div>
								{arrival.arrivalTerminal && <div className="text-label whitespace-nowrap">Terminal {arrival.arrivalTerminal}</div>}
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
}

export default Flights;
