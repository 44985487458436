import React, { useState } from "react";
import { FiCamera } from "react-icons/fi";
import { FileInput, Logo, Toast } from "../../Components";
import { apis } from "../../Utils/axios";

function MobileStore() {
	const Params = new URLSearchParams(window.location.search);
	const [UploadFiles, setUploadFiles] = useState([]);

	const onSelect = (obj) => {
		Toast.success("File uploaded");
		setUploadFiles((prev) => [...prev, obj.file.url]);
	};

	return (
		<div className="w-[100%] h-[100%] overflow-y-auto overflow-x-hidden fixed top-0 bottom-0 left-0 right-0 bg-black text-white flex-center">
			<div className="w-full flex flex-col gap-12 p-8">
				<div>
					<Logo className="flex-center mb-4" />
					<div className="text-xs text-center">Uploaded photos will be available in receipt store when you create receipts</div>
				</div>
				<FileInput url={apis.addInStore} data={{ code: Params.get("code") }} multiple onUpload={(obj) => onSelect(obj)}>
					<div className="bg-primary p-8 flex-center gap-4 rounded-xl text-2xl">
						<FiCamera /> Upload Photo
					</div>
				</FileInput>
				{UploadFiles.length > 0 && (
					<div className="text-center">
						<div className="bg-[#222] py-2 px-4 text-xs max-w-min whitespace-nowrap rounded-full m-auto mb-4">{UploadFiles.length} Uploaded Just Now</div>
						<div className="flex-center flex-wrap gap-4">
							{UploadFiles.map((url, i) => {
								return <img src={url} alt="receipt" className="w-14 h-14 object-cover rounded-full" />;
							})}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default MobileStore;
