import { Popover, Transition } from "@headlessui/react";
import React from "react";
import { useState } from "react";
import { FiTrash, FiUsers } from "react-icons/fi";
import { usePopper } from "react-popper";
import { IncrementDecrement, Button } from "../..";
import { FaChild, FaUser } from "react-icons/fa";

function SelectRooms({ className = "", label, onChange = () => {}, rooms = [] }) {
	const totalGuests = rooms.reduce((a, b) => a + b.adult + b.child, 0);
	//Popper stuff
	const [PopRef, setPopRef] = useState(null);
	const [PopElem, setPopElem] = useState(null);
	const { styles, attributes } = usePopper(PopRef, PopElem, {
		placement: "bottom-start",
	});

	const updateGuests = (index, val, type) => {
		const updatedRooms = rooms.map((room, i) => {
			if (i === index) {
				return {
					...room,
					[type]: val,
				};
			} else {
				return room;
			}
		});
		onChange(updatedRooms);
	};

	const updateRooms = (index, type) => {
		let updatedRooms;
		if (type === "remove") {
			updatedRooms = rooms.filter((room, i) => i !== index);
		} else {
			updatedRooms = [
				...rooms,
				{
					adult: 1,
					child: 0,
				},
			];
		}
		onChange(updatedRooms);
	};

	return (
		<>
			<Popover className={`relative popup ${className}`}>
				{({ open }) => (
					<>
						{label && <label className="block mb-2">{label}</label>}
						<Popover.Button ref={setPopRef} className={`w-full`}>
							<div ref={setPopRef} className="text-sm ring-1 ring-inset ring-muted bg-canvas shadow-sm rounded-md p-2.5 align-center cursor-pointer">
								<FiUsers className="mr-2" />
								<div className="whitespace-nowrap">
									{totalGuests} Guest in {rooms.length} Room
								</div>
							</div>
						</Popover.Button>
						<Transition ref={setPopElem} style={styles.popper} {...attributes.popper} className="z-10">
							<Popover.Panel className={`content !max-w-xs`}>
								{rooms.map((room, i) => (
									<div key={i}>
										<div className="flex flex-col p-4 gap-y-4">
											<div className="flex-center-between">
												<h5>Room {i + 1}</h5>
												<Button onClick={() => updateRooms(1, "remove")} variant="danger" className="btn-sm">
													<FiTrash />
												</Button>
											</div>
											<div className="flex-center-between">
												<div className="text-sm font-semibold align-center gap-x-2">
													<FaUser /> Adult
												</div>
												<IncrementDecrement min={1} max={2} minError="" maxError="" count={room.adult} onChange={(val) => updateGuests(i, val, "adult")} />
											</div>
											<div className="flex-center-between">
												<div className="text-sm font-semibold align-center gap-x-2">
													<FaChild />
													Child
												</div>
												<IncrementDecrement min={0} max={2} minError="" maxError="" count={room.child} onChange={(val) => updateGuests(i, val, "child")} />
											</div>
										</div>
									</div>
								))}
								<div className="p-4 pt-0">
									<Button onClick={() => updateRooms()} variant="outlined" className="w-full btn-sm">
										+ Add Room
									</Button>
								</div>
							</Popover.Panel>
						</Transition>
					</>
				)}
			</Popover>
		</>
	);
}

export default SelectRooms;
