import React, { useEffect, useState } from "react";
import "./SplashScreen.css";
import Logo from "../../Assets/Images/logo.png";

function SplashScreen() {
	const [End, setEnd] = useState();

	useEffect(() => {
		setTimeout(() => {
			setEnd(true);
		}, 1800);
	}, []);

	if (End) return <></>;

	return (
		<div className="splash-wrapper">
			<img src={Logo} alt="Travel Wings Logo" className="max-w-sm fade-in" />
		</div>
	);
}

export default SplashScreen;
