import React from "react";
import { TbCircleCheckFilled } from "react-icons/tb";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Button, Img, Popup } from "../../";
import { FiMap } from "react-icons/fi";

function PreviewCard({ room = {}, hotel = {}, occupancy = [] }) {
	const { amenities = [], name = "", locality = "" } = hotel;

	const launchMaps = () => {
		window.open(`https://www.google.com.sa/maps/search/${locality.latitude},${locality.longitude}`, "_blank");
	};

	return (
		<>
			<div className="grid grid-cols-5 mt-4 bg-white border rounded-md">
				<div className="flex flex-col justify-between col-span-2 p-4 bg-light">
					<div>
						<Swiper slidesPerView={"auto"} onSlideChange={(i) => {}} modules={[Navigation]} navigation={true} className="rounded-lg swiper-carousel">
							<SwiperSlide>
								<Img src={hotel.thumbnail} className="w-full h-full" />
							</SwiperSlide>
						</Swiper>
					</div>
				</div>
				<div className="col-span-3 p-4">
					<div className="mb-2">
						<div className="flex gap-x-2">
							{amenities.length > 0 && (
								<Popup button="Amenities" btnClass="btn btn-outlined btn-xs">
									<div className="p-4">
										{amenities.map((amenity, i) => {
											const { category, amenities } = amenity;
											return (
												<div key={i} className="mb-6">
													<h4 className="mb-2">{category}</h4>
													<div className="flex flex-wrap gap-2">
														{amenities.map((amenity, i) => (
															<div key={i} className="chip">
																{amenity}
															</div>
														))}
													</div>
												</div>
											);
										})}
									</div>
								</Popup>
							)}
							<Button onClick={launchMaps} variant="outlined" className="btn-xs">
								<FiMap className="mr-1" />
								Map
							</Button>
						</div>

						<h3 className="mt-2 text-ellipsis">{name}</h3>
						<div className="text-sm text-secondary text-ellipsis">{locality.address}</div>
					</div>
					<h3>
						For {occupancy.length} Rooms {`(${room.name})`}
					</h3>
					{room?.inclusions.map((item, i) => {
						return (
							<div key={i} className="mt-2 text-sm align-center text-secondary">
								<TbCircleCheckFilled className="mr-2 text-primary" />
								{item === "Half Board" ? "Breakfast and Dinner 🌮" : item === "Full Board" ? "Breakfast, Lunch and Dinner 🌮" : item}
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
}

export default PreviewCard;
