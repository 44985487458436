import React, { useEffect, useState } from "react";
import { Booking } from "../../../../../../Components";
import { BottomBar, ButtonGroup, CardsRenderer, CardsWrapper, DateRange, Pagination, Searchbox, Toast } from "../../../../../../Components";
import { apis, get } from "../../../../../../Utils/axios";
import moment from "moment";

function FlightRequests() {
	const [RequestStatus, setRequestStatus] = useState({ label: "Pending", value: "pending" });
	const initial = {
		bookings: [],
		count: 0,
		pages: 0,
	};
	const [Query, setQuery] = useState({
		search: "",
		page: 1,
		dates: {
			startDate: moment().subtract(30, "days").valueOf(),
			endDate: moment().add(30, "days").valueOf(),
		},
	});
	const [Requests, setRequests] = useState(initial);
	const [Loading, setLoading] = useState(true);

	useEffect(() => {
		fetchRequests(RequestStatus.value, Query);
	}, [RequestStatus, Query]);

	const fetchRequests = (type, query) => {
		setLoading(true);
		let url;
		if (type === "pending") {
			url = apis.getRequestedFlightRequests;
		} else if (type === "fulfilled") {
			url = apis.getFulfilledFlightRequests;
		} else if (type === "cancelled") {
			url = apis.getDeniedFlightRequests;
		}
		get(url + "/all", query, (r, e) => {
			if (r) {
				setRequests(r);
				setLoading(false);
			} else if (e) {
				Toast.handleError(e);
				setLoading(false);
			}
		});
	};

	return (
		<>
			<div>
				<BottomBar>
					<div className="w-full flex-center-between">
						<h5>{Requests.count} Flight Requests</h5>
						<div>
							<Pagination active={Query.page} total={Requests.pages} onChange={(page) => setQuery({ ...Query, page })} />
						</div>
					</div>
				</BottomBar>
				<div className="mt-6 flex-center-between">
					<div className="align-center">
						<ButtonGroup
							size="xs !p-1.5"
							selected={RequestStatus}
							onClick={setRequestStatus}
							className="text-sm"
							options={[
								{ label: "Pending", value: "pending" },
								{ label: "Fulfilled", value: "fulfilled" },
								{ label: "Cancelled", value: "cancelled" },
							]}
						/>
					</div>
					<div className="align-center gap-x-2">
						<Searchbox placeholder="Search requests" cmd="s" onChange={(search) => setQuery({ ...Query, search })} />
						<DateRange setRange={(dates) => setQuery({ ...Query, dates })} range={Query.dates} inputClass="!p-1.5 !text-xs" />
					</div>
				</div>
				<CardsWrapper loading={Loading}>
					<CardsRenderer
						items={Requests.bookings}
						render={(booking, i) => {
							return <Booking key={i} booking={booking} type="flight" onReload={() => fetchRequests(RequestStatus.value, Query)} />;
						}}
					/>
				</CardsWrapper>
			</div>
		</>
	);
}

export default FlightRequests;
