import React from "react";
import { Button, Input, Modal, Toast, Form, OfficeSearch } from "../../";
import { useState } from "react";
import { apis, post } from "../../../Utils/axios";
import { useAdminPermission } from "../../../Hooks/useAdminPermission";

function AddTeam({ onReload = () => {}, officeId, btnLabel = "+ Add Team", btnClass = "", btnVariant = "primary" }) {
	const { checkAdminPermission } = useAdminPermission();
	const [NewTeam, setNewTeam] = useState();
	const initial = {
		name: "",
		office: {},
	};
	const [Values, setValues] = useState(initial);
	const [Loader, setLoader] = useState();

	const onSubmit = () => {
		if (!Values.office.value && !officeId) {
			return Toast.error("Please select Office this team belongs to");
		}

		setLoader(true);
		post(
			apis.addTeam,
			{
				...Values,
				officeId: officeId || Values.office.value,
			},
			(r, e) => {
				if (r) {
					setLoader();
					onReload();
					setNewTeam();
					setValues(initial);
					Toast.success("Team added");
				} else if (e) {
					console.log(e);
					Toast.handleError(e);
					setLoader();
				}
			}
		);
	};

	if (!checkAdminPermission("EDIT_ORGANIZATION")) {
		return null;
	}

	return (
		<>
			<Button onClick={() => setNewTeam(true)} variant={btnVariant} className={btnClass}>
				{btnLabel}
			</Button>
			<Modal open={NewTeam} onClose={() => setNewTeam()}>
				<div className="p-6">
					<h1>New Team</h1>
					<div className="mt-2 text-label">Teams are the lowest level of grouping which can be used to group employees.</div>
					<Form onSubmit={onSubmit} className="flex flex-col mt-4 gap-y-4">
						<Input required value={Values.name} onChange={(name) => setValues((prev) => ({ ...prev, name }))} label="Team Name" placeholder="Name" />
						{!officeId && <OfficeSearch label="Office this team belongs to" placeholder="Search Offices" onSelect={(office) => setValues((prev) => ({ ...prev, office }))} selected={Values.office} />}
						<Button loading={Loader} type="submit" className="w-full mt-4">
							Create Team
						</Button>
					</Form>
				</div>
			</Modal>
		</>
	);
}

export default AddTeam;
