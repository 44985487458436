import React, { useEffect, useState } from "react";
import { Button } from "../../Components";
import { useLoginHook } from "../../Hooks/useLoginHook";
import Logo from "../../Assets/Images/logo.png";

function LoadingScreen() {
	const { logout } = useLoginHook();
	const [ShowStuckHelp, setShowStuckHelp] = useState();

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowStuckHelp(true);
		}, 7000);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<>
			<div className="relative w-full h-full flex-center">
				<img src={Logo} alt="Travel Wings Logo" className="max-w-sm fade-in" />
				{ShowStuckHelp && (
					<div className="absolute left-0 w-full gap-2 bottom-10 flex-center">
						<div className="text-label">Stuck at loading ? Sign out and login again</div>
						<Button onClick={logout} variant="outlined" className="btn-xs">
							Sign out
						</Button>
					</div>
				)}
			</div>
		</>
	);
}

export default LoadingScreen;
