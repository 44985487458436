let initialState = {
	loading: true,
	organization: false,
	policy: false,
	approval: false,
	costCenter: false,
	reports: false,
	analytics: false,
	customField: false,
	payout: false,
};

const clientSettings = (state = initialState, { data, type }) => {
	if (type === "CLIENT_SETTINGS") {
		return {
			...state,
			...data,
			loading: false,
		};
	} else {
		return state;
	}
};

export { clientSettings };
