import React from 'react';
import { FaBed, FaBus, FaCar, FaPlane, FaReceipt, FaTrain } from 'react-icons/fa';
import { Button, Input, Portal } from '../../../../../../Components';
import { FiTrash } from 'react-icons/fi';
import PlaneEmoji from '../../../../../../Assets/Images/emojis/plane.png';
import StayEmoji from '../../../../../../Assets/Images/emojis/stay.png';
import BusEmoji from '../../../../../../Assets/Images/emojis/bus.png';
import CabEmoji from '../../../../../../Assets/Images/emojis/cab.png';
import TrainEmoji from '../../../../../../Assets/Images/emojis/train.png';
import ReceiptEmoji from '../../../../../../Assets/Images/emojis/receipt.png';
import { useNavigate } from 'react-router-dom';

function TripBuilder({}) {

    const NAVIGATE = useNavigate()

    return ( 
        <Portal>
        <div className='absolute top-0 left-0 z-20 h-full w-full bg-light flex p-8 fade-in overflow-y-auto'>
            <div className='flex-grow'>
                <div className='text-4xl'>
                    Start adding items to your trip
                </div>
                <div className='text-sm mt-2 '>
                    Trips are a great way to organize your travel plans. <br/>You can add flights, hotels, car rentals, trains, and more.
                </div>
                <div className='flex text-muted text-3xl gap-x-6 mt-12'>
                    <img src={PlaneEmoji} alt='plane' className='w-8 h-8'/>
                    <img src={StayEmoji} alt='stay' className='w-8 h-8'/>
                    <img src={BusEmoji} alt='bus' className='w-8 h-8'/>
                    <img src={CabEmoji} alt='cab' className='w-8 h-8'/>
                    <img src={TrainEmoji} alt='train' className='w-8 h-8'/>
                    <img src={ReceiptEmoji} alt='receipt' className='w-8 h-8'/>
                </div>
                <div className='flex mt-12 h-96'>
                    <div className='border-l-4'/>
                    <div className='flex flex-col justify-between'>
                        <div className='flex items-start gap-x-6'>
                            <div className='w-4 h-4 rounded-full bg-surface -ml-2.5'/>
                            <div>
                                <div className='rounded-lg w-32 h-4 bg-surface'/>
                                <div className='flex mt-4 gap-x-4'>
                                    <div className='w-60 h-20 rounded-lg bg-surface'/>
                                    <div className='w-32 h-20 rounded-lg bg-surface'/>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-start gap-x-6'>
                            <div className='w-4 h-4 rounded-full bg-surface -ml-2.5'/>
                            <div>
                                <div className='rounded-lg w-32 h-4 bg-surface'/>
                                <div className='flex mt-4'>
                                    <div className='w-60 h-20 rounded-lg bg-surface'/>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-start gap-x-6'>
                            <div className='w-4 h-4 rounded-full bg-surface -ml-2.5'/>
                            <div>
                                <div className='rounded-lg w-32 h-4 bg-surface'/>
                                <div className='flex mt-4'>
                                    <div className='w-32 h-20 rounded-lg bg-surface'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='sticky top-0 w-72'>
                <div className='p-4 border rounded-lg bg-canvas shadow-sm overflow-hidden'>
                    <h3>New Trip</h3>
                    <div className='mt-4'>
                        <Input placeholder='Trip Title'/>
                        <div className='flex gap-x-2'>
                            <Button className='mt-4 w-full'>Create Trip</Button>
                            <Button onClick={()=>NAVIGATE(-1)} variant='danger' className='mt-4'><FiTrash className='mr-2'/>Cancel</Button>
                        </div>
                    </div>
                </div>
                <div className='mt-4 border rounded-lg bg-canvas shadow-sm overflow-hidden'>
                    <h3 className='p-4'>Add to trip</h3>
                    <div className='border-t p-4 align-center gap-x-4 hover:bg-light cursor-pointer'>
                        <FaPlane/>Add Flight
                    </div>
                    <div className='border-t p-4 align-center gap-x-4 hover:bg-light cursor-pointer'>
                        <FaBed/>Add Stay
                    </div>
                    <div className='border-t p-4 align-center gap-x-4 hover:bg-light cursor-pointer'>
                        <FaBus/>Add Bus
                    </div>
                    <div className='border-t p-4 align-center gap-x-4 hover:bg-light cursor-pointer'>
                        <FaCar/>Add Cab
                    </div>
                    <div className='border-t p-4 align-center gap-x-4 hover:bg-light cursor-pointer'>
                        <FaTrain/>Add Train
                    </div>
                    <div className='border-t p-4 align-center gap-x-4 hover:bg-light cursor-pointer'>
                        <FaReceipt/>Add Receipt
                    </div>
                </div>
            </div>
        </div>
        </Portal>
     );
}

export default TripBuilder;