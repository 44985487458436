import React, { useEffect, useState } from "react";
import { Loader, SidePaneContent, Toast, Weather } from "../../../../../../Components";
import { StayFilters, StaySearch } from "../../../../../../Components";
import StayResult from "./Result";
import { useSearchParams } from "react-router-dom";
import { apis, get } from "../../../../../../Utils/axios";

function StaySRP() {
	const [searchParams] = useSearchParams();
	const city = searchParams.get("city");
	const lat = searchParams.get("lat");
	const lng = searchParams.get("lng");
	const rooms = searchParams.get("rooms");
	const parsedRooms = JSON.parse(searchParams.get("rooms") || "[]");
	const checkIn = parseInt(searchParams.get("checkIn"));
	const checkOut = parseInt(searchParams.get("checkOut"));
	const requestId = searchParams.get("requestId") || "";
	const approvalId = searchParams.get("approvalId") || "";

	const [ShowMap, setShowMap] = useState();
	const [Loading, setLoading] = useState(true);
	const [SelectedStay, setSelectedStay] = useState();
	const [Result, setResult] = useState({
		stays: [],
	});

	const [Filters, setFilters] = useState({
		maxPrice: 0,
		inPolicy: false,
		refundable: false,
		chains: [],
		amenities: [],
		stars: [],
		query: "",
	});

	useEffect(() => {
		searchStays({ lat, lng, rooms, checkIn, checkOut, requestId, approvalId });
	}, [lat, lng, rooms, checkIn, checkOut, requestId, approvalId]);

	const searchStays = ({ lat, lng, rooms, checkIn, checkOut, requestId, approvalId }) => {
		setLoading(true);
		setSelectedStay();
		get(
			apis.searchStays,
			{
				lat,
				lng,
				occupancy: JSON.parse(rooms || "[]"),
				checkIn,
				checkOut,
				requestId,
				approvalId,
			},
			(r, e) => {
				if (r) {
					setResult(r);
					setLoading(false);
				} else if (e) {
					Toast.handleError(e);
					setLoading(false);
				}
			}
		);
	};

	return (
		<>
			<Loader loading={Loading} />
			<SidePaneContent>
				<div className="h-full p-4">
					<StayFilters map={ShowMap} setMap={setShowMap} stays={Result.stays} filters={Filters} updateFilters={setFilters} />
				</div>
			</SidePaneContent>
			<div className={`p-4 relative h-full`}>
				<StaySearch map={ShowMap} city={{ label: city, lat, lng }} range={{ startDate: checkIn, endDate: checkOut }} rooms={parsedRooms} hideModes />
				{Loading ? (
					<div className="w-full mt-20 flex-center">
						<div>
							<span className="text-sm text-secondary">Hang tight, finding the best stays for you ...</span>
							<Weather place={city} className="mt-6" />
						</div>
					</div>
				) : (
					<StayResult
						approval={Result.approval}
						map={ShowMap}
						filters={Filters}
						stays={Result.stays}
						onSelect={setSelectedStay}
						SelectedFlight={SelectedStay}
						searchData={{
							rooms,
							checkIn,
							checkOut,
							requestId,
							approvalId,
						}}
					/>
				)}
			</div>
		</>
	);
}

export default StaySRP;
