import React from "react";
import { Button, Disclosure, Searchbox } from "../../../../Components";
import { FiArrowUpRight, FiHelpCircle } from "react-icons/fi";
import { FaBed, FaBus, FaPlane, FaReceipt } from "react-icons/fa";
import Policy from "../../../Policy";

function Support() {
	return (
		<div className="p-4">
			<div className="flex-center-between">
				<h1>Help & Support</h1>
			</div>
			<div className="mt-4 flex-center-between gap-x-4">
				<Searchbox placeholder="Search help guides" />
				<Button variant="outlined" className="btn-xs">
					<FiHelpCircle className="mr-1 inline-item" />
					Raise Support Ticket
				</Button>
			</div>

			<div className="mt-6">
				<h4>The Basics</h4>

				<div className="mt-4 align-center gap-x-4">
					<div className="p-4 border rounded-lg cursor-pointer hover:bg-light">
						<h1 className="mb-2 flex-center-between">
							<FaPlane />
							<FiArrowUpRight />
						</h1>
						<div className="font-semibold">How to book a flight</div>
						<div className="text-label">Learn how to get started by booking a flight</div>
					</div>
					<div className="p-4 border rounded-lg cursor-pointer hover:bg-light">
						<h1 className="mb-2 flex-center-between">
							<FaBed />
							<FiArrowUpRight />
						</h1>
						<div className="font-semibold">How to reserve a Stay</div>
						<div className="text-label">Learn how to get started by reserving a stay</div>
					</div>
					<div className="p-4 border rounded-lg cursor-pointer hover:bg-light">
						<h1 className="mb-2 flex-center-between">
							<FaBus />
							<FiArrowUpRight />
						</h1>
						<div className="font-semibold">How to book a bus</div>
						<div className="text-label">Learn how to get started by booking a bus</div>
					</div>
					<div className="p-4 border rounded-lg cursor-pointer hover:bg-light">
						<h1 className="mb-2 flex-center-between">
							<FaReceipt />
							<FiArrowUpRight />
						</h1>
						<div className="font-semibold">File a Reimbursement</div>
						<div className="text-label">Learn how to get started with claiming a Reimbursement</div>
					</div>
				</div>
			</div>

			<div className="mt-6 text-sm">
				<h4>Frequently Asked Questions</h4>

				<div className="grid grid-cols-2 gap-4 mt-4">
					<div className="flex flex-col gap-4">
						<Disclosure heading="How to track my reimbursement" className="p-2 border rounded-md">
							<div className="mt-2 text-label">
								Reprehenderit labore eiusmod aliquip laborum incididunt nostrud et voluptate minim ipsum aliquip sit. Et incididunt dolore duis elit deserunt mollit consequat. Enim pariatur irure velit et nisi incididunt elit consequat culpa est elit culpa.
							</div>
						</Disclosure>
						<Disclosure heading="What is Personal Booking" className="p-2 border rounded-md">
							<div className="mt-2 text-label">
								Reprehenderit labore eiusmod aliquip laborum incididunt nostrud et voluptate minim ipsum aliquip sit. Et incididunt dolore duis elit deserunt mollit consequat. Enim pariatur irure velit et nisi incididunt elit consequat culpa est elit culpa.
							</div>
						</Disclosure>
					</div>
					<div className="flex flex-col gap-4">
						<Disclosure heading="Who approves my travel requests" className="p-2 border rounded-md">
							<div className="mt-2 text-label">
								Reprehenderit labore eiusmod aliquip laborum incididunt nostrud et voluptate minim ipsum aliquip sit. Et incididunt dolore duis elit deserunt mollit consequat. Enim pariatur irure velit et nisi incididunt elit consequat culpa est elit culpa.
							</div>
						</Disclosure>
						<Disclosure heading="Can i change my booking after approval" className="p-2 border rounded-md">
							<div className="mt-2 text-label">
								Reprehenderit labore eiusmod aliquip laborum incididunt nostrud et voluptate minim ipsum aliquip sit. Et incididunt dolore duis elit deserunt mollit consequat. Enim pariatur irure velit et nisi incididunt elit consequat culpa est elit culpa.
							</div>
						</Disclosure>
					</div>
				</div>
			</div>
			<Policy className="mt-12" />
		</div>
	);
}

export default Support;
