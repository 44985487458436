import React from "react";
import { FaShieldAlt } from "react-icons/fa";

function HasApproval({ approval }) {
	if (approval) {
		const { amount } = approval;
		return (
			<div className="bg-compliment rounded-md mt-4 p-2 fade-in-down">
				<div className="flex-center-between text-accent px-2">
					<h5 className="align-center gap-2">
						<FaShieldAlt /> You have an approval
					</h5>
					<div className="text-xs">You can book any flight upto ₹ {amount}</div>
				</div>
			</div>
		);
	}

	return null;
}

export default HasApproval;
