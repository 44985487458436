import React from "react";
import StatusIndicator from "./status";
import { Button, CopyValue } from "../../..";
import { useNavigate } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

function StayBookingStatus({ booking = {} }) {
	const NAVIGATE = useNavigate();
	const { id, status, checkIn, checkOut, locality, approvalRequest, adminRequest } = booking;

	const searchStay = () => {
		NAVIGATE(`/app/travel/stays/search/?city=${locality.localityName}&lat=${locality.localityLatitude}&lng=${locality.localityLongitude}&checkIn=${checkIn}&checkOut=${checkOut}&rooms=${JSON.stringify([{ adult: 1, child: 0 }])}&approvalId=${id}`);
	};

	return (
		<div className="p-2 border-t flex-center-between bg-whitish">
			{approvalRequest && status === "approved" ? (
				<Button onClick={searchStay} variant="primary" className="btn-xs">
					Book Now <FiArrowRight className="ml-1" />
				</Button>
			) : (
				<CopyValue className="text-xxs">
					{approvalRequest ? "STY/APP" : adminRequest ? "STY/REQ" : "STY"}-{id}
				</CopyValue>
			)}
			<StatusIndicator status={status} />
		</div>
	);
}

export default StayBookingStatus;
