import React from "react";
import { Badge } from "../../..";

function StatusIndicator({ status }) {
	switch (status.toLowerCase()) {
		//Admin Request
		case "fulfilled":
			return <Badge variant="success">FulFilled</Badge>;
		case "cancelled":
			return <Badge variant="danger">Cancelled</Badge>;
		case "requested":
			return <Badge variant="warning">Requested</Badge>;
		default:
			return null;
	}
}

export default StatusIndicator;
