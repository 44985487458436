import { combineReducers } from "redux";
import { authorization } from "./authorization";
import { preferences } from "./preferences";
import { configuration } from "./configuration";
import { adminPermissions } from "./adminPermissions";
import { clientSettings } from "./clientSettings";
import { userPolicy } from "./userPolicy";

const reducers = combineReducers({
	authorization,
	preferences,
	configuration,
	adminPermissions,
	userPolicy,
	clientSettings,
});
export default reducers;
