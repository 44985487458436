import React from "react";
import useFetch from "../../../../../Hooks/useFetch";
import apis from "../../../../../Utils/apis";
import { StampToDate } from "../../../../../Utils/utils";
import Flight from "./flight";
import Stay from "./stay";
import Cab from "./cab";
import moment from "moment";

function Timeline() {
	const { response } = useFetch(apis.getTravelTimeline, null, {});
	const bookingDates = Object.keys(response);

	return (
		<>
			<h3 className="mt-10">{moment().format("MMMM")} Timeline</h3>
			<div className="w-full mt-4 rounded-xl">
				<div className="flex items-end overflow-y-auto scrollbar-hidden">
					{bookingDates.length ? (
						bookingDates.map((bookingDate, i) => {
							return (
								<>
									{i > 0 && (
										<div className="p-4 align-center">
											<div className="w-2 h-2 rounded-full bg-dark" />
											<div className="flex-grow w-20 border-t-2 border-dashed border-dark" />
											<div className="w-2 h-2 rounded-full bg-dark" />
										</div>
									)}
									<div key={i} className="gap-2 pb-1 align-center flex-nowrap">
										<div className="p-2 px-3 text-xs font-semibold text-white rounded-full cursor-pointer bg-dark whitespace-nowrap">{StampToDate(Number(bookingDate))}</div>
										{response[bookingDate].map((booking, i) => {
											if (booking.type === "flight") {
												return <Flight key={i} {...booking} />;
											}
											if (booking.type === "stay") {
												return <Stay key={i} {...booking} />;
											}
											if (booking.type === "cab") {
												return <Cab key={i} {...booking} />;
											}
											if (booking.type === "train") {
												return <Stay key={i} {...booking} />;
											}
											return null;
										})}
									</div>
								</>
							);
						})
					) : (
						<>
							<div className="text-xs font-semibold bg-light border px-3 py-2 rounded-full mb-1.5">No travel in {moment().format("MMMM")}</div>
							<div className="align-center p-3 mb-1.5">
								<div className="w-2 h-2 rounded-full bg-dark" />
								<div className="flex-grow w-20 border-t-2 border-dashed border-dark" />
								<div className="w-2 h-2 rounded-full bg-dark" />
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}

export default Timeline;
