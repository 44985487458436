import React from "react";
import { Badge } from "../../..";

function StatusIndicator({ status = "" }) {
	switch (status.toLowerCase()) {
		//Approval
		case "confirming":
			return <Badge variant="warning">Confirming</Badge>;
		case "confirmed":
			return <Badge variant="success">Confirmed</Badge>;
		case "approved":
			return <Badge variant="success">Approved</Badge>;
		case "rejected":
			return <Badge variant="danger">Rejected</Badge>;
		case "pending":
			return <Badge variant="warning">Pending</Badge>;
		//Booking
		case "booked":
			return <Badge variant="success">Booked</Badge>;
		case "cancelled":
			return <Badge variant="danger">Cancelled</Badge>;
		case "refunded":
			return <Badge>Refunded</Badge>;
		case "failed":
			return <Badge variant="danger">Failed</Badge>;
		case "Processing":
			return <Badge variant="warning">Processing</Badge>;
		//Admin Request
		case "fulfilled":
			return <Badge variant="success">FulFilled</Badge>;
		case "denied":
			return <Badge variant="danger">Denied</Badge>;
		case "requested":
			return <Badge variant="warning">Requested</Badge>;
		default:
			return null;
	}
}

export default StatusIndicator;
