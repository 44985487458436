import React from "react";
import ProfileMenu from "../ProfileMenu";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { Logo } from "..";
import { useNavigate } from "react-router-dom";
import ProfileSwitch from "./profileSwitch";

function FullSidebar({ options = [], content }) {
	const NAVIGATE = useNavigate();
	const pathname = window.location.pathname;

	return (
		<>
			<div className="sidebar">
				<div className="flex-1 p-4 overflow-y-auto scrollbar-autohide">
					<ProfileMenu />
					<ProfileSwitch />
					<div className="mt-4 menu">
						{options.map((option, index) => {
							if (typeof option === "string") {
								return (
									<div key={`label-${index}`} className="label">
										{option}
									</div>
								);
							} else {
								return (
									<div key={index}>
										<div className={`item flex justify-between ${pathname.startsWith(option.route) ? "active" : ""}`} onClick={option.route ? () => NAVIGATE(option.route) : option.onClick}>
											<span className="align-center gap-x-4">
												{option.icon}
												{option.label}{" "}
											</span>
											<span>{option.children && <FiChevronDown />}</span>
										</div>
										{option.children?.length > 0 && (
											<div key={`sub-${index}`} className={`sub-items ${pathname.startsWith(option.route) ? "active" : ""}`}>
												{option.children.map((child, i) => {
													return (
														<div key={`child-${i}`} className={`sub-item gap-x-4 ${pathname.startsWith(child.route) ? "active" : ""}`} onClick={child.route ? () => NAVIGATE(child.route) : child.onClick}>
															<div className="flex">
																<FiChevronRight className="mr-4 arrow" />
																{child.icon}
															</div>
															{child.label}
														</div>
													);
												})}
											</div>
										)}
									</div>
								);
							}
						})}
					</div>
				</div>
				<div className="p-2">
					{content}
					<div className="flex justify-center">
						<Logo className="items-center font-bold text-secondary" short={true} dotSize="3" dot="ml-2" />
					</div>
				</div>
			</div>
		</>
	);
}

export default FullSidebar;
