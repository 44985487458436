import React, { useEffect } from "react";
import { Chips, Spinner, Toast } from "../..";
import { useState } from "react";
import { apis, get } from "../../../Utils/axios";
import { useUserConfig } from "../../../Hooks/useUserConfig";
import { useClientSettings } from "../../../Hooks/useClientSettings";
import { TbMoneybag } from "react-icons/tb";

function CostCenterSearch({ onSelect = () => {}, placeholder = "Search Cost Center", label = "Select Cost Center", selected = {}, className = "" }) {
	const { isEnabled } = useClientSettings();
	const { checkUserPermission } = useUserConfig();
	const isTA = checkUserPermission("TA");

	const [AllCostCenters, setAllCostCenters] = useState([]);
	const [Loader, setLoader] = useState();

	useEffect(() => {
		fetchCostCenters();
	}, []);

	const fetchCostCenters = (query) => {
		setLoader(true);
		//Use admin api only for admin
		get(isTA ? apis.searchCostCenters : apis.searchMyCostCenters, {}, (r, e) => {
			if (r) {
				if (r.costCenters.length === 0) {
					onSelect({});
				}
				setAllCostCenters(r.costCenters);
				setLoader(false);
			} else if (e) {
				console.log(e);
				Toast.handleError(e);
				setLoader(false);
			}
		});
	};

	if (Loader) {
		return (
			<div className="p-4 flex-center">
				<Spinner />
			</div>
		);
	}

	if (isEnabled("COST_CENTER") && AllCostCenters.length) {
		return (
			<div className={`${className}`}>
				<h4 className="gap-1 align-center">
					<TbMoneybag />
					{label}
				</h4>
				<div className="text-label">Select the cost center which reflects the nature of this booking or you can confirm with your manager</div>
				<Chips className="mt-4" onSelect={onSelect} selected={selected} options={AllCostCenters.map((costCenter) => ({ label: costCenter.name, value: costCenter.id }))} />
			</div>
		);
	}

	return null;
}

export default CostCenterSearch;
