import React from "react";
import { Combobox, Toast } from "../..";
import { useState } from "react";
import AddOffice from "../../Admin/Add/office";
import { apis, get } from "../../../Utils/axios";

function OfficeSearch({ onSelect = () => {}, placeholder = "Search Offices", label, selected = {}, className = "" }) {
	const [AllOffices, setAllOffices] = useState([]);
	const [Loader, setLoader] = useState();

	const onChange = (query) => {
		if (query) {
			fetchOffices(query);
		} else {
			setAllOffices([]);
		}
	};

	const fetchOffices = (query) => {
		setLoader(true);
		get(apis.getAllOffices, { search: query, page: 1 }, (r, e) => {
			if (r) {
				setAllOffices(r.offices);
				setLoader(false);
			} else if (e) {
				console.log(e);
				Toast.handleError(e);
				setLoader(false);
			}
		});
	};

	return (
		<div className="flex items-end w-full gap-2">
			<Combobox
				loading={Loader}
				label={label}
				placeholder={placeholder}
				className={className}
				onChange={onChange}
				options={AllOffices.map((office) => ({
					...office,
					label: office.name,
					value: office.id,
				}))}
				selected={selected}
				onSelect={onSelect}
			/>
			<AddOffice btnLabel="+ New" />
		</div>
	);
}

export default OfficeSearch;
