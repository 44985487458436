import React, { useRef, useState } from "react";
import { Clock, Logo } from "..";
import { IsDev } from "../../Utils/utils";

function LockScreen() {
	const Dev = IsDev();
	const REF = useRef();
	const [Show, setShow] = useState();

	window.onblur = function () {
		if (!Dev) {
			REF.current = setTimeout(() => {
				setShow(true);
			}, 30000);
		}
	};

	window.onfocus = function () {
		REF.current && clearTimeout(REF.current);
		setShow(false);
	};

	if (Show)
		return (
			<>
				<div className="absolute top-0 left-0 z-20 w-full h-full text-white bg-black bg-opacity-80 backdrop-blur-md flex-center fade-in">
					<div className="w-full max-w-md text-center">
						<Logo className="justify-center" />
						<h1 className="mt-5 text-2xl font-thin">
							<Clock />
						</h1>
					</div>
				</div>
			</>
		);

	return null;
}

export default LockScreen;
