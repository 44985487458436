import React, { useEffect, useState } from "react";
import { Select, Input, Toast } from "..";
import { apis, get } from "../../Utils/axios";
import { useClientSettings } from "../../Hooks/useClientSettings";

function CustomFields({ activity, categoryId, getCustomFields, className = "" }) {
	const { isEnabled } = useClientSettings();
	const [Fields, setFields] = useState([]);
	const [Values, setValues] = useState({});

	useEffect(() => {
		if (activity && isEnabled("CUSTOM_FIELD")) {
			fetchFields(activity, categoryId);
		}
	}, [activity, categoryId]);

	const fetchFields = (activity, categoryId) => {
		get(apis.getCustomFields, { activity, categoryId }, (r, e) => {
			if (r) {
				setFields(r.fields);
			} else if (e) {
				Toast.handleError(e);
			}
		});
	};

	useEffect(() => {
		if (getCustomFields) {
			getCustomFields.current = () => {
				let values = {};
				for (const field of Fields) {
					const fieldValue = Values[field.name];
					if (!field.optional && !fieldValue) {
						Toast.error(`Please enter "${field.placeholder}"`);
						//making values undefined to show error
						values = undefined;
						break;
					}
					values[field.name] = fieldValue;
				}
				//Check if returned values was undefined to check for error
				return values;
			};
		}
	}, [getCustomFields, Values, Fields]);

	const updateValues = (name, value) => {
		setValues((prev) => ({ ...prev, [name]: value }));
	};

	if (Fields.length)
		return (
			<div className={`flex flex-col gap-4 ${className}`}>
				{Fields.map((field, i) => {
					const { type, name, placeholder, options, optional } = field;
					if (type === "text" || type === "number") {
						return <Input required={!optional} value={Values[name]} onChange={(value) => updateValues(name, value)} type={type} key={i} label={placeholder} placeholder={placeholder} />;
					}
					if (type === "select") {
						return <Select selected={Values[name]} onChange={(value) => updateValues(name, value)} btnClass="w-full" options={options?.split(",").map((option) => ({ label: option?.trim(), value: option?.trim() }))} key={i} label={placeholder} placeholder={placeholder} />;
					}
					return null;
				})}
			</div>
		);

	return null;
}

export default CustomFields;
