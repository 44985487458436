import React, { useEffect, useState } from "react";
import { Button, DatePicker, DateRange, Select, Toast } from "../../../../Components";
import { FaPlaneArrival, FaPlaneDeparture } from "react-icons/fa";
import { TbArrowsExchange } from "react-icons/tb";
import AirportSearch from "../../Inputs/AirportSearch";
import { useNavigate } from "react-router-dom";
import { useUserConfig } from "../../../../Hooks/useUserConfig";
import SelectPassengers from "../../Inputs/SelectPassengers";
import moment from "moment";

function FlightSearch({ hideModes, from, to, date, range, passengers, cabinClass, mode }) {
	const NAVIGATE = useNavigate();
	const { role, checkUserPermission } = useUserConfig();
	const isUser = role === "USER";

	const MODES = [
		{ label: "Search One Way", value: "oneway" },
		{ label: "Search Round Flights", value: "round" },
	];

	const CLASSES = [
		{ label: "Economy", value: "1" },
		{ label: "Premium Economy", value: "2" },
		{ label: "Business", value: "3" },
		{ label: "First Class", value: "4" },
	];

	const [Values, setValues] = useState({
		from: from || {},
		to: to || {},
		date: date || moment().valueOf(),
		range: range,
		passengers: passengers || {
			adult: 1,
			child: 0,
			infant: 0,
		},
		cabinClass: cabinClass || CLASSES[0],
		mode: mode || MODES[0],
	});

	console.log(Values);

	useEffect(() => {
		//Quick Selection
		if (from) {
			setValues((prev) => ({ ...prev, from }));
		}
		if (to) {
			setValues((prev) => ({ ...prev, to }));
		}
	}, [from, to]);

	const updateValues = (obj) => {
		setValues((prev) => ({ ...prev, ...obj }));
	};

	const switchMode = (option) => {
		if (option.value === "oneway") {
			updateValues({ mode: MODES[0] });
		} else {
			updateValues({ mode: MODES[1] });
		}
	};

	const swapAirports = () => {
		const temp = Values.from;
		updateValues({ from: Values.to, to: temp });
	};

	const searchFlights = () => {
		if (!Values.from?.airportCode) {
			return Toast.error("Please select From airport");
		}
		if (!Values.to?.airportCode) {
			return Toast.error("Please select Tp airport");
		}
		if (Values.mode?.value === "oneway" && !Values.date) {
			return Toast.error("Please select date");
		}
		if (Values.mode?.value === "round" && (!Values.range?.startDate || !Values.range?.endDate)) {
			return Toast.error("Please select travel dates");
		}
		if (Values.mode.value === MODES[0].value) {
			NAVIGATE(
				`/app/travel/flights/oneway?fromCity=${Values.from?.cityName}&from=${Values.from?.airportName}&fromCode=${Values.from?.airportCode}&toCity=${Values.to?.cityName}&to=${Values.to?.airportName}&toCode=${Values.to?.airportCode}&date=${Values.date}&adult=${Values.passengers?.adult}&child=${Values.passengers?.child}&infant=${Values.passengers?.infant}&cabinClass=${Values.cabinClass?.value}`
			);
		} else {
			NAVIGATE(
				`/app/travel/flights/round?fromCity=${Values.from?.cityName}&from=${Values.from?.airportName}&fromCode=${Values.from?.airportCode}&toCity=${Values.to?.cityName}&to=${Values.to?.airportName}&toCode=${Values.to?.airportCode}&date=${Values.range.startDate}&returnDate=${Values.range.endDate}&adult=${Values.passengers?.adult}&child=${Values.passengers?.child}&infant=${Values.passengers?.infant}&cabinClass=${Values.cabinClass?.value}`
			);
		}
	};

	return (
		<div>
			{!hideModes && (
				<div className="mb-4 flex-center-between">
					<div className="flex gap-2 max-w-min">
						<Select variant="outlined" btnClass="btn-xs" onChange={switchMode} selected={Values.mode} options={MODES} />
						<Select variant="outlined" btnClass="btn-xs" onChange={(cabinClass) => updateValues({ cabinClass })} selected={Values.cabinClass} options={CLASSES} />
					</div>
				</div>
			)}
			<div className="flex p-4 rounded-md bg-light gap-x-2">
				<div className="flex-grow">
					<AirportSearch selected={Values.from} label="Travelling from" onSelect={(from) => updateValues({ from })} placeholder="Travelling from" icon={<FaPlaneDeparture />} />
				</div>
				{isUser && (
					<div className="p-2 text-2xl rounded-md cursor-pointer flex-center bg-light mt-7">
						<TbArrowsExchange onClick={swapAirports} className="transition-all duration-200 hover:rotate-180" />
					</div>
				)}
				<div className="flex-grow">
					<AirportSearch selected={Values.to} label="Going to" onSelect={(to) => updateValues({ to })} placeholder="Going to" icon={<FaPlaneArrival />} />
				</div>
				{checkUserPermission("TA") && (
					<div className="flex-grow">
						<SelectPassengers
							passengers={Values.passengers}
							onChange={(obj) =>
								updateValues({
									passengers: {
										...Values.passengers,
										...obj,
									},
								})
							}
							label="Passengers"
						/>
					</div>
				)}
				<div className="flex-grow">{Values?.mode?.value === "oneway" ? <DatePicker label="Travel Date" setDate={(date) => updateValues({ date })} date={Values.date} /> : <DateRange label="Travel Dates" setRange={(range) => updateValues({ range })} range={Values.range} />}</div>
				<div className="flex-grow">
					<Button onClick={searchFlights} variant="primary" className="w-full mt-7">
						Find
					</Button>
				</div>
			</div>
		</div>
	);
}

export default FlightSearch;
