import React from "react";
import { StampTo24hr, StampToDate } from "../../../../Utils/utils";
import { FaCar, FaRegClock } from "react-icons/fa";
import BookingStatus from "../Components/cabBookingStatus";
import ViewBooking from "../ViewBooking";
import { useState } from "react";
import { Badge } from "../../..";
import ViewRequest from "../../Request/ViewRequest";

function CabBooking({ id, pickupTime, amount, pickup, drop, variant, type, bookingId, booking, status, adminRequest, onReload = () => {} }) {
	const [BookingId, setBookingId] = useState();
	const [RequestId, setRequestId] = useState();

	const openBooking = () => {
		if (adminRequest) {
			setRequestId(id);
		} else {
			setBookingId(id);
		}
	};

	return (
		<>
			<div onClick={openBooking} className="overflow-hidden border shadow-sm cursor-pointer rounded-xl bg-canvas min-w-min hover:bg-light">
				<div className="p-4">
					<div className="flex-center-between">
						<h4 className="gap-2 capitalize align-center">
							<FaCar />
							{type} Cab
						</h4>
						<div className="text-xs">{StampToDate(pickupTime)}</div>
					</div>
					<div className="mt-3 flex-center-between cent">
						<div className="gap-2 align-center">
							<FaRegClock /> {StampTo24hr(pickupTime)}
						</div>
						<Badge>{drop.split(",")[0]}</Badge>
					</div>
				</div>
				{status && <BookingStatus booking={{ status, id, adminRequest }} />}
			</div>
			<ViewBooking id={BookingId} type="cab" onClose={() => setBookingId()} onReload={onReload} />
			<ViewRequest requestId={RequestId} onClose={() => setRequestId()} onReload={onReload} />
		</>
	);
}

export default CabBooking;
