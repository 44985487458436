import React from 'react';
import { StampTo24hr } from '../../../../../../../Utils/utils';
import { FaBus, FaPlane } from "react-icons/fa";
import BookingStatus from './Components/bookingStatus';

function BusBooking({operator, rating, bus, from, to, departure, arrival, amount, date, mode, type, onSelect=()=>{}, status, id, className=''}) {
    return ( 
        <div className='rounded-xl bg-canvas shadow-sm border min-w-min cursor-pointer hover:shadow-md overflow-hidden'>
            <div className='p-4'>
                <div className='flex-center-between'>
                    <h4>{operator.name}</h4>
                    <div className='text-xs font-bold'>₹ {amount}</div>
                </div>
                <div className='flex mt-3 gap-x-4'>
                    <div>
                        <div className='font-bold whitespace-nowrap'>{StampTo24hr(departure.time)}</div>
                        <div className='text-secondary text-xs'>{departure.point}</div>
                    </div>
                    <div className='flex-grow flex-center w-24'>
                        <div className='w-full'>
                            <div className='align-center'>
                                <div className='w-2 h-2 rounded-full border border-dark'/>
                                <div className='flex-grow border-t border-dark border-dotted'/>
                                <FaBus/>
                                <div className='flex-grow border-t border-dark border-dotted'/>
                                <div className='w-2 h-2 rounded-full border border-dark'/>
                            </div>
                            <div className='text-center mt-2 text-xs whitespace-nowrap'>2hr 30min</div>
                        </div>
                    </div>
                    <div>
                        <div className='font-bold whitespace-nowrap'>{StampTo24hr(arrival.time)}</div>
                        <div className='text-secondary text-xs'>{arrival.point}</div>
                    </div>
                </div>
            </div>
            {
                status && <BookingStatus status={status} id={id}/>
            }
        </div>
     );
}

export default BusBooking;