const PREF_ACTIONS = {
	sidebar: "SIDE_BAR",
	sidePane: "SIDE_PANE",
	fontSize: "FONT_SIZE",
	reset: "RESET",
};

const previous = JSON.parse(localStorage.getItem("preferences") || "{}");

let initialState = {
	sidePane: true,
	sidebar: true,
	fontSize: "medium",
	...previous,
};

const updateLocalStorage = (updatedState) => {
	localStorage.setItem("preferences", JSON.stringify(updatedState));
};

const preferences = (state = initialState, { data, type }) => {
	if (type === PREF_ACTIONS.sidebar) {
		const updatedState = {
			...state,
			sidebar: data.sidebar,
		};
		updateLocalStorage(updatedState);
		return updatedState;
	} else if (type === PREF_ACTIONS.sidePane) {
		const updatedState = {
			...state,
			sidePane: data.sidePane,
		};
		updateLocalStorage(updatedState);
		return updatedState;
	} else if (type === PREF_ACTIONS.fontSize) {
		const updatedState = {
			...state,
			fontSize: data.fontSize,
		};
		updateLocalStorage(updatedState);
		return updatedState;
	} else if (type === PREF_ACTIONS.reset) {
		updateLocalStorage(initialState);
		return initialState;
	} else {
		return state;
	}
};

export { PREF_ACTIONS, preferences };
