import React, { useRef, useState } from "react";
import { FiMapPin } from "react-icons/fi";
import { AnimatedTick, Button, Checkbox, Chips, CustomFields, DatePicker, Form, TimePicker, Toast, PlaceSearch } from "../../../../../Components";
import { FaArrowRight } from "react-icons/fa";
import { apis, post } from "../../../../../Utils/axios";
import { mergeDateAndTime } from "../../../../../Utils/utils";

function CabHome() {
	const initial = {
		pickup: {},
		drop: {},
		date: "",
		time: "",
		variant: {
			value: "hatchback",
			label: "Hatchback",
		},
		notes: "",
		forAirport: false,
	};
	const [Values, setValues] = useState(initial);
	const [Success, setSuccess] = useState(false);
	const GET_CUSTOM_FIELDS = useRef();

	const updateValues = (obj) => {
		setValues((prev) => ({ ...prev, ...obj }));
	};

	const onSubmit = () => {
		if (!Values.pickup?.value) {
			return Toast.error("Please select pickup location");
		}
		if (!Values.drop?.value) {
			return Toast.error("Please select drop location");
		}
		const customFields = GET_CUSTOM_FIELDS.current();
		if (!customFields) {
			return;
		}
		post(
			apis.requestCab,
			{
				pickup: Values.pickup.value,
				drop: Values.drop.value,
				pickupTime: mergeDateAndTime(Values.date, Values.time),
				variant: Values.variant.value,
				notes: Values.notes,
				forAirport: Values.forAirport,
				customFields,
			},
			(r, e) => {
				if (r) {
					Toast.success("Request submitted");
					setValues(initial);
					setSuccess(true);
				} else if (e) {
					Toast.handleError(e);
				}
			}
		);
	};

	if (Success) {
		return (
			<div className="w-full mt-20 flex-center">
				<div className="bg-light rounded-xl p-8">
					<div className="h-20 flex-center">
						<AnimatedTick />
					</div>
					<div className="text-center mt-4">
						<h1>Cab Requested</h1>
						<div className="text-label mt-4">
							Your request for cab booking has been sent to travel head, <br />
							you can check your cab booking for status
						</div>
						<Button onClick={() => setSuccess(false)} className="mx-auto mt-6">
							Request Another
						</Button>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="p-4 pt-2">
			<div className="max-w-lg">
				<h3>Request a Cab</h3>
				<div className="text-label">Your request will be forwarded to travel heads for booking</div>
				<Form onSubmit={onSubmit} className="border rounded-lg p-4 mt-4">
					<div>
						<h4>Location</h4>
						<div className="text-label">Where you want the pickup and drop</div>
						<div className="grid grid-cols-2 mt-4 gap-2">
							<PlaceSearch label="Pickup" placeholder="Pickup Location" icon={<FiMapPin />} selected={Values.pickup} onSelect={(pickup) => updateValues({ pickup })} />
							<PlaceSearch label="Drop" placeholder="Drop Location" icon={<FiMapPin />} selected={Values.drop} onSelect={(drop) => updateValues({ drop })} />
						</div>
					</div>
					<div className="mt-4">
						<h4>Pickup Timings</h4>
						<div className="text-label">When should the cab reach the pickup location</div>
						<div className="grid grid-cols-2 mt-4 gap-2">
							<DatePicker label="Date" date={Values.date} setDate={(date) => updateValues({ date })} />
							<TimePicker label="Time" required value={Values.time} onChange={(time) => updateValues({ time })} />
						</div>
					</div>
					<div className="mt-4">
						<h4>Cab Type</h4>
						<div className="text-label">Select your preference for cab type</div>
						<Chips
							selected={Values.variant}
							onSelect={(variant) => updateValues({ variant })}
							className="mt-4"
							options={[
								{
									label: "Hatchback",
									value: "hatchback",
								},
								{
									label: "Sedan",
									value: "sedan",
								},
								{
									label: "SUV",
									value: "suv",
								},
							]}
						/>
					</div>
					<CustomFields className="mt-4" getCustomFields={GET_CUSTOM_FIELDS} activity="CAB_BOOK" />
					<div className="flex flex-col gap-2 mt-4">
						<label>Notes (Optional)</label>
						<textarea onChange={(e) => updateValues({ notes: e.target.value })} className="input text-sm !ring-1 !ring-inset !ring-muted !bg-canvas shadow-sm rounded-md" placeholder="Notes for the cab driver" />
					</div>
					<Checkbox className="mt-4" label="I am taking this cab to or from the airport" checked={Values.forAirport} onChange={() => updateValues({ forAirport: !Values.forAirport })} />
					<Button type="submit" className="mt-4" variant="primary">
						Submit Request <FaArrowRight className="ml-2" />
					</Button>
				</Form>
			</div>
		</div>
	);
}

export default CabHome;
