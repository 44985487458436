import React, { useState } from "react";
import { Form, Input, Button, UploadPhoto, DatePicker, Toast, Logo, ButtonGroup } from "../../../../Components";
import moment from "moment";
import { FiArrowRight } from "react-icons/fi";
import { apis, post } from "../../../../Utils/axios";
import HappyDog from "../../../../Assets/Images/happy_dog.jpg";
import { validateIndianPhoneNumber } from "../../../../Utils/utils";
import { useLoginHook } from "../../../../Hooks/useLoginHook";

function Signup() {
	const Params = new URLSearchParams(window.location.search);
	const code = Params.get("code");

	const { logout } = useLoginHook();
	const [SetupDone, setSetupDone] = useState();
	const [Loader, setLoader] = useState();
	const [Values, setValues] = useState({
		fName: null,
		lName: null,
		gender: {},
		photo: null,
		phone: "",
		dob: moment().subtract(18, "years").valueOf(),
	});

	const onSubmit = () => {
		if (!Values.gender?.value) {
			return Toast.error("Please select gender");
		}
		if (!validateIndianPhoneNumber(Values.phone)) {
			return Toast.error("Please enter a valid phone number");
		}
		if (Values.password !== Values.confirm) {
			Toast.error("Passwords do not match");
			return;
		}
		setLoader(true);
		post(
			apis.onboardNewUser,
			{
				...Values,
				gender: Values.gender?.value,
				code,
			},
			(r, e) => {
				if (r) {
					setSetupDone(true);
					setLoader();
				} else if (e) {
					Toast.handleError(e);
					setLoader();
				}
			}
		);
	};

	if (SetupDone) {
		return (
			<div className="h-full w-full align-center justify-center">
				<div className="w-full max-w-md text-center p-4">
					<img src={HappyDog} alt="Happy dog" className="max-w-xs rounded-full m-auto mb-8" />
					<Logo className="justify-center" />
					<div className="text-5xl font-bold mt-6">Account Ready</div>
					<div className="text-sm mt-3">Your password has been successfully activated, you can now sign in</div>
					<Button onClick={logout} className="mt-8 m-auto">
						Sign in to your account
					</Button>
				</div>
			</div>
		);
	}

	return (
		<>
			<div className="h-full w-full align-center justify-center">
				<div className="w-full max-w-sm text-center p-4">
					<div className="text-5xl font-bold mt-6">We are thrilled</div>
					<div className="text-sm mt-3">Please enter your profile details to setup your account</div>

					<div className="mt-10 flex flex-col text-start">
						<Form onSubmit={onSubmit} loading={Loader}>
							<UploadPhoto className="m-auto mb-8" photo={Values.photo} onUpdate={(obj) => setValues((prev) => ({ ...prev, photo: obj.file.url }))} />
							<div className="grid lg:grid-cols-2 gap-4 mb-4">
								<Input required value={Values.fName} onChange={(fName) => setValues((prev) => ({ ...prev, fName }))} label="First Name" placeholder="First Name" />
								<Input required value={Values.lName} onChange={(lName) => setValues((prev) => ({ ...prev, lName }))} label="Last Name" placeholder="Last Name" />
							</div>
							<div className="grid lg:grid-cols-2 gap-4 mb-4">
								<Input type="password" value={Values.password} onChange={(password) => setValues((prev) => ({ ...prev, password }))} required label="New Password" placeholder="Password" />
								<Input type="password" value={Values.confirm} onChange={(confirm) => setValues((prev) => ({ ...prev, confirm }))} required label="Confirm New Password" placeholder="Confirm Password" />
							</div>
							<div className="grid lg:grid-cols-2 gap-4 mb-4">
								<div>
									<label className="mb-2 block">Gender</label>
									<ButtonGroup
										selected={Values.gender}
										onClick={(gender) => setValues((prev) => ({ ...prev, gender }))}
										options={[
											{
												label: "Male",
												value: "MALE",
											},
											{
												label: "Female",
												value: "FEMALE",
											},
										]}
									/>
								</div>
								<DatePicker backDateSelection={true} maxDate={moment().subtract(18, "years").valueOf()} date={Values.dob} setDate={(dob) => setValues((prev) => ({ ...prev, dob }))} label="Date of Birth" />
							</div>
							<Input required value={Values.phone} onChange={(phone) => setValues((prev) => ({ ...prev, phone }))} label="Phone" placeholder="Phone Number" />
							<Button variant="primary" loading={Loader} type="submit" className="mt-8 w-full">
								Go to your Account <FiArrowRight className="ml-2" />
							</Button>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
}

export default Signup;
