import React, { useState } from "react";
import { Attachments, Button, Dialog, KeyValue, Violations, Toast } from "../..";
import StatusIndicator from "../Components/status";
import { StampToDate } from "../../../Utils/utils";
import NextAction from "./nextAction";

function ReceiptDetails({ receipt }) {
	const { id, date, amount, category, status, description, violations, approvers } = receipt;
	const [ConfirmDialog, setConfirmDialog] = useState();

	const withdraw = () => {
		setConfirmDialog();
		Toast.success(`Request Withdrawn`);
	};

	const canWithdraw = status === "approval";

	return (
		<>
			<div className="p-4 rounded-t-lg shadow-sm bg-canvas">
				<h1>Expense</h1>
				<div className="mt-6">
					<h4>Receipt Details</h4>
					<div className="flex flex-col mt-4 gap-y-2">
						<Violations className="mb-2" violations={violations} />
						<KeyValue label="Receipt ID">{id}</KeyValue>
						<KeyValue label="Date">{StampToDate(date)}</KeyValue>
						<KeyValue label="Amount">₹ {amount}</KeyValue>
						<KeyValue label="Category">{category?.name}</KeyValue>
						<KeyValue label="Purpose">{description}</KeyValue>
						<KeyValue label="Status">
							<StatusIndicator status={status} />
						</KeyValue>
					</div>
					{receipt.attachments?.length > 0 && (
						<>
							<h4 className="mt-4">Attachments</h4>
							<Attachments className="mt-4" files={receipt.attachments} />
						</>
					)}
				</div>
				{/* <div className="mt-6">
					<h4>Approvers</h4>
					<Approvers approvers={approvers} />
				</div> */}
			</div>
			<div className="rotate-180 zigzag" />
			<NextAction status={status} />
			{/* {canWithdraw && (
				<Button onClick={() => setConfirmDialog("withdraw")} variant="danger" className="w-full mt-4">
					Withdraw Reimbursement
				</Button>
			)} */}
			<Dialog variant="danger" open={ConfirmDialog === "withdraw"} title="Withdraw Reimbursement" description="Are you sure you want to withdraw this reimbursement request" submitLabel="Withdraw" onSubmit={withdraw} onClose={setConfirmDialog} />
		</>
	);
}

export default ReceiptDetails;
