import React from "react";
import { Img } from "..";
import moment from "moment";
import TYPES from "./types";
import { useNavigate } from "react-router-dom";

function Notification({ type, createdAt, unread, notification, onHover = () => {} }) {
	const NAVIGATE = useNavigate();
	let icon, title, description, onClick;

	if (TYPES[type]) {
		icon = TYPES[type].icon(notification);
		title = TYPES[type].title(notification);
		description = TYPES[type].description(notification);
		onClick = TYPES[type].onClick;
	}

	const notificationAction = () => {
		if (onClick) {
			const url = onClick(notification);
			url && NAVIGATE(url);
		}
	};

	return (
		<div onClick={notificationAction} onMouseEnter={unread ? onHover : null} className={`fade-in-right notification ${unread ? "unread" : ""} ${onClick ? "cursor-pointer" : ""}`}>
			{icon ? <div className="rounded-full mr-2 min-w-[2rem] w-8 h-8 flex-center bg-compliment">{icon}</div> : <Img src={icon} alt="avatar" className="w-8 h-8 mr-2 rounded-full pixelated" />}
			<div>
				<div className="text-sm break-words">{title}</div>
				<div className="break-words description">{description}</div>
				<div className="text-xs text-secondary">{moment(createdAt).format("hh:mm a DD MMM")}</div>
			</div>
			<div className="options"></div>
		</div>
	);
}

export default Notification;
