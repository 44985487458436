import { BsAirplane, BsBusFront, BsCarFront, BsChat, BsDoorClosed, BsEnvelope, BsInfoLg, BsLaptop, BsShield, BsShieldCheck, BsShieldX, BsTrainFront } from "react-icons/bs";

const TYPES = {
	UPGRADED_TO_ADMIN: {
		title: () => "You are now an admin",
		description: () => "You account has been upgraded to admin privileges by your manager.",
		icon: () => <BsInfoLg />,
	},
	POLICY_CHANGED: {
		title: () => "Your Policy was changed",
		description: () => "You policy was changed by your manager.",
		icon: () => <BsInfoLg />,
	},
	UPGRADED_TO_TA: {
		title: () => "You are now a Travel head",
		description: () => "You account has been upgraded to Travel head privileges by your manager.",
		icon: () => <BsInfoLg />,
	},
	TEAM_CHANGED: {
		title: () => "Moved to another team",
		description: ({ team }) => `You have been moved to ${team} team by your manager.`,
		icon: () => <BsInfoLg />,
	},
	INVITATION_ACCEPTED: {
		title: ({ name }) => `${name} accepted your invitation`,
		description: ({ name }) => `${name} has accepted your invitation to join the team.`,
		icon: () => <BsEnvelope />,
	},
	NEW_MESSAGE: {
		title: ({ from }) => `New message from ${from}`,
		description: ({ message, from }) => {
			if (message.startsWith("{")) {
				//JSON
				return "Open chat to view";
			}
			if (message?.startsWith("http")) {
				return <img src={message} className="rounded-lg w-14" alt="attachment" />;
			}
			return `${from}: ${message}`;
		},
		icon: () => <BsChat />,
		onClick: ({ userId }) => `/app/chat?userId=${userId}`,
	},
	NEW_APPROVAL: {
		title: ({ from, approvalType }) => `${from} requested a ${approvalType} approval`,
		description: ({ from, approvalType, amount }) => `${from} has requested approval for ${approvalType} of amount Rs. ${amount}.`,
		icon: () => <BsShield />,
	},
	APPROVAL_REJECTED: {
		title: ({ requestType }) => `Your ${requestType} approval has been rejected`,
		description: ({ requestType }) => `Your ${requestType} approval request has been rejected.`,
		icon: () => <BsShieldX />,
	},
	APPROVAL_GRANTED: {
		title: ({ requestType }) => `Your ${requestType} approval has been granted`,
		description: ({ requestType }) => `Your ${requestType} approval request has been granted.`,
		icon: () => <BsShieldCheck />,
	},
	NEW_REQUEST: {
		title: ({ bookingType }) => `New ${bookingType} booking request`,
		description: ({ bookingType }) => `A user has requested a ${bookingType} booking.`,
		icon: ({ bookingType }) => {
			if (bookingType === "flight") return <BsAirplane />;
			else if (bookingType === "stay") return <BsDoorClosed />;
			else if (bookingType === "cab") return <BsCarFront />;
			else if (bookingType === "train") return <BsTrainFront />;
			else if (bookingType === "bus") return <BsBusFront />;
			else return <BsLaptop />;
		},
	},
	REQUEST_BOOKED: {
		title: ({ bookingType }) => `Travel head booked your ${bookingType}`,
		description: ({ bookingType }) => `Travel head has booked a ${bookingType} for you.`,
		icon: ({ bookingType }) => {
			if (bookingType === "flight") return <BsAirplane />;
			else if (bookingType === "stay") return <BsDoorClosed />;
			else if (bookingType === "cab") return <BsCarFront />;
			else if (bookingType === "train") return <BsTrainFront />;
			else if (bookingType === "bus") return <BsBusFront />;
			else return <BsLaptop />;
		},
	},
	REQUEST_DENIED: {
		title: ({ bookingType }) => `Travel head denied your ${bookingType} request`,
		description: ({ bookingType }) => `Travel head has denied your request for ${bookingType} booking.`,
		icon: ({ bookingType }) => {
			if (bookingType === "flight") return <BsAirplane />;
			else if (bookingType === "stay") return <BsDoorClosed />;
			else if (bookingType === "cab") return <BsCarFront />;
			else if (bookingType === "train") return <BsTrainFront />;
			else if (bookingType === "bus") return <BsBusFront />;
			else return <BsLaptop />;
		},
	},
};

export default TYPES;
