import React from "react";
import { Tabs } from "../../../../Components";
import { Tab } from "@headlessui/react";
import Guests from "./Guests";
import Users from "./Users";

function Travellers() {
	return (
		<>
			<div className="p-4">
				<Tabs options={["Users", "Guests"]}>
					<Tab.Panel>
						<Users />
					</Tab.Panel>
					<Tab.Panel>
						<Guests />
					</Tab.Panel>
				</Tabs>
			</div>
		</>
	);
}

export default Travellers;
