let initialState = {
	loading: true,
};

const adminPermissions = (state = initialState, { data, type }) => {
	if (type === "ADMIN_PERMISSIONS") {
		return {
			...state,
			...data,
			loading: false,
		};
	} else {
		return state;
	}
};

export { adminPermissions };
