import React from "react";
import { Tabs } from "../../../../Components";
import { Tab } from "@headlessui/react";
import Profile from "./profile";
// import Preferences from "./preferences";
import Security from "./Security";
import { useUserConfig } from "../../../../Hooks/useUserConfig";

function Settings() {
	const { role } = useUserConfig();
	const isUser = role === "USER";

	let options = ["Security"];

	if (isUser) {
		options = ["My Profile", "Security"];
	}

	return (
		<>
			<div className="p-4">
				<h2 className="mb-4">Your Account</h2>
				<Tabs options={options}>
					{isUser && (
						<Tab.Panel>
							<Profile />
						</Tab.Panel>
					)}
					<Tab.Panel>
						<Security />
					</Tab.Panel>
				</Tabs>
			</div>
		</>
	);
}

export default Settings;
