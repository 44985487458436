import React, { useState, useEffect } from "react";
import { Badge } from "../../../../../../../../Components";
import { apis, get } from "Utils/axios";

function FareRules({ refundable, fromCode, toCode, date, adult, child, infant, cabinClass, requestId, approvalId, flightOptionKey, flightSearchKey }) {
	const [Rules, setRules] = useState(null);

	useEffect(() => {
		getFareRules(fromCode, toCode, date, adult, child, infant, cabinClass, requestId, approvalId, flightOptionKey, flightSearchKey);
	}, [fromCode, toCode, date, adult, child, infant, cabinClass, requestId, approvalId, flightOptionKey, flightSearchKey]);

	const getFareRules = (fromCode, toCode, date, adult, child, infant, cabinClass, requestId, approvalId, flightOptionKey, flightSearchKey) => {
		get(apis.getFareRules, { from: fromCode?.toUpperCase(), to: toCode?.toUpperCase(), date, adult, child, infant, cabinClass, requestId, approvalId, flightOptionKey, flightSearchKey }, (r) => {
			setRules(r);
		});
	};

	return (
		<>
			<h6>Cancellation</h6>
			{refundable ? (
				<>
					<Badge className="mt-2" variant="success">
						Refundable
					</Badge>
					<div className="mt-2 flex-center-between text-label">
						<div>Cancellation Charges</div>
					</div>
					<div className="mt-2 flex-center-between text-label">
						<div>Amendment Charges</div>
					</div>
				</>
			) : (
				<>
					<Badge className="mt-2" variant="warning">
						Non-Refundable
					</Badge>
					<div className="mt-3 flex-center-between text-label">
						<div>This booking is not eligible for refund on cancellation</div>
					</div>
				</>
			)}
		</>
	);
}

export default FareRules;
