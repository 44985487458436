import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { apis, get } from "../../../../../../../../Utils/axios";
import FlightCard from "./flightCard";
import FareCard from "./fareCard";

function UserChoice() {
	const [searchParams] = useSearchParams();
	const requestId = searchParams.get("requestId") || "";

	const [UserChoice, setUserChoice] = useState();

	useEffect(() => {
		if (requestId) {
			get(apis.getRequestDetails, { requestId }, (r, e) => {
				if (r?.choices) {
					setUserChoice(r.choices[0]);
				}
			});
		}
	}, [requestId]);

	if (UserChoice) {
		const { onwardFlight, returnFlight, onwardFare, returnFare, type } = UserChoice;
		return (
			<div className="bg-accent rounded-lg mt-4 p-2 shadow-sm fade-in-down sticky top-0 z-10">
				<div className="flex-center-between mb-2 text-white px-2">
					<h4>User's Choice</h4>
					<div className="text-xs">Price of this flight may have changed since you received the request</div>
				</div>
				<div className="grid grid-cols-2 gap-x-2">
					<div className="bg-canvas rounded-md flex flex-col divide-y">
						<FlightCard segments={onwardFlight?.segments} duration={onwardFlight?.duration} />
						{/* <FareCard fare={onwardFare} /> */}
					</div>
					<div className="bg-canvas rounded-md flex flex-col divide-y">
						<FlightCard segments={returnFlight?.segments} duration={returnFlight?.duration} />
						{/* <FareCard fare={returnFare} /> */}
					</div>
				</div>
			</div>
		);
	}

	return null;
}

export default UserChoice;
