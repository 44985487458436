import React, { useState, useEffect } from "react";
import RequestDetails from "./request";
import { TbBed, TbBus, TbCar, TbMap, TbPlane, TbReceipt, TbTrain } from "react-icons/tb";
import ApprovalTypes from "./Types";
import { Button, CustomInputs, Modal, ReplyInChat } from "../..";
import { Dialog, Input, Loader, Toast } from "../..";
import { apis, get, post } from "../../../Utils/axios";
import OpenBooking from "./booking";

function ViewApproval({ approvalId, onReload = () => {}, onClose = () => {}, readOnly }) {
	const ICONS = {
		trip: <TbMap />,
		expense: <TbReceipt />,
		flight: <TbPlane />,
		bus: <TbBus />,
		stay: <TbBed />,
		train: <TbTrain />,
		cab: <TbCar />,
	};
	const [ConfirmDialog, setConfirmDialog] = useState();
	const [Approval, setApproval] = useState({
		request: {},
		approvers: [],
		type: "",
		status: "",
		amount: "",
		date: "",
		id: "",
		trail: "",
		violations: [],
		customInput: {},
		requestedBy: {},
	});
	const [Remarks, setRemarks] = useState("");
	const [Loading, setLoading] = useState(true);

	useEffect(() => {
		if (approvalId) {
			fetchApproval(approvalId);
		}
	}, [approvalId]);

	const fetchApproval = (approvalId) => {
		setLoading(true);
		get(apis.getApprovalDetails, { approvalId }, (r, e) => {
			if (r) {
				setApproval(r);
				setLoading(false);
			} else if (e) {
				Toast.handleError(e);
				setLoading(false);
			}
		});
	};

	const actionOnApproval = (action) => {
		if (action === "rejected" && !Remarks) {
			return Toast.error("Please enter remarks");
		}
		post(apis.actionOnApproval, { approvalId, action, remarks: Remarks }, (r, e) => {
			if (r) {
				setConfirmDialog();
				Toast.success(`Approval request ${action}`);
				fetchApproval(approvalId);
				setLoading(false);
				onReload();
			} else if (e) {
				Toast.handleError(e);
				setLoading(false);
			}
		});
	};

	const withdrawApproval = () => {
		post(apis.withdrawApproval, { approvalId }, (r, e) => {
			if (r) {
				setConfirmDialog();
				Toast.success(`Approval request withdrawn`);
				fetchApproval(approvalId);
				setLoading(false);
				onReload();
			} else if (e) {
				Toast.handleError(e);
				setLoading(false);
			}
		});
	};

	const replyInChat = () => {
		return {
			content: Approval,
			type: "approval",
			userId: Approval?.requestedBy?.id,
		};
	};

	return (
		<>
			<Modal contentClass="!max-w-5xl !bg-light" open={approvalId} onClose={onClose}>
				<div className="flex flex-col p-6 gap-y-4">
					<div className="flex justify-between">
						<h1 className="capitalize align-center gap-x-2">
							{ICONS[Approval?.type]}
							{Approval?.type} Approval
						</h1>
						{Approval.status === "pending" ? (
							readOnly ? (
								<div>
									<Button loading={Loading} onClick={() => setConfirmDialog("withdraw")} variant="danger" className="flex-grow">
										Withdraw
									</Button>
								</div>
							) : (
								<div className="flex gap-x-2">
									<Button loading={Loading} onClick={() => setConfirmDialog("approve")} variant="success" className="flex-grow">
										Approve
									</Button>
									<Button loading={Loading} onClick={() => setConfirmDialog("decline")} variant="danger" className="flex-grow">
										Decline
									</Button>
									<ReplyInChat onClick={replyInChat} />
								</div>
							)
						) : null}
					</div>
					<div className="grid grid-cols-5 mt-2 gap-x-4">
						<div className="flex flex-col col-span-2 overflow-y-auto gap-y-4">
							<RequestDetails {...Approval} />
							{Approval?.request?.notes && (
								<div className="p-4 rounded-lg shadow-sm bg-canvas">
									<label>Notes from user</label>
									<div className="text-label">{Approval?.request?.notes}</div>
								</div>
							)}
						</div>
						<div className="flex flex-col col-span-3 overflow-y-auto gap-y-4">
							<OpenBooking {...Approval} />
							<ApprovalTypes type={Approval?.type} request={Approval?.request?.data} />
							<CustomInputs className="p-4 rounded-md shadow-sm bg-canvas" fields={Approval.customInput?.fields} />
						</div>
					</div>
				</div>
				<Loader loading={Loading} />
				<Dialog variant="success" open={ConfirmDialog === "approve"} title="Approve Request" description="Are you sure you want to approve this request" submitLabel="Approve" onSubmit={() => actionOnApproval("approved")} onClose={setConfirmDialog} />
				<Dialog variant="danger" open={ConfirmDialog === "withdraw"} title="Withdraw Request" description="Are you sure you want to withdraw this request" submitLabel="Withdraw" onSubmit={withdrawApproval} onClose={setConfirmDialog} />
				<Dialog variant="danger" open={ConfirmDialog === "decline"} title="Decline Request" description="Are you sure you want to decline this request" submitLabel="Decline" onSubmit={() => actionOnApproval("rejected")} onClose={setConfirmDialog}>
					<div className="px-6">
						<Input onChange={(value) => setRemarks(value)} value={Remarks} placeholder="Reason for decline" required />
					</div>
				</Dialog>
			</Modal>
		</>
	);
}

export default ViewApproval;
