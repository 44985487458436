import React, { useState } from "react";
import { useUserConfig } from "../../../../Hooks/useUserConfig";
import { Button, Modal } from "../../..";
import EmployeeCard from "../../../Cards/EmployeeInfo";
import EmployeeSearch from "../../../SearchInputs/EmployeeSearch";
import { Toast } from "../../..";
import { apis, post } from "../../../../Utils/axios";

function BookedFor({ travellers = [], bookingId, type, onReload = () => {} }) {
	const { checkUserPermission } = useUserConfig();
	const [AddTravellers, setAddTravellers] = useState(false);
	const [SelectedUser, setSelectedUser] = useState({});

	const tagUser = () => {
		if (!SelectedUser.id) {
			return Toast.error("Select a user to add in this booking");
		}
		let url;
		if (type === "flight") {
			url = apis.addUserInFlightBooking;
		} else if (type === "stay") {
			url = apis.addUserInStayBooking;
		}
		post(url, { bookingId, userId: SelectedUser.id }, (r, e) => {
			if (r) {
				setAddTravellers(false);
				Toast.success("User added to booking");
				onReload();
			} else if (e) {
				Toast.handleError(e);
			}
		});
	};

	if (checkUserPermission("TA"))
		return (
			<>
				<div className="p-6 mt-4 rounded-lg shadow-md bg-canvas">
					<div className="flex-center-between">
						<h4 className="gap-x-2">Booked for</h4>
						<Button onClick={() => setAddTravellers(true)} variant="outlined" className="flex btn-xs">
							+ Add Travellers
						</Button>
					</div>
					<div className="flex flex-col gap-2 mt-4">
						{travellers?.map((traveller, i) => (
							<EmployeeCard key={i} employee={traveller} />
						))}
					</div>
				</div>
				<Modal open={AddTravellers} onClose={() => setAddTravellers(false)}>
					<div className="flex gap-2 p-2">
						<EmployeeSearch selected={SelectedUser} onSelect={setSelectedUser} searchOnly />
						<Button onClick={tagUser} className="btn-sm">
							Add to Booking
						</Button>
					</div>
				</Modal>
			</>
		);
	return null;
}

export default BookedFor;
