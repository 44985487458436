import React, { useState } from "react";
import { Button, Logo, Toast } from "../../../../Components";
import { apis, post } from "../../../../Utils/axios";
import Rainbow from "../../../../Assets/Images/intros/rainbow.png";
import Football from "../../../../Assets/Images/football.jpg";
import { useLoginHook } from "../../../../Hooks/useLoginHook";

function JoinTeam({ profile }) {
	const Params = new URLSearchParams(window.location.search);
	const code = Params.get("code");
	const by = Params.get("by");
	const { logout } = useLoginHook();

	const [Loader, setLoader] = useState();
	const [TeamJoined, setTeamJoined] = useState();

	const onSubmit = () => {
		setLoader(true);
		post(apis.joinTeam, { code }, (r, e) => {
			if (r) {
				setTeamJoined(true);
				setLoader();
			} else if (e) {
				Toast.handleError(e);
				setLoader();
			}
		});
	};

	if (TeamJoined) {
		return (
			<div className="h-full w-full align-center justify-center">
				<div className="w-full max-w-lg text-center p-4">
					<img src={Football} alt="Happy dog" className="max-w-xs rounded-full m-auto mb-8" />
					<Logo className="justify-center" />
					<div className="text-5xl font-bold mt-6">Now a Team Player</div>
					<div className="text-sm mt-3">Your have successfully joined {by}'s team, login to your account</div>
					<Button onClick={logout} className="mt-8 m-auto">
						Sign in to your account
					</Button>
				</div>
			</div>
		);
	}

	return (
		<>
			<div className="h-full w-full align-center justify-center">
				<div className="w-full max-w-sm text-center p-4">
					<img src={Rainbow} alt="Rainbow" className="m-auto h-40 floating" />
					<div className="text-5xl font-bold mt-6">Join the Team</div>
					<div className="text-sm mt-3">You have been invited by {by} to join their company on team's plan</div>
					<div className="border text-start bg-whitish rounded-lg p-6 mt-8">
						<h5>When you join their team, they will be able to</h5>
						<div className="flex flex-col gap-y-1 text-sm mt-4">
							<div>✅ Manage your travel bookings</div>
							<div>✅ Manage your expenses</div>
							<div>✅ Issue Reimbursements</div>
							<div>✅ Manage your account</div>
							<div>✅ Enforce policies on your account</div>
						</div>
					</div>
					<Button onClick={onSubmit} loading={Loader} className="w-full mt-6" variant="primary">
						Join the team
					</Button>
				</div>
			</div>
		</>
	);
}

export default JoinTeam;
