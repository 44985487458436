import React, { useState } from "react";
import { AnimatedTick, Button, ViewBooking } from "../../../../../../Components";

function Success({ booking = {} }) {
	const [BookingId, setBookingId] = useState();

	return (
		<div className="w-full h-full flex-center bg-light">
			<div className="bg-canvas shadow-sm border rounded-xl p-8">
				<div className="h-20 flex-center">
					<AnimatedTick />
				</div>
				<div className="text-center mt-4">
					<h1>Booking Confirmed</h1>
					<div className="text-label mt-4">
						We are confirming your booking with the airline, <br />
						you can see your to know the status
					</div>
					<Button onClick={() => setBookingId(booking.bookingId)} variant="outlined" className="m-auto mt-8">
						View Booking
					</Button>
					<ViewBooking id={BookingId} type='stay' onClose={() => setBookingId()} />
				</div>
			</div>
		</div>
	);
}

export default Success;
