import React from "react";
import { Button, Modal, Dialog, Toast, Input, Loader, CustomInputs, ReplyInChat } from "../../..";
import RequestTypes from "./Types";
import { useState } from "react";
import { useEffect } from "react";
import RequestDetails from "./request";
import { apis, get, post } from "../../../../Utils/axios";
import { useUserConfig } from "../../../../Hooks/useUserConfig";
import OpenBooking from "./booking";

function ViewRequest({ requestId, onClose = () => {}, onReload = () => {} }) {
	const { checkUserPermission } = useUserConfig();
	const [Request, setRequest] = useState({
		choices: [],
		amount: "",
		type: "",
		status: "",
		date: "",
		bookingDate: "",
		id: "",
		customInput: {},
		requestedBy: {},
	});

	const [Loading, setLoading] = useState();
	const [Remarks, setRemarks] = useState("");
	const [ConfirmDialog, setConfirmDialog] = useState();

	useEffect(() => {
		if (requestId) {
			fetchRequestDetails(requestId);
		}
	}, [requestId]);

	const fetchRequestDetails = (requestId) => {
		setLoading(true);
		get(apis.getRequestDetails, { requestId }, (r, e) => {
			if (r) {
				setRequest(r);
				setLoading(false);
			} else if (e) {
				Toast.handleError(e);
				setLoading(false);
			}
		});
	};

	const declineRequest = () => {
		if (!Remarks) {
			return Toast.error("Please enter remarks");
		}
		post(apis.denyBookingRequest, { requestId, remarks: Remarks }, (r, e) => {
			if (r) {
				setConfirmDialog();
				Toast.success(`Booking request declined`);
				fetchRequestDetails(requestId);
				setLoading(false);
				onReload();
			} else if (e) {
				Toast.handleError(e);
				setLoading(false);
			}
		});
	};

	const withdrawnRequest = () => {
		post(apis.withdrawBookingRequest, { requestId }, (r, e) => {
			if (r) {
				setConfirmDialog();
				Toast.success(`Booking request withdrawn`);
				fetchRequestDetails(requestId);
				setLoading(false);
				onReload();
			} else if (e) {
				Toast.handleError(e);
				setLoading(false);
			}
		});
	};

	const replyInChat = () => {
		//Parsing request data to booking type
		let content;
		if (Request.type === "flight") {
			const { onwardFlight, returnFlight, flight, type } = Request.choices[0];
			if (type === "round") {
				const { departureCode, departureTime, arrivalTime, arrivalCode, airlineCode, flightNumber } = onwardFlight.segments[0];
				const { departureTime: returnDate } = returnFlight.segments[0];
				content = { airlineCode, flightNumber, departureCode, departureTime, arrivalCode, arrivalTime, returnDate };
			} else {
				const { departureCode, departureTime, arrivalTime, arrivalCode, airlineCode, flightNumber } = flight.segments[0];
				content = { airlineCode, flightNumber, departureCode, departureTime, arrivalCode, arrivalTime };
			}
		} else if (Request.type === "stay") {
			const { hotel, checkIn, checkOut } = Request.choices[0];
			const { photo, rating, amount, name, address, locality } = hotel;
			content = { photo, rating, amount, name, address, checkIn, checkOut, locality };
		} else if (Request.type === "cab") {
			const { pickupTime, amount, pickup, drop, variant, bookingId, booking } = Request.choices[0];
			content = { pickupTime, amount, pickup, drop, variant, bookingId, booking };
		} else if (Request.type === "train") {
			const { amount, board, drop, coach, date, bookingId, booking } = Request.choices[0];
			content = { date, amount, board, drop, coach, bookingId, booking };
		}
		return {
			content: {
				...content,
				id: requestId,
				adminRequest: true,
				type: Request.type,
				status: Request.status,
			},
			type: "request",
			userId: Request?.requestedBy?.id,
		};
	};

	return (
		<>
			<Modal open={requestId} onClose={onClose} contentClass="!max-w-4xl !bg-light">
				<div className="flex flex-col p-6 gap-y-4">
					<div className="flex-center-between">
						<h1>Travel Request</h1>
						{Request.status === "requested" ? (
							checkUserPermission("TA") ? (
								<div className="align-center gap-x-2">
									{/* <TagBooking /> */}
									<Button onClick={() => setConfirmDialog("cancel")} variant="danger">
										Cancel Request
									</Button>
									<ReplyInChat onClick={replyInChat} />
								</div>
							) : (
								<Button onClick={() => setConfirmDialog("withdraw")} variant="danger">
									Withdraw
								</Button>
							)
						) : null}
					</div>
					<div className="grid grid-cols-5 gap-x-4 mt-2 max-h-[600px]">
						<div className="flex flex-col col-span-2 overflow-y-auto gap-y-4">
							<RequestDetails {...Request} />
							{Request?.notes && (
								<div className="p-4 rounded-lg shadow-sm bg-canvas">
									<label>Notes from user</label>
									<div className="text-label">{Request?.notes}</div>
								</div>
							)}
						</div>
						<div className="flex flex-col col-span-3 overflow-y-auto gap-y-4">
							<OpenBooking {...Request} />
							<RequestTypes
								request={Request}
								onReload={() => {
									onReload();
									fetchRequestDetails(requestId);
								}}
							/>
							<CustomInputs className="p-4 rounded-md shadow-sm bg-canvas" fields={Request.customInput?.fields} />
						</div>
					</div>
				</div>
				<Loader loading={Loading} />
				<Dialog variant="danger" open={ConfirmDialog === "cancel"} title="Decline Request" description="Are you sure you want to decline this request" submitLabel="Decline" onSubmit={declineRequest} onClose={setConfirmDialog}>
					<div className="px-6">
						<Input onChange={setRemarks} value={Remarks} placeholder="Reason for cancel" required />
					</div>
				</Dialog>
				<Dialog variant="danger" open={ConfirmDialog === "withdraw"} title="Withdraw Request" description="Are you sure you want to withdraw this request" submitLabel="Withdraw" onSubmit={withdrawnRequest} onClose={setConfirmDialog} />
			</Modal>
		</>
	);
}

export default ViewRequest;
