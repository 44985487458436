import React, { useState } from "react";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { FileInput, Img, ReceiptStore } from "../../../../../../Components";
import apis from "../../../../../../Utils/apis";

function ExpenseAttachments({ attachments, runningOCR, updateAttachments }) {
	const [ActiveIndex, setActiveIndex] = useState(0);

	if (attachments.length) {
		return (
			<>
				<div className="w-full h-[100vh] flex flex-col p-[4vh] gap-[4vh]">
					<div className="h-[78vh] max-w-fit m-auto rounded-2xl overflow-hidden relative">
						<img src={attachments[ActiveIndex]?.file?.url} alt="attachment" className="h-full object-cover" />
						{runningOCR && (
							<div className="absolute left-0 top-0 right-0 bottom-0 bg-black frosted flex-center">
								<div>
									<div className="ocr-animation" />
									<div className="text-white text-center text-xs mt-6">Reading Receipt</div>
								</div>
							</div>
						)}
					</div>
					<div className="h-[10vh] flex-center gap-4 flex-nowrap scrollbar-hidden">
						{attachments.map((attachment, index) => (
							<div className="relative group">
								<Img onClick={() => setActiveIndex(index)} key={index} src={attachment?.file?.url} className={`w-[10vh] h-[10vh] hover:scale-110 cursor-pointer transition-all rounded-xl ${ActiveIndex === index ? "ring-2 ring-primary" : ""}`} />
								<div onClick={() => updateAttachments((prev) => prev.filter((a) => a.file.url !== attachment.file.url))} className="hidden text-sm group-hover:flex bg-dangerText absolute top-1 right-1 rounded-full text-white p-2 cursor-pointer">
									<FiTrash2 />
								</div>
							</div>
						))}
						<div className="flex flex-col gap-2">
							<ReceiptStore onSelect={(file) => updateAttachments((prev) => [...prev, { file }])} />
							<FileInput url={apis.uploadReceipt} data={{}} onUpload={(obj) => updateAttachments((prev) => [...prev, obj])}>
								<fiv className="btn btn-dark btn-sm cursor-pointer gap-2 w-full">
									<FiPlus /> Upload
								</fiv>
							</FileInput>
						</div>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<div>
				<FileInput url={apis.uploadReceipt} data={{}} onUpload={(obj) => updateAttachments((prev) => [...prev, obj])}>
					<div className="text-secondary cursor-pointer">
						<div className="m-auto w-min p-4 mb-4 border border-secondary border-dashed rounded-lg">
							<FiPlus className="text-4xl" />
						</div>
						Drop file or Click here to upload
					</div>
				</FileInput>
				<ReceiptStore className="m-auto mt-10" onSelect={(file) => updateAttachments((prev) => [...prev, { file }])} />
			</div>
		</>
	);
}

export default ExpenseAttachments;
