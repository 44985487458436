import React from "react";
import FlightRequest from "./flight";
import StayRequest from "./stay";
import CabRequest from "./cab";
import TrainRequest from "./train";

function RequestTypes({ request = {}, onClose = () => {}, onReload = () => {} }) {
	const type = request.type;

	if (type === "flight") {
		return <FlightRequest {...request} onClose={onClose} onReload={onReload} />;
	}
	if (type === "stay") {
		return <StayRequest {...request} onClose={onClose} onReload={onReload} />;
	}
	if (type === "cab") {
		return <CabRequest {...request} onClose={onClose} onReload={onReload} />;
	}
	if (type === "train") {
		return <TrainRequest {...request} onClose={onClose} onReload={onReload} />;
	}

	return null;
}

export default RequestTypes;
