import React, { useEffect, useState } from "react";
import { apis, get } from "../../Utils/axios";
import { StampToDate } from "../../Utils/utils";
import moment from "moment";

function Weather({ place, className = "" }) {
	const [CurrentWeather, setCurrentWeather] = useState({
		current: {
			temperature: "",
			weather_descriptions: [],
			weather_icons: [],
			pressure: "",
			humidity: "",
			cloudcover: "",
		},
		location: {
			name: "",
		},
		request: {},
		loading: true,
	});

	useEffect(() => {
		if (place) {
			getWeather(place);
		}
	}, [place]);

	const getWeather = (query) => {
		get(apis.getWeather, { query }, (r, e) => {
			if (r) {
				setCurrentWeather(r);
			} else if (e) {
				console.log(e);
			}
		});
	};

	return (
		<div className={`p-4 border rounded-md ${className}`}>
			<div>
				<div className="flex justify-between">
					<h3 className="capitalize">{place?.split(",")[0]}</h3>
					<div className="text-sm">{StampToDate(moment().valueOf())}</div>
				</div>
				{CurrentWeather.loading ? <div className="text-label">Checking Weather...</div> : <div className="text-4xl">{CurrentWeather?.current?.temperature}°C</div>}
				<div className="align-center">{CurrentWeather?.current?.weather_descriptions.join(", ")}</div>
			</div>
		</div>
	);
}

export default Weather;
