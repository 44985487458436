import React from "react";
import { FaPlane } from "react-icons/fa";
import { StampTo24hr, getTimeBetweenSegments } from "../../../../../../../../Utils/utils";
import { Img, Modal } from "../../../../../../../../Components";

function Layovers({ segments, onClose = () => {} }) {
	return (
		<>
			<Modal contentClass="!bg-light" open={segments} onClose={onClose}>
				<h2 className="p-4">{segments?.length - 1} Layover</h2>
				<div className="flex flex-col gap-2 rounded-lg overflow-hidden">
					{segments?.map((segment, i) => {
						const duration = segment.duration / 60;
						const durationHours = Math.floor(duration / 60);
						const durationMinutes = duration % 60;
						const layoverTime = getTimeBetweenSegments(segments)
						const layoverHours = Math.floor(layoverTime / 60);
						const layoverMinutes = layoverTime % 60;

						return (
							<>
							{
								i !== 0 && <div key={i} className="text-label text-center">
									{layoverHours} Hr {layoverMinutes} Min Layover at {segment.departureCode}
								</div>
							}
							<div key={i} className={`flex-grow p-4 align-center gap-x-4 border-t border-b bg-canvas`}>
								<div className="">
									<Img src={segment.logo} alt={segment.airlineCode} className="w-10 h-10 rounded-full !bg-canvas" />
									<div className="text-xs text-secondary whitespace-nowrap mt-2">
										{segment.airlineName} <br />
										{segment.airlineCode}-{segment.flightNumber}
									</div>
								</div>
								<div className="max-w-[8rem]">
									<div className="font-bold whitespace-nowrap text-xl">{StampTo24hr(segment.departureTime)}</div>
									<div className="text-secondary text-sm">{segment.departureCode}</div>
									<div className="text-xs text-secondary">Terminal {segment.departureTerminal}</div>
								</div>
								<div className="flex-grow min-w-[5rem]">
									<div className="text-center mb-2 text-xs text-secondary whitespace-nowrap">
										{durationHours}Hr {durationMinutes}m
									</div>
									<div className="align-center">
										<div className="w-2 h-2 rounded-full border border-dark" />
										<div className="flex-grow border-t border-muted border-dashed" />
										<FaPlane />
										<div className="flex-grow border-t border-muted border-dashed" />
										<div className="w-2 h-2 rounded-full border border-dark" />
									</div>
								</div>
								<div className="max-w-[8rem]">
									<div className="font-bold whitespace-nowrap text-xl">{StampTo24hr(segment.arrivalTime)}</div>
									<div className="text-secondary text-sm">{segment.arrivalCode}</div>
									<div className="text-xs text-secondary">Terminal {segment.arrivalTerminal}</div>
								</div>
							</div>
							</>
						);
					})}
				</div>
			</Modal>
		</>
	);
}

export default Layovers;
