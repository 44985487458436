import React, { useState } from "react";
import { StaySearch } from "../../../../../Components";
import { Chips } from "../../../../../Components";
import { FiZap } from "react-icons/fi";

function StaysHome() {
	const CITIES = [
		{
			label: "Delhi",
			value: {
				place_id: "ChIJLbZ-NFv9DDkRQJY4FbcFcgM",
				label: "Delhi, India",
				value: "Delhi, India",
				lat: 28.7040592,
				lng: 77.10249019999999,
			},
		},
		{
			label: "Mumbai",
			value: {
				place_id: "ChIJwe1EZjDG5zsRaYxkjY_tpF0",
				label: "Mumbai, Maharashtra, India",
				value: "Mumbai, Maharashtra, India",
				lat: 19.0759837,
				lng: 72.8776559,
			},
		},
		{
			label: "Bangalore",
			value: {
				place_id: "ChIJbU60yXAWrjsR4E9-UejD3_g",
				label: "Bengaluru, Karnataka, India",
				value: "Bengaluru, Karnataka, India",
				lat: 12.9715987,
				lng: 77.5945627,
			},
		},
		{
			label: "Pune",
			value: {
				place_id: "ChIJARFGZy6_wjsRQ-Oenb9DjYI",
				label: "Pune, Maharashtra, India",
				value: "Pune, Maharashtra, India",
				lat: 18.5204303,
				lng: 73.8567437,
			},
		},
		{
			label: "Ahmedabad",
			value: {
				place_id: "ChIJSdRbuoqEXjkRFmVPYRHdzk8",
				label: "Ahmedabad, Gujarat, India",
				value: "Ahmedabad, Gujarat, India",
				lat: 23.022505,
				lng: 72.5713621,
			},
		},
	];

	const [City, setCity] = useState();

	return (
		<>
			<div className="p-4">
				<StaySearch city={City} />
				<div className="align-center mt-6">
					<div className="text-sm align-center mr-2 whitespace-nowrap">
						<FiZap className="mr-1" />
						Trending Cities
					</div>
					<Chips options={CITIES} onSelect={(option) => setCity(option.value)} />
				</div>
				<div className="mt-8">
					<h3>Popular in Delhi</h3>
					
				</div>
			</div>
		</>
	);
}

export default StaysHome;
