import React from "react";
import { Badge } from "..";
import { Tooltip } from "react-tooltip";

function OutOfPolicyBadge({ inPolicy, violations = [] }) {
	return (
		<>
			<div data-tooltip-id="ask-approval" data-tooltip-content={violations.join(", ")}>
				<Badge variant="danger" className="mb-2">
					Out of Policy
				</Badge>
				<Tooltip id="ask-approval" className="text-xs" />
			</div>
		</>
	);
}

export default OutOfPolicyBadge;
