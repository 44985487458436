import React from "react";
import { FaCar } from "react-icons/fa";
import Status from "./status";

function Cab({ drop, status }) {
	return (
		<>
			<div className="group shadow-sm border rounded-full pr-3 align-center gap-2 text-sm font-semibold cursor-pointer whitespace-nowrap">
				<Status status={status} />
				<FaCar /> {drop.split(" ")[0]}
			</div>
		</>
	);
}

export default Cab;
