import React from "react";
import useUserProfile from "../../../../Hooks/useUserProfile";
import { Button, UploadPhoto, Toast } from "../../../../Components";
import ApprovalTasks from "./approvals";
import CoverPhoto from "./cover";
import Timeline from "./Timeline";
import { FiLogOut, FiSettings } from "react-icons/fi";
import BookingsCount from "./bookingsCount";
import { useNavigate } from "react-router-dom";
import { useClientSettings } from "../../../../Hooks/useClientSettings";
import AtAGlance from "./glance";
import { apis, patch } from "../../../../Utils/axios";
import { useLoginHook } from "../../../../Hooks/useLoginHook";

function Dashboard() {
	const NAVIGATE = useNavigate();
	const { logout } = useLoginHook();
	const { profile, reload } = useUserProfile();
	const { isEnabled } = useClientSettings();
	const teamsPlan = isEnabled("ORGANIZATION") && isEnabled("POLICY");

	const onUpdate = (obj) => {
		patch(apis.updateMyProfile, { photo: obj.file.url }, (r, e) => {
			if (r) {
				reload();
				Toast.success(`Photo updated`);
			} else if (e) {
				console.log(e);
				Toast.handleError(e);
			}
		});
	};

	return (
		<>
			<CoverPhoto />
			<div className="p-4">
				<div className="flex-center-between">
					<div className="gap-4 align-center">
						<UploadPhoto size="lg" photo={profile.photo} onUpdate={onUpdate} />
						<div>
							<h1 className="text-2xl">Hello {profile.fName},</h1>
							<div className="text-sm text-secondary">Welcome back to your account</div>
						</div>
					</div>
					<div className="flex gap-2">
						<Button onClick={() => NAVIGATE("/app/account")} variant="outlined" className="btn-sm">
							<FiSettings className="mr-2" />
							Profile
						</Button>
						<Button onClick={logout} variant="outlined" className="btn-sm">
							<FiLogOut className="mr-2" />
							Sign Out
						</Button>
					</div>
				</div>
				<Timeline />
				{teamsPlan ? <AtAGlance /> : <BookingsCount />}
				{teamsPlan && <ApprovalTasks />}
			</div>
		</>
	);
}

export default Dashboard;
