import React from "react";
import { Attachments, KeyValue } from "../../..";
import { StampToDate } from "../../../../Utils/utils";

function ReceiptApproval({ receipt }) {
	const { id, date, amount, category, description } = receipt;

	return (
		<>
			<div className="bg-canvas shadow-sm rounded-lg p-4">
				<h4>Receipt Details</h4>
				<div className="flex flex-col gap-y-2 mt-4">
					<KeyValue label="Receipt ID">{id}</KeyValue>
					<KeyValue label="Date">{StampToDate(date)}</KeyValue>
					<KeyValue label="Amount">₹ {amount}</KeyValue>
					<KeyValue label="Category">{category?.name}</KeyValue>
					<KeyValue label="Purpose">{description}</KeyValue>
				</div>
				{receipt.attachments?.length > 0 && (
					<>
						<h4 className="mt-4">Attachments</h4>
						<Attachments className="mt-4" files={receipt.attachments} />
					</>
				)}
			</div>
		</>
	);
}

export default ReceiptApproval;
