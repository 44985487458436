import React, { useEffect, useRef, useState } from "react";
import { Portal, Searchbox, Toast } from "../Library";
import { apis, post } from "../../Utils/axios";
import Messages from "./messages";
import Shortcuts from "./shortcuts";
import QueryBox from "./queryBox";

function CommandPalette() {
	// const msgSound = new Audio(MsgSound);
	const [ShowPalette, setShowPalette] = useState(false);
	const [AskAI, setAskAI] = useState(false);

	const [MockMessages, setMockMessages] = useState([]);
	const [Chats, setChats] = useState([]);

	const [MathAnswer, setMathAnswer] = useState();

	const [Query, setQuery] = useState("");
	const [Loading, setLoading] = useState(false);
	const SEARCH_BOX = useRef();
	const SCROLLER = useRef();

	useEffect(() => {
		document.addEventListener("keydown", (e) => {
			if (e.key === "Escape") onClose();
		});
	}, []);

	//Auto scroll to bottom
	useEffect(() => {
		if (SCROLLER.current) {
			SCROLLER.current.scrollTop = SCROLLER.current.scrollHeight;
		}
	}, [Chats, AskAI]);

	const onBtnClick = () => {
		if (AskAI && Query) {
			setQuery("");
			setChats((prev) => [...prev, { content: Query, role: "user" }]);
			askGpt([...Chats, { content: Query, role: "user" }]);
			return;
		}
		switchToAI();
	};

	const onChange = (e) => {
		const query = e.target.value;
		setQuery(query);
		if (AskAI) return;
		if (!query) return clearResult();
		calculateAnswer(query);
	};

	const askGpt = (chats) => {
		setLoading(true);
		post(apis.askGpt, { chats }, (r, e) => {
			if (r) {
				setChats((prev) => [...prev, ...r]);
				setLoading(false);
			} else if (e) {
				Toast.handleError(e);
				setLoading(false);
			}
		});
	};

	const calculateAnswer = (query) => {
		if (query.startsWith("=")) {
			try {
				setMathAnswer(eval(query.slice(1)));
			} catch (error) {
				return;
			}
		}
	};

	const onClose = () => {
		if (AskAI) return setAskAI(false);
		setShowPalette(false);
		clearResult();
	};

	const clearResult = () => {
		setMathAnswer();
		setAskAI(false);
		setMockMessages([]);
	};

	const textToHtml = (text) => {
		const wrapper = document.createElement("div");
		wrapper.innerHTML = text.trim();
		return wrapper.firstChild;
	};

	const switchToAI = () => {
		setAskAI(true);
		SEARCH_BOX.current.focus();
		//For dramatic effect
		if (!Chats.length) {
			setTimeout(() => {
				setMockMessages([
					{
						content: "Hii I'm Trippy, You can ask me questions about travel analytics",
						role: "assistant",
					},
				]);
			}, 500);
		}
	};

	return (
		<>
			<Searchbox onFocus={() => setShowPalette(true)} placeholder="Search" />
			{ShowPalette && (
				<Portal>
					<div onClick={onClose} className={`fade-in fixed z-30 top-0 bottom-0 left-0 right-0 z-99 flex justify-center bg-[rgba(0,0,0,0.65)] p-6`}>
						<div onClick={(e) => e.stopPropagation()} className={`max-w-[34rem] w-full flex flex-col gap-4 p-6 fade-in-down transition-all ease-out duration-300 rounded-3xl ${AskAI ? "bg-[rgba(20,20,20,1)]" : "bg-transparent"}`}>
							<div className={`transition-all ease-out duration-300 flex-center font-bold text-lg text-white ${AskAI ? "pt-0" : "pt-24"}`}>
								The Payment Project
								<div className={`w-3 h-3 ml-2 rounded-full bg-primary`}></div>
							</div>
							<div className={`overflow-hidden transition-all ease-out duration-300 ${AskAI ? "h-[100%] opacity-1" : "h-0 opacity-0"} flex-center`}>
								<div ref={SCROLLER} className="flex flex-col w-full h-full gap-4 overflow-y-auto scrollbar-hidden">
									<div className="flex-grow" />
									<Messages messages={[...MockMessages, ...Chats]} loading={Loading} />
								</div>
							</div>
							<div className="rounded-lg bg-canvas">
								<QueryBox askAI={AskAI} onChange={onChange} onClick={onBtnClick} query={Query} handleRef={SEARCH_BOX} />
								{!AskAI && (
									<>
										{MathAnswer && <div className="p-3 pt-0 text-3xl font-bold fade-in-down">{MathAnswer}</div>}
										<Shortcuts query={Query} />
									</>
								)}
							</div>
						</div>
					</div>
					{!AskAI && <div className="fixed left-0 right-0 z-30 text-xs text-center text-white fade-in-up bottom-10">Press Esc To Close</div>}
				</Portal>
			)}
		</>
	);
}

export default CommandPalette;
