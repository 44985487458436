import React from "react";
import CabDetails from "./cabDetails";
import ImportBooking from "../../../../ImportBooking";

function CabRequest({ id, choices, status, onReload = () => {} }) {
	const request = choices[0];

	return (
		<>
			{status === "requested" && <ImportBooking onReload={onReload} type="cab" requestId={id} btnLabel="Add Booking" btnVariant="outlined" />}
			<div className="p-4 rounded-lg shadow-sm bg-canvas">
				<div className="mb-4 flex-center-between">
					<h4>Cab Details</h4>
				</div>
				<CabDetails {...request} />
			</div>
		</>
	);
}

export default CabRequest;
