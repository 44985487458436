import { useSelector, useDispatch } from "react-redux";
import { CONFIG_ACTIONS } from "../Redux/Reducers/configuration";
import { useEffect } from "react";
import { apis, get } from "../Utils/axios";

//Fetching flag to prevent multiple requests
let Fetching = false;

function useUserConfig() {
	const PERMISSIONS = {
		TA: "isTa",
		ADMIN: "isAdmin",
	};

	const dispatch = useDispatch();

	let config = useSelector((state) => state.configuration);

	useEffect(() => {
		if (!Fetching && config?.userConfig.loading) {
			Fetching = true;
			get(apis.getMyConfig, {}, (r, e) => {
				if (r) {
					dispatch({
						type: CONFIG_ACTIONS.userConfig,
						data: {
							userConfig: {
								...r,
								loading: false,
							},
						},
					});
					Fetching = false;
				} else if (e) {
					console.log(e);
					Fetching = false;
				}
			});
		}
	}, []);

	return {
		...config,
		switchRole: (value) => {
			dispatch({
				type: CONFIG_ACTIONS.role,
				data: { role: value },
			});
		},
		checkUserPermission: (permission) => {
			if (config?.userConfig[PERMISSIONS[permission]]) {
				return true;
			}
			return false;
		},
	};
}

export { useUserConfig };
